import { FC, memo } from 'react';

import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import { useSvgIcon } from 'u9/hooks';
import { colors } from 'utils/styles/theme';

import * as Styled from './DropdownWarning.styles';

export interface DropdownWarningProps {
  title: string;
  text: string;
}

const DropdownWarning: FC<DropdownWarningProps> = ({ title, text }) => {
  const { SvgIcon: WarningIcon } = useSvgIcon('warning');

  return (
    <Styled.DropdownWarningWrapper>
      <CardDropdown
        button={
          <Styled.DropdownWarningIcon>
            <WarningIcon />
          </Styled.DropdownWarningIcon>
        }
        cardProps={{
          title,
          text,
          type: 'warning',
          cardStyles: {
            background: colors.black,
          },
        }}
        clickType="hover"
      />
    </Styled.DropdownWarningWrapper>
  );
};

export default memo(DropdownWarning);
