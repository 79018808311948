import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useCallback } from 'react';

import { useCurrentVersionMode } from 'components/modules/Project/hooks/useCurrentVersionMode';
import { useProjectStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import {
  Project,
  ProjectMode,
  ProjectsListItem,
  ProjectStatus,
} from 'utils/api.types';
import { ROUTES } from 'utils/routes';

export const useProjectAccess = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const { currentVersionMode } = useCurrentVersionMode();
  const isPitch = currentVersionMode === ProjectMode.pitch;
  const { checkAnyAccessRight } = useCheckAccessRights();
  const forceEditModeId = useProjectStore(s => s.forceEditModeId);

  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);
  const isAtLeastProducer = checkAnyAccessRight([
    'executive producer',
    'administrator',
    'owner',
    'super admin',
  ]);

  const getIsProjectClosed = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return (
        project?.status === ProjectStatus.closed ||
        project?.status === ProjectStatus.cancelled
      );
    },
    []
  );

  const getIsProjectOwner = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return session?.user?.email === project?.producer_email;
    },
    [session?.user?.email]
  );

  const getCanEditProjectByPermission = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return getIsProjectOwner(project) || isAdmin || project?.project_shared;
    },
    [getIsProjectOwner, isAdmin]
  );

  const getIsTryingToEditPitchInNotPitchStatus = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      if (router.route === ROUTES.PROJECTS_LIST) return false;
      return isPitch && project?.mode !== ProjectMode.pitch;
    },
    [isPitch, router.route]
  );

  const getCanEditProject = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      if (forceEditModeId === project.id) return true;
      return (
        !getIsProjectClosed(project) &&
        !getIsTryingToEditPitchInNotPitchStatus(project) &&
        getCanEditProjectByPermission(project)
      );
    },
    [
      forceEditModeId,
      getCanEditProjectByPermission,
      getIsProjectClosed,
      getIsTryingToEditPitchInNotPitchStatus,
    ]
  );

  const getCanRequestProjectPermission = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return !isAdmin && !getCanEditProjectByPermission(project);
    },
    [getCanEditProjectByPermission, isAdmin]
  );

  const getCanShareProjectAccess = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return (
        getCanEditProjectByPermission(project) &&
        (isAtLeastProducer || getIsProjectOwner(project))
      );
    },
    [getCanEditProjectByPermission, getIsProjectOwner, isAtLeastProducer]
  );

  const getCanChangeProjectStatus = useCallback(() => {
    return isAdmin;
  }, [isAdmin]);

  const getCanEditJobNumber = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return isAdmin || project.mode === ProjectMode.pitch;
    },
    [isAdmin]
  );

  const getCanEditVertical = useCallback(
    (project?: ProjectsListItem | Project) => {
      if (!project) return false;
      return isAdmin || project.mode === ProjectMode.pitch;
    },
    [isAdmin]
  );

  return {
    getIsTryingToEditPitchInNotPitchStatus,
    getIsProjectClosed,
    getIsProjectOwner,
    getCanEditProjectByPermission,
    getCanEditProject,
    getCanRequestProjectPermission,
    getCanShareProjectAccess,
    getCanChangeProjectStatus,
    getCanEditJobNumber,
    getCanEditVertical,
  };
};
