import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  max-height: ${setVh(60)};
  width: 1000rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 50rem;
`;

export const Divider = styled.div`
  width: 50rem;
`;

export const ExpenseInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20rem;
`;
