import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useCreateNotification } from 'queries/notifications';
import {
  useCurrentProject,
  useProjectClosureStatus,
  useUpdateProject,
} from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectStatus } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './HandleJobClosureRequestModal.styles';

export interface HandleJobClosureRequestModalProps {
  onCloseSuccess?: () => void;
}

const HandleJobClosureRequestModal: FC<HandleJobClosureRequestModalProps> = ({
  onCloseSuccess,
}) => {
  const router = useRouter();
  const closeModal = useGlobalStore(s => s.closeModal);
  const openModal = useGlobalStore(s => s.openModal);
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const currentProject = useCurrentProject();
  const { data: projectClosureStatus } = useProjectClosureStatus({
    projectId: currentProject?.id,
  });
  const { mutateAsync: updateProject } = useUpdateProject();
  const { mutateAsync: sendNotification } = useCreateNotification();

  const isAlreadyClosed = currentProject?.status === ProjectStatus.closed;

  const contractsFolderMissing = projectClosureStatus?.issues?.some(
    ({ code }) => code === 'contract_missing'
  );
  const invoiceStatusMissing = projectClosureStatus?.issues?.some(
    ({ code }) => code === 'invoice_status'
  );

  const close = () => {
    closeModal('handleJobClosureRequestModal');
  };

  const handleConfirm = async () => {
    if (!currentProject?.id) return;
    await updateProject({
      projectId: currentProject?.id,
      project: {
        locked: false,
        status: ProjectStatus.closed,
      },
    });
    onCloseSuccess?.();
    if (router.query.receiverUserId) {
      const receiverUserId = Number(router.query.receiverUserId);
      await sendNotification({
        msg_code: 'job_closure.approved',
        projectId: currentProject?.id,
        receiver_user_id: receiverUserId,
      });
    }
    delete router.query.approveJobClosureModal;
    delete router.query.receiverUserId;
    router.replace(
      {
        query: router.query,
      },
      undefined,
      { shallow: true }
    );
    close();
  };

  const handleToggleLock = async () => {
    if (!currentProject?.id) return;
    await updateProject({
      projectId: currentProject?.id,
      project: {
        locked: !currentProject?.locked,
      },
    });
  };

  const openContracts = () => {
    window.open(currentProject?.contract_url, '_blank');
  };

  const openBudgetModal = () => {
    openModal('budgetModal');
  };

  return (
    <ConfigModal
      title={copy.project.handleJobClosureRequestModal.title}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        {isAlreadyClosed ? (
          <div>
            {copy.project.handleJobClosureRequestModal.alreadyFilledMessage}
          </div>
        ) : (
          <>
            <Styled.Description>
              {projectClosureStatus?.closable
                ? copy.project.handleJobClosureRequestModal.allDone
                : copy.project.handleJobClosureRequestModal.conditionsNotMet}
            </Styled.Description>

            <Styled.ConditionList>
              <Styled.ConditionItem>
                <Styled.ConditionItemStart>
                  <Styled.ConditionItemText>
                    {
                      copy.project.handleJobClosureRequestModal
                        .contractsConditionLabel
                    }
                  </Styled.ConditionItemText>
                  {'-'}
                  <Styled.ConditionItemStatus isDone={!contractsFolderMissing}>
                    {contractsFolderMissing
                      ? copy.project.handleJobClosureRequestModal.actionRequired
                      : copy.project.handleJobClosureRequestModal.done}
                  </Styled.ConditionItemStatus>
                </Styled.ConditionItemStart>
                <Styled.LookupButton onClick={openContracts}>
                  {copy.project.handleJobClosureRequestModal.open}
                </Styled.LookupButton>
              </Styled.ConditionItem>
              <Styled.ConditionItem>
                <Styled.ConditionItemStart>
                  <Styled.ConditionItemText>
                    {
                      copy.project.handleJobClosureRequestModal
                        .invoiceStatusConditionLabel
                    }
                  </Styled.ConditionItemText>
                  {'-'}
                  <Styled.ConditionItemStatus isDone={!invoiceStatusMissing}>
                    {invoiceStatusMissing
                      ? copy.project.handleJobClosureRequestModal.actionRequired
                      : copy.project.handleJobClosureRequestModal.done}
                  </Styled.ConditionItemStatus>
                </Styled.ConditionItemStart>
                <Styled.LookupButton onClick={openBudgetModal}>
                  {copy.project.handleJobClosureRequestModal.open}
                </Styled.LookupButton>
              </Styled.ConditionItem>
              <Styled.ConditionItem>
                <Styled.ConditionItemStart>
                  <Styled.ConditionItemText>
                    {copy.project.handleJobClosureRequestModal.jasLabel}
                  </Styled.ConditionItemText>
                  {'-'}
                  <Styled.ConditionItemStatus isIndeterminate>
                    {copy.project.handleJobClosureRequestModal.indeterminate}
                  </Styled.ConditionItemStatus>
                </Styled.ConditionItemStart>
              </Styled.ConditionItem>
              <Styled.ConditionItem>
                <Styled.ConditionItemStart>
                  <Styled.ConditionItemText>
                    {copy.project.requestJobClosureModal.sowMatchLabel}
                  </Styled.ConditionItemText>
                  {'-'}
                  <Styled.ConditionItemStatus isIndeterminate>
                    {copy.project.requestJobClosureModal.indeterminate}
                  </Styled.ConditionItemStatus>
                </Styled.ConditionItemStart>
              </Styled.ConditionItem>
              <Styled.ConditionItem>
                <Styled.ConditionItemStart>
                  <Styled.ConditionItemText>
                    {copy.project.requestJobClosureModal.invoiceReceivedLabel}
                  </Styled.ConditionItemText>
                  {'-'}
                  <Styled.ConditionItemStatus isIndeterminate>
                    {copy.project.requestJobClosureModal.indeterminate}
                  </Styled.ConditionItemStatus>
                </Styled.ConditionItemStart>
              </Styled.ConditionItem>
            </Styled.ConditionList>
          </>
        )}
        <Styled.ButtonsWrapper>
          <Button
            label={
              currentProject?.locked
                ? copy.project.handleJobClosureRequestModal.unlock
                : copy.project.handleJobClosureRequestModal.lock
            }
            icon="lock"
            color={currentColor}
            onClick={handleToggleLock}
          />
          <Button
            label={
              isAlreadyClosed
                ? copy.project.handleJobClosureRequestModal.alreadyFilledButton
                : copy.project.handleJobClosureRequestModal.saveButton
            }
            color={currentColor}
            icon={'check'}
            disabled={!projectClosureStatus?.closable}
            onClick={handleConfirm}
          />
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(HandleJobClosureRequestModal);
