import { useCallback } from 'react';

import { Column } from '../Table.types';

export const useGetColumnById = (columns: Column[]) => {
  const getColumnById = useCallback(
    (colId: Column['id']) => {
      return (
        columns.find(({ id }) => id === colId) || {
          flex: 1,
          headerLabel: '',
          align: 'left',
        }
      );
    },
    [columns]
  );

  return {
    getColumnById,
  };
};
