import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCopyStore } from 'store';
import { ProjectMode } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

export interface ModeProps {
  modes: ProjectMode[];
  setModes: Dispatch<SetStateAction<ProjectMode[]>>;
}

import * as Styled from '../ProjectsListFiltersModal.styles';

const Mode: FC<ModeProps> = ({ modes, setModes }) => {
  const copy = useCopyStore(s => s.copy);

  const availableModes = [
    {
      id: ProjectMode.pitch,
      name: copy.app.modes.pitch,
    },
    {
      id: ProjectMode.production,
      name: copy.app.modes.production,
    },
    {
      id: ProjectMode.hosting,
      name: copy.app.modes.hosting,
    },
  ];

  const toggleMode = (mode: ProjectMode) => {
    setModes(prev => {
      if (prev.includes(mode)) {
        return prev.filter(m => m !== mode);
      } else {
        return [...prev, mode];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.modes}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(modes.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {modes.length > 0
            ? modes.map(mode => copy.app.modes[mode]).join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {availableModes?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={modes.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleMode(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Mode);
