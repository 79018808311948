import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Report } from 'utils/api.types';

export const FINANCIAL_REPORTS_KEY = 'FINANCIAL_REPORTS';

export const useFinancialReports = (options?: UseQueryOptions<Report[]>) => {
  const getFinancialReports = useApiStore(s => s.apiClient.getFinancialReports);
  return useQuery({
    queryKey: FINANCIAL_REPORTS_KEY,
    queryFn: async () => (await getFinancialReports()).data,
    ...options,
  });
};

export const useGetFinancialReport = () => {
  const getFinancialReport = useApiStore(s => s.apiClient.getFinancialReport);
  return useMutation(
    async (variables: Parameters<typeof getFinancialReport>[0]) =>
      getFinancialReport(variables)
  );
};

export const useGenerateFinancialReport = () => {
  const generateFinancialReport = useApiStore(
    s => s.apiClient.generateFinancialReport
  );
  const queryClient = useQueryClient();
  return useMutation(generateFinancialReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(FINANCIAL_REPORTS_KEY);
    },
  });
};
