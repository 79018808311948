import { useCallback, useMemo } from 'react';

import { useCopyStore } from 'store';
import { Project, ProjectMode, ProjectsListItem } from 'utils/api.types';

export const useJobNumberPrefix = () => {
  const copy = useCopyStore(s => s.copy);
  const prefixMap: Record<ProjectMode, string> = useMemo(
    () => ({
      [ProjectMode.hosting]: copy.app.jobNumberPrefixes.hosting,
      [ProjectMode.pitch]: copy.app.jobNumberPrefixes.pitch,
      [ProjectMode.production]: '',
    }),
    [copy.app.jobNumberPrefixes]
  );

  const getJobNumberPrefix = useCallback(
    (projectMode?: ProjectMode): string => {
      if (projectMode === undefined) return '';
      return prefixMap[projectMode] || '';
    },
    [prefixMap]
  );

  const addJobNumberPrefix = useCallback(
    (jobNumber: Project['job_number'], projectMode?: ProjectMode) => {
      return `${getJobNumberPrefix(projectMode) || ''}${jobNumber}`;
    },
    [getJobNumberPrefix]
  );

  const stripJobNumberPrefix = useCallback((prefixedJobNumber: string) => {
    return prefixedJobNumber.replace(/^\w-/, '');
  }, []);

  const getJobNumberForStatus = useCallback(
    (project: Project | ProjectsListItem) => {
      return project.mode === ProjectMode.pitch
        ? project.pitch_number
          ? addJobNumberPrefix(project.pitch_number, project.mode)
          : '-'
        : project.job_number
        ? addJobNumberPrefix(project.job_number, project.mode)
        : '-';
    },
    [addJobNumberPrefix]
  );

  return {
    getJobNumberPrefix,
    addJobNumberPrefix,
    stripJobNumberPrefix,
    getJobNumberForStatus,
  };
};
