import { useRouter } from 'next/router';
import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCreateProjectVersion, useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project } from 'utils/api.types';
import { getProjectRoute } from 'utils/routes';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './AddProjectVersion.styles';

export interface AddProjectVersionModalProps {
  projectId: Project['id'];
}

const AddProjectVersion: FC<AddProjectVersionModalProps> = ({ projectId }) => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const { data: currentProject } = useProject({ projectId });
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.fieldRequired,
      },
    }),
    [copy.app.validatorMessages.fieldRequired]
  );
  const [
    projectVersion,
    setProjectVersion,
    isProjectVersionValid,
    invalidMessage,
  ] = useField('', validators);

  const { mutateAsync: addProjectVersion, isLoading } =
    useCreateProjectVersion();

  const handleSave = async () => {
    if (!isProjectVersionValid || !currentProject) return;
    const { data: version } = await addProjectVersion({
      projectId,
      input: { name: projectVersion, mode: currentProject.mode },
    });
    router.push(getProjectRoute(projectId, version.id));
    closeModal('addProjectVersion');
  };

  const modalCopy = copy.app.addPitchModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading || !isProjectVersionValid,
        onClick: handleSave,
        dataCy: 'add-project-version-confirm',
      }}
      color={currentColor}
      onCloseClick={() => closeModal('addProjectVersion')}
    >
      <Styled.Wrapper>
        <FormInput
          value={projectVersion}
          onChange={setProjectVersion}
          placeholder={modalCopy.label}
          error={!isProjectVersionValid && invalidMessage ? invalidMessage : ''}
          dataCy="add-project-version-input"
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(AddProjectVersion);
