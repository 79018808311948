import * as SliderPrimitive from '@radix-ui/react-slider';
import { rgba } from 'polished';
import styled from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Root = styled(SliderPrimitive.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 40rem;
`;

export const SliderTrack = styled(SliderPrimitive.Track)`
  position: relative;
  flex-grow: 1;
  height: 6rem;
  background: ${colors.selectiveYellow};

  &[data-disabled] {
    background: ${colors.dustyGray};
  }
`;

export const SliderRange = styled(SliderPrimitive.Range)`
  position: absolute;
  height: 100%;
  background: ${colors.white};

  &[data-disabled] {
    background: ${colors.dustyGray};
  }
`;

export const SliderThumb = styled(SliderPrimitive.SliderThumb)`
  display: block;
  width: 36rem;
  height: 36rem;
  background-color: ${colors.grayNurse};
  border-radius: 50%;

  &:hover {
    ${brightnessFilter}
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.malibu};
  }

  &[data-disabled] {
    background: ${colors.dustyGray};
  }
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.white, 0.5)};
  margin-bottom: 15rem;
`;

export const Top = styled.div``;

export const TopHtmlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10rem;
  ${({ theme: { locale } }) => setTypography('body4', locale)}
`;

export const Start = styled.div``;

export const End = styled.div``;

export const Value = styled.div``;
