import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 50rem;
  margin-left: 80rem;
  margin-bottom: 80rem;
  padding-top: 60rem;
  height: 180rem;
`;

export const StatWrapper = styled.div`
  margin-right: 122rem;
`;

export const StatLabel = styled.div`
  color: ${colors.dustyGray};
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  margin-bottom: 5rem;
`;

export const StatValue = styled.div`
  color: ${colors.white};
  ${({ theme: { locale } }) => setTypography('heading4', locale)}
`;

export const ButtonWrapper = styled.div`
  position: relative;
  top: 50%;
  margin-right: 30rem;
`;

export const StartWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const EndWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const CheckboxWrapper = styled.div``;

export const CardDropdownWrapper = styled.div`
  margin-left: 10rem;
  margin-bottom: 5rem;
`;
