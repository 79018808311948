import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { RoleRequestNotification as RoleRequestNotificationType } from 'utils/api.types';
import { getAdminRoute } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface RoleRequestNotificationProps {
  notification: RoleRequestNotificationType;
}

const RoleRequestNotification: FC<RoleRequestNotificationProps> = ({
  notification,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.roleRequest}
        replacements={[
          ['{user}', notification.extra_info.username],
          ['{role}', notification.extra_info.roleName],
          ['{roleName}', notification.extra_info.roleName],
          [
            '{button}',
            <Link
              href={getAdminRoute('ROLES_REQUESTS')}
              key="button"
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {copy.app.notifications.roleRequestButton}
              </Styled.NotificationInlineButton>
              ,
            </Link>,
          ],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(RoleRequestNotification);
