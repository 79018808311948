import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Vertical } from 'utils/api.types';

export const VERTICALS_KEY = 'VERTICALS';

export const useVerticals = (options?: UseQueryOptions<Vertical[]>) => {
  const getVerticals = useApiStore(s => s.apiClient.getVerticals);
  return useQuery({
    queryKey: VERTICALS_KEY,
    queryFn: async () => (await getVerticals()).data,
    ...options,
  });
};

export const useAddVertical = () => {
  const addVertical = useApiStore(s => s.apiClient.addVertical);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addVertical>[0]) => addVertical(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VERTICALS_KEY);
      },
    }
  );
};

export const useUpdateVertical = () => {
  const updateVertical = useApiStore(s => s.apiClient.updateVertical);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateVertical>[0]) =>
      updateVertical(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VERTICALS_KEY);
      },
    }
  );
};

export const useDeleteVertical = () => {
  const deleteVertical = useApiStore(s => s.apiClient.deleteVertical);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteVertical>[0]) =>
      deleteVertical(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VERTICALS_KEY);
      },
    }
  );
};
