import { useState } from 'react';

import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useCurrentProjectVersion } from 'queries/projectRows';

export const useTotalSlider = () => {
  let minTotal = Infinity;
  let maxTotal = 0;
  const { data: projectVersion } = useCurrentProjectVersion();
  const queryString = useParseProjectQueryString();

  projectVersion?.rows.forEach(row => {
    const total = row.total;
    if (total < minTotal) {
      minTotal = Math.floor(total);
    }
    if (total > maxTotal) {
      maxTotal = Math.ceil(total);
    }
  });

  const [isTotalDirty, setIsTotalDirty] = useState(false);
  const [total, setTotal] = useState([
    queryString.minTotal && queryString.minTotal >= minTotal
      ? queryString.minTotal
      : minTotal,
    queryString.maxTotal && queryString.maxTotal <= maxTotal
      ? queryString.maxTotal
      : maxTotal,
  ]);

  const onTotalChange: typeof setTotal = (...args) => {
    setIsTotalDirty(true);
    setTotal(...args);
  };

  return {
    total,
    setTotal: onTotalChange,
    minTotal,
    maxTotal,
    isTotalDirty,
  };
};
