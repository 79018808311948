import { FC, memo, useState } from 'react';

import Counter from 'components/Counter/Counter';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { AddProjectGroupProps } from 'components/modals/AddProjectGroupModal/AddProjectGroup';
import { MenuItems } from 'components/modules/ProjectsList/hooks/useDropdown';
import { useProjectDropdown } from 'components/modules/ProjectsList/hooks/useProjectDropdown';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCurrentProject } from 'queries/project';
import {
  useAddProjectToGroup,
  useProjectGroups,
  useRemoveProjectFromGroup,
} from 'queries/projectGroups';
import { useCopyStore, useGlobalStore } from 'store';
import { replaceString } from 'utils/replace';

import * as Styled from './ProjectDropdown.styles';

export interface ProjectDropdownProps {}

const ProjectDropdown: FC<ProjectDropdownProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const currentColor = useGlobalStore(s => s.currentColor);
  const openModal = useGlobalStore(s => s.openModal);
  const { getCanShareProjectAccess } = useProjectAccess();
  const [isOpen, setIsOpen] = useState(false);

  const { getJobNumberForStatus } = useJobNumberPrefix();
  const { data: projectGroups } = useProjectGroups();
  const { mutateAsync: addProjectToGroup } = useAddProjectToGroup();
  const { mutateAsync: removeProjectFromGroup } = useRemoveProjectFromGroup();

  const { handleClick, items } = useProjectDropdown({
    currentProject,
  });

  if (!currentProject || currentProject.deleted) return null;

  const isSensitive = !!currentProject?.access;
  const isTest = currentProject?.test_flag || false;
  const accessRequestCount = currentProject?.access_request_count;

  const currentProjectGroup = projectGroups?.find(({ projects }) =>
    projects.some(id => id === currentProject.id)
  );

  const handleGroupChange = async (value: string) => {
    const groupId = Number(value);
    const hasGroup = !!currentProjectGroup?.id;
    const groupChanged = currentProjectGroup?.id !== groupId;
    if (groupChanged) {
      if (hasGroup) {
        removeProjectFromGroup({
          projectId: currentProject.id,
          groupId: currentProjectGroup.id,
        });
      }
      addProjectToGroup({
        projectId: currentProject.id,
        groupId,
      });
    }
  };

  const handleCreateGroup = () => {
    openModal<AddProjectGroupProps>('addProjectGroup');
  };

  const handleRemoveFromGroup = () => {
    if (currentProjectGroup?.id) {
      removeProjectFromGroup({
        projectId: currentProject.id,
        groupId: currentProjectGroup.id,
      });
    }
  };

  return (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger dataCy="project-page-menu__button">
          <Styled.Start>
            {(currentProject?.job_number || currentProject?.pitch_number) && (
              <Styled.HeaderText>
                {getJobNumberForStatus(currentProject)}
              </Styled.HeaderText>
            )}
            {currentProject?.client?.id && (
              <Styled.HeaderText>
                <OverflowEllipsis text={currentProject?.client.name} />
              </Styled.HeaderText>
            )}
            {currentProject?.brand?.id && (
              <Styled.HeaderText>
                <OverflowEllipsis text={currentProject?.brand.name} />
              </Styled.HeaderText>
            )}
            <Styled.HeaderText>
              <OverflowEllipsis text={currentProject?.name} />
            </Styled.HeaderText>
          </Styled.Start>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items.map(({ displayName, id, icon, preventClosing }) =>
            id === MenuItems.hide ? (
              <DropdownMenuCheckboxItem
                key={id}
                icon={icon}
                checked={isSensitive}
                onSelect={event => {
                  event.preventDefault();
                  if (isSensitive || !preventClosing) {
                    setIsOpen(false);
                  }
                  handleClick(id);
                }}
                dataCy={`${id}__menu-item`}
              >
                {displayName}
              </DropdownMenuCheckboxItem>
            ) : id === MenuItems.testProject ? (
              <DropdownMenuCheckboxItem
                key={id}
                icon={icon}
                checked={isTest}
                onSelect={event => {
                  event.preventDefault();
                  if (!preventClosing) {
                    setIsOpen(false);
                  }
                  handleClick(id);
                }}
                dataCy={`${id}__menu-item`}
              >
                {displayName}
              </DropdownMenuCheckboxItem>
            ) : id === MenuItems.projectGroups ? (
              <DropdownMenuSub key={id}>
                <DropdownMenuSubTrigger>
                  <OverflowEllipsis
                    text={
                      currentProjectGroup
                        ? replaceString(
                            copy.projectsList.group,
                            '{name}',
                            currentProjectGroup.name
                          )
                        : displayName
                    }
                  />
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuRadioGroup
                      value={String(currentProjectGroup?.id)}
                      onValueChange={handleGroupChange}
                    >
                      {projectGroups?.map(({ id, name }, index, arr) => (
                        <DropdownMenuRadioItem value={String(id)} key={id}>
                          <OverflowEllipsis text={name} />
                          {index === arr.length - 1 && (
                            <DropdownMenuSeparator />
                          )}
                        </DropdownMenuRadioItem>
                      ))}
                    </DropdownMenuRadioGroup>
                    {currentProjectGroup && (
                      <DropdownMenuItem onSelect={handleRemoveFromGroup}>
                        {copy.projectsList.removeFromGroup}
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem onSelect={handleCreateGroup}>
                      {copy.projectsList.createGroup}
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            ) : (
              <DropdownMenuItem
                key={id}
                icon={icon}
                onSelect={event => {
                  event.preventDefault();
                  if (!preventClosing) {
                    setIsOpen(false);
                  }
                  if (id !== MenuItems.pitchVersions) {
                    handleClick(id);
                  }
                }}
                dataCy={`${id}__menu-item`}
              >
                {displayName}
                {id === MenuItems.share && accessRequestCount > 0 && (
                  <Styled.Counter>
                    <Counter number={accessRequestCount} />
                  </Styled.Counter>
                )}
                {id === MenuItems.info && <DropdownMenuSeparator />}
              </DropdownMenuItem>
            )
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {getCanShareProjectAccess(currentProject) &&
      currentProject?.access_request_count &&
      currentProject?.access_request_count > 0 ? (
        <Styled.Counter color={currentColor} />
      ) : null}
    </>
  );
};

export default memo(ProjectDropdown);
