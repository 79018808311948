import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
  DragDropContext,
  OnDragEndResponder,
  OnDragStartResponder,
} from 'react-beautiful-dnd';

import { TableTheme } from './Table.types';

import * as Styled from './Table.styles';

type TableProps = {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
} & TableTheme;

type TableContextType = { isDragging?: boolean } & TableTheme;

const defaultTableContext: TableContextType = {
  tableTheme: 'light',
  size: 'mediumNarrow',
};

export const TableContext =
  createContext<TableContextType>(defaultTableContext);

const Table = ({
  children,
  tableTheme,
  isDraggable,
  handleDragEnd,
  size = 'mediumNarrow',
  isSelectable,
  onSelectChange,
  resetSelectedItemsState,
  selectedItemsState,
  dynamicColumns,
  columns,
  activeColumns,
  onColumnSelect,
  width = '',
}: TableProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const contextValue = useMemo(
    () => ({
      tableTheme,
      size,
      isDraggable,
      handleDragEnd,
      isDragging,
      isSelectable,
      onSelectChange,
      resetSelectedItemsState,
      selectedItemsState,
      dynamicColumns,
      columns,
      activeColumns,
      onColumnSelect,
    }),
    [
      tableTheme,
      size,
      isDraggable,
      handleDragEnd,
      isDragging,
      isSelectable,
      onSelectChange,
      resetSelectedItemsState,
      selectedItemsState,
      dynamicColumns,
      columns,
      activeColumns,
      onColumnSelect,
    ]
  );

  const onDragStart: OnDragStartResponder = useCallback(
    start => {
      const isSelected = !!selectedItemsState?.[start.draggableId];
      if (!isSelected && isSelectable) {
        resetSelectedItemsState?.();
      }

      setIsDragging(true);
    },
    [isSelectable, resetSelectedItemsState, selectedItemsState]
  );

  const onDragEnd: OnDragEndResponder = useCallback(
    (result, provided) => {
      setIsDragging(false);
      handleDragEnd?.(result, provided);
    },
    [handleDragEnd]
  );

  return (
    <TableContext.Provider value={contextValue}>
      {isDraggable ? (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Styled.Wrapper width={width}>{children}</Styled.Wrapper>
        </DragDropContext>
      ) : (
        <Styled.Wrapper width={width}>{children}</Styled.Wrapper>
      )}
    </TableContext.Provider>
  );
};

export default Table;
