import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { useGetDefaultComment } from 'components/modules/Project/BurnEdit/hooks/useGetDefaultComment';
import { useOrganization } from 'queries/organizations';
import { useCurrentProject, useReconciliationData } from 'queries/project';
import { useCopyStore, useGlobalStore, useProjectStore } from 'store';
import { BurnFieldsUpdate } from 'store/project';
import gtm from 'u9/utils/gtm';
import { ColorNames } from 'utils/styles/theme';

import { isReconcileViewType } from '../ReconcileModal';

import * as Styled from './SelectIntegrationsView.styles';

export interface SelectIntegrationsViewProps {
  setSelectingIntegrationsView: Dispatch<SetStateAction<boolean>>;
}

const SelectIntegrationsView: FC<SelectIntegrationsViewProps> = ({
  setSelectingIntegrationsView,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModals = useGlobalStore(s => s.closeModals);
  const { data: organization } = useOrganization();
  const { getDefaultComment } = useGetDefaultComment();
  const resetUpdatedBurnFields = useProjectStore(s => s.resetUpdatedBurnFields);
  const setUpdatedBurnFields = useProjectStore(s => s.setUpdatedBurnFields);
  const setBurnEditMode = useProjectStore(s => s.setBurnEditMode);
  const [floatSelected, setFloatSelected] = useState(false);
  const [teamdeckSelected, setTeamdeckSelected] = useState(false);
  const [jasSelected, setJasSelected] = useState(false);
  const copy = useCopyStore(s => s.copy);

  const currentProject = useCurrentProject();
  const { data } = useReconciliationData({ projectId: currentProject?.id });
  const { burns } = data || { burns: [] };

  const selectedBurns = burns
    .map(({ burn, id }) => ({
      id,
      burn: Object.fromEntries(
        Object.entries(burn).filter(([key]) => {
          if (isReconcileViewType(key)) {
            if (key === 'float') return floatSelected;
            if (key === 'teamdeck') return teamdeckSelected;
            if (key === 'jas') return jasSelected;
          } else {
            return false;
          }
        })
      ),
    }))
    .filter(({ burn }) => Object.keys(burn).length > 0);

  const handleContinue = () => {
    gtm.trackEvent('Action', 'reconciliation_end');
    resetUpdatedBurnFields();
    const burnUpdateEntries: BurnFieldsUpdate = Object.fromEntries(
      selectedBurns.map(({ burn, id }) => [
        id,
        {
          value: Math.max(...Object.values(burn)),
          comment: getDefaultComment(),
          checkmark: true,
          isSourceAutoUpdate: true,
        },
      ])
    );
    setUpdatedBurnFields(burnUpdateEntries);
    setBurnEditMode(true);
    closeModals(['reconcileModal']);
  };

  const floatEnabled = !!organization?.integrations?.find(
    integration => integration.name === 'float'
  )?.api_key;
  const teamdeckEnabled = !!organization?.integrations?.find(
    integration => integration.name === 'teamdeck'
  )?.api_key;

  const isAnySelected = floatSelected || teamdeckSelected || jasSelected;

  const modalCopy = copy.app.reconcileSuccessModal.selectIntegrationsView;

  return (
    <Styled.Wrapper>
      <Styled.Info>{modalCopy.info}</Styled.Info>
      <Styled.SelectionWrapper>
        {floatEnabled && (
          <Checkbox
            setValue={setFloatSelected}
            value={floatSelected}
            label={modalCopy.float}
            variant="square"
          />
        )}
        {teamdeckEnabled && (
          <Checkbox
            setValue={setTeamdeckSelected}
            value={teamdeckSelected}
            label={modalCopy.teamdeck}
            variant="square"
          />
        )}
        <Checkbox
          setValue={setJasSelected}
          value={jasSelected}
          label={modalCopy.jas}
          variant="square"
        />
      </Styled.SelectionWrapper>
      <Styled.Buttons>
        <Button
          label={modalCopy.backButton}
          icon="arrow_left"
          color={ColorNames.nandor}
          onClick={() => setSelectingIntegrationsView(false)}
        />
        <Button
          label={modalCopy.continueButton}
          icon="check"
          onClick={handleContinue}
          color={currentColor}
          disabled={!isAnySelected}
        />
      </Styled.Buttons>
    </Styled.Wrapper>
  );
};

export default memo(SelectIntegrationsView);
