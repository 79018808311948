import { useCallback, useMemo } from 'react';

import { useRoles } from 'queries/roles';
import { useVendorServicesWithOther } from 'queries/vendorServices';
import { useCopyStore } from 'store';
import { Either } from 'types/helpers';

export type RoleItem = {
  id: string | number;
  label: string;
  category: string;
} & Either<{ roleId: number; rowNo: number }, { vendorServiceId?: number }>;

export const useItems = () => {
  const { data: roles } = useRoles();
  const { data: vendorServicesWithOther } = useVendorServicesWithOther();
  const copy = useCopyStore(s => s.copy);
  const vendorsLabel = useMemo(
    () => copy.project.resourcesModal.vendors,
    [copy.project.resourcesModal.vendors]
  );

  const getVendorServices = useCallback(() => {
    return vendorServicesWithOther.map(vendorService => ({
      id: vendorService.id,
      label: vendorService.name ?? '',
      category: 'vendorService',
    }));
  }, [vendorServicesWithOther]);

  const getRoles = useCallback(
    (rolesCategory: string) => {
      let rolesForCategory = [];

      if (!roles) return [];

      if (rolesCategory) {
        const categoryIndex = roles.findIndex(
          ({ category }) => category === rolesCategory
        );
        const nextCategoryIndex = roles.findIndex(
          ({ category }, index) => index > categoryIndex && !!category
        );

        rolesForCategory = roles.slice(
          categoryIndex + 1,
          nextCategoryIndex === -1 ? roles.length : nextCategoryIndex
        );
      } else {
        rolesForCategory = roles;
      }

      return rolesForCategory.map(
        role => ({
          id: `role-${role.role_id}`,
          label: role.role || '',
          roleId: role.role_id,
          rowNo: role.row_no,
          category: 'role',
        }),
        []
      );
    },
    [roles]
  );

  const getItemsForCategory = useCallback(
    (category: string | null) => {
      if (!category) return [];
      if (category === vendorsLabel) {
        return getVendorServices();
      } else {
        return getRoles(category);
      }
    },
    [getRoles, getVendorServices, vendorsLabel]
  );

  return {
    getItemsForCategory,
  };
};
