import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCopyStore } from 'store';
import { ProjectStatus } from 'utils/api.types';

export interface StatusesProps {
  statuses: ProjectStatus[];
  setStatuses: Dispatch<SetStateAction<ProjectStatus[]>>;
}

import { rgba } from 'polished';

import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

const Statuses: FC<StatusesProps> = ({ statuses, setStatuses }) => {
  const copy = useCopyStore(s => s.copy);

  const availableStatuses = [
    {
      id: ProjectStatus.active,
      name: copy.app.statuses.active,
    },
    {
      id: ProjectStatus.closed,
      name: copy.app.statuses.closed,
    },
    {
      id: ProjectStatus.cancelled,
      name: copy.app.statuses.cancelled,
    },
  ];

  const toggleStatus = (status: ProjectStatus) => {
    setStatuses(prev => {
      if (prev.includes(status)) {
        return prev.filter(m => m !== status);
      } else {
        return [...prev, status];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.statuses}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(statuses.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {statuses.length > 0
            ? statuses.map(status => copy.app.statuses[status]).join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {availableStatuses?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={statuses.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleStatus(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Statuses);
