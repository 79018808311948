import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCheckAccessRights } from 'utils/accessRights';
import { Project } from 'utils/api.types';

export const useCanEditRndFields = ({ project }: { project?: Project }) => {
  const { getCanEditProject } = useProjectAccess();
  const canEditProject = getCanEditProject(project);
  const { checkAnyAccessRight } = useCheckAccessRights();
  const canEditRndFields =
    !project ||
    checkAnyAccessRight(['administrator', 'owner', 'super admin']) ||
    canEditProject;

  return { canEditRndFields };
};
