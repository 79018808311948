import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 0 20rem;
`;

export const Description = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  color: ${rgba(colors.white, 0.5)};
  padding: 20rem;
`;
