import { Table } from '@tanstack/react-table';
import { useCallback } from 'react';

export const useDnd = <T>({ table }: { table: Table<T> }) => {
  const reorderRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const selectedRowIndexesInitial = table
        .getSelectedRowModel()
        .rows.map(row => row.index);
      const selectedRowIndexes =
        selectedRowIndexesInitial.length > 0
          ? selectedRowIndexesInitial
          : [draggedRowIndex];

      const tableItems = table.getCoreRowModel().rows ?? [];
      const draggedRowId = tableItems[draggedRowIndex].id;

      const filtered = tableItems.filter((item, index) =>
        selectedRowIndexes.includes(index)
      );
      const remaining = tableItems.filter(
        (item, index) => !selectedRowIndexes.includes(index)
      );

      const filteredOrdered = [
        // Dragged item comes in first
        ...filtered.filter(({ id }) => id === draggedRowId),
        ...filtered.filter(({ id }) => id !== draggedRowId),
      ];

      const destinationIndex = (() => {
        const destinationIndexOffset = selectedRowIndexes.reduce(
          (previous, selectedItemIndex) => {
            if (selectedItemIndex === draggedRowIndex) {
              return previous;
            }

            if (Number(selectedItemIndex) >= targetRowIndex) {
              return previous;
            }

            return previous + 1;
          },
          0
        );

        return targetRowIndex - destinationIndexOffset;
      })();

      const reordered = [...remaining];
      reordered.splice(destinationIndex, 0, ...filteredOrdered);

      const { bulkMove, dragCancelled } = (() => {
        const didIndexIncrease = targetRowIndex > draggedRowIndex;

        const lowerIndex = Math.min(targetRowIndex, draggedRowIndex);
        const higherIndex = Math.max(targetRowIndex, draggedRowIndex);

        // Bulk move is offset from current to new position, excluding selected items.
        // This is how we communicate backend where we want to move items
        const bulkMove =
          tableItems
            .slice(lowerIndex, didIndexIncrease ? higherIndex + 1 : higherIndex)
            .filter(
              item =>
                !selectedRowIndexes.includes(
                  tableItems.findIndex(({ id }) => id === item.id)
                )
            ).length * (didIndexIncrease ? 1 : -1);
        const dragCancelled = bulkMove === 0;

        return {
          bulkMove,
          dragCancelled,
        };
      })();

      return {
        reordered,
        filteredOrdered,
        bulkMove,
        dragCancelled,
      };
    },
    [table]
  );

  return {
    reorderRow,
  };
};
