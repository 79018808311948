import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableTheme } from '../Table.types';

export const SELECT_ALL_ID = 'SELECT_ALL';

export const useMultiSelect = (
  initialState: TableTheme['selectedItemsState']
) => {
  const [selectedItemsState, setSelectedItemsState] = useState(initialState);

  const isAnySelected = useMemo(
    () => Object.values(selectedItemsState || {}).some(value => value),
    [selectedItemsState]
  );

  const onSelectChange: TableTheme['onSelectChange'] = useCallback(
    (id: string, value: boolean) => {
      if (id === SELECT_ALL_ID) {
        return setSelectedItemsState(() => {
          return Object.fromEntries(
            Object.keys(selectedItemsState || {}).map(key => [
              key,
              isAnySelected ? false : true,
            ])
          );
        });
      }

      setSelectedItemsState(prev => {
        return {
          ...prev,
          ...{ [id]: value },
        };
      });
    },
    [isAnySelected, selectedItemsState]
  );

  const resetSelectedItemsState: TableTheme['resetSelectedItemsState'] =
    useCallback(() => {
      setSelectedItemsState(prevSelectedItems => {
        return Object.fromEntries(
          Object.entries(prevSelectedItems || {}).map(([key]) => [key, false])
        );
      });
    }, []);

  useEffect(() => {
    setSelectedItemsState(prevSelectedItems =>
      Object.fromEntries(
        Object.entries(initialState || {}).map(([key]) => {
          return [key, !!prevSelectedItems?.[key]];
        })
      )
    );
  }, [initialState]);

  return {
    selectedItemsState,
    onSelectChange,
    resetSelectedItemsState,
  };
};
