import * as LabelPrimitive from '@radix-ui/react-label';
import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    padding: 20rem 0;
    flex: 1;
    border-bottom: 1px solid ${rgba(colors.white, 0.5)};
    ${({ theme: { locale } }) => setTypography('input', locale)}
    text-align: left;
  }
`;
export const Label = styled(LabelPrimitive.Root)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: locale }) => setTypography('caption', locale)};
`;
