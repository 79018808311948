import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { User } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';
import { ARCHIVE_KEY } from './archive';
import { BOB_ACCESS_REQUESTS_KEY } from './bobAccessRequests';

export const USERS_KEY = 'USERS';

export const useUsers = (
  variables?: Parameters<typeof getUsers>[0],
  options?: UseQueryOptions<User[]>
) => {
  const getUsers = useApiStore(s => s.apiClient.getUsers);
  return useQuery({
    queryKey: [USERS_KEY, variables?.account_type],
    queryFn: async () => (await getUsers(variables)).data,
    ...options,
  });
};

export const useUser = (
  variables: Partial<Parameters<typeof getUser>[0]>,
  options?: UseQueryOptions<User>
) => {
  const getUser = useApiStore(s => s.apiClient.getUser);
  return useQuery({
    queryKey: [USERS_KEY, variables?.id],
    queryFn: async () => {
      if (variables.id === undefined)
        return Promise.reject(new Error('id is undefined'));
      return (
        await getUser({
          id: variables.id,
          account_type: variables.account_type ?? 'user',
        })
      ).data;
    },
    ...options,
  });
};

export const useAddUser = () => {
  const addUser = useApiStore(s => s.apiClient.addUser);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addUser>[0]) => addUser(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BOB_ACCESS_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(USERS_KEY);
      },
    }
  );
};

export const useUpdateUser = () => {
  const updateUser = useApiStore(s => s.apiClient.updateUser);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateUser>[0]) => updateUser(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BOB_ACCESS_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(USERS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};

export const useDeleteUser = () => {
  const deleteUser = useApiStore(s => s.apiClient.deleteUser);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteUser>[0]) => deleteUser(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BOB_ACCESS_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(USERS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
