import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import { useDeleteProjectGroup } from 'queries/projectGroups';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectGroup } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteProjectGroupModalProps {
  group: ProjectGroup;
}

const DeleteProjectGroupModal: FC<DeleteProjectGroupModalProps> = ({
  group,
}) => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: handleDelete, isLoading: deleteLoading } =
    useDeleteProjectGroup();

  const handleClose = () => {
    closeModal('deleteProjectGroup');
  };

  const handleConfirmClick = async () => {
    const response = await handleDelete({
      id: group.id,
    });
    router.push(ROUTES.PROJECTS_LIST);
    if (response?.data?.deleted) {
      handleClose();
    }
  };

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: deleteLoading,
        label: copy.app.deleteProjectGroupModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: deleteLoading,
        label: copy.app.deleteProjectGroupModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteLoading,
      }}
      description={replaceString(
        copy.app.deleteProjectGroupModal.description,
        '{name}',
        group.name
      )}
      title={copy.app.deleteProjectGroupModal.title}
    />
  );
};

export default memo(DeleteProjectGroupModal);
