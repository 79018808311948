import { Column, flexRender, Table as TableType } from '@tanstack/react-table';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import SearchInput, {
  SearchInputProps,
} from 'components/inputs/SearchInput/SearchInput';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useProfile, useUpdateProfile } from 'queries/profile';
import { useCopyStore, useGlobalStore } from 'store';
import { ReactComponent as ArrowLeft } from 'svgs/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'svgs/arrow_right.svg';
import { ReactComponent as Caret } from 'svgs/caret.svg';
import { ReactComponent as DoubleArrowLeft } from 'svgs/double_arrow_left.svg';
import { ReactComponent as DoubleArrowRight } from 'svgs/double_arrow_right.svg';
import { useSvgIcon } from 'u9/hooks';
import { replaceStrings } from 'utils/replace';

import * as Styled from './Table.styles';

export const TableDefaultWrapper = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperOffset, setWrapperOffset] = useState(0);

  useEffect(() => {
    const clientRect = wrapperRef.current?.getBoundingClientRect();
    const top = clientRect?.top ?? 0;
    setWrapperOffset(top);
  }, []);

  return (
    <Styled.TableDefaultWrapper wrapperOffset={wrapperOffset} ref={wrapperRef}>
      {children}
    </Styled.TableDefaultWrapper>
  );
};

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ ...props }, ref) => <Styled.Table ref={ref} {...props} />);
Table.displayName = 'Table';

const THead = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ ...props }, ref) => <Styled.THead ref={ref} {...props} />);
THead.displayName = 'THead';

const TBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ ...props }, ref) => <Styled.TBody ref={ref} {...props} />);
TBody.displayName = 'TBody';

const TableHeaderRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ ...props }, ref) => <Styled.TableHeaderRow ref={ref} {...props} />);
TableHeaderRow.displayName = 'TableHeaderRow';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & Styled.TableRowTypes
>(({ ...props }, ref) => <Styled.TableRow ref={ref} {...props} role="row" />);
TableRow.displayName = 'TableRow';

const THeadCell = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & Styled.THeadCellTypes
>(({ ...props }, ref) => <Styled.THeadCell ref={ref} {...props} />);
THeadCell.displayName = 'THeadCell';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & Styled.TableCellTypes
>(({ ...props }, ref) => {
  return <Styled.TableCell ref={ref} {...props} />;
});
TableCell.displayName = 'TableCell';

const THeadCellContent = ({
  column,
  text,
  textAlign = 'left',
  whiteSpace = 'nowrap',
  rightContent = null,
  style,
}: {
  column: Column<any, any>;
  text: string;
  textAlign?: CSSProperties['textAlign'];
  whiteSpace?: CSSProperties['whiteSpace'];
  rightContent?: React.ReactNode | React.ReactNode[];
  style?: CSSProperties;
}) => {
  return (
    <Styled.THeadCellContent
      {...(column.getCanSort()
        ? {
            onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
          }
        : {})}
      style={style}
    >
      <OverflowEllipsis text={text} styles={{ textAlign, whiteSpace }} />
      {rightContent}
      <Styled.SortIcon
        rotated={column.getIsSorted() === 'asc'}
        isSorted={column.getCanSort() && column.getIsSorted()}
      >
        <Caret />
      </Styled.SortIcon>
    </Styled.THeadCellContent>
  );
};

export const DefaultTableHeader = ({ table }: { table: TableType<any> }) => {
  return (
    <>
      {table.getHeaderGroups().map(headerGroup => (
        <TableHeaderRow key={headerGroup.id}>
          {table.options.meta?.dndEnabled && (
            <th style={{ width: '10px', borderRight: 0 }} />
          )}
          {headerGroup.headers.map(header => (
            <THeadCell
              key={header.id}
              style={{ width: header.getSize() }}
              isSelectColumn={header.column.columnDef.meta?.isSelectColumn}
              isSortEnabled={header.column.getCanSort()}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </THeadCell>
          ))}
        </TableHeaderRow>
      ))}
    </>
  );
};

export const DefaultTableBody = ({
  table,
  emptyLabel,
}: {
  table: TableType<any>;
  emptyLabel?: string;
}) => {
  const copy = useCopyStore(s => s.copy);

  return table.getRowModel().rows?.length ? (
    <>
      {table.getRowModel().rows.map(row => (
        <TableRow key={row.id} style={{ height: '100rem' }}>
          {row.getVisibleCells().map(cell => (
            <TableCell
              key={cell.id}
              style={{ width: cell.column.getSize() }}
              isSelectColumn={cell.column.columnDef.meta?.isSelectColumn}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  ) : (
    <TableRow>
      <TableCell
        colSpan={table.getAllColumns().length}
        style={{ textAlign: 'center' }}
      >
        {emptyLabel || copy.app.tableEmpty}
      </TableCell>
    </TableRow>
  );
};

export const TablePagination = ({
  table,
  totalCount,
}: {
  table: TableType<any>;
  totalCount: number;
}) => {
  const copy = useCopyStore(s => s.copy);
  const paginationState = table.getState().pagination;

  const label = replaceStrings(copy.app.paginationLabel, [
    [
      '{from}',
      String(paginationState.pageIndex * paginationState.pageSize + 1),
    ],
    [
      '{to}',
      String(
        paginationState.pageIndex * paginationState.pageSize +
          table.getRowModel().rows?.length
      ),
    ],
    ['{total}', String(totalCount)],
  ]);

  return (
    <Styled.PaginationWrapper>
      <Styled.PaginationLabel>{label}</Styled.PaginationLabel>
      <Styled.PaginationButtons>
        <Styled.PaginationButton
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.setPageIndex(0)}
        >
          <DoubleArrowLeft />
        </Styled.PaginationButton>
        <Styled.PaginationButton
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          <ArrowLeft />
        </Styled.PaginationButton>
        <Styled.PaginationButton
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          <ArrowRight />
        </Styled.PaginationButton>
        <Styled.PaginationButton
          disabled={!table.getCanNextPage()}
          onClick={() => table.setPageIndex(table.getPageCount())}
        >
          <DoubleArrowRight />
        </Styled.PaginationButton>
      </Styled.PaginationButtons>
    </Styled.PaginationWrapper>
  );
};

export const DynamicColumnsDropdown = ({
  table,
}: {
  table: TableType<any>;
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const triedToInitializeColumns = useRef<boolean>(false);
  const { SvgIcon: TriggerIcon } = useSvgIcon('settings');
  const { data: profile } = useProfile();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const allColumnsCount = table.getAllColumns().map(column => column.id).length;
  const hiddenColumnsCount = Object.entries(
    table.getState().columnVisibility
  ).filter(([, value]) => value === false).length;

  const visibleColumnsCounts = allColumnsCount - hiddenColumnsCount;

  // Save column preference on BE
  const saveToStorage = () => {
    if (table.options.meta?.id && profile) {
      try {
        const hiddenColumns = Object.entries(table.getState().columnVisibility)
          .filter(([, value]) => value === false)
          .map(([key]) => key);
        const visibleColumnsToSave = table
          .getAllColumns()
          .map(column => column.id)
          .filter(column => !hiddenColumns.includes(column));
        updateProfile({
          table_columns: {
            ...profile.table_columns,
            [table.options.meta.id]: visibleColumnsToSave,
          },
        });
      } catch (e) {}
    }
  };

  // Read from BE on load
  useEffect(() => {
    if (
      profile &&
      !triedToInitializeColumns.current &&
      table.options.meta?.id &&
      profile.table_columns?.[table.options.meta?.id]
    ) {
      triedToInitializeColumns.current = true;
      const cols = profile.table_columns?.[table.options.meta?.id];
      const columnsToHide = Object.fromEntries(
        table
          .getAllColumns()
          .map(({ id }) => id)
          .filter(id => !cols.includes(id))
          .map(id => [id, false])
      );
      table.setColumnVisibility(columnsToHide);
    }
  }, [profile, table]);

  return (
    <Styled.DynamicColumnsButton>
      <DropdownMenu data-cy="dynamic-columns">
        <Styled.DynamicColumnsButtonTrigger
          isAnyColumnHidden={!table.getIsAllColumnsVisible()}
          color={currentColor}
        >
          <Styled.DynamicColumnsButtonTriggerIcon>
            <TriggerIcon />
          </Styled.DynamicColumnsButtonTriggerIcon>
          {copy.app.tableColumnsButton}
        </Styled.DynamicColumnsButtonTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onSelect={event => {
              event.preventDefault();
              table.toggleAllColumnsVisible(true);
              setTimeout(() => {
                saveToStorage();
              }, 0);
            }}
          >
            {copy.app.columnVisibilitySelectAll}
            <DropdownMenuSeparator />
          </DropdownMenuItem>
          {table
            .getAllColumns()
            .filter(({ getCanHide }) => getCanHide())
            .map(column => (
              <DropdownMenuCheckboxItem
                key={column.id}
                onSelect={event => {
                  event.preventDefault();
                  column.toggleVisibility();
                  setTimeout(() => {
                    saveToStorage();
                  }, 0);
                }}
                checked={column.getIsVisible()}
                disabled={visibleColumnsCounts === 1 && column.getIsVisible()}
              >
                {column.columnDef.meta?.text}
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DynamicColumnsButton>
  );
};

export const FiltersButton = ({
  onClick,
  anyFiltersActive,
}: {
  onClick: () => void;
  anyFiltersActive?: boolean;
}) => {
  const { SvgIcon: FiltersIcon } = useSvgIcon('filters');
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);

  return (
    <ToolbarButton
      onClick={onClick}
      isActiveColor={anyFiltersActive}
      currentColor={currentColor}
    >
      <ToolbarButtonIcon>
        <FiltersIcon />
      </ToolbarButtonIcon>
      {copy.app.filterButton}
    </ToolbarButton>
  );
};

export const DragHandle = React.memo(
  ({ showOnRowHover }: { showOnRowHover: boolean }) => {
    const { SvgIcon: DragHandleIcon } = useSvgIcon('dragHandle');

    return (
      <Styled.DragHandle
        {...(showOnRowHover ? { 'data-visible-on-row-hover': true } : {})}
      >
        <Styled.DragHandleIcon>
          <DragHandleIcon />
        </Styled.DragHandleIcon>
      </Styled.DragHandle>
    );
  }
);
DragHandle.displayName = 'DragHandle';

export const RowIconButton = ({
  showOnRowHover,
  onClick,
  tooltip,
}: {
  showOnRowHover: boolean;
  onClick: () => void;
  tooltip: string;
}) => {
  const { SvgIcon: AddIcon } = useSvgIcon('add');

  return (
    <Tooltip content={tooltip}>
      <Styled.RowIconButton
        {...(showOnRowHover ? { 'data-visible-on-row-hover': true } : {})}
        onClick={onClick}
      >
        <Styled.RowIconButtonIconWrapper>
          <AddIcon />
        </Styled.RowIconButtonIconWrapper>
      </Styled.RowIconButton>
    </Tooltip>
  );
};

export const TableSearchbar = (props: SearchInputProps) => {
  return <SearchInput {...props} />;
};

export const TableToolbar = Styled.TableToolbar;
export const TableToolbarStart = Styled.TableToolbarStart;
export const TableToolbarEnd = Styled.TableToolbarEnd;
export const ToolbarButton = Styled.ToolbarButton;
export const ToolbarButtonDivider = Styled.ToolbarButtonDivider;
export const ToolbarButtonIcon = Styled.ToolbarButtonIcon;

export {
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
  TBody,
  THead,
  THeadCell,
  THeadCellContent,
};
