import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import * as Styled from './Popover.styles';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverArrow = PopoverPrimitive.Arrow;
const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <Styled.Content collisionPadding={20} ref={ref} {...props}>
      {props.children}
    </Styled.Content>
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverAnchor, PopoverArrow, PopoverContent, PopoverTrigger };
