import { useCallback } from 'react';

import { formatPercent } from 'utils/formatters';

export const useFormatPercent = () => {
  const formatPercentShort = useCallback(
    (percent?: string | number) => formatPercent(percent, { precision: 0 }),
    []
  );

  return {
    formatPercent,
    formatPercentShort,
  };
};
