import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Payment } from 'utils/api.types';

export const PAYMENTS_KEY = 'PAYMENTS';

export const usePayments = (
  variables?: Parameters<typeof getPayments>[0],
  options?: UseQueryOptions<Payment[]>
) => {
  const getPayments = useApiStore(s => s.apiClient.getPayments);
  return useQuery({
    queryKey: [PAYMENTS_KEY, variables?.projectId],
    queryFn: async () =>
      (await getPayments(variables)).data.sort((a, b) => b.id - a.id),
    ...options,
  });
};

export const useUpdatePayment = () => {
  const updatePayments = useApiStore(s => s.apiClient.updatePayment);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updatePayments>[0]) =>
      updatePayments(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PAYMENTS_KEY);
      },
    }
  );
};

export const useCreatePayment = () => {
  const createPayment = useApiStore(s => s.apiClient.createPayment);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof createPayment>[0]) =>
      createPayment(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PAYMENTS_KEY);
      },
    }
  );
};
