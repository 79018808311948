import * as Sentry from '@sentry/react';

import { getErrorCode } from './api.helpers';

export const sentryCaptureException = (err: unknown) => {
  const isKnownErrorCode = getErrorCode(err);
  if (isKnownErrorCode) {
    return;
  }
  Sentry.captureException(err);
};
