import { FC, memo } from 'react';

import FormSlider, {
  DefaultTopHtml,
} from 'components/inputs/FormSlider/FormSlider';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useCopyStore } from 'store';

import { useRateSlider } from './hooks/useRateSlider';

export type RateSliderProps = ReturnType<typeof useRateSlider>;

const RateSlider: FC<RateSliderProps> = ({
  rate,
  maxRate,
  minRate,
  setRate,
}) => {
  const { formatMoney } = useFormatMoney();
  const copy = useCopyStore(s => s.copy);

  return (
    <FormSlider
      label={copy.app.projectFiltersModal.rate}
      step={1}
      min={minRate === Infinity ? 0 : minRate}
      max={maxRate === 0 ? 0 : maxRate}
      disabled={minRate === Infinity && maxRate === 0}
      value={rate}
      onValueChange={setRate}
      topHtml={
        <DefaultTopHtml value={[formatMoney(rate[0]), formatMoney(rate[1])]} />
      }
    />
  );
};

export default memo(RateSlider);
