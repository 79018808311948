import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 40rem;
`;

export const Name = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
`;

export const PermissionDropdown = styled.div`
  width: 150rem;
  margin-right: 19rem;
`;
