import { SelectReconcileIntegrationModalProps } from 'components/modals/SelectReconcileIntegrationModal/SelectReconcileIntegrationModal';
import { useGlobalStore } from 'store';
import { ReconcileIntegrationType } from 'utils/api.types';

export const useHandleSelectIntegrationProject = () => {
  const openModal = useGlobalStore(s => s.openModal);

  const handleSelectIntegrationProject = (
    integration: ReconcileIntegrationType
  ) => {
    openModal<SelectReconcileIntegrationModalProps>(
      'selectReconcileIntegrationModal',
      {
        integration,
      }
    );
  };

  return { handleSelectIntegrationProject };
};
