import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useSpinner } from 'hooks/useSpinner';
import {
  useAddProjectBudgetEntry,
  useCurrentProjectBudgetEntries,
} from 'queries/budgetEntries';
import { useCurrentProject } from 'queries/project';
import { getErrorCode } from 'utils/api.helpers';
import { BudgetEntry } from 'utils/api.types';

import { emptyBudgetEntry } from '../BudgetModal';

export const Errors = ['tryAgain', 'notSupported'] as const;

export const useBudgets = () => {
  const [error, setError] = useState<unknown>();
  const currentProject = useCurrentProject();
  const { commonErrorHandler } = useCommonErrorHandling();
  const { data = [], isLoading: isProjectEntriesLoading } =
    useCurrentProjectBudgetEntries();
  const currentVersionId = useCurrentVersionId();

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: ['fx.error', 'fx.runtime_error'],
        localHandler(error) {
          if (getErrorCode(error) === 'fx.error') {
            setError('notSupported');
          } else if (getErrorCode(error) === 'fx.runtime_error') {
            setError('tryAgain');
          }
        },
      });
    },
    [commonErrorHandler]
  );

  const { mutateAsync: addProjectBudgetEntry, isLoading: isLoadingAddProject } =
    useAddProjectBudgetEntry({
      onError: handleError,
    });
  const [budgetEntries, setBudgetEntries] = useState<BudgetEntry[]>(data);

  const isLoading = useMemo(
    () => isProjectEntriesLoading || isLoadingAddProject,
    [isProjectEntriesLoading, isLoadingAddProject]
  );

  useEffect(() => {
    setBudgetEntries(data);
  }, [data]);

  useEffect(() => {
    if (
      currentProject?.id &&
      currentVersionId &&
      !isLoading &&
      data.length === 0
    ) {
      addProjectBudgetEntry({
        projectId: currentProject?.id,
        projectVersionId: currentVersionId,
        budgetEntry: emptyBudgetEntry,
      });
    }
  }, [
    currentProject?.id,
    data.length,
    addProjectBudgetEntry,
    isLoading,
    currentVersionId,
  ]);

  const spinner = useSpinner(isLoading);

  return {
    budgetEntries: budgetEntries,
    setBudgetEntries,
    spinner: spinner,
    loading: isLoading,
    error: error,
  };
};
