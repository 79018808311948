import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1200rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 50rem;
`;

export const FieldSpace = styled.div`
  width: 50rem;
`;

export const AddressFields = styled.div`
  margin-top: 50rem;
`;
