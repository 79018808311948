import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  height: ${setVh(70)};
  width: 1200rem;
  padding: 10rem;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 80rem;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60rem;
  width: 100%;
  align-items: flex-end;
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.white, 0.5)};
  width: 100%;
  margin-bottom: 10rem;
`;

export const FieldWrapper = styled.div`
  flex: 1;
  padding: 0 10rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 80rem;
  right: 80rem;
  z-index: 1;
`;

export const ResetButton = styled.div`
  margin-right: 35rem;
`;

export const ContentWrapper = styled.div`
  padding: 30rem;
`;
