import { useCallback } from 'react';

import { DeleteContractsLinkModalProps } from 'components/modals/DeleteContractsLinkModal/DeleteContractsLinkModal';
import { useGlobalStore } from 'store';

export const useDeleteLinkModal = () => {
  const openModal = useGlobalStore(s => s.openModal);

  const handleOpenDeleteModal = useCallback(() => {
    openModal<DeleteContractsLinkModalProps>('deleteContractsLinkModal');
  }, [openModal]);

  return {
    handleOpenDeleteModal,
  };
};
