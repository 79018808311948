import { ReconcileResult } from 'utils/api.types';

import { ReconcileMatchTypes, useGetColorMatch } from './useGetColorMatch';

export const useCounters = ({
  items,
  overspentRowIds,
}: {
  items: ReconcileResult['items'];
  overspentRowIds: number[];
}) => {
  const { getMatchType } = useGetColorMatch({ overspentRowIds });

  const counters = {
    redMatchCount: items.reduce(
      (prev, current) =>
        prev + (getMatchType(current) === ReconcileMatchTypes.red ? 1 : 0),
      0
    ),
    amberMatchCount: items.reduce(
      (prev, current) =>
        prev + (getMatchType(current) === ReconcileMatchTypes.amber ? 1 : 0),
      0
    ),
    greenMatchCount: items.reduce(
      (prev, current) =>
        prev + (getMatchType(current) === ReconcileMatchTypes.green ? 1 : 0),
      0
    ),
    total: items.length,
  };

  return counters;
};
