import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { ProjectDeletedNotification as ProjectDeletedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface ProjectDeletedNotificationProps {
  notification: ProjectDeletedNotificationType;
}

const ProjectDeletedNotification: FC<ProjectDeletedNotificationProps> = ({
  notification,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.projectDeleted}
        replacements={[
          ['{project}', notification.extra_info.projectName],
          ['{user}', notification.extra_info.deletedBy],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(ProjectDeletedNotification);
