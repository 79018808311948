import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { usePerformanceDashboardStore } from 'store';
import { getPerformanceRoute } from 'utils/routes';

import { useCurrentPerformanceRoute } from './useCurrentPerformanceRoute';

import { encode, parse } from 'querystring';

export const PD_QUERY_PARAM_SESSION_COOKIE =
  'PERFORMANCE_DASHBOARD_QUERY_PARAMS';

export const useSetNewQueryParams = () => {
  const currentPerformanceRoute = useCurrentPerformanceRoute();
  const router = useRouter();
  const budgetFrom = usePerformanceDashboardStore(s => s.budgetFrom);
  const budgetTo = usePerformanceDashboardStore(s => s.budgetTo);
  const endDate = usePerformanceDashboardStore(s => s.endDate);
  const excludeClosed = usePerformanceDashboardStore(s => s.excludeClosed);
  const includeEnd = usePerformanceDashboardStore(s => s.includeEnd);
  const includeStart = usePerformanceDashboardStore(s => s.includeStart);
  const intExtFilter = usePerformanceDashboardStore(s => s.intExtFilter);
  const marginVariation = usePerformanceDashboardStore(s => s.marginVariation);
  const startDate = usePerformanceDashboardStore(s => s.startDate);
  const similarProjectId = usePerformanceDashboardStore(
    s => s.similarProjectId
  );
  const timeframe = usePerformanceDashboardStore(s => s.timeframe);
  const verticals = usePerformanceDashboardStore(s => s.verticals);
  const icons = usePerformanceDashboardStore(s => s.icons);
  const includeNonIcon = usePerformanceDashboardStore(s => s.includeNonIcon);
  const resultsByDate = usePerformanceDashboardStore(s => s.resultsByDate);
  const internalPercent = usePerformanceDashboardStore(s => s.internalPercent);

  return useCallback(() => {
    const encoded = parse(
      encode({
        budgetFrom,
        budgetTo,
        endDate,
        excludeClosed: Number(excludeClosed),
        includeEnd: Number(includeEnd),
        includeStart: Number(includeStart),
        intExtFilter: Number(intExtFilter),
        includeNonIcon: Number(includeNonIcon),
        resultsByDate: Number(resultsByDate),
        internalPercent,
        marginVariation,
        startDate,
        similarProjectId,
        timeframe,
        verticals,
        icons,
      })
    );
    try {
      Cookies.set(PD_QUERY_PARAM_SESSION_COOKIE, JSON.stringify(encoded), {
        sameSite: 'lax',
      });
    } catch (e) {}

    router.push(
      {
        pathname: getPerformanceRoute(currentPerformanceRoute),
        query: encoded,
      },
      undefined,
      { shallow: true }
    );
  }, [
    budgetFrom,
    budgetTo,
    endDate,
    excludeClosed,
    includeEnd,
    includeStart,
    intExtFilter,
    includeNonIcon,
    resultsByDate,
    marginVariation,
    startDate,
    similarProjectId,
    timeframe,
    verticals,
    icons,
    router,
    currentPerformanceRoute,
    internalPercent,
  ]);
};
