import { FC, memo } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import FormInput from 'components/inputs/FormInput/FormInput';
import AddressFields, {
  ModalAddressFieldsProps,
} from 'components/modals/AdminItemModal/AddressFields/AddressFields';
import { useCopyStore } from 'store';

import { useEditResource } from '../hooks/useEditResource';

import * as Styled from '../EditResourceModal.styles';

export interface VendorPartialProps {
  nameField: ReturnType<typeof useEditResource>['nameField'];
  emailField: ReturnType<typeof useEditResource>['emailField'];
  emailErrorMessage: ReturnType<typeof useEditResource>['emailErrorMessage'];
  vendorServiceItems: ReturnType<typeof useEditResource>['vendorServiceItems'];
  vendorServiceDropdown: ReturnType<
    typeof useEditResource
  >['vendorServiceDropdown'];
  addressLine1: ModalAddressFieldsProps['addressLine1'];
  setAddressLine1: ModalAddressFieldsProps['setAddressLine1'];
  addressLine2: ModalAddressFieldsProps['addressLine2'];
  setAddressLine2: ModalAddressFieldsProps['setAddressLine2'];
  city: ModalAddressFieldsProps['city'];
  setCity: ModalAddressFieldsProps['setCity'];
  zipCode: ModalAddressFieldsProps['zipCode'];
  setZipCode: ModalAddressFieldsProps['setZipCode'];
  countryCode: ModalAddressFieldsProps['countryCode'];
  setCountryCode: ModalAddressFieldsProps['setCountryCode'];
}

const VendorPartial: FC<VendorPartialProps> = ({
  emailErrorMessage,
  emailField,
  nameField,
  vendorServiceDropdown,
  vendorServiceItems,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
}) => {
  const copy = useCopyStore(s => s.copy);
  const modalCopy = copy.project.fillVendorInformation.editVendorModal;

  const [vendorName, setVendorName, , vendorNameError] = nameField;
  const [email, setEmail, , emailError] = emailField;
  const [activeVendorServiceId, setActiveVendorServiceId] =
    vendorServiceDropdown;

  return (
    <>
      <Styled.Row>
        <FormInput
          value={vendorName}
          onChange={setVendorName}
          placeholder={modalCopy.vendorLabel}
          required
          error={vendorNameError}
          dataCy="edit-vendor-modal__vendor-name"
        />
        <Styled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          placeholder={modalCopy.emailLabel}
          required
          error={emailError || emailErrorMessage}
          dataCy="edit-vendor-modal__email"
        />
      </Styled.Row>
      <Select
        label={modalCopy.serviceLabel}
        value={String(activeVendorServiceId) || undefined}
        onValueChange={setActiveVendorServiceId}
      >
        {vendorServiceItems?.map(({ id, displayName }) => (
          <SelectItem key={id} value={String(id)}>
            {displayName}
          </SelectItem>
        ))}
      </Select>
      <Styled.AddressFields>
        <AddressFields
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          city={city}
          setCity={setCity}
          zipCode={zipCode}
          setZipCode={setZipCode}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      </Styled.AddressFields>
    </>
  );
};

export default memo(VendorPartial);
