export const googleValidationCountries = [
  { countryCode: 'AU', label: 'Australia' },
  { countryCode: 'AT', label: 'Austria' },
  { countryCode: 'BE', label: 'Belgium' },
  { countryCode: 'BR', label: 'Brazil' },
  { countryCode: 'CA', label: 'Canada' },
  { countryCode: 'CL', label: 'Chile' },
  { countryCode: 'CO', label: 'Colombia' },
  { countryCode: 'CZ', label: 'Czechia' },
  { countryCode: 'DK', label: 'Denmark' },
  { countryCode: 'EE', label: 'Estonia' },
  { countryCode: 'FI', label: 'Finland' },
  { countryCode: 'FR', label: 'France' },
  { countryCode: 'DE', label: 'Germany' },
  { countryCode: 'HU', label: 'Hungary' },
  { countryCode: 'IE', label: 'Ireland' },
  { countryCode: 'IT', label: 'Italy' },
  { countryCode: 'LV', label: 'Latvia' },
  { countryCode: 'LT', label: 'Lithuania' },
  { countryCode: 'LU', label: 'Luxembourg' },
  { countryCode: 'MY', label: 'Malaysia' },
  { countryCode: 'MX', label: 'Mexico' },
  { countryCode: 'NL', label: 'Netherlands' },
  { countryCode: 'NO', label: 'Norway' },
  { countryCode: 'NZ', label: 'New Zealand' },
  { countryCode: 'PL', label: 'Poland' },
  { countryCode: 'PR', label: 'Puerto Rico' },
  { countryCode: 'SG', label: 'Singapore' },
  { countryCode: 'SK', label: 'Slovakia' },
  { countryCode: 'SI', label: 'Slovenia' },
  { countryCode: 'ES', label: 'Spain' },
  { countryCode: 'SE', label: 'Sweden' },
  { countryCode: 'CH', label: 'Switzerland' },
  { countryCode: 'GB', label: 'United Kingdom' },
  { countryCode: 'US', label: 'United States' },
];
