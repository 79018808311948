import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { UnhideProjectModalProps } from 'components/modals/UnhideProjectModal/UnhideProjectModal';
import { useParseProjectsListQueryString } from 'components/modules/ProjectsList/hooks/useParseProjectsListQueryString';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useAddProject, useUpdateProject } from 'queries/project';
import { useAddProjectToGroup } from 'queries/projectGroups';
import { useCopyStore, useGlobalStore } from 'store';
import { getErrorCode } from 'utils/api.helpers';
import {
  Brand,
  Client,
  GeoArea,
  Project,
  ProjectDetails,
  ProjectMode,
  ProjectSensitivity,
  ProjectStatus,
  Vertical,
} from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { getProjectRoute } from 'utils/routes';

import { useProject } from './useProject';

export interface HandleAdd {
  projectId?: Project['id'];
  isJobNumberValid: boolean;
  isProjectNameValid: boolean;
  activeVerticalId?: Vertical['id'];
  activeStatus: ProjectStatus;
  activeMode: ProjectMode;
  activeClientId?: Client['id'];
  activeBrandId?: Brand['id'];
  activeTerritoryId?: GeoArea['id'];
  projectName: string;
  jobNumber: Project['job_number'];
  isSensitive: boolean;
  isTest: boolean;
  rndCategory?: string;
  rndPercent: number;
  rndExclude: boolean;
  rndDescription: string;
  setDuplicateError: Dispatch<SetStateAction<string>>;
  setClosing: Dispatch<SetStateAction<boolean>>;
}

export const useHandleAdd = ({
  projectId,
  isJobNumberValid,
  isProjectNameValid,
  activeVerticalId,
  activeStatus,
  activeMode,
  activeClientId,
  activeBrandId,
  activeTerritoryId,
  projectName,
  jobNumber,
  isSensitive,
  isTest,
  setDuplicateError,
  setClosing,
  rndCategory,
  rndDescription,
  rndExclude,
  rndPercent,
}: HandleAdd) => {
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const closeModals = useGlobalStore(s => s.closeModals);
  const openModal = useGlobalStore(s => s.openModal);
  const router = useRouter();
  const parsedFilters = useParseProjectsListQueryString();
  const { commonErrorHandler } = useCommonErrorHandling();

  const showJobNumberDuplicated = useCallback(() => {
    setDuplicateError(copy.app.validatorMessages.jobNumberDuplicated);
  }, [setDuplicateError, copy]);

  const showPitchNumberDuplicated = useCallback(() => {
    setDuplicateError(copy.app.validatorMessages.pitchNumberDuplicated);
  }, [setDuplicateError, copy]);

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: [
          'project.pitch_number_not_unique',
          'project.pitch_number_not_unique',
        ],
        localHandler(error) {
          if (getErrorCode(error) === 'project.pitch_number_not_unique') {
            showPitchNumberDuplicated();
          } else if (getErrorCode(error) === 'project.job_number_not_unique') {
            showJobNumberDuplicated();
          }
        },
      });
    },
    [commonErrorHandler, showJobNumberDuplicated, showPitchNumberDuplicated]
  );

  const { mutateAsync: handleUpdateProject, isLoading: updateLoading } =
    useUpdateProject({
      onError: handleError,
    });

  const { mutateAsync: handleAddProject, isLoading: addLoading } =
    useAddProject({ onError: handleError });

  const {
    mutateAsync: handleAddProjectToGroup,
    isLoading: addProjectToGroupLoading,
  } = useAddProjectToGroup();

  const isAnyInvalid = useMemo(
    () =>
      [isJobNumberValid, isProjectNameValid].some(valid => !valid) ||
      (!activeVerticalId && !project?.vertical?.id),
    [
      activeVerticalId,
      isJobNumberValid,
      isProjectNameValid,
      project?.vertical?.id,
    ]
  );

  const handleAdd = useCallback(async () => {
    if (!isAnyInvalid) {
      const handler = async () => {
        const projectStatus = activeStatus ?? project?.status;
        const projectMode = activeMode ?? project?.mode;
        const projectValues: ProjectDetails = {
          name: projectName.replace(/\s+/g, ' ').trim(),
          status: projectStatus,
          mode: projectMode,
          test_flag: isTest,

          access: isSensitive
            ? ProjectSensitivity.sensitive
            : ProjectSensitivity.default,
          ...(activeVerticalId && {
            vertical_id: activeVerticalId,
          }),
          ...(activeClientId && {
            client_id: activeClientId as Client['id'],
          }),
          ...(activeBrandId && {
            brand_id: activeBrandId as Brand['id'],
          }),
          ...(activeTerritoryId && {
            territory_id: activeTerritoryId as GeoArea['id'],
          }),
          ...(activeMode === ProjectMode.pitch
            ? {
                pitch_number: jobNumber,
              }
            : {
                job_number: jobNumber,
              }),
          research_and_development: {
            category: rndCategory,
            description: rndDescription,
            exclude: rndExclude,
            percentage: rndPercent,
          },
        };
        try {
          if (project) {
            const [response] = await Promise.all([
              handleUpdateProject({
                projectId: project.id,
                project: projectValues,
              }),
            ]);
            if (response?.data) {
              setClosing(true);
            }
          } else {
            const response = await handleAddProject({
              ...projectValues,
              pitch_name: replaceString(
                copy.app.defaultPitchName,
                '{number}',
                '1'
              ),
            });
            const groupId = parsedFilters.groupId;
            const shouldAddProjectToGroup = !!groupId;
            if (shouldAddProjectToGroup) {
              handleAddProjectToGroup({
                groupId,
                projectId: response.data.id,
              });
            }

            router.push(
              getProjectRoute(response.data.id, response.data.main_version_id)
            );
          }
        } catch (error) {}
      };

      const isUnhidingProject =
        !isSensitive && project?.access === ProjectSensitivity.sensitive;

      if (isUnhidingProject) {
        openModal<UnhideProjectModalProps>('unhideProjectModal', {
          handleConfirm: () => {
            handler();
            closeModals(['unhideProjectModal']);
          },
          handleDecline: () => closeModals(['unhideProjectModal']),
        });
      } else {
        handler();
      }
    }
  }, [
    isAnyInvalid,
    isSensitive,
    project,
    activeStatus,
    activeMode,
    projectName,
    isTest,
    activeVerticalId,
    activeClientId,
    activeBrandId,
    activeTerritoryId,
    jobNumber,
    rndCategory,
    rndDescription,
    rndExclude,
    rndPercent,
    handleUpdateProject,
    setClosing,
    handleAddProject,
    copy.app.defaultPitchName,
    parsedFilters.groupId,
    router,
    handleAddProjectToGroup,
    openModal,
    closeModals,
  ]);

  return {
    handleAdd,
    updateLoading,
    addLoading,
    addProjectToGroupLoading,
  };
};
