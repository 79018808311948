import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { hover } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  margin-left: 20rem;
`;

export const RowWrapper = styled.div``;

export const CellWrapper = styled.div<{ hasLink?: boolean }>`
  margin: 1rem 0;
  opacity: 0.5;
  border-bottom: 1px solid transparent;
  display: flex;

  ${({ hasLink }) =>
    hasLink
      ? css`
          opacity: 1;
          cursor: pointer;
          border-bottom: 1px solid ${colors.white};

          ${hover(brightnessFilter)}
        `
      : ''}
`;

export const ToolbarWrapper = styled.div<{ visible?: boolean }>`
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);

  ${({ visible }) =>
    visible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

export const InputWrapper = styled.div`
  margin: 3rem 0;
  width: 100%;
`;

export const ActionButtonsWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
  display: flex;
  background: ${colors.outerSpace};
`;

export const ActionButton = styled.div<{ color: ColorNames }>`
  width: 60rem;
  height: 60rem;
  margin-right: 8rem;
  border-radius: ${layout.borderRadius.small}rem;
  background: ${({ color }) => colors[color]};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-out;

  ${hover(brightnessFilter)}
`;

export const IconWrapper = styled.div`
  width: 24rem;
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  overflow: visible;

  svg {
    overflow: visible;
  }
`;

export const LinkIconWrapper = styled.div`
  width: 40rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10rem;
`;
