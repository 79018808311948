import { useField } from 'components/inputs/hooks/useField';
import { useProject } from 'queries/project';
import { Project } from 'utils/api.types';

export const useRndPercentLevelField = (projectId?: Project['id']) => {
  const { data: project } = useProject(
    { projectId },
    {
      enabled: !!projectId,
    }
  );

  const [rndPercentLevel, setRndPercentLevel] = useField(
    String(project?.research_and_development?.percentage || '')
  );

  return {
    rndPercentLevel,
    setRndPercentLevel,
  };
};
