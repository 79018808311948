import { Organization } from '@u9/bob3-shared/lib/types/api.types';
import { PerformanceDashboardFilters } from 'store/performanceDashboard';
import {
  PerformanceDashboardInput,
  ProjectMode,
  ProjectStatus,
} from 'utils/api.types';

import { ParsedUrlQuery } from 'querystring';

export const parseDashboardParams = (
  params: PerformanceDashboardFilters,
  organization?: Organization,
  hasQuery?: boolean
): PerformanceDashboardInput => {
  const {
    budgetFrom,
    budgetTo,
    endDate,
    excludeClosed,
    includeEnd,
    includeStart,
    intExtFilter,
    marginVariation,
    similarProjectId,
    startDate,
    timeframe,
    verticals,
    icons,
    includeNonIcon,
    resultsByDate,
    internalPercent,
  } = params;

  return {
    resultsByDate,
    startDate: startDate ?? '',
    endDate: endDate ?? '',
    includeStart,
    includeEnd,
    includeModes: [...[ProjectMode.production]],
    includeStatuses: [
      ...(excludeClosed
        ? [ProjectStatus.active]
        : [ProjectStatus.closed, ProjectStatus.active]),
    ],
    verticals:
      verticals?.length > 0
        ? verticals
        : hasQuery
        ? []
        : organization?.dashboard_default_filter?.vertical_ids || [],
    budgetFrom: budgetFrom !== '' ? Number(budgetFrom) : null,
    budgetTo: budgetTo !== '' ? Number(budgetTo) : null,
    marginVariation: marginVariation !== '' ? Number(marginVariation) : null,
    timeframe: timeframe ?? null,
    similarProjectId: similarProjectId ?? null,
    includeNonIcon,
    icons,
    ...(intExtFilter
      ? {
          internalPercent,
        }
      : {}),
  };
};

export const getHasQuery = (queryString: ParsedUrlQuery): boolean => {
  // These 2 fields are required
  return !!queryString.startDate && !!queryString.endDate;
};
