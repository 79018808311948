import { useRouter } from 'next/router';

import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useCopyStore } from 'store';
import { replaceString } from 'utils/replace';
import { ADMIN_ROUTES, ROUTES } from 'utils/routes';

export const useSearchPlaceholder = () => {
  const copy = useCopyStore(s => s.copy);
  const router = useRouter();
  const adminRoute = useCurrentAdminRoute();

  const pageCopyMap: { [index: string]: string } = {
    [ROUTES.PROJECTS_LIST]: copy.app.navigation.projects,
    [ROUTES.PERFORMANCE]: copy.app.navigation.performance,
    [ROUTES.REPORTS_LIST]: copy.app.navigation.reports,
  } as const;
  const adminPageCopyMap: { [index: string]: string } = {
    [ADMIN_ROUTES.USERS]: copy.app.subnav.users.users,
    [ADMIN_ROUTES.BOB_ACCESS]: copy.app.subnav.users.bobAccess,
    [ADMIN_ROUTES.PROJECT_ACCESS]: copy.app.subnav.users.projectAccess,

    [ADMIN_ROUTES.INTERNAL]: copy.app.subnav.resources.internal,
    [ADMIN_ROUTES.INTERNAL_UNUSED]: copy.app.subnav.resources.internalUnused,
    [ADMIN_ROUTES.INTERNAL_UNVERIFIED]:
      copy.app.subnav.resources.internalUnverified,

    [ADMIN_ROUTES.EXTERNAL]: copy.app.subnav.resources.external,
    [ADMIN_ROUTES.EXTERNAL_UNUSED]: copy.app.subnav.resources.externalUnused,
    [ADMIN_ROUTES.EXTERNAL_UNVERIFIED]:
      copy.app.subnav.resources.externalUnverified,

    [ADMIN_ROUTES.ROLES]: copy.app.subnav.roles.roles,
    [ADMIN_ROUTES.ROLES_REQUESTS]: copy.app.subnav.roles.requests,

    [ADMIN_ROUTES.VENDOR]: copy.app.navigation.adminVendors,
    [ADMIN_ROUTES.BRANDS]: copy.app.navigation.adminBrands,
    [ADMIN_ROUTES.CLIENTS]: copy.app.navigation.adminClients,
    [ADMIN_ROUTES.ARCHIVE]: copy.app.navigation.adminArchive,
    [ADMIN_ROUTES.TERRITORY]: copy.app.navigation.adminTerritory,
    [ADMIN_ROUTES.VERTICAL]: copy.app.navigation.adminVerticals,
    [ADMIN_ROUTES.VENDOR_SERVICE]: copy.app.navigation.adminVendorServices,
  } as const;

  const pageCopy = pageCopyMap[router.route] || '';
  const adminPageCopy = adminPageCopyMap[adminRoute] || '';

  return replaceString(
    copy.app.searchInputPlaceholder,
    '{name}',
    pageCopy || adminPageCopy || ''
  );
};
