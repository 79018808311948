import { FC, memo } from 'react';

import { useDeclineProjectPermission } from 'queries/projectPermission';
import { useCopyStore, useGlobalStore } from 'store';
import { Project } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeclineProjectAccessModalProps {
  username: string;
  userEmail: string;
  projectId: Project['id'];
}

const DeclineProjectAccessModal: FC<DeclineProjectAccessModalProps> = ({
  projectId,
  userEmail,
  username,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: declineProjectPermission, isLoading } =
    useDeclineProjectPermission();

  const handleClose = () => {
    closeModal('declineProjectAccess');
  };

  const handleConfirm = async ({
    userEmail,
    projectId,
  }: {
    userEmail: string;
    projectId: Project['id'];
  }) => {
    const { data: response } = await declineProjectPermission({
      projectId,
      username: userEmail,
    });

    if (response.username) {
      closeModal('declineProjectAccess');
    }
  };

  return (
    <ConfirmModal
      title={copy.admin.declineProjectAccessModal.title}
      description={replaceString(
        copy.admin.declineProjectAccessModal.description,
        '{user}',
        username
      )}
      cancelButtonProps={{
        label: copy.admin.declineProjectAccessModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: copy.admin.declineProjectAccessModal.confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: () => handleConfirm({ userEmail, projectId }),
        loading: isLoading,
        disabled: isLoading,
      }}
    />
  );
};

export default memo(DeclineProjectAccessModal);
