import { rgba } from 'polished';
import { createGlobalStyle, css } from 'styled-components';

import { setTypography } from './mixins';
import resetCSS from './reset';
import { colors } from './theme';

import '@bryntum/scheduler/scheduler.material.css';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
  }

  ::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  html {
    position: absolute;
    overflow-x: hidden;
    font-size: 3.125%; // 16px * 3.125% = 0.5px; (16px is browser default)
  }

  /**
    Safari has issues computing working with relative font sizes on html tag.
    This selector targets safari only (source: https://stackoverflow.com/questions/16348489/is-there-a-way-to-apply-styles-to-safari-only)
    We don't want to do that for every browser, because setting font-size to absolute value breaks accessibility
    Absolute values do not respect browser global font-size settings. Percentages do - that's why we keep percentages for all the other browsers.
  */
  _::-webkit-full-page-media,
  _:future,
  html {
    font-size: 0.5px;
  }

  body {
    background: ${colors.white};
    color: ${colors.outerSpace};
    ${({ theme: { locale } }) => setTypography('body4', locale)}
    white-space: pre-wrap;
    font-variant-numeric: tabular-nums;
  }

  button,
  input,
  textarea,
  select,
  dialog {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  svg {
    outline: none;
    display: block;
  }

  // 100% width and height is important to safari, but breaks react query devtools
  svg:not(#ReactQueryDevtoolsPanel svg) {
    width: 100%;
    height: 100%;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  .cashflow-paid-invoices {
    background-color: ${colors.mountainMeadow} !important;
    border-inline-start: 2px solid ${colors.mountainMeadow} !important;
  }

  .cashflow-unpaid-invoices {
    background-color: ${colors.blueRibbon} !important;
    border-inline-start: 2px solid ${colors.blueRibbon} !important;
  }

  .cashflow-future-invoices {
    background-color: ${colors.electricViolet} !important;
    border-inline-start: 2px solid ${colors.electricViolet} !important;
  }

  .b-scheduler {
    width: 100%;
  }

  .b-selected {
    background-color: transparent !important;
  }

  .cashflow-spacer {
    background: ${rgba(colors.outerSpace, 0.05)} !important;
  }
`;

const sentryFeedbackDialog = css`
  .sentry-error-embed-wrapper {
    white-space: normal;
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
  ${sentryFeedbackDialog}
`;
