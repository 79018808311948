import { MutableRefObject, useCallback } from 'react';

import { ChangeItemCategoryModalProps } from 'components/modals/ChangeItemCategoryModal/ChangeItemCategoryModal';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useGlobalStore } from 'store';

interface UseChangeItemCategory {
  onConfirmChangeCategory: () => void;
  initialItem: MutableRefObject<AdminListItem | undefined>;
  currentCategory: string;
  isLoading?: boolean;
}

export const useChangeItemCategory = ({
  onConfirmChangeCategory,
  initialItem,
  currentCategory,
}: UseChangeItemCategory) => {
  const openModal = useGlobalStore(s => s.openModal);
  const closeModal = useGlobalStore(s => s.closeModal);

  const openChangeCategoryModal = useCallback(() => {
    openModal<ChangeItemCategoryModalProps>('changeItemCategoryModal', {
      currentCategory,
      initialItem,
      onConfirmChangeCategory,
    });
  }, [currentCategory, initialItem, onConfirmChangeCategory, openModal]);

  const closeChangeCategoryModal = useCallback(() => {
    closeModal('changeItemCategoryModal');
  }, [closeModal]);

  return {
    openChangeCategoryModal,
    closeChangeCategoryModal,
  };
};
