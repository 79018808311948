import { useCallback } from 'react';

import { ProjectModalProps } from 'components/modals/ProjectModal/ProjectModal';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

interface UseOpenProjectInfoActionProps {
  currentProjectId?: Project['id'];
}

export const useOpenProjectInfoAction = ({
  currentProjectId,
}: UseOpenProjectInfoActionProps) => {
  const openModal = useGlobalStore(s => s.openModal);

  const openProjectInfoAction = useCallback(() => {
    // https://github.com/radix-ui/primitives/issues/2122
    setTimeout(() => {
      openModal<ProjectModalProps>('editProject', {
        projectId: currentProjectId,
      });
    }, 0);
  }, [currentProjectId, openModal]);

  return [openProjectInfoAction];
};
