import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { BrandRequest } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';

export const BRAND_REQUESTS_KEY = 'BRAND_REQUESTS';

export const useBrandRequests = (options?: UseQueryOptions<BrandRequest[]>) => {
  const getBrandRequests = useApiStore(s => s.apiClient.getBrandRequests);
  return useQuery({
    queryKey: BRAND_REQUESTS_KEY,
    queryFn: async () => (await getBrandRequests()).data,
    ...options,
  });
};

export const useAddBrandRequest = () => {
  const addBrandRequest = useApiStore(s => s.apiClient.addBrandRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addBrandRequest>[0]) =>
      addBrandRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(BRAND_REQUESTS_KEY);
      },
    }
  );
};

export const useDeleteBrandRequest = () => {
  const deleteBrandRequest = useApiStore(s => s.apiClient.deleteBrandRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteBrandRequest>[0]) =>
      deleteBrandRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BRAND_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};
