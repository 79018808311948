import { useCallback } from 'react';

import { DeleteProjectModalProps } from 'components/modals/DeleteProjectModal/DeleteProjectModal';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

interface UseDeleteActionProps {
  currentProjectId?: Project['id'];
}

export const useDeleteAction = ({ currentProjectId }: UseDeleteActionProps) => {
  const openModal = useGlobalStore(s => s.openModal);

  const deleteAction = useCallback(() => {
    if (currentProjectId) {
      // https://github.com/radix-ui/primitives/issues/2122
      setTimeout(() => {
        openModal<DeleteProjectModalProps>('deleteProject', {
          projectId: currentProjectId,
        });
      }, 0);
    }
  }, [currentProjectId, openModal]);

  return [deleteAction];
};
