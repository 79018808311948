import { FC, memo, useCallback } from 'react';

import { useCurrentProject } from 'queries/project';
import { useDeleteProjectComment } from 'queries/projectComments';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectComment } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteCommentModalProps {
  commentId: ProjectComment['id'];
}

const DeleteCommentModal: FC<DeleteCommentModalProps> = ({ commentId }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();

  const { mutateAsync: handleDelete, isLoading: deleteLoading } =
    useDeleteProjectComment();

  const handleClose = useCallback(() => {
    closeModal('deleteComment');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (currentProject?.id) {
      const deleted = await handleDelete({
        projectSlug: currentProject?.id,
        commentId,
      });
      if (deleted?.data?.deleted) {
        handleClose();
      }
    }
  }, [handleDelete, currentProject?.id, commentId, handleClose]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: deleteLoading,
        label: copy.project.deleteCommentModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: deleteLoading,
        label: copy.project.deleteCommentModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteLoading,
        dataCy: 'project-comment__delete-confirm',
      }}
      description={copy.project.deleteCommentModal.title}
      title={copy.project.deleteCommentModal.description}
    />
  );
};

export default memo(DeleteCommentModal);
