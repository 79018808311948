import { FC, memo, useCallback } from 'react';

import { useUpdateProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ArchiveItem } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface RestoreDeletedItemModalProps {
  item: ArchiveItem;
}

const RestoreDeletedItemModal: FC<RestoreDeletedItemModalProps> = ({
  item,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: updateProject, isLoading: isProjectUpdateLoading } =
    useUpdateProject();
  const isAnyLoading = isProjectUpdateLoading;

  const handleClose = useCallback(() => {
    closeModal('restoreDeletedItemModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (item.type === 'project') {
      updateProject({ projectId: item.id, project: { deleted: false } });
    }
    handleClose();
  }, [handleClose, item.id, item.type, updateProject]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: isAnyLoading,
        label: copy.admin.restoreDeletedItemModal.declineButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: isAnyLoading,
        label: copy.admin.restoreDeletedItemModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isAnyLoading,
        dataCy: 'restore-item__confirm-button',
      }}
      description={replaceString(
        copy.admin.restoreDeletedItemModal.description,
        '{name}',
        item.name
      )}
      title={copy.admin.restoreDeletedItemModal.title}
    />
  );
};

export default memo(RestoreDeletedItemModal);
