import { rgba } from 'polished';
import { FC, memo } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useProjectIcons } from 'hooks/useProjectIcons';
import { useCopyStore, usePerformanceDashboardStore } from 'store';
import { useSvgIconList } from 'u9/hooks';
import { colors } from 'utils/styles/theme';

import * as Styled from './FlagDropdown.styles';

export interface FlagDropdown {}

const FlagDropdown: FC<FlagDropdown> = () => {
  const activeItems = usePerformanceDashboardStore(s => s.icons);
  const setIcons = usePerformanceDashboardStore(s => s.setIcons);
  const setIncludeNonIcon = usePerformanceDashboardStore(
    s => s.setIncludeNonIcon
  );
  const includeNonIcon = usePerformanceDashboardStore(s => s.includeNonIcon);

  const copy = useCopyStore(s => s.copy);
  const projectIcons = useProjectIcons();

  const generalTabCopy = copy.performanceDashboard.filtersModal.generalTab;

  const isAtLeastOneIconSelected = activeItems.length > 0;
  const isAnyOptionSelected = isAtLeastOneIconSelected || includeNonIcon;

  const items = projectIcons.map(projectIcon => ({
    displayName: projectIcon.displayName,
    id: projectIcon.icon,
    icon: projectIcon.icon,
    preventClosing: true,
    hasCheckbox: true,
  }));

  const svgIconElements = useSvgIconList(activeItems, true);

  const label = (
    <Styled.LabelIconsWrapper>
      {includeNonIcon && (
        <Styled.NoIconWrapper>
          {generalTabCopy.noIconLabel}
        </Styled.NoIconWrapper>
      )}
      {svgIconElements.map((SvgIcon, index) => (
        <Styled.LabelIconWrapper key={activeItems[index]}>
          <Styled.LabelIcon>
            <SvgIcon />
          </Styled.LabelIcon>
        </Styled.LabelIconWrapper>
      ))}
    </Styled.LabelIconsWrapper>
  );

  return (
    <Styled.Wrapper>
      <Styled.Label>{generalTabCopy.iconsLabel}</Styled.Label>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(svgIconElements.length > 0 || includeNonIcon
              ? {}
              : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {svgIconElements.length > 0 || includeNonIcon
            ? label
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuCheckboxItem
            checked={false}
            onSelect={event => {
              event.preventDefault();
              if (isAnyOptionSelected) {
                setIcons([]);
                setIncludeNonIcon(false);
              } else {
                const allIcons = projectIcons.map(({ icon }) => icon);
                setIcons(allIcons);
                setIncludeNonIcon(true);
              }
            }}
          >
            {isAnyOptionSelected
              ? generalTabCopy.deselectAll
              : generalTabCopy.selectAll}
            <DropdownMenuSeparator />
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={includeNonIcon}
            onSelect={event => {
              event.preventDefault();
              setIncludeNonIcon(!includeNonIcon);
            }}
          >
            {generalTabCopy.includeNonIcon}
          </DropdownMenuCheckboxItem>
          {items.map(({ id, displayName }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={activeItems.includes(id)}
              icon={id}
              onSelect={event => {
                event.preventDefault();
                if (activeItems.includes(id)) {
                  setIcons(activeItems.filter(prevItem => prevItem !== id));
                } else {
                  setIcons([...activeItems, id]);
                }
              }}
            >
              {displayName}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.Wrapper>
  );
};

export default memo(FlagDropdown);
