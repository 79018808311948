import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CommentWrapper = styled.div`
  width: 70%;
  display: flex;
`;

export const TargetWrapper = styled.div`
  width: 25%;
`;
