import * as Dialog from '@radix-ui/react-dialog';
import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

const titleHeight = '220rem';
const maxModalHeight = '90vh';
const buttonsHeight = '200rem';

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const Content = styled(Dialog.Content)`
  max-height: ${maxModalHeight};
  min-width: 1200rem;
  border-radius: ${layout.borderRadius.big}rem;
  color: ${colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${contentShow} 150ms;
  overflow: hidden;
`;

export const TitleWrapper = styled.div<{
  color: ColorNames;
}>`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  color: ${colors.white};
  height: ${titleHeight};
  display: flex;
  align-items: center;
  padding-left: 80rem;
  background: ${({ color }) => colors[color]};
  padding-right: 200rem;
`;

export const Title = styled(Dialog.Title)``;

export const ContentWrapper = styled.div`
  background: ${colors.outerSpace};
  border-bottom-left-radius: ${layout.borderRadius.big}rem;
  border-bottom-right-radius: ${layout.borderRadius.big}rem;
`;

export const ContentScroll = styled.div<{
  paddingBottom?: number;
  zeroPadding: boolean;
  hasButton: boolean;
}>`
  max-height: calc(
    ${maxModalHeight} - ${titleHeight} -
      ${({ hasButton }) => (hasButton ? buttonsHeight : '0rem')}
  );
  padding: 80rem 80rem
    ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}rem` : '260rem')};

  ${({ zeroPadding }) =>
    zeroPadding
      ? css`
          padding: 0;
        `
      : ''}
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${colors.outerSpace};
  width: 100%;
  height: ${buttonsHeight};
  padding-right: 80rem;
`;

const overlayShow = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: ${rgba(colors.white, 0.5)};
  animation: ${overlayShow} 150ms;
`;

export const CloseButton = styled(Dialog.Close)`
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background: ${colors.outerSpace};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80rem;
  right: 80rem;
  cursor: pointer;

  ${hover(brightnessFilter)};

  &:focus-visible {
    ${focusVisible};
  }
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
