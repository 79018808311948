import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useResolveResourceDuplicates } from 'queries/duplicates';
import { useCopyStore, useGlobalStore } from 'store';
import {
  ResolveResourceDuplicatesInput,
  Resource,
  ResourceDuplicate,
  ResourceType,
} from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ResolveResourceDuplicates.styles';

export interface ResolveResourceDuplicatesProps {
  resourceDuplicates: ResourceDuplicate[];
}

const OPTION_INCORRECT = 'INCORRECT';
const OPTION_SEPARATE = 'SEPARATE';
const OPTION_CORRECT = 'CORRECT';

const ResolveResourceDuplicates: FC<ResolveResourceDuplicatesProps> = ({
  resourceDuplicates,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: resolve, isLoading: isResolveLoading } =
    useResolveResourceDuplicates();
  const [isCorrectResourceDropdownOpen, setIsCorrectResourceDropdownOpen] =
    useState(false);

  const [resolveOptions, setResolveOptions] = useState(
    resourceDuplicates.map(({ id }, index) =>
      index === 0
        ? { id, option: OPTION_CORRECT }
        : { id, option: OPTION_INCORRECT }
    )
  );

  const correctResourceId = resolveOptions.find(
    ({ option }) => option === OPTION_CORRECT
  )?.id;
  const correctResource = resourceDuplicates?.find(
    ({ id }) => id === correctResourceId
  );

  const close = () => {
    closeModal('resolveResourceDuplicates');
  };

  const getFormattedResource = (resource?: ResourceDuplicate) => {
    const typeText =
      resource?.type === ResourceType.external
        ? copy.app.territoryShort.external
        : resource?.type === ResourceType.mfr
        ? copy.app.territoryShort.mfr
        : copy.app.territoryShort.internal;
    return `${resource?.name} (${resource?.email}) - ${typeText} - ${resource?.role}`;
  };

  const resolveItems = [
    {
      id: OPTION_INCORRECT,
      label: copy.admin.duplicates.modal.optionIncorrect,
    },
    { id: OPTION_SEPARATE, label: copy.admin.duplicates.modal.optionSeparate },
  ];

  const itemsToResolve = resourceDuplicates?.filter(
    ({ id }) => id !== correctResourceId
  );

  const assignNewCorrectResource = (resourceId: Resource['id']) => {
    setResolveOptions(
      resolveOptions.map(({ id, option }) => ({
        id,
        option:
          option === OPTION_CORRECT
            ? OPTION_INCORRECT
            : id === resourceId
            ? OPTION_CORRECT
            : option,
      }))
    );
  };

  const handleResolveItems = async () => {
    await resolve(
      resolveOptions
        .filter(({ option }) => option !== OPTION_CORRECT)
        .map(({ id, option }) => {
          if (option === OPTION_INCORRECT) {
            return {
              id,
              parent_id: correctResourceId,
              action: 'merge',
            } as ResolveResourceDuplicatesInput;
          } else {
            return {
              id,
              parent_id: correctResourceId,
              action: 'split',
            } as ResolveResourceDuplicatesInput;
          }
        })
    );
    close();
  };

  return (
    <ConfigModal
      title={copy.admin.duplicates.modal.title}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.CorrectResourceWrapper>
          <Combobox
            open={isCorrectResourceDropdownOpen}
            onOpenChange={setIsCorrectResourceDropdownOpen}
            label={copy.admin.duplicates.modal.correctLabel}
          >
            <ComboboxTrigger label={getFormattedResource(correctResource)} />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  {resourceDuplicates?.map(resource => (
                    <ComboboxItem
                      key={resource.id}
                      selected={resource.id === correctResourceId}
                      onSelect={() => {
                        assignNewCorrectResource(resource.id);
                        setIsCorrectResourceDropdownOpen(false);
                      }}
                    >
                      {getFormattedResource(resource)}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.CorrectResourceWrapper>
        <Styled.ResourcesToResolveLabel>
          {copy.admin.duplicates.modal.description}
        </Styled.ResourcesToResolveLabel>
        <Styled.ResourcesToResolve>
          {itemsToResolve?.map(resource => (
            <Styled.ResourceToResolveItem key={resource.id}>
              <Styled.Resource>
                {getFormattedResource(resource)}
              </Styled.Resource>
              <Styled.ChoiceDropdown>
                <Select
                  value={
                    resolveOptions.find(({ id }) => id === resource.id)?.option
                  }
                  onValueChange={newOption => {
                    setResolveOptions(prev =>
                      prev.map(item =>
                        item.id === resource.id
                          ? {
                              id: item.id,
                              option: newOption,
                            }
                          : item
                      )
                    );
                  }}
                  label={copy.admin.duplicates.modal.selectLabel}
                >
                  {resolveItems.map(({ id, label }) => (
                    <SelectItem key={id} value={id}>
                      {label}
                    </SelectItem>
                  ))}
                </Select>
              </Styled.ChoiceDropdown>
            </Styled.ResourceToResolveItem>
          ))}
        </Styled.ResourcesToResolve>
        <Styled.Buttons>
          <Button
            label={copy.admin.duplicates.modal.cancelButton}
            color={ColorNames.nandor}
            icon="close"
            onClick={close}
            disabled={isResolveLoading}
          />
          <Button
            label={copy.admin.duplicates.modal.confirmButton}
            color={currentColor}
            icon="check"
            onClick={handleResolveItems}
            disabled={isResolveLoading}
            loading={isResolveLoading}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ResolveResourceDuplicates);
