import countryCodes from 'country-codes-list';
import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import useDebounce from 'hooks/useDebounce';
import { useValidateAddress } from 'queries/address';
import { useCopyStore } from 'store';
import { googleValidationCountries } from 'utils/googleValidationCountries';

import * as Styled from './AddressFields.styles';

export interface ModalAddressFieldsProps {
  addressLine1: string;
  setAddressLine1: Dispatch<SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  countryCode: string | null;
  setCountryCode: Dispatch<SetStateAction<string | null>>;
  theme?: 'light' | 'dark';
  disabled?: boolean;
}

const countryCodesList = countryCodes.all();

const ModalAddressFields: FC<ModalAddressFieldsProps> = ({
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
  theme = 'dark',
  disabled,
}) => {
  const copy = useCopyStore(s => s.copy);
  const [countryOpen, setCountryOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const {
    mutate: validateAddress,
    data: validatedAddress,
    isLoading: validationLoading,
  } = useValidateAddress();

  const addressToDebounce = `${addressLine1}${addressLine2}${city}${zipCode}${countryCode}`;

  const addressDebounced = useDebounce(addressToDebounce, 1000);

  const isDebouncing = addressToDebounce !== addressDebounced;

  const googleCanValidate = googleValidationCountries.some(
    ({ countryCode: code }) => countryCode === code
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (
      mounted &&
      !isDebouncing &&
      countryCode &&
      googleCanValidate &&
      (addressLine1 || addressLine2)
    ) {
      validateAddress({
        address_lines: [addressLine1, addressLine2].filter(Boolean),
        ...(city ? { city: city } : {}),
        ...(zipCode ? { postal_code: zipCode } : {}),
        country_code: countryCode,
      });
    }
    // addressDebounced contains the rerun rules for other fields
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDebounced]);

  const isAddressLine1Diff =
    addressLine1 !== (validatedAddress?.data.address_lines?.[0] ?? '');
  const isAddressLine2Diff =
    addressLine2 !== (validatedAddress?.data.address_lines?.[1] ?? '');
  const isCityDiff = city !== (validatedAddress?.data.city ?? '');
  const isZipCodeDiff = zipCode !== (validatedAddress?.data.postal_code ?? '');
  const isCountryCodeDiff =
    countryCode !== (validatedAddress?.data.country_code ?? null);

  const isAddressDiff =
    isAddressLine1Diff ||
    isAddressLine2Diff ||
    isCityDiff ||
    isZipCodeDiff ||
    isCountryCodeDiff;

  const showSuggestedAddress = useDebounce(
    googleCanValidate &&
      !!validatedAddress?.data.formatted_address &&
      !isDebouncing &&
      !validationLoading &&
      isAddressDiff,
    500
  );

  const handleAcceptAddress = () => {
    const data = validatedAddress?.data;
    setZipCode(data?.postal_code ?? '');
    setCity(data?.city ?? '');
    setAddressLine1(data?.address_lines?.[0] ?? '');
    setAddressLine2(data?.address_lines?.[1] ?? '');
    setCountryCode(data?.country_code ?? '');
  };

  return (
    <>
      <Styled.Row>
        <Styled.FullWidth>
          <Combobox
            open={countryOpen}
            onOpenChange={setCountryOpen}
            label={copy.app.address.country}
            colorVariant={theme}
          >
            <ComboboxTrigger
              label={
                countryCodesList?.find(
                  ({ countryCode: code }) => code === countryCode
                )?.countryNameEn
              }
              disabled={disabled}
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  {countryCodesList?.map(
                    ({ countryCode: code, countryNameEn, flag }) => (
                      <ComboboxItem
                        key={code}
                        selected={code === countryCode}
                        onSelect={() => {
                          setCountryCode(prev => (prev === code ? null : code));
                          setCountryOpen(false);
                        }}
                      >
                        {`${flag} ${countryNameEn}`}
                      </ComboboxItem>
                    )
                  )}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.FullWidth>
      </Styled.Row>
      <Styled.Row>
        <Styled.FullWidth>
          <FormInput
            value={addressLine1}
            onChange={setAddressLine1}
            placeholder={copy.app.address.addressLine1}
            theme={theme}
            disabled={disabled}
          />
        </Styled.FullWidth>
      </Styled.Row>
      <Styled.Row>
        <Styled.FullWidth>
          <FormInput
            value={addressLine2}
            onChange={setAddressLine2}
            placeholder={copy.app.address.addressLine2}
            theme={theme}
            disabled={disabled}
          />
        </Styled.FullWidth>
      </Styled.Row>
      <Styled.Row>
        <FormInput
          value={zipCode}
          onChange={setZipCode}
          placeholder={copy.app.address.zipCode}
          theme={theme}
          disabled={disabled}
        />
        <Styled.FieldSpace />
        <FormInput
          value={city}
          onChange={setCity}
          placeholder={copy.app.address.city}
          theme={theme}
          disabled={disabled}
        />
      </Styled.Row>
      <Styled.Row>
        <Styled.FullWidth>
          <Styled.SuggestedAddress isVisible={showSuggestedAddress}>
            <Styled.SuggestedAddressLabel theme={theme}>
              {copy.app.address.suggested}
            </Styled.SuggestedAddressLabel>
            <Styled.SuggestedAddressButton
              onClick={handleAcceptAddress}
              theme={theme}
            >
              {copy.app.address.accept}{' '}
              {validatedAddress?.data.formatted_address}
            </Styled.SuggestedAddressButton>
          </Styled.SuggestedAddress>
        </Styled.FullWidth>
      </Styled.Row>
    </>
  );
};

export default memo(ModalAddressFields);
