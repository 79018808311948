import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import { CopyStoreProps, CopyStoreType } from './copy.types';

type CopyStore = ReturnType<typeof createCopyStore>;

const createCopyStore = (initProps: CopyStoreProps) => {
  return createStore<CopyStoreType>()(() => ({
    copy: initProps.copy,
    locale: initProps.locale,
    currentPage: initProps.currentPage,
  }));
};

export const CopyStoreContext = createContext<CopyStore | null>(null);

export default function useCopyStore<T>(
  selector: (state: CopyStoreType) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(CopyStoreContext);
  if (!store) throw new Error('Missing CopyStore.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

export const useInitCopyStore = (initProps: CopyStoreProps) => {
  const storeRef = useRef<CopyStore>();
  if (!storeRef.current) {
    storeRef.current = createCopyStore(initProps);
  }

  return storeRef;
};
