import isValidProp from '@emotion/is-prop-valid';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as Sentry from '@sentry/react';
import { MotionConfig } from 'framer-motion';
import Error from 'next/error';
import { SessionProvider } from 'next-auth/react';
import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';

import LayoutManager from 'components/layouts/LayoutManager/LayoutManager';
import { useGTM } from 'hooks/useGTM';
import QueryClientProvider from 'queries/QueryClientProvider/QueryClientProvider';
import { setupStoreDevTools } from 'store';
import { ApiContext, useInitApiStore } from 'store/api';
import { CopyStoreContext, useInitCopyStore } from 'store/copy';
import { GlobalStoreContext, useInitGlobalStore } from 'store/global';
import {
  ProjectsListStoreContext,
  useInitProjectsListStore,
} from 'store/projectsList';
import { AppProps, PageProps } from 'types/page';
import ErrorBoundary from 'u9/components/ErrorBoundary/ErrorBoundary';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import { getApiClient } from 'utils/api';
import filterSentryConsoleErrors from 'utils/filterSentryConsoleErrors';
import { getDefaultProjectsListFilter } from 'utils/getDefaultProjectsListFilter';
import { ROUTES } from 'utils/routes';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';
import { AppThemeProvider } from 'utils/ThemeProvider';

import 'intersection-observer';

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    release: process.env.BITBUCKET_BUILD_NUMBER,
    beforeSend: event => filterSentryConsoleErrors(event),
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

const App = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const [isMounted, setMounted] = useState(false);

  const apiStoreRef = useInitApiStore({
    apiClient: getApiClient(pageProps.authData?.token ?? undefined),
  });

  const globalStoreRef = useInitGlobalStore({
    searchbarValue:
      typeof router.query.search === 'string' ? router.query.search : '',
    subdomain: pageProps.subdomain,
  });
  const copyStoreRef = useInitCopyStore({
    copy: pageProps.initialCopy,
    locale: pageProps.initialLocale,
    currentPage: pageProps.currentPage,
  });

  const projectsListStoreRef = useInitProjectsListStore({
    listFilter: getDefaultProjectsListFilter(
      pageProps?.authData?.session ?? null
    ),
  });

  const PageComponent = useMemo(
    () => <Component key={router.route} {...pageProps} router={router} />,
    [Component, pageProps, router]
  );

  const ErrorBoundaryComponent = useMemo(
    () => (process.env.ENV === 'local' ? ErrorBoundary : Sentry.ErrorBoundary),
    []
  );

  useEffect(() => {
    setupStoreDevTools();
    setMounted(true);
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, [isMounted]);

  useGTM(pageProps.authData?.session);

  // TODO: Remove this after trial ends
  useEffect(() => {
    localStorage.removeItem('b-scheduler-trial-start');
  }, []);

  if (router.route === '/test') return <>{PageComponent}</>;
  if (router.route === ROUTES.AUTH) return <>{PageComponent}</>;
  if (pageProps.subdomain === 'auth' && router.route !== ROUTES.FORBIDDEN)
    return null;

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: pageProps.initialLocale }}>
        <MotionConfig isValidProp={isValidProp}>
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />

          <ErrorBoundaryComponent
            {...(process.env.ENV !== 'local'
              ? {
                  fallback: <Error statusCode={isMounted ? 400 : 500} />,
                  ...(isMounted
                    ? {
                        showDialog: true,
                        // TODO: Customise Sentry user feedback widget
                        dialogOptions: {},
                      }
                    : {}),
                }
              : {})}
          >
            <Head {...pageProps.initialCopy.head} />

            <ApiContext.Provider value={apiStoreRef.current || null}>
              <GlobalStoreContext.Provider
                value={globalStoreRef.current || null}
              >
                <CopyStoreContext.Provider value={copyStoreRef.current || null}>
                  <ProjectsListStoreContext.Provider
                    value={projectsListStoreRef.current || null}
                  >
                    <SessionProvider session={pageProps?.authData?.session}>
                      <QueryClientProvider>
                        <Hydrate state={pageProps.dehydratedState}>
                          <DndProvider backend={HTML5Backend}>
                            <AppThemeProvider>
                              <Tooltip.Provider delayDuration={500}>
                                <LayoutManager>{PageComponent}</LayoutManager>
                              </Tooltip.Provider>
                            </AppThemeProvider>
                          </DndProvider>

                          {process.env.IS_DEBUG && (
                            <ReactQueryDevtools
                              initialIsOpen={false}
                              position="bottom-right"
                            />
                          )}
                        </Hydrate>
                      </QueryClientProvider>
                    </SessionProvider>
                  </ProjectsListStoreContext.Provider>
                </CopyStoreContext.Provider>
              </GlobalStoreContext.Provider>
            </ApiContext.Provider>
          </ErrorBoundaryComponent>

          {process.env.IS_DEBUG && <Version />}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
