import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${rgba(colors.white, 0.5)};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
`;

export const Label = styled.div`
  width: 45%;
`;

export const Value = styled.div`
  width: 45%;
  text-align: right;
  color: ${colors.white};
`;

export const PreviewTitle = styled.div`
  ${({ theme: { locale } }) => setTypography('heading3', locale)}
`;

export const ContentWrapper = styled.div`
  border: 1px solid ${rgba(colors.white, 0.5)};
  padding: 10rem 20rem;
  margin-top: 20rem;
`;
