import { FC, memo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useProjectFieldLists } from 'queries/projectFieldLists';
import { useCopyStore, useGlobalStore } from 'store';
import { replaceString } from 'utils/replace';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CustomTargetModal.styles';

export interface CustomTargetModalProps {}

const CustomTargetModal: FC<CustomTargetModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const { mutate: updateProject, isLoading } = useUpdateProject();
  const { data: projectFieldLists } = useProjectFieldLists();
  const [target, setTarget] = useState(
    String(currentProject?.custom_target || '')
  );

  const handleTargetChange = (value = '') => {
    const constrainedValue = Math.max(Math.min(Number(value), 100), 0);
    setTarget(String(constrainedValue));
  };

  const items =
    projectFieldLists?.custom_target_comment?.map(comment => {
      return { displayName: comment, id: comment };
    }) || [];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeCommentId, setActiveCommentId] = useState<string | null>(
    currentProject?.custom_target_comment || ''
  );
  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [commentToCreate, setCommentToCreate] = useState('');

  const handleSave = () => {
    if (currentProject?.id) {
      updateProject({
        projectId: currentProject?.id,
        project: {
          custom_target: Number(target),
          custom_target_comment: commentToCreate || activeCommentId || '',
        },
      });
    }

    closeModal('customTargetModal');
  };

  const modalCopy = copy.app.customTargetModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      onCloseClick={() => closeModal('customTargetModal')}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        onClick: handleSave,
        loading: isLoading,
        icon: 'check',
        dataCy: 'custom-target-modal__save-button',
      }}
      color={currentColor}
    >
      <Styled.Wrapper>
        <Styled.CommentWrapper>
          <Combobox
            open={dropdownOpen}
            onOpenChange={isOpen => {
              setDropdownOpen(isOpen);
              setDropdownSearchValue('');
            }}
            label={modalCopy.typeLabel}
          >
            <ComboboxTrigger
              label={
                commentToCreate ||
                items.find(({ id }) => id === activeCommentId)?.displayName
              }
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput
                  value={dropdownSearchValue}
                  onValueChange={setDropdownSearchValue}
                />
                <ComboboxEmpty />
                <ComboboxList>
                  {dropdownSearchValue.length > 0 && (
                    <ComboboxItem
                      value={dropdownSearchValue}
                      onSelect={() => {
                        setCommentToCreate(dropdownSearchValue);
                        setDropdownOpen(false);
                      }}
                    >
                      {replaceString(
                        copy.app.comboboxCreateButton,
                        '{name}',
                        dropdownSearchValue
                      )}
                    </ComboboxItem>
                  )}
                  {items.map(({ displayName, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeCommentId === id}
                      onSelect={() => {
                        setCommentToCreate('');
                        setActiveCommentId(prev =>
                          prev === id ? null : id ?? null
                        );
                        setDropdownOpen(false);
                      }}
                    >
                      {displayName}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.CommentWrapper>
        <Styled.TargetWrapper>
          <FormInput
            value={target}
            onChange={handleTargetChange}
            placeholder={modalCopy.targetLabel}
            mask="integer"
            dataCy="custom-target-modal__target-field"
          />
        </Styled.TargetWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CustomTargetModal);
