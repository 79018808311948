import { useQuery, UseQueryOptions } from 'react-query';

import { useApiStore } from 'store';
import { AdminAlerts } from 'utils/api.types';

export const ADMIN_ALERTS_KEY = 'ADMIN_ALERTS';

export const useAdminAlerts = (options?: UseQueryOptions<AdminAlerts>) => {
  const getAdminAlerts = useApiStore(s => s.apiClient.getAdminAlerts);
  return useQuery({
    queryKey: ADMIN_ALERTS_KEY,
    queryFn: async () => (await getAdminAlerts()).data,
    ...options,
  });
};
