import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 80rem;
  flex: 1;
`;

export const StartPicker = styled.div`
  display: flex;
  flex: 1;
`;

export const EndPicker = styled.div`
  display: flex;
  flex: 1;
`;
