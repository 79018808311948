import { Dispatch, FC, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useClients } from 'queries/clients';
import { useCopyStore } from 'store';
import { Client } from 'utils/api.types';

import * as Styled from '../DeleteAdminItemModal.styles';

export interface ClientContentProps {
  item: AdminListItem;
  activeClientId: Client['id'] | null;
  setActiveClientId: Dispatch<SetStateAction<Client['id'] | null>>;
}

const ResourceContent: FC<ClientContentProps> = ({
  item,
  activeClientId,
  setActiveClientId,
}) => {
  const { data: clients } = useClients();
  const copy = useCopyStore(s => s.copy);
  const clientsWithoutCurrent = clients?.filter(
    ({ id }) => id !== item.clientId
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Styled.SubPromptWrapper>
      <Styled.SubPrompt>
        {copy.admin.deleteModal.clientDuplicatePrompt}
      </Styled.SubPrompt>
      <Combobox
        open={dropdownOpen}
        onOpenChange={setDropdownOpen}
        label={copy.admin.deleteModal.clientDuplicateDropdown}
      >
        <ComboboxTrigger
          label={
            clientsWithoutCurrent?.find(({ id }) => id === activeClientId)?.name
          }
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput />
            <ComboboxEmpty />
            <ComboboxList>
              {clientsWithoutCurrent?.map(({ name, email, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeClientId === id}
                  onSelect={() => {
                    setActiveClientId(activeClientId === id ? null : id);
                    setDropdownOpen(false);
                  }}
                >
                  {`${name} ${email ? `(${email})` : ''}`}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.SubPromptWrapper>
  );
};

export default ResourceContent;
