import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Role } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';
import { ROLES_REQUESTS_KEY } from './rolesRequests';

export const ROLES_KEY = 'ROLES';

export const useRoles = (options?: UseQueryOptions<Role[]>) => {
  const getRoles = useApiStore(s => s.apiClient.getRoles);
  return useQuery({
    queryKey: ROLES_KEY,
    queryFn: async () => (await getRoles()).data?.rows,
    ...options,
  });
};

export const useAddRole = () => {
  const addRole = useApiStore(s => s.apiClient.addRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addRole>[0]) => addRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ROLES_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(ROLES_REQUESTS_KEY);
      },
    }
  );
};

export const useUpdateRole = () => {
  const updateRole = useApiStore(s => s.apiClient.updateRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateRole>[0]) => updateRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ROLES_KEY);
      },
    }
  );
};

export const useDeleteRole = () => {
  const deleteRole = useApiStore(s => s.apiClient.deleteRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteRole>[0]) => deleteRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ROLES_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};
