import { Session } from 'next-auth';

import { getCheckAnyAccessRight } from './accessRights';

export const getDefaultProjectsListFilter = (session: Session | null) => {
  const isAdmin =
    session?.accessRights &&
    getCheckAnyAccessRight(session.accessRights, [
      'administrator',
      'owner',
      'super admin',
    ]);
  const listFilter = isAdmin ? 'all' : 'my';
  return listFilter;
};
