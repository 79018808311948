import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { MfrRate } from 'utils/api.types';

export const SUPPORTED_MFR_RATES = 'SUPPORTED_MFR_RATES';

export const useSupportedMfrRate = (options?: UseQueryOptions<MfrRate[]>) => {
  const getSupportedMfrRates = useApiStore(
    s => s.apiClient.getSupportedMfrRates
  );
  return useQuery({
    queryKey: SUPPORTED_MFR_RATES,
    queryFn: async () => (await getSupportedMfrRates()).data,
    ...options,
  });
};

export const useAddSupportedMfrRate = () => {
  const addSupportedMfrRates = useApiStore(
    s => s.apiClient.addSupportedMfrRates
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addSupportedMfrRates>[0]) =>
      addSupportedMfrRates(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SUPPORTED_MFR_RATES);
      },
    }
  );
};

export const useDeleteSupportedMfrRate = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSupportedMfrRates>>,
    unknown,
    Parameters<typeof deleteSupportedMfrRates>[0]
  >
) => {
  const deleteSupportedMfrRates = useApiStore(
    s => s.apiClient.deleteSupportedMfrRates
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteSupportedMfrRates>[0]) =>
      deleteSupportedMfrRates(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SUPPORTED_MFR_RATES);
      },
      ...options,
    }
  );
};

export const useUpdateSupportedMfrRate = () => {
  const updateSupportedMfrRates = useApiStore(
    s => s.apiClient.updateSupportedMfrRates
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateSupportedMfrRates>[0]) =>
      updateSupportedMfrRates(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SUPPORTED_MFR_RATES);
      },
    }
  );
};
