import { useProjectStore } from 'store';
import { ReconcileExternalItem } from 'utils/api.types';

export const useIsResourceWrapped = () => {
  const wrapResourcesFloat = useProjectStore(s => s.wrapResourcesFloat);
  const wrapResourcesTeamdeck = useProjectStore(s => s.wrapResourcesTeamdeck);

  const getIsResourceWrapped = (item: ReconcileExternalItem) => {
    if (item.type === 'float' && wrapResourcesFloat?.includes(item.id))
      return true;
    if (item.type === 'teamdeck' && wrapResourcesTeamdeck?.includes(item.id))
      return true;
    return false;
  };

  return {
    getIsResourceWrapped,
  };
};
