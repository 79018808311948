import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useCurrentProject } from 'queries/project';

export const useCurrentVersionId = () => {
  const currentProject = useCurrentProject();
  const router = useRouter();
  return useMemo(() => {
    const versionId = router.query.projectSlug?.[1];
    return versionId ? Number(versionId) : currentProject?.main_version_id;
  }, [currentProject?.main_version_id, router.query.projectSlug]);
};
