import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { FC, memo } from 'react';

import { useCopyStore, useGlobalStore } from 'store';
import {
  AppNotification,
  isPermissionsChangedNotification,
} from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface PopupNotificationProps {
  notification: AppNotification;
}

const PopupNotification: FC<PopupNotificationProps> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const router = useRouter();
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);

  const isPermissionChangedNotification =
    isPermissionsChangedNotification(notification);

  const modalCopy = (() => {
    if (isPermissionChangedNotification) {
      return copy.app.popupNotification.permissionsChanged;
    } else {
    }
  })();

  const handleCancel = () => {
    if (isPermissionChangedNotification) {
      closeModal('popupNotification');
    }
  };

  const handleConfirm = async () => {
    if (isPermissionChangedNotification) {
      await signOut({
        redirect: false,
      });
      router.reload();
    }
  };

  if (!modalCopy) {
    return null;
  }

  return (
    <ConfirmModal
      title={modalCopy.title}
      description={modalCopy.description}
      cancelButtonProps={{
        label: modalCopy.cancelButton,
        onClick: handleCancel,
        icon: 'close',
        color: ColorNames.nandor,
      }}
      confirmButtonProps={{
        label: modalCopy.confirmButton,
        onClick: handleConfirm,
        icon: 'check',
        color: currentColor,
      }}
    />
  );
};

export default memo(PopupNotification);
