import { useCallback } from 'react';

import { Project } from 'utils/api.types';
import { copyToClipboard } from 'utils/copyToClipboard';
import { ROUTES } from 'utils/routes';

interface UseGetLinkAction {
  currentProjectId?: Project['id'];
  mainVersionId?: Project['main_version_id'];
}

export const useGetLinkAction = ({
  currentProjectId,
  mainVersionId,
}: UseGetLinkAction) => {
  const copyAction = useCallback(() => {
    copyToClipboard(
      `${document.location.origin}${ROUTES.PROJECTS_LIST}/${currentProjectId}/${mainVersionId}`
    );
  }, [currentProjectId, mainVersionId]);

  return [copyAction];
};
