import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

import { Column, TableTheme } from '../Table.types';

export const Wrapper = styled.div<
  { width: string; minWidth: string; background: string } & TableTheme
>`
  display: flex;
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  align-items: center;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  word-break: break-word;

  ${({ background }) =>
    background
      ? css`
          background: ${background};
        `
      : ''}
`;

export const ContentWrapper = styled.div<
  { align: Column['align'] } & TableTheme
>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;

  ${({ size }) =>
    size === 'small'
      ? css`
          padding: 0rem 60rem;
          min-height: 60rem;
        `
      : ''}

  ${({ size }) =>
    size === 'medium'
      ? css`
          padding: 25rem 60rem 25rem 110rem;
          min-height: 110rem;
        `
      : ''}

  ${({ size }) =>
    size === 'large'
      ? css`
          padding: 50rem 60rem;
          min-height: 160rem;
        `
      : ''}

  ${({ size }) =>
    size === 'smallNarrow'
      ? css`
          padding: 0 25rem;
          min-height: 60rem;
        `
      : ''}

  ${({ size }) =>
    size === 'mediumNarrow'
      ? css`
          padding: 25rem 25rem;
          min-height: 110rem;
        `
      : ''}

  ${({ align }) =>
    align === 'center'
      ? css`
          justify-content: center;
          text-align: center;
        `
      : ''}

  ${({ align }) =>
    align === 'right'
      ? css`
          justify-content: flex-end;
          text-align: end;
        `
      : ''}

`;
