import { useField } from 'components/inputs/hooks/useField';
import { useProject } from 'queries/project';
import { Project } from 'utils/api.types';

export const useRndDescriptionField = (projectId?: Project['id']) => {
  const { data: project } = useProject(
    { projectId },
    {
      enabled: !!projectId,
    }
  );

  const [rndDescription, setRndDescription] = useField(
    project?.research_and_development?.description || ''
  );

  return {
    rndDescription,
    setRndDescription,
  };
};
