import React, { useCallback, useRef } from 'react';

import Datepicker, { DatepickerProps } from '../Datepicker/Datepicker';

import * as Styled from './DatepickerRange.styles';

export interface DatepickerRangeProps {
  startLabel?: DatepickerProps['fieldLabel'];
  startInitValue?: DatepickerProps['value'];
  endLabel?: DatepickerProps['fieldLabel'];
  endInitValue?: DatepickerProps['value'];
  startDefaultExpanded?: boolean;
  endDefaultExpanded?: boolean;
  color?: DatepickerProps['color'];
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  closeOnChange?: DatepickerProps['closeOnChange'];
}

const DatepickerRange = ({
  color,
  endInitValue,
  endLabel,
  onChange,
  startInitValue,
  startLabel,
  startDefaultExpanded = false,
  endDefaultExpanded = false,
  closeOnChange = false,
}: DatepickerRangeProps) => {
  const lastStartDate = useRef<Date | null>(startInitValue || null);
  const lastEndDate = useRef<Date | null>(endInitValue || null);

  const isIllegalRange = (
    startDate: Date | null = null,
    endDate: Date | null = null
  ) => {
    if (!startDate || !endDate) {
      return true;
    }
    const isStartAfterEnd = startDate.getTime() > endDate.getTime();
    return isStartAfterEnd;
  };

  const handleStartChange = useCallback(
    (startDate: Date) => {
      if (isIllegalRange(startDate, lastEndDate.current)) {
        onChange && onChange(lastEndDate.current, startDate);
        lastStartDate.current = lastEndDate.current;
        lastEndDate.current = startDate;
      } else {
        lastStartDate.current = startDate;
        onChange && onChange(lastStartDate.current, lastEndDate.current);
      }
    },
    [onChange]
  );

  const handleEndChange = useCallback(
    (endDate: Date) => {
      if (isIllegalRange(lastStartDate.current, endDate)) {
        onChange && onChange(endDate, lastStartDate.current);
        lastEndDate.current = lastStartDate.current;
        lastStartDate.current = endDate;
      } else {
        lastEndDate.current = endDate;
        onChange && onChange(lastStartDate.current, lastEndDate.current);
      }
    },
    [onChange]
  );

  return (
    <Styled.Wrapper>
      <Styled.StartPicker>
        <Datepicker
          color={color}
          fieldLabel={startLabel}
          value={startInitValue}
          onChange={handleStartChange}
          defaultExpanded={startDefaultExpanded}
          closeOnChange={closeOnChange}
        />
      </Styled.StartPicker>
      <Styled.EndPicker>
        <Datepicker
          color={color}
          fieldLabel={endLabel}
          value={endInitValue}
          onChange={handleEndChange}
          defaultExpanded={endDefaultExpanded}
          closeOnChange={closeOnChange}
        />
      </Styled.EndPicker>
    </Styled.Wrapper>
  );
};

export default React.memo(DatepickerRange);
