import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useVendorServices } from 'queries/vendorServices';
import { useCopyStore } from 'store';
import { VendorService as VendorServiceType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface VendorServiceProps {
  vendorServiceIds: VendorServiceType['id'][];
  setVendorServiceIds: Dispatch<SetStateAction<VendorServiceType['id'][]>>;
}

const VendorService: FC<VendorServiceProps> = ({
  setVendorServiceIds,
  vendorServiceIds,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { data: vendorServices } = useVendorServices();

  const toggleVendorService = (vendorServiceId: VendorServiceType['id']) => {
    setVendorServiceIds(prev => {
      if (prev.includes(vendorServiceId)) {
        return prev.filter(m => m !== vendorServiceId);
      } else {
        return [...prev, vendorServiceId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.vendorService}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(vendorServiceIds.length > 0
              ? {}
              : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {vendorServiceIds.length > 0
            ? vendorServiceIds
                .map(
                  vendorServiceId =>
                    vendorServices?.find(({ id }) => id === vendorServiceId)
                      ?.name
                )
                .join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {vendorServices?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={vendorServiceIds.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleVendorService(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(VendorService);
