import styled from 'styled-components';

import { focusVisible } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.button`
  width: 52rem;
  height: 52rem;
  color: ${colors.outerSpace};
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${colors.alto};
  }

  &:focus-visible {
    ${focusVisible};
  }
`;

export const IconWrapper = styled.div`
  width: 26rem;
  height: 26rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
