import { useCallback } from 'react';

import { MfrDiscountModalProps } from 'components/modals/MfrDiscountModal/MfrDiscountModal';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

export const useHandleMfrDiscount = ({
  currentProjectId,
}: {
  currentProjectId?: Project['id'];
}) => {
  const openModal = useGlobalStore(s => s.openModal);

  const handleMfrDiscount = useCallback(() => {
    if (currentProjectId) {
      // https://github.com/radix-ui/primitives/issues/2122
      setTimeout(() => {
        openModal<MfrDiscountModalProps>('mfrDiscountModal', {
          projectId: currentProjectId,
        });
      }, 0);
    }
  }, [currentProjectId, openModal]);

  return [handleMfrDiscount];
};
