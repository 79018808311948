import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 15rem;
  gap: 50rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 50rem;
  margin-top: 100rem;
`;

export const AlreadyRequestedLabel = styled.div`
  color: ${colors.selectiveYellow};
  margin-bottom: 50rem;
`;

export const ProjectDataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  padding-bottom: 70rem;
`;

export const ProjectDataItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProjectDataLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.white, 0.5)};
`;

export const ProjectDataValue = styled.div``;
