import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Field = styled.input<{
  placeholderColor?: string;
  isDisabled: boolean;
}>`
  width: 100%;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
        `
      : ''}

  &::placeholder {
    ${({ placeholderColor }) =>
      placeholderColor
        ? css`
            color: ${placeholderColor};
          `
        : ''}

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            pointer-events: none;
            cursor: default;
            color: ${rgba(colors.white, 0.5)};
          `
        : ''}
  }
`;

export const DisabledIcon = styled.div`
  margin-right: 15rem;
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
`;

export const LoadingWrapper = styled.div`
  margin-right: 15rem;
  opacity: 0.5;
`;

export const StartHtmlWrapper = styled.div`
  margin-right: 20rem;
  max-width: 250rem;
  min-width: fit-content;
  opacity: 0.4;
`;

export const EndHtmlWrapper = styled.div`
  min-width: fit-content;
`;
