import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { colors } from 'utils/styles/theme';

import { Column, TableTheme } from '../Table.types';

const cellPaddingBottom = '36rem';

export const Wrapper = styled.div<
  {
    width: string;
    minWidth: string;
    clickable: boolean;
    align?: Column['align'];
    topBorder: boolean;
  } & TableTheme
>`
  display: flex;
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  justify-content: flex-start;
  text-align: center;
  align-items: center;

  ${({ align }) =>
    align === 'center'
      ? css`
          justify-content: center;
        `
      : ''}

  ${({ align }) =>
    align === 'right'
      ? css`
          justify-content: flex-end;
        `
      : ''}

  ${({ topBorder, tableTheme }) =>
    topBorder
      ? css`
          border-top: 1px solid
            ${tableTheme === 'dark'
              ? rgba(colors.white, 0.1)
              : rgba(colors.outerSpace, 0.1)};
        `
      : ''}
`;

export const ContentWrapper = styled.div<{ size: TableTheme['size'] }>`
  user-select: none;

  ${({ size }) =>
    size === 'small'
      ? css`
          padding: 40rem 60rem;
        `
      : ''}

  ${({ size }) =>
    size === 'medium'
      ? css`
          padding: 45rem 60rem;
        `
      : ''}

  ${({ size }) =>
    size === 'large'
      ? css`
          padding: 50rem 60rem;
        `
      : ''}

${({ size }) =>
  size === 'smallNarrow'
    ? css`
        padding: 45rem 25rem;
      `
    : ''}

  ${({ size }) =>
    size === 'mediumNarrow'
      ? css`
          padding: 45rem 25rem;
        `
      : ''}
`;

export const Divider = styled.div<TableTheme>`
  height: calc(100% - ${cellPaddingBottom});
  width: 1px;
  background: ${rgba(colors.outerSpace, 0.1)};
  position: absolute;
  right: 0;
  top: 0;

  ${({ tableTheme }) =>
    tableTheme === 'dark'
      ? css`
          background: ${rgba(colors.white, 0.1)};
        `
      : ''}
`;

export const SortIcon = styled.div<{ rotated: boolean; hasDivider: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 24rem;
  position: absolute;
  right: ${({ hasDivider }) => (hasDivider ? '20rem' : '0')};
  top: 50%;
  transition: transform 0.2s ease-out;
  transform-origin: center;
  transform: translateY(-50%)
    ${({ rotated }) => `rotate(${rotated ? '180deg' : '0deg'})`};
`;
