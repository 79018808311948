import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled from 'styled-components';

import { slideDownAnimation } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Content = styled(PopoverPrimitive.Content)`
  background: ${colors.black};
  color: ${colors.white};
  overflow: hidden;
  border-radius: 30rem;

  ${slideDownAnimation};

  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
`;
