import { FC, memo, useCallback } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import { useProjectAccessRequests } from 'queries/projectAccessRequests';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, User } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import { ConfirmShareProjectModalProps } from '../ConfirmShareProjectModal/ConfirmShareProjectModal';
import PermissionRequestsList from './PermissionRequestsList/PermissionRequestsList';
import PermittedUserList from './PermittedUserList/PermittedUserList';
import UserPicker from './UserPicker/UserPicker';

import * as Styled from './ShareProjectModal.styles';

export interface ShareProjectModalProps {
  projectId: Project['id'];
}

const ShareProjectModal: FC<ShareProjectModalProps> = ({ projectId }) => {
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const openModal = useGlobalStore(s => s.openModal);
  const currentColor = useGlobalStore(s => s.currentColor);

  const { data: projectAccessRequests, isLoading } = useProjectAccessRequests({
    projectId,
  });

  const onCloseClick = () => {
    closeModal('shareProjectModal');
  };

  const onUserPicked = useCallback(
    (user: User) => {
      openModal<ConfirmShareProjectModalProps>('confirmShareProject', {
        projectId,
        userEmail: user.username ?? '',
        username: user.name,
      });
    },
    [openModal, projectId]
  );

  return (
    <ConfigModal
      title={copy.app.shareProjectModal.title}
      onCloseClick={onCloseClick}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        {isLoading ? (
          <Spinner color={ColorNames.white} />
        ) : (
          <>
            <Styled.Description>
              {copy.app.shareProjectModal.description}
            </Styled.Description>
            <Styled.ContentWrapper>
              <Styled.PermissionRequestsList>
                <PermissionRequestsList items={projectAccessRequests} />
              </Styled.PermissionRequestsList>
              <Styled.UsersDropdown>
                <UserPicker onUserPicked={onUserPicked} />
              </Styled.UsersDropdown>
              <Styled.PermittedUserList>
                <PermittedUserList projectId={projectId} />
              </Styled.PermittedUserList>
              <Styled.Button>
                <Button
                  label={copy.app.shareProjectModal.button}
                  icon="check"
                  onClick={onCloseClick}
                  color={currentColor}
                />
              </Styled.Button>
            </Styled.ContentWrapper>
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ShareProjectModal);
