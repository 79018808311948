import { FC, memo, useMemo, useState } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddClientRequest } from 'queries/clientRequests';
import { useCopyStore, useGlobalStore } from 'store';
import { Client } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ClientRequestModal.styles';

export interface ClientRequestModalProps {
  clientName: Client['name'];
}

const ClientRequestModal: FC<ClientRequestModalProps> = ({ clientName }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const {
    mutateAsync: addClientRequest,
    isLoading: isAddingClientRequest,
    isSuccess: isClientRequested,
  } = useAddClientRequest();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.nameRequired,
    ]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    clientName,
    validators
  );
  const emailValidators = useMemo(
    () => ({
      email: {
        enabled: true,
        message: copy.app.validatorMessages.validEmail,
      },
    }),
    [copy.app.validatorMessages.validEmail]
  );
  const [email, setEmail, isEmailValid, emailInvalidMessage] = useField(
    '',
    emailValidators
  );
  const [message, setMessage] = useState('');

  const close = () => {
    closeModal('clientRequest');
  };

  const suggestClient = async () => {
    addClientRequest({
      name: name,
      ...(email ? { email: email } : {}),
      message: message,
    });
  };

  const isAnyInvalid = !isNameValid || !isEmailValid;

  return (
    <ConfigModal
      title={copy.app.clientRequestModal.title}
      buttonProps={
        isClientRequested
          ? {
              label: copy.app.clientRequestModal.successButton,
              color: currentColor,
              onClick: close,
              icon: 'check',
            }
          : {
              label: copy.app.clientRequestModal.cta,
              color: currentColor,
              onClick: suggestClient,
              icon: 'check',
              disabled: isAnyInvalid || isAddingClientRequest,
            }
      }
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        {isClientRequested ? (
          <Styled.Description>
            {copy.app.clientRequestModal.success}
          </Styled.Description>
        ) : (
          <>
            <Styled.Description>
              {copy.app.clientRequestModal.description}
            </Styled.Description>
            <FormInput
              onChange={setName}
              value={name}
              placeholder={copy.app.clientRequestModal.name}
              error={nameInvalidMessage}
              required
            />
            <FormInput
              onChange={setEmail}
              value={email}
              placeholder={copy.app.clientRequestModal.email}
              error={emailInvalidMessage}
            />
            <FormInput
              onChange={setMessage}
              value={message}
              placeholder={copy.app.clientRequestModal.message}
            />
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ClientRequestModal);
