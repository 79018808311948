import { useMemo } from 'react';

import { basicRegexSearch } from 'utils/regex';

export const useFilter = <T>(
  data?: T[],
  searchText?: string,
  fields?: string[],
  skipFilterFor?: (item: T) => boolean
) => {
  return useMemo(() => {
    if (!data) return data;
    return searchText
      ? data.filter(record => {
          return skipFilterFor && skipFilterFor(record)
            ? true
            : fields?.some(field =>
                basicRegexSearch(record, field, searchText)
              );
        })
      : data;
  }, [data, fields, searchText, skipFilterFor]);
};
