import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useRoles } from 'queries/roles';
import { useCopyStore } from 'store';
import { Role as RoleType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface RoleProps {
  roleIds: RoleType['role_id'][];
  setRoleIds: Dispatch<SetStateAction<RoleType['role_id'][]>>;
}

const Role: FC<RoleProps> = ({ setRoleIds, roleIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: roles } = useRoles();

  const rolesWithoutGroups = roles?.filter(({ category }) => !category);

  const toggleRole = (roleId: RoleType['role_id']) => {
    setRoleIds(prev => {
      if (prev.includes(roleId)) {
        return prev.filter(m => m !== roleId);
      } else {
        return [...prev, roleId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.role}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(roleIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {roleIds.length > 0
            ? roleIds
                .map(
                  roleId =>
                    rolesWithoutGroups?.find(
                      ({ role_id }) => role_id === roleId
                    )?.role
                )
                .join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {rolesWithoutGroups?.map(({ role, role_id }) => (
            <DropdownMenuCheckboxItem
              key={role_id}
              checked={roleIds.includes(role_id)}
              onSelect={event => {
                event.preventDefault();
                toggleRole(role_id);
              }}
            >
              {role}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Role);
