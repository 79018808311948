import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useProject, useUpdateProjectVersion } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectVersion } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './EditProjectVersionNameModal.styles';

export interface EditProjectVersionNameModalProps {
  projectId: Project['id'];
  versionId: ProjectVersion['id'];
}

const EditProjectVersionNameModal: FC<EditProjectVersionNameModalProps> = ({
  projectId,
  versionId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { data: project } = useProject({ projectId });
  const pitch = project?.versions?.find(({ id }) => id === versionId);
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.fieldRequired,
      },
    }),
    [copy.app.validatorMessages.fieldRequired]
  );
  const [versionName, setVersionName, isVersionNameValid, invalidMessage] =
    useField(pitch?.name || '', validators);

  const { mutateAsync: updateProjectVersion, isLoading } =
    useUpdateProjectVersion();

  const handleSave = async () => {
    if (!isVersionNameValid) return;
    await updateProjectVersion({
      projectId,
      projectVersion: { id: pitch?.id, name: versionName },
    });
    closeModal('editProjectVersionName');
  };

  const modalCopy = copy.app.editPitchNameModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading || !isVersionNameValid,
        onClick: handleSave,
      }}
      color={currentColor}
      onCloseClick={() => closeModal('editProjectVersionName')}
    >
      <Styled.Wrapper>
        <FormInput
          value={versionName}
          onChange={setVersionName}
          placeholder={modalCopy.label}
          error={!isVersionNameValid ? invalidMessage : ''}
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditProjectVersionNameModal);
