import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { useEffect } from 'react';

import gtm from 'u9/utils/gtm';

export const useGTM = (session?: Session | null) => {
  const router = useRouter();

  useEffect(() => {
    gtm.initialize(session?.user?.email ?? '');
  }, [session?.user?.email]);

  useEffect(() => {
    gtm.trackPage();
  }, [router.pathname]);
};
