import { memo } from 'react';

import useProjectDisplayText from 'hooks/useProjectDisplayText';
import { Project } from 'utils/api.types';

import * as Styled from '../../ProjectModal.styles';

export interface BudgetStatusProps {
  activeStatus: Project['status'];
  activeMode: Project['mode'];
}

export const BudgetStatus = ({
  activeStatus,
  activeMode,
}: BudgetStatusProps) => {
  const getStatusDisplayText = useProjectDisplayText();

  return (
    <Styled.Label>
      Status: {getStatusDisplayText(activeMode, activeStatus)}
    </Styled.Label>
  );
};

export default memo(BudgetStatus);
