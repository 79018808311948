export const download = (filename: string, data: string | Blob) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(data as Blob);

  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const downloadBlob = (filename: string, dataString: string) => {
  download(filename, new Blob([dataString], { type: 'octet/stream' }));
};
