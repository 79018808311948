import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${hover(css`
    color: ${rgba(colors.outerSpace, 0.7)};
  `)}
`;

export const BellIconWrapper = styled.div`
  width: 30rem;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewNotificationIndicator = styled.div`
  width: 20rem;
  height: 20rem;
  position: absolute;
  top: -10rem;
  right: -5rem;
  background: ${colors.blueRibbon};
  border-radius: 50%;
  border: 2px solid ${colors.white};
`;
