import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useUpdateResource } from 'queries/resources';
import { useCopyStore, useGlobalStore } from 'store';
import { VerificationStatus } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import * as Styled from './UnverifyResourceModal.styles';

export interface UnverifyResourceModalProps {
  item: AdminListItem;
}

const UnverifyResourceModal: FC<UnverifyResourceModalProps> = ({ item }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const [message, setMessage] = useField('');
  const { mutateAsync: updateResource, isLoading } = useUpdateResource();

  const handleClose = () => {
    closeModal('unverifyResourceModal');
  };

  const handleSave = async () => {
    if (item.resourceId) {
      await updateResource({
        id: item.resourceId,
        verification_status: {
          message: message,
          status: VerificationStatus.rejected,
        },
      });
    }

    handleClose();
  };

  const { confirmButton, declineButton, description, messageLabel, title } =
    copy.admin.unverifiedResourcesList.declineModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: declineButton,
        color: ColorNames.nandor,
        icon: 'check',
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleSave,
        disabled: isLoading,
        loading: isLoading,
        dataCy: 'unverify-resource-modal__save-button',
      }}
      description={replaceString(description, '{name}', item.name)}
      title={title}
      content={
        <Styled.FieldWrapper>
          <FormInput
            value={message}
            onChange={setMessage}
            placeholder={messageLabel}
            dataCy="unverify-resource-modal__message-field"
          />
        </Styled.FieldWrapper>
      }
    />
  );
};

export default memo(UnverifyResourceModal);
