import Link from 'next/link';
import styled from 'styled-components';

import { focusVisible } from 'utils/styles/mixins';

export const LogoSection = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 30rem;
  height: 100%;
`;

export const LogoWrapper = styled.div``;

export const LogoMargin = styled.div`
  width: 50rem;
`;

export const LogoSectionLink = styled(Link)`
  flex: 1;

  &:focus-visible {
    ${LogoSection} {
      ${focusVisible}
    }
  }
`;
