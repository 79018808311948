import { FC, memo } from 'react';

import { ColorNames } from '@u9/bob3-shared/lib/utils/styles/theme';
import { useCopyStore, useGlobalStore } from 'store';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface UnhideProjectModalProps {
  handleConfirm: () => void;
  handleDecline: () => void;
}

const UnhideProjectModal: FC<UnhideProjectModalProps> = ({
  handleConfirm,
  handleDecline,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  return (
    <ConfirmModal
      cancelButtonProps={{
        label: copy.app.unhideProjectModal.cancelButton,
        color: ColorNames.nandor,
        onClick: handleDecline,
        icon: 'close',
      }}
      confirmButtonProps={{
        label: copy.app.unhideProjectModal.confirmButton,
        color: currentColor,
        onClick: handleConfirm,
        icon: 'check',
        dataCy: 'unhide-project-modal-confirm',
      }}
      description={copy.app.unhideProjectModal.description}
      title={copy.app.unhideProjectModal.title}
    />
  );
};

export default memo(UnhideProjectModal);
