import { FC, memo, useCallback, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useDeleteResource, useResources } from 'queries/resources';
import { useCopyStore, useGlobalStore } from 'store';
import { Resource } from 'utils/api.types';
import { replaceStrings } from 'utils/replace';
import { ADMIN_ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import * as Styled from './DeleteUnusedResourceModal.styles';

export interface DeleteUnusedResourceModalProps {
  item: AdminListItem;
}

const DeleteUnusedResourceModal: FC<DeleteUnusedResourceModalProps> = ({
  item,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const adminRoute = useCurrentAdminRoute();
  const { data: resources } = useResources();
  const resourcesWithoutCurrent = resources?.filter(
    ({ id }) => id !== item.resourceId
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeResourceId, setActiveResourceId] = useState<
    Resource['id'] | null
  >(null);

  const { mutateAsync: deleteResource, isLoading: isLoading } =
    useDeleteResource();

  const handleClose = useCallback(() => {
    closeModal('deleteUnusedResourceModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (!item?.resourceId) return;
    await deleteResource({
      id: item.resourceId,
      ...(activeResourceId && { parent_id: activeResourceId }),
    });
    handleClose();
  }, [activeResourceId, deleteResource, handleClose, item?.resourceId]);

  const {
    title,
    description,
    cancelButton,
    confirmButton,
    dropdownLabel,
    resourceDuplicatePrompt,
  } = copy.admin.deleteUnusedResourceModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
        dataCy: 'delete-unused-resource-confirm',
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
        disabled: isLoading,
      }}
      description={replaceStrings(description, [
        ['{name}', item?.name],
        [
          '{category}',
          adminRoute === ADMIN_ROUTES.INTERNAL_UNUSED
            ? copy.app.subnav.resources.internal
            : copy.app.subnav.resources.external,
        ],
      ])}
      title={title}
      content={
        <Styled.SubPromptWrapper>
          <Styled.SubPrompt>{resourceDuplicatePrompt}</Styled.SubPrompt>
          <Combobox
            open={dropdownOpen}
            onOpenChange={setDropdownOpen}
            label={dropdownLabel}
          >
            <ComboboxTrigger
              label={
                resourcesWithoutCurrent?.find(
                  ({ id }) => id === activeResourceId
                )?.name
              }
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  {resourcesWithoutCurrent?.map(({ name, email, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeResourceId === id}
                      onSelect={() => {
                        setActiveResourceId(
                          activeResourceId === id ? null : id
                        );
                        setDropdownOpen(false);
                      }}
                    >
                      {`${name} (${email})`}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.SubPromptWrapper>
      }
    />
  );
};

export default memo(DeleteUnusedResourceModal);
