import { FC, memo, useCallback } from 'react';

import { useDeleteUser } from 'queries/users';
import { useCopyStore, useGlobalStore } from 'store';
import { BobAccessRequest } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DenyBobAccessRequestModalProps {
  item: BobAccessRequest;
}

const DenyBobAccessRequestModal: FC<DenyBobAccessRequestModalProps> = ({
  item,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: deleteUser, isLoading } = useDeleteUser();

  const handleClose = useCallback(() => {
    closeModal('denyBobAccessRequest');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    await deleteUser(item.username);
    handleClose();
  }, [deleteUser, handleClose, item.username]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: isLoading,
        label: copy.admin.declineBobAccessRequest.declineButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: isLoading,
        label: copy.admin.declineBobAccessRequest.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
        dataCy: 'duplicate-project__confirm-button',
      }}
      description={replaceString(
        copy.admin.declineBobAccessRequest.description,
        '{fullName}',
        item.full_name
      )}
      title={copy.admin.declineBobAccessRequest.title}
    />
  );
};

export default memo(DenyBobAccessRequestModal);
