import * as SliderPrimitive from '@radix-ui/react-slider';
import React from 'react';

import * as Styled from './FormSlider.styles';

export interface FormSliderProps extends SliderPrimitive.SliderProps {
  label: string;
  topHtml?: React.ReactNode | React.ReactNode[];
}

const FormSlider = (props: FormSliderProps) => {
  const { label, topHtml, ...sliderProps } = props;
  const value = props.value || props.defaultValue;

  return (
    <Styled.Wrapper>
      <Styled.Label>{label}</Styled.Label>
      {topHtml && topHtml}
      <Styled.Root {...sliderProps}>
        <Styled.SliderTrack>
          <Styled.SliderRange />
        </Styled.SliderTrack>
        {value?.map((_, i) => <Styled.SliderThumb key={i} />)}
      </Styled.Root>
    </Styled.Wrapper>
  );
};

export const DefaultTopHtml = ({ value }: { value: (string | number)[] }) => {
  return (
    <Styled.TopHtmlWrapper>
      <Styled.Start>
        <Styled.Value>{value[0]}</Styled.Value>
      </Styled.Start>
      {value[1] && (
        <Styled.End>
          <Styled.Value>{value[1]}</Styled.Value>
        </Styled.End>
      )}
    </Styled.TopHtmlWrapper>
  );
};

export default React.memo(FormSlider);
