import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore, useGlobalStore } from 'store';
import { BobAccessRequest } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';

export const BOB_ACCESS_REQUESTS_KEY = 'BOB_ACCESS_REQUESTS';

export const useBobAccessRequests = (
  options?: UseQueryOptions<BobAccessRequest[]>
) => {
  const getBobAccessRequests = useApiStore(
    s => s.apiClient.getBobAccessRequests
  );
  const subdomain = useGlobalStore(s => s.subdomain);
  return useQuery({
    queryKey: BOB_ACCESS_REQUESTS_KEY,
    queryFn: async () =>
      (await getBobAccessRequests({ workspace: subdomain })).data,
    ...options,
  });
};

export const useAddBobAccessRequest = () => {
  const addBobAccessRequest = useApiStore(s => s.apiClient.addBobAccessRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addBobAccessRequest>[0]) =>
      addBobAccessRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(BOB_ACCESS_REQUESTS_KEY);
      },
    }
  );
};
