import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';

import EditableLabel from 'components/inputs/EditableLabel/EditableLabel';
import { BudgetEntry } from 'utils/api.types';

export interface TermsCellProps {
  props: CellContext<BudgetEntry, unknown>;
  isDisabled: boolean;
  saveBudgetEntry: () => void;
  handleChange: (value: any) => void;
}

// We wanted to have some cap, 4000 days is almost 11 years. Seems sufficient
const MAX_TERMS_VALUE = 4000;

const TermsCell: FC<TermsCellProps> = ({
  props,
  isDisabled,
  saveBudgetEntry,
  handleChange,
}) => {
  const [value, setValue] = useState(props.row.original.terms || '');

  const handleChangeTerms = (v: string) => {
    const value = String(Math.min(Number(v), MAX_TERMS_VALUE));
    setValue(value);
  };

  const save = () => {
    handleChange(value);
    setTimeout(() => {
      saveBudgetEntry();
    }, 0);
  };

  return (
    <EditableLabel
      onChange={handleChangeTerms}
      onBlur={save}
      onEnter={save}
      onEscape={save}
      value={value}
      placeholder={'-'}
      styles={{ width: '100%' }}
      dataCy="budget-modal__terms_field"
      disabled={isDisabled}
      mask="integer"
    />
  );
};

export default TermsCell;
