import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useResources } from 'queries/resources';
import { useCopyStore } from 'store';
import { Resource as ResourceType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface ResourceProps {
  resourceIds: ResourceType['id'][];
  setResourceIds: Dispatch<SetStateAction<ResourceType['id'][]>>;
}

const Resource: FC<ResourceProps> = ({ setResourceIds, resourceIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: resources } = useResources();

  const toggleResource = (resourceId: ResourceType['id']) => {
    setResourceIds(prev => {
      if (prev.includes(resourceId)) {
        return prev.filter(m => m !== resourceId);
      } else {
        return [...prev, resourceId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.resource}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(resourceIds.length > 0
              ? {}
              : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {resourceIds.length > 0
            ? resourceIds
                .map(
                  resourceId =>
                    resources?.find(({ id }) => id === resourceId)?.name
                )
                .join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {resources?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={resourceIds.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleResource(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Resource);
