import { FC, memo } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useCostAnalysis } from 'queries/costAnalysis';
import { useCopyStore, useGlobalStore } from 'store';
import { CostAnalysisInput } from 'utils/api.types';
import { replaceString } from 'utils/replace';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CostAnalysisModal.styles';

export interface CostAnalysisModalProps {
  type: CostAnalysisInput['type'];
  id: CostAnalysisInput['id'];
  entityName: string;
}

const CostAnalysisModal: FC<CostAnalysisModalProps> = ({
  type,
  id,
  entityName,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { data: costAnalysis } = useCostAnalysis({ type, id });

  const { formatMoney } = useFormatMoney();

  const handleClose = () => {
    closeModal('costAnalysis');
  };

  return (
    <ConfigModal
      title={copy.app.costAnalysisModal.title}
      buttonProps={{
        label: copy.app.costAnalysisModal.button,
        icon: 'check',
        color: currentColor,
        onClick: handleClose,
      }}
      onCloseClick={handleClose}
    >
      <Styled.Wrapper>
        <Styled.EntityName>
          <OverflowEllipsis
            text={replaceString(
              copy.app.costAnalysisModal.entityLabel,
              '{name}',
              entityName
            )}
          />
        </Styled.EntityName>
        <Styled.CostRow header>
          <Styled.CostLabel>
            {copy.app.costAnalysisModal.projectName}
          </Styled.CostLabel>
          <Styled.CostValue>{copy.app.costAnalysisModal.cost}</Styled.CostValue>
        </Styled.CostRow>
        {costAnalysis?.map(cost => (
          <Styled.CostRow key={cost.id}>
            <Styled.CostLabel>{cost.project_name}</Styled.CostLabel>
            <Styled.CostValue>{formatMoney(cost.cost)}</Styled.CostValue>
          </Styled.CostRow>
        ))}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CostAnalysisModal);
