import { FC, memo } from 'react';

import { ContextualMenuItem } from 'components/ContextualMenu/ContextualMenu';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useCopyStore, usePerformanceDashboardStore } from 'store';

export interface TimeFrameProps {}

export enum TimeFrameEnum {
  DAYS_30 = 30,
  MONTHS_3 = 90,
  MONTHS_6 = 180,
  MONTHS_12 = 360,
}

const TimeFrame: FC<TimeFrameProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const timeframe = usePerformanceDashboardStore(s => s.timeframe);
  const setTimeframe = usePerformanceDashboardStore(s => s.setTimeframe);

  const items: ContextualMenuItem[] = [
    {
      id: TimeFrameEnum.DAYS_30,
      displayName: copy.app.timeframe['30'],
    },
    {
      id: TimeFrameEnum.MONTHS_3,
      displayName: copy.app.timeframe['90'],
    },
    {
      id: TimeFrameEnum.MONTHS_6,
      displayName: copy.app.timeframe['180'],
    },
    {
      id: TimeFrameEnum.MONTHS_12,
      displayName: copy.app.timeframe['360'],
    },
  ];

  return (
    <Select
      label={copy.performanceDashboard.filtersModal.kpisTab.timeframe}
      value={timeframe ? String(timeframe) : undefined}
      onValueChange={v => setTimeframe(v as unknown as TimeFrameEnum)}
      dataCy="project-modal__territory-dropdown"
    >
      {items.map(({ displayName, id }) => (
        <SelectItem key={id} value={String(id)} dataCy={`${id}__menu-item`}>
          {displayName}
        </SelectItem>
      ))}
    </Select>
  );
};

export default memo(TimeFrame);
