import { useSession } from 'next-auth/react';
import { useCallback } from 'react';

import { AccessRight } from './api.types';

export const getCheckAccessRight = (
  userAccessRights: AccessRight[],
  accessRightToCheck: AccessRight
) => {
  return userAccessRights.includes(accessRightToCheck);
};

export const getCheckAnyAccessRight = (
  userAccessRights: AccessRight[],
  accessRightsToCheck: AccessRight[]
) => {
  return accessRightsToCheck.some(accessRight =>
    userAccessRights.includes(accessRight)
  );
};

export const useCheckAccessRights = () => {
  const { data: session } = useSession();

  const checkAccessRight = useCallback(
    (accessRight: AccessRight) => {
      if (!session?.accessRights) return false;
      return getCheckAccessRight(session.accessRights, accessRight);
    },
    [session]
  );

  const checkAnyAccessRight = useCallback(
    (accessRights: AccessRight[]) => {
      if (!session?.accessRights) return false;
      return getCheckAnyAccessRight(session.accessRights, accessRights);
    },
    [session]
  );

  return {
    checkAccessRight,
    checkAnyAccessRight,
  };
};
