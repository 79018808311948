import * as Portal from '@radix-ui/react-portal';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Card, { CardProps } from 'components/Card/Card';
import { usePopupPosition } from 'hooks/usePopupPosition';

import * as Styled from './CardDropdown.styles';

export const clickTypes = ['click', 'mousedown', 'hover'] as const;

export interface CardDropdownProps {
  cardProps: Omit<CardProps, 'handleCloseClick'>;
  button?: React.ReactNode;
  defaultOpen?: boolean;
  onClose?: () => void;
  clickType?: typeof clickTypes[number];
}

const CardDropdown = ({
  button,
  cardProps,
  defaultOpen = false,
  onClose,
  clickType = 'click',
}: CardDropdownProps) => {
  const wrapperEl = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { popupStyle, popupWrapperRef } = usePopupPosition<HTMLDivElement>({
    isOpen,
    popupWidthVariant: 'initial',
  });

  const open = () => {
    if (clickType === 'click' || clickType === 'mousedown') {
      setIsOpen(true);
    }
  };

  const close = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  const bodyClick = useCallback(
    (event: MouseEvent) => {
      const isOutsideWrapperClick = !wrapperEl.current?.contains(
        event.target as HTMLDivElement
      );
      if (isOutsideWrapperClick && isOpen) {
        close();
      }
    },
    [close, isOpen, wrapperEl]
  );

  const onMouseEnter = () => {
    if (clickType === 'hover') {
      setIsOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (clickType === 'hover') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (clickType !== 'hover') {
      document.body.addEventListener(clickType, bodyClick, { capture: true });
    }

    return () => {
      if (clickType !== 'hover') {
        document.body.removeEventListener(clickType, bodyClick);
      }
    };
  }, [bodyClick, clickType]);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(defaultOpen);
    }
  }, [defaultOpen]);

  return (
    <Styled.Wrapper ref={popupWrapperRef}>
      {button && (
        <Styled.ButtonWrapper
          onClick={open}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseEnter}
        >
          {button}
        </Styled.ButtonWrapper>
      )}
      {isOpen && (
        <Portal.Root>
          <Styled.PopupWrapper style={popupStyle}>
            <Card
              handleCloseClick={
                clickType === 'hover' && !defaultOpen ? null : close
              }
              {...cardProps}
            />
          </Styled.PopupWrapper>
        </Portal.Root>
      )}
    </Styled.Wrapper>
  );
};

export default CardDropdown;
