import { darken, rgba, setLightness } from 'polished';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { ColorNames, colors } from 'utils/styles/theme';

import { TableTheme } from '../Table.types';

export const dynamicColumnsRightPadding = '125rem';
export const startPadding = '125rem';

export const Wrapper = styled.div<
  Partial<DraggableProvidedDragHandleProps> &
    TableTheme & {
      border?: boolean;
      hoverEffect: boolean;
      isDragDrop?: boolean;
      textColor?: string;
      isSelect?: boolean;
      isSelected?: boolean;
      isHeader?: boolean;
      currentColor?: ColorNames;
      background?: string;
      isSelectedAndDragging?: boolean;
      hasDynamicColumns?: boolean;
      isGroupRow?: boolean;
    }
>`
  display: flex;
  justify-content: stretch;
  cursor: pointer;
  position: relative;

  ${({ border, tableTheme }) =>
    border
      ? css`
          border-bottom: 1px solid
            ${rgba(
              tableTheme === 'dark' ? colors.white : colors.outerSpace,
              0.1
            )};
        `
      : ''}

  ${({ hoverEffect }) =>
    hoverEffect &&
    hover(css`
      background: ${darken(0.02, colors.white)};
    `)}

  ${({ tableTheme, hoverEffect }) =>
    tableTheme === 'dark'
      ? css`
          ${hoverEffect &&
          hover(css`
            background: ${darken(0.02, colors.outerSpace)};
          `)}
        `
      : ''}

  ${({ isDragDrop, isSelect }) =>
    isDragDrop || isSelect
      ? css`
          position: relative;
        `
      : ''}

  ${({ background }) =>
    background
      ? css`
          background: ${background};
        `
      : ''}

  ${({ isDragDrop, isSelect, isGroupRow }) =>
    (isDragDrop || isSelect) && !isGroupRow
      ? css`
          padding-left: ${startPadding};
        `
      : ''}

  ${({ isHeader, isSelect, isSelected, currentColor }) =>
    !isHeader && isSelect && isSelected && currentColor
      ? css`
          background: ${setLightness(0.94, colors[currentColor])} !important;
        `
      : ''}

  ${({ isSelectedAndDragging }) =>
    isSelectedAndDragging
      ? css`
          filter: brightness(0.94);
        `
      : ''}

  ${({ textColor }) =>
    textColor
      ? css`
          color: ${textColor};
        `
      : ''}

  ${({ hasDynamicColumns, isGroupRow }) =>
    hasDynamicColumns && !isGroupRow
      ? css`
          padding-right: ${dynamicColumnsRightPadding};
        `
      : ''}
`;

export const DragHandle = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 35rem;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  width: 40rem;
  height: 60rem;
  visibility: hidden;

  ${({ visible }) =>
    visible
      ? css`
          visibility: visible;
        `
      : ''}
`;

export const DragHandleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  opacity: 0.4;
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 75rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const SelectionCountWrapper = styled.div`
  position: absolute;
  left: 0rem;
  top: 0;
  transform: translate(-25%, -25%);
  width: 50rem;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${colors.nandor};
  ${({ theme: { locale } }) => setTypography('heading4', locale)}
  color: ${colors.white};
`;

export const SettingsButtonWrapper = styled.div<{
  isAnyColumnHidden: boolean;
  color: ColorNames;
}>`
  position: absolute;
  right: 40rem;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56rem;
  height: 56rem;
  border-radius: 15rem;
  background: ${rgba(colors.dustyGray, 0.15)};
  z-index: 1;

  ${hover(css`
    background: ${rgba(colors.dustyGray, 0.2)};
  `)}

  ${({ isAnyColumnHidden, color }) =>
    isAnyColumnHidden
      ? css`
          background: ${colors[color]};
          color: ${colors.white};

          ${hover(css`
            background: ${rgba(colors[color], 0.8)};
          `)}
        `
      : ''}
`;

export const DropdownIconOuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownIconWrapper = styled.div`
  width: 26rem;
  height: 26rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
