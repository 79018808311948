import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { hover, setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div<{ hasRequestActive: boolean }>`
  ${({ hasRequestActive }) =>
    hasRequestActive
      ? css`
          margin-bottom: 80rem;
        `
      : ''}
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.white, 0.5)};
  margin-bottom: 30rem;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32rem;
`;

export const UserWrapper = styled.div``;

export const NameWrapper = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
`;

export const EmailWrapper = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  color: ${colors.dustyGray};
`;

export const GrantAccessCta = styled.div<{ color: ColorNames }>`
  background: ${({ color }) => colors[color]};
  color: ${colors.white};
  min-width: 230rem;
  padding: 10rem 50rem;
  text-align: center;
  cursor: pointer;
  border-radius: ${layout.borderRadius.big}rem;
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  ${hover(brightnessFilter)}
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const DeclineButtonWrapper = styled.div`
  width: 48rem;
  height: 48rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grayNurse};
  cursor: pointer;
  margin-right: 10rem;
  ${hover(brightnessFilter)}
`;

export const CloseIconWrapper = styled.div`
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.doveGray};
`;
