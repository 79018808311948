import useDebounce from 'hooks/useDebounce';
import { useRoles } from 'queries/roles';
import { useRolesSuggestions } from 'queries/rolesSuggestions';
import { useVendorServices } from 'queries/vendorServices';
import { sort, sortString } from 'utils/sort';

import { RoleItem } from './useItems';

export const useSuggestedItems = ({
  searchValue,
  currentlyVisibleItems,
}: {
  searchValue: string;
  currentlyVisibleItems: RoleItem[];
}) => {
  const debouncedSearch = useDebounce(searchValue, 500);
  const searchEnabled = debouncedSearch.length >= 3;
  const { data: rolesSuggestions } = useRolesSuggestions(
    { searchValue: debouncedSearch },
    { enabled: searchEnabled }
  );

  const { data: roles } = useRoles({ enabled: searchEnabled });
  const { data: vendorServices } = useVendorServices({
    enabled: searchEnabled,
  });

  const suggestedRoles: RoleItem[] =
    roles
      ?.filter(({ role_id }) => rolesSuggestions?.roles.includes(role_id))
      .filter(
        ({ role_id }) =>
          !currentlyVisibleItems.some(
            ({ category, roleId }) => category === 'role' && roleId === role_id
          )
      )
      .map(({ role_id, role = '', row_no }) => ({
        id: `role-${role_id}`,
        roleId: role_id || 0,
        label: role,
        category: 'role',
        rowNo: row_no,
      })) ?? [];

  const suggestedVendorServices: RoleItem[] =
    vendorServices
      ?.filter(({ id }) => rolesSuggestions?.vendor_services.includes(id))
      .filter(
        suggestedItem =>
          !currentlyVisibleItems.some(
            ({ category, id }) =>
              category === 'vendorService' && id === suggestedItem.id
          )
      )
      .map(({ id, name = '' }) => ({
        id,
        label: name,
        category: 'vendorService',
      })) ?? [];

  const suggestedItems = sort(
    [...suggestedRoles, ...suggestedVendorServices],
    'label',
    sortString
  );

  return {
    suggestedItems,
  };
};
