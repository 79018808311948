import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
  width: 1400rem;
`;

export const Description = styled.div`
  color: ${rgba(colors.white, 0.5)};
  padding-bottom: 60rem;
  ${({ theme: { locale } }) => setTypography('body3', locale)};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20rem;
  padding-top: 100rem;
`;

export const ActiveResources = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const ActiveResource = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 40rem;
`;

export const ResourceName = styled.div`
  align-self: stretch;
  width: calc(50% - 140rem);
  min-width: calc(50% - 140rem);
  max-width: calc(50% - 140rem);
  display: flex;
  align-items: center;
  gap: 20rem;
  border-bottom: 1px solid ${rgba(colors.white, 0.5)};
`;

export const Arrow = styled.div`
  width: 120rem;
  min-width: 120rem;
  max-width: 120rem;
  height: auto;
  color: ${rgba(colors.white, 0.5)};
`;

export const RoleDropdown = styled.div`
  width: calc(50% - 140rem);
  min-width: calc(50% - 140rem);
  max-width: calc(50% - 140rem);
`;

export const ValidationIcon = styled.div`
  width: 32rem;
  height: 32rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ValidIcon = styled.div`
  color: ${colors.mountainMeadow};
  width: 26rem;
  height: 26rem;
`;

export const InvalidIcon = styled.div`
  color: ${colors.sunsetOrange};
  width: 26rem;
  height: 26rem;
`;
