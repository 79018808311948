import { useCallback } from 'react';

import { ShareProjectModalProps } from 'components/modals/ShareProjectModal/ShareProjectModal';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

type UseShareActionProps = {
  currentProjectId?: Project['id'];
};

export const useShareAction = ({ currentProjectId }: UseShareActionProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const share = useCallback(() => {
    if (currentProjectId) {
      // https://github.com/radix-ui/primitives/issues/2122
      setTimeout(() => {
        openModal<ShareProjectModalProps>('shareProjectModal', {
          projectId: currentProjectId,
        });
      }, 0);
    }
  }, [currentProjectId, openModal]);
  return [share];
};
