import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 80rem;
`;

export const Dropdown = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 80rem;
  gap: 40rem;
  display: flex;
  justify-content: flex-end;
`;
