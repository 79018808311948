import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 50rem;
  margin-top: 100rem;
`;

export const AlreadyRequestedLabel = styled.div`
  color: ${colors.selectiveYellow};
  margin-bottom: 50rem;
`;

export const Description = styled.div`
  padding-bottom: 80rem;
`;

export const ConditionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const ConditionItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${rgba(colors.white, 0.2)};
  padding: 20rem;
  ${({ theme: { locale } }) => setTypography('caption', locale)}
`;

export const ConditionItemText = styled.div``;

export const ConditionItemStatus = styled.div<{
  isDone?: boolean;
  isIndeterminate?: boolean;
}>`
  color: ${colors.sunsetOrange};

  ${({ isDone }) =>
    isDone &&
    `
      color: ${colors.mountainMeadow};
    `}

  ${({ isIndeterminate }) =>
    isIndeterminate &&
    `
      color: ${colors.selectiveYellow};
    `}
`;

export const LookupButton = styled.button`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  border: 1px solid ${rgba(colors.white, 0.5)};
  padding: 10rem 20rem;
  border-radius: 20rem;
  cursor: pointer;

  ${hover(css`
    background: ${rgba(colors.white, 0.1)};
  `)};

  &:focus-visible {
    box-shadow: ${colors.malibu} 0 0 0 2px;
  }
`;

export const ConditionItemStart = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;
