import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxInput,
  ComboboxItem,
  ComboboxLabel,
  ComboboxList,
  ComboboxLoading,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import useDebounce from 'hooks/useDebounce';
import {
  useCurrentProject,
  useIntegrationProjects,
  useReconcile,
} from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ReconcileIntegrationType } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './SelectReconcileIntegrationModal.styles';

export interface SelectReconcileIntegrationModalProps {
  integration: ReconcileIntegrationType;
}

const SelectReconcileIntegrationModal: FC<
  SelectReconcileIntegrationModalProps
> = ({ integration }) => {
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const currentProject = useCurrentProject();
  const [queryInput, setQueryInput] = useState(
    currentProject?.job_number || ''
  );
  const debouncedQueryInput = useDebounce(queryInput, 500);

  const { mutateAsync: reconcile, isLoading } = useReconcile();

  const { data: integrationProjects, isLoading: isLoadingProjects } =
    useIntegrationProjects(
      {
        type: integration,
        name: debouncedQueryInput,
      },
      {
        enabled: debouncedQueryInput.length > 2,
      }
    );

  const items = (integrationProjects || []).map(project => ({
    id: project.external_id,
    displayName: project.name,
  }));
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  const [comboboxOpen, setComboboxOpen] = useState(false);

  const handleConfirm = async () => {
    if (activeItemId && currentProject?.id) {
      await reconcile({
        integration_type: integration,
        project_id: currentProject?.id,
        external_id: activeItemId,
        report_type: 'integration',
      });
      closeModal('selectReconcileIntegrationModal');
    }
  };

  const modalCopy = copy.app.selectReconcileIntegrationModal[integration];

  return (
    <ConfigModal
      title={modalCopy.title}
      color={currentColor}
      onCloseClick={() => closeModal('selectReconcileIntegrationModal')}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Dropdown>
          <Combobox
            open={comboboxOpen}
            onOpenChange={setComboboxOpen}
            label={modalCopy.dropdownLabel}
          >
            <ComboboxTrigger
              label={items.find(({ id }) => id === activeItemId)?.displayName}
              emptyLabel={modalCopy.placeholder}
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot shouldFilter={false}>
                <ComboboxInput
                  value={queryInput}
                  onValueChange={setQueryInput}
                />
                {isLoadingProjects && <ComboboxLoading />}
                {!isLoadingProjects &&
                  (!integrationProjects ||
                    integrationProjects?.length === 0) && (
                    <ComboboxLabel>{modalCopy.noResults}</ComboboxLabel>
                  )}
                <ComboboxList>
                  {items.map(({ displayName, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeItemId === id}
                      onSelect={() => {
                        setActiveItemId(id);
                        setComboboxOpen(false);
                      }}
                    >
                      {displayName}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.Dropdown>
        <Styled.ButtonsWrapper>
          <Button
            label={modalCopy.cancelButton}
            color={ColorNames.nandor}
            onClick={() => closeModal('selectReconcileIntegrationModal')}
            icon="close"
          />
          <Button
            label={modalCopy.confirmButton}
            color={currentColor}
            onClick={handleConfirm}
            icon="check"
            loading={isLoading}
            disabled={!activeItemId}
          />
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(SelectReconcileIntegrationModal);
