import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { TableTheme } from '../Table.types';

export const Wrapper = styled.div<TableTheme>`
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  color: ${rgba(colors.outerSpace, 0.4)};

  ${({ tableTheme }) =>
    tableTheme === 'dark'
      ? css`
          color: ${rgba(colors.white, 0.5)};
        `
      : ''}
`;
