import { useUpdateProject } from 'queries/project';
import { Project, ProjectSensitivity } from 'utils/api.types';

type UseHideProjectProps = {
  currentProjectId?: Project['id'];
};

export const useHideProjectAction = ({
  currentProjectId,
}: UseHideProjectProps) => {
  const { mutateAsync: updateProject } = useUpdateProject();

  const hideProject = async ({ isSensitive }: { isSensitive: boolean }) => {
    if (currentProjectId) {
      updateProject({
        projectId: currentProjectId,
        project: {
          access: isSensitive
            ? ProjectSensitivity.sensitive
            : ProjectSensitivity.default,
        },
      });
    }
  };

  return [hideProject];
};
