import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { Organization } from '@u9/bob3-shared/lib/types/api.types';
import { useApiStore, useGlobalStore } from 'store';

export const ORGANIZATIONS_KEY = 'ORGANIZATIONS';

// Later job_number_regex will be part of workspace settings
const addJobNumberRegex = (organization: Organization): Organization => {
  return {
    ...organization,
    // eslint-disable-next-line
    job_number_regex: '^\\d{4}$',
    pitch_number_regex: '^\\d{4}$',

    ...(organization.subdomain === 'lpb'
      ? {
          job_number_regex: '^\\d{5}$',
          pitch_number_regex: '^\\d{2}-\\d{3}$',
        }
      : {}),

    ...(organization.subdomain === 'silkroad'
      ? {
          job_number_regex: '^\\d{6}$',
          pitch_number_regex: '^\\d{6}$',
        }
      : {}),
  };
};

export const useOrganization = (options?: UseQueryOptions<Organization>) => {
  const getOrganization = useApiStore(s => s.apiClient.getOrganization);
  const subdomain = useGlobalStore(s => s.subdomain);
  return useQuery({
    queryKey: ORGANIZATIONS_KEY,
    queryFn: async () => (await getOrganization(subdomain)).data,
    select: addJobNumberRegex,
    enabled: !!subdomain,
    ...options,
  });
};

export const useUpdateOrganization = () => {
  const updateOrganization = useApiStore(s => s.apiClient.updateOrganization);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateOrganization>[0]) =>
      updateOrganization(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ORGANIZATIONS_KEY);
      },
    }
  );
};
