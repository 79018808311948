import { FC, memo, useState } from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddPermission, useUpdatePermission } from 'queries/permissions';
import { useCopyStore, useGlobalStore } from 'store';
import { AccessRight, Permission } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './PermissionModal.styles';

export interface PermissionModalProps {
  permission?: Permission;
}

const PermissionModal: FC<PermissionModalProps> = ({ permission }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: addPermission, isLoading: addLoading } =
    useAddPermission();
  const { mutateAsync: updatePermission, isLoading: updateLoading } =
    useUpdatePermission();

  const getInitialCheckboxValue = (accessRight: AccessRight) => {
    return permission?.access_set?.includes(accessRight) ?? false;
  };

  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    permission?.name ?? '',
    {
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }
  );

  const [isProducer, setIsProducer] = useState(
    getInitialCheckboxValue('producer')
  );
  const [isExecutiveProducer, setIsExecutiveProducer] = useState(
    getInitialCheckboxValue('executive producer')
  );
  const [isAdministrator, setIsAdministrator] = useState(
    getInitialCheckboxValue('administrator')
  );
  const [isOwner, setIsOwner] = useState(getInitialCheckboxValue('owner'));
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    getInitialCheckboxValue('super admin')
  );
  const [isPitches, setIsPitches] = useState(
    getInitialCheckboxValue('pitches')
  );

  const isEditModal = !!permission;

  const atLeastOneAccessRightSelected =
    isProducer ||
    isExecutiveProducer ||
    isAdministrator ||
    isOwner ||
    isSuperAdmin ||
    isPitches;

  const canProceed = isNameValid && atLeastOneAccessRightSelected;

  const handleEdit = async () => {
    const newAccessRights = [
      isProducer && 'producer',
      isExecutiveProducer && 'executive producer',
      isAdministrator && 'administrator',
      isOwner && 'owner',
      isSuperAdmin && 'super admin',
      isPitches && 'pitches',
    ].filter(Boolean) as AccessRight[];
    const currentAccessRights = permission?.access_set ?? [];
    const addDiff = newAccessRights.filter(
      right => !currentAccessRights.includes(right)
    );
    const removeDiff = currentAccessRights.filter(
      right => !newAccessRights.includes(right)
    );

    if (permission) {
      await updatePermission({
        id: permission?.id,
        name,
        add_access: addDiff,
        remove_access: removeDiff,
      });
      close();
    }
  };

  const handleAdd = async () => {
    await addPermission({
      name,
      access_set: [
        isProducer && 'producer',
        isExecutiveProducer && 'executive producer',
        isAdministrator && 'administrator',
        isOwner && 'owner',
        isSuperAdmin && 'super admin',
        isPitches && 'pitches',
      ].filter(Boolean) as AccessRight[],
    });
    close();
  };

  const close = () => {
    closeModal('permissionModal');
  };

  const isLoading = addLoading || updateLoading;

  return (
    <ConfigModal
      title={
        isEditModal
          ? copy.admin.permissionModal.editTitle
          : copy.admin.permissionModal.title
      }
      buttonProps={{
        label: isEditModal
          ? copy.admin.permissionModal.editCta
          : copy.admin.permissionModal.cta,
        color: currentColor,
        icon: isEditModal ? 'check' : 'add',
        loading: isLoading,
        disabled: !canProceed,
        onClick: isEditModal ? handleEdit : handleAdd,
      }}
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        <Styled.Description>
          {isEditModal
            ? copy.admin.permissionModal.editDescription
            : copy.admin.permissionModal.description}
        </Styled.Description>
        <Styled.NameField>
          <FormInput
            onChange={setName}
            value={name}
            required
            error={nameInvalidMessage}
            placeholder={copy.admin.permissionModal.namePlaceholder}
          />
        </Styled.NameField>
        <Styled.AccessRights>
          <Checkbox
            label={copy.app.accessRightsDescription.producer}
            value={isProducer}
            setValue={setIsProducer}
            variant="square"
          />
          <Checkbox
            label={copy.app.accessRightsDescription['executive producer']}
            value={isExecutiveProducer}
            setValue={setIsExecutiveProducer}
            variant="square"
          />
          <Checkbox
            label={copy.app.accessRightsDescription.administrator}
            value={isAdministrator}
            setValue={setIsAdministrator}
            variant="square"
          />
          <Checkbox
            label={copy.app.accessRightsDescription.owner}
            value={isOwner}
            setValue={setIsOwner}
            variant="square"
          />
          <Checkbox
            label={copy.app.accessRightsDescription['super admin']}
            value={isSuperAdmin}
            setValue={setIsSuperAdmin}
            variant="square"
          />
          <Checkbox
            label={copy.app.accessRightsDescription.pitches}
            value={isPitches}
            setValue={setIsPitches}
            variant="square"
          />
        </Styled.AccessRights>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(PermissionModal);
