import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React, { ForwardedRef } from 'react';

import { useSvgIcon } from 'u9/hooks';

import * as Styled from './DropdownMenu.styles';

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuLabel = Styled.Label;
export const DropdownMenuGroup = Styled.Group;
export const DropdownMenuRadioGroup = Styled.RadioGroup;
export const DropdownMenuSeparator = Styled.Separator;
export const DropdownMenuSub = DropdownMenuPrimitive.Sub;
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

export const DropdownMenuTrigger = React.forwardRef(
  (
    {
      dataCy,
      ...props
    }: { dataCy?: string } & DropdownMenuPrimitive.DropdownMenuTriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) => {
    const { SvgIcon: CaretIcon } = useSvgIcon('caret_down');

    return (
      <Styled.Trigger {...props} ref={forwardedRef} data-cy={dataCy}>
        {props.children && (
          <Styled.TriggerTextContent>
            {props.children}
          </Styled.TriggerTextContent>
        )}
        <Styled.TriggerIconButton>
          <Styled.CaretIconWrapper>
            <CaretIcon />
          </Styled.CaretIconWrapper>
        </Styled.TriggerIconButton>
      </Styled.Trigger>
    );
  }
);
DropdownMenuTrigger.displayName = 'DropdownMenuTrigger';

export const DropdownMenuContent = React.forwardRef(
  (
    { children, ...props }: DropdownMenuPrimitive.DropdownMenuContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <DropdownMenuPrimitive.Portal>
        <Styled.Content collisionPadding={20} {...props} ref={forwardedRef}>
          {children}
          <DropdownMenuPrimitive.Arrow />
        </Styled.Content>
      </DropdownMenuPrimitive.Portal>
    );
  }
);
DropdownMenuContent.displayName = 'DropdownMenuContent';

export const DropdownMenuItem = React.forwardRef(
  (
    {
      children,
      icon,
      dataCy,
      ...props
    }: DropdownMenuPrimitive.DropdownMenuItemProps & {
      icon?: string;
      dataCy?: string;
    },
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { SvgIcon: Icon } = useSvgIcon(icon);
    return (
      <Styled.Item
        {...props}
        ref={forwardedRef}
        onClick={event => event.stopPropagation()}
        onSelect={event => {
          event.stopPropagation();
          props.onSelect?.(event);
        }}
        data-cy={dataCy}
      >
        {icon && Icon && (
          <Styled.ItemIconWrapper>
            <Icon />
          </Styled.ItemIconWrapper>
        )}
        {children}
      </Styled.Item>
    );
  }
);
DropdownMenuItem.displayName = 'DropdownMenuItem';

export const DropdownMenuCheckboxItem = React.forwardRef(
  (
    {
      children,
      icon,
      dataCy,
      ...props
    }: DropdownMenuPrimitive.DropdownMenuCheckboxItemProps & {
      icon?: string;
      dataCy?: string;
    },
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { SvgIcon: CheckIcon } = useSvgIcon('check');
    const { SvgIcon: Icon } = useSvgIcon(icon);
    return (
      <Styled.CheckboxItem
        {...props}
        ref={forwardedRef}
        onClick={event => event.stopPropagation()}
        onSelect={event => {
          event.stopPropagation();
          props.onSelect?.(event);
        }}
        data-cy={dataCy}
      >
        {icon && Icon && (
          <Styled.ItemIconWrapper>
            <Icon />
          </Styled.ItemIconWrapper>
        )}
        <Styled.ItemTextContent>{children}</Styled.ItemTextContent>
        {props.checked === 'indeterminate' && '-'}
        <Styled.CheckIconWrapper>
          {props.checked === true && <CheckIcon />}
        </Styled.CheckIconWrapper>
      </Styled.CheckboxItem>
    );
  }
);
DropdownMenuCheckboxItem.displayName = 'DropdownMenuCheckboxItem';

export const DropdownMenuRadioItem = React.forwardRef(
  (
    {
      children,
      icon,
      ...props
    }: DropdownMenuPrimitive.MenuRadioItemProps & { icon?: string },
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { SvgIcon: Icon } = useSvgIcon(icon);

    return (
      <Styled.RadioItem
        {...props}
        ref={forwardedRef}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {icon && Icon && (
          <Styled.ItemIconWrapper>
            <Icon />
          </Styled.ItemIconWrapper>
        )}
        <Styled.ItemTextContent>{children}</Styled.ItemTextContent>
        <DropdownMenuPrimitive.ItemIndicator>
          <Styled.RadioItemIndicator />
        </DropdownMenuPrimitive.ItemIndicator>
      </Styled.RadioItem>
    );
  }
);
DropdownMenuRadioItem.displayName = 'DropdownMenuRadioItem';

export const DropdownMenuSubTrigger = React.forwardRef(
  (
    {
      children,
      icon,
      ...props
    }: DropdownMenuPrimitive.DropdownMenuSubTriggerProps & {
      icon?: string;
    },
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { SvgIcon: CaretIcon } = useSvgIcon('caret');
    const { SvgIcon: Icon } = useSvgIcon(icon);

    return (
      <Styled.SubTrigger
        {...props}
        ref={forwardedRef}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {icon && Icon && (
          <Styled.ItemIconWrapper>
            <Icon />
          </Styled.ItemIconWrapper>
        )}
        <Styled.ItemTextContent>{children}</Styled.ItemTextContent>
        <Styled.SubMenuTriggerRightSlot>
          <Styled.TriggerCaretIconWrapper>
            <CaretIcon />
          </Styled.TriggerCaretIconWrapper>
        </Styled.SubMenuTriggerRightSlot>
      </Styled.SubTrigger>
    );
  }
);
DropdownMenuSubTrigger.displayName = 'DropdownMenuSubTrigger';

export const DropdownMenuSubContent = React.forwardRef(
  (
    { children, ...props }: DropdownMenuPrimitive.DropdownMenuSubContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Styled.SubContent sideOffset={-5} {...props} ref={forwardedRef}>
        {children}
      </Styled.SubContent>
    );
  }
);
DropdownMenuSubContent.displayName = 'DropdownMenuSubContent';
