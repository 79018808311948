import { rgba } from 'polished';

import { useCurrentProjectVersion } from 'queries/projectRows';
import { ReconcileExternalItem, ReconcileItem } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import { useIsResourceWrapped } from './useIsResourceWrapped';

export enum ReconcileMatchTypes {
  red = 'red',
  amber = 'amber',
  green = 'green',
}

const matchTypeToColor: Record<
  ReconcileMatchTypes,
  { primary: string; secondary: string }
> = {
  [ReconcileMatchTypes.green]: {
    primary: rgba(colors.mountainMeadow, 0.5),
    secondary: 'transparent',
  },
  [ReconcileMatchTypes.amber]: {
    primary: rgba(colors.selectiveYellow, 0.5),
    secondary: rgba(colors.selectiveYellow, 0.3),
  },
  [ReconcileMatchTypes.red]: {
    primary: rgba(colors.sunsetOrange, 0.5),
    secondary: rgba(colors.sunsetOrange, 0.3),
  },
};

const wrappedResourceColors = {
  primary: rgba(colors.mountainMeadow, 0.5),
  secondary: rgba(colors.silver, 0.2),
};

export const useGetColorMatch = ({
  overspentRowIds,
}: {
  overspentRowIds: number[];
}) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const currentRows = currentProjectVersion?.rows;

  const getMatchType = (item: ReconcileItem): ReconcileMatchTypes => {
    const rowItems = currentRows;

    const { row_id } = item;
    const isRowMatch = !!row_id && rowItems?.find(({ id }) => id === row_id);

    if (item.row_id && overspentRowIds.includes(item.row_id))
      return ReconcileMatchTypes.red;
    if (isRowMatch) return ReconcileMatchTypes.green;
    return ReconcileMatchTypes.amber;
  };

  const getMatchColor = (item: ReconcileItem) => {
    const matchType = getMatchType(item);

    return matchTypeToColor[matchType];
  };

  const getMatchColorByType = (type: ReconcileMatchTypes) => {
    return matchTypeToColor[type];
  };

  const getMatchTypeBulk = (items: ReconcileItem[]): ReconcileMatchTypes => {
    const matchTypes = items.map(item => getMatchType(item));

    if (
      matchTypes.every(matchType => matchType === ReconcileMatchTypes.green)
    ) {
      return ReconcileMatchTypes.green;
    }
    if (matchTypes.some(matchType => matchType === ReconcileMatchTypes.red)) {
      return ReconcileMatchTypes.red;
    }
    return ReconcileMatchTypes.amber;
  };

  return { getMatchType, getMatchColor, getMatchTypeBulk, getMatchColorByType };
};

export const useGetColorMatchIntegration = () => {
  const { getIsResourceWrapped } = useIsResourceWrapped();

  const getMatchColor = (item: ReconcileExternalItem) => {
    if (getIsResourceWrapped(item)) return wrappedResourceColors;
    if (item?.resource_id || item?.vendor_id) return matchTypeToColor.green;
    return matchTypeToColor.amber;
  };

  return { getMatchColor };
};
