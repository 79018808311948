import { FC, memo, useCallback, useState } from 'react';

import { availableCurrencies } from '@u9/bob3-shared/lib/types/api.types';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import {
  useAddSupportedClientRates,
  useSupportedClientRates,
} from 'queries/supportedClientRates';
import { useCopyStore, useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './AddClientRateColumnModal.styles';

export interface AddClientRateColumnModalProps {}

const AddClientRateColumnModal: FC<AddClientRateColumnModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const copy = useCopyStore(s => s.copy);
  const { data: supportedClientRates } = useSupportedClientRates();
  const { mutateAsync: addSupportedClientRate, isLoading } =
    useAddSupportedClientRates();

  const dropdownCurrencies = availableCurrencies.filter(
    availableCurrency => !supportedClientRates?.includes(availableCurrency)
  );

  const noOptions = dropdownCurrencies.length === 0;

  const [activeCurrency, setActiveCurrency] = useState<string>(
    dropdownCurrencies[0]
  );

  const close = useCallback(() => {
    closeModal('addClientRateColumnModal');
  }, [closeModal]);

  const handleAdd = async () => {
    if (!noOptions) {
      await addSupportedClientRate({
        currency: activeCurrency as typeof availableCurrencies[number],
      });
    }
    closeModal('addClientRateColumnModal');
  };

  return (
    <ConfigModal
      buttonProps={{
        label: noOptions
          ? copy.admin.clientRateModal.noOptionsButton
          : copy.admin.clientRateModal.button,
        color: currentColor,
        icon: noOptions ? 'check' : 'add',
        onClick: handleAdd,
        loading: isLoading,
        disabled: isLoading,
        dataCy: 'client-rate-modal__button',
      }}
      onCloseClick={close}
      title={copy.admin.clientRateModal.title}
    >
      <Styled.Wrapper>
        {noOptions ? (
          <Styled.NoItems>
            {copy.admin.clientRateModal.noOptions}
          </Styled.NoItems>
        ) : (
          <Select
            value={activeCurrency}
            onValueChange={setActiveCurrency}
            dataCy="client-rate-modal__currency"
            label={copy.admin.clientRateModal.currencyField}
          >
            {dropdownCurrencies.map(currency => (
              <SelectItem
                key={currency}
                value={currency}
                dataCy={`${currency}__menu-item`}
              >
                {copy.app.currenciesLong[currency]}
              </SelectItem>
            ))}
          </Select>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(AddClientRateColumnModal);
