import { useMemo } from 'react';

const getIconComponent = (icon: string, failSilently = false) => {
  if (!icon) return () => null;
  const req = require.context('!@svgr/webpack!svgs/', false, /\.svg$/);
  const path = `./${icon}.svg`;
  if (failSilently) {
    const fileExists = req.keys().includes(path);
    if (!fileExists) {
      return () => null;
    }
  }
  return req(path).default as React.FunctionComponent<
    React.SVGProps<SVGSVGElement>
  >;
};

export const useSvgIcon = (icon = '', failSilently = false) => {
  const SvgIcon = useMemo(() => {
    if (!icon) {
      return () => null;
    }
    return getIconComponent(icon, failSilently);
  }, [failSilently, icon]);

  return { SvgIcon };
};

export const useSvgIconList = (icons: string[], failSilently = false) => {
  const iconComponents = useMemo(
    () => icons.map(icon => getIconComponent(icon, failSilently)),
    [failSilently, icons]
  );

  return iconComponents;
};

export default useSvgIcon;
