import { Project, ProjectsListItem } from 'utils/api.types';

import { useDeleteAction } from './useDeleteAction';
import { useDeletePitchAction } from './useDeletePitchAction';
import { useDownloadAction } from './useDownloadAction';
import { useDownloadClientReportAction } from './useDownloadClientReportAction';
import { useDownloadMfrReportAction } from './useDownloadMfrReportAction';
import { useDropdown } from './useDropdown';
import { useDuplicateAction } from './useDuplicateAction';
import { useDuplicateProjectVersionAction } from './useDuplicateProjectVersionAction';
import { useEditProjectVersionNameAction } from './useEditProjectVersionNameAction';
import { useGetLinkAction } from './useGetLinkAction';
import { useGetProjectVersionLinkAction } from './useGetProjectVersionLink';
import { useHandleMfrDiscount } from './useHandleMfrDiscount';
import { useHideProjectAction } from './useHideProjectAction';
import { useOpenProjectInfoAction } from './useOpenProjectInfoAction';
import { useRequestPermissionAction } from './useRequestPermissionAction';
import { useShareAction } from './useShareAction';
import { useToggleTestProject } from './useToggleTestProject';

interface UseProjectDropdownProps {
  currentProject: ProjectsListItem | Project | undefined;
  isPerformanceDashboard?: boolean;
  isVariantDropdown?: boolean;
}

export const useProjectDropdown = ({
  currentProject,
  isVariantDropdown = false,
}: UseProjectDropdownProps) => {
  const currentProjectId = currentProject?.id;
  const mainVersionId = currentProject?.main_version_id;

  const [getLink] = useGetLinkAction({
    currentProjectId,
    mainVersionId,
  });
  const [openProjectInfo] = useOpenProjectInfoAction({
    currentProjectId,
  });
  const [duplicate] = useDuplicateAction({ currentProjectId });
  const [download] = useDownloadAction({ currentProject });
  const [downloadClientReport] = useDownloadClientReportAction({
    currentProject,
  });
  const [handleDelete] = useDeleteAction({
    currentProjectId,
  });
  const [handleShare] = useShareAction({
    currentProjectId,
  });
  const [handleRequestPermission] = useRequestPermissionAction({
    currentProjectId,
  });
  const [hideProject] = useHideProjectAction({
    currentProjectId,
  });
  const [toggleTestProject] = useToggleTestProject({ currentProjectId });
  const [handleMfrDiscount] = useHandleMfrDiscount({ currentProjectId });
  const [getPitchLink] = useGetProjectVersionLinkAction({ currentProjectId });
  const [editPitchName] = useEditProjectVersionNameAction({ currentProjectId });
  const [duplicatePitch] = useDuplicateProjectVersionAction({
    currentProjectId,
  });
  const [deletePitch] = useDeletePitchAction({ currentProjectId });
  const [downloadMfr] = useDownloadMfrReportAction({ currentProject });

  const { items, handleClick } = useDropdown({
    currentProject,
    getLink,
    openProjectInfo,
    duplicate,
    download,
    downloadClientReport,
    handleDelete,
    handleShare,
    handleRequestPermission,
    hideProject,
    toggleTestProject,
    handleMfrDiscount,
    getPitchLink,
    editPitchName,
    duplicatePitch,
    deletePitch,
    downloadMfr,
    isVariantDropdown,
  });

  return { items, handleClick };
};
