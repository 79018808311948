import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { ContextualMenuItem } from 'components/ContextualMenu/ContextualMenu';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useFormatMoney } from 'hooks/useFormatMoney';
import useProjectDisplayText from 'hooks/useProjectDisplayText';
import {
  useCurrentProject,
  useRequestJobNumber,
  useUpdateProject,
} from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectMode, ProjectVersion } from 'utils/api.types';
import { getDateInBEFormat } from 'utils/formatters';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './RequestJobNumberModal.styles';

export interface RequestJobNumberModalProps {}

const RequestJobNumberModal: FC<RequestJobNumberModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const { mutateAsync: updateProject, isLoading: updateProjectLoading } =
    useUpdateProject();
  const { mutateAsync: requestJobNumber, isLoading } = useRequestJobNumber();
  const [success, setSuccess] = useState(false);
  const { formatMoney } = useFormatMoney();

  const [kickoffDate, setKickoffDate] = useState(
    (currentProject?.kickoff_date && new Date(currentProject?.kickoff_date)) ||
      new Date()
  );
  const [deliveryDate, setDeliveryDate] = useState(
    (currentProject?.delivery_date &&
      new Date(currentProject?.delivery_date)) ||
      new Date()
  );
  const [selectedPitchVersion, setSelectedPitchVersion] = useState<
    ProjectVersion['id'] | undefined
  >(currentProject?.main_version_id);

  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const budget = currentProjectVersion?.budget;

  const getStatusDisplayText = useProjectDisplayText();
  const modeItems: ContextualMenuItem[] = [
    {
      displayName: getStatusDisplayText(
        ProjectMode.production,
        currentProject?.status
      ),
      id: ProjectMode.production,
    },
    {
      displayName: getStatusDisplayText(
        ProjectMode.hosting,
        currentProject?.status
      ),
      id: ProjectMode.hosting,
    },
  ];
  const [activeModeId, setActiveModeId] = useState<
    ProjectMode.production | ProjectMode.hosting
  >(ProjectMode.production);

  const handleConfirm = async () => {
    if (!currentProject?.id || !selectedPitchVersion) return;
    await updateProject({
      projectId: currentProject?.id,
      project: {
        kickoff_date: getDateInBEFormat(kickoffDate),
        delivery_date: getDateInBEFormat(deliveryDate),
      },
    });
    const {
      data: { success },
    } = await requestJobNumber({
      project_id: currentProject?.id,
      version_id: selectedPitchVersion,
      mode: activeModeId,
    });
    if (success) {
      setSuccess(true);
    }
  };

  const modalCopy = copy.project.requestJobNumberModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      color={currentColor}
      onCloseClick={() => closeModal('requestJobNumberModal')}
      zeroPadding
    >
      {success ? (
        <Styled.Wrapper>
          {modalCopy.confirmText}
          <Styled.ButtonsWrapper>
            <Button
              label={modalCopy.closeButton}
              icon="check"
              color={currentColor}
              onClick={() => closeModal('requestJobNumberModal')}
              loading={isLoading || updateProjectLoading}
              disabled={isLoading || updateProjectLoading}
            />
          </Styled.ButtonsWrapper>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          {currentProject?.job_number_requested && (
            <Styled.AlreadyRequestedLabel>
              {modalCopy.alreadyRequested}
            </Styled.AlreadyRequestedLabel>
          )}
          <Styled.ProjectDataSection>
            <Styled.Row>
              <Styled.ProjectDataItem>
                <Styled.ProjectDataLabel>
                  {modalCopy.projectName}
                </Styled.ProjectDataLabel>
                <Styled.ProjectDataValue>
                  {currentProject?.name || '-'}
                </Styled.ProjectDataValue>
              </Styled.ProjectDataItem>
              <Styled.ProjectDataItem>
                <Styled.ProjectDataLabel>
                  {modalCopy.client}
                </Styled.ProjectDataLabel>
                <Styled.ProjectDataValue>
                  {currentProject?.client?.name || '-'}
                </Styled.ProjectDataValue>
              </Styled.ProjectDataItem>
            </Styled.Row>
            <Styled.Row>
              <Styled.ProjectDataItem>
                <Styled.ProjectDataLabel>
                  {modalCopy.budget}
                </Styled.ProjectDataLabel>
                <Styled.ProjectDataValue>
                  {formatMoney(budget?.total_budget) || '-'}
                </Styled.ProjectDataValue>
              </Styled.ProjectDataItem>
              <Styled.ProjectDataItem>
                <Styled.ProjectDataLabel>
                  {modalCopy.subLevelCategory}
                </Styled.ProjectDataLabel>
                <Styled.ProjectDataValue>
                  {currentProject?.vertical?.name || '-'}
                </Styled.ProjectDataValue>
              </Styled.ProjectDataItem>
            </Styled.Row>

            <Styled.ProjectDataItem>
              <Styled.ProjectDataLabel>
                {modalCopy.territory}
              </Styled.ProjectDataLabel>
              <Styled.ProjectDataValue>
                {currentProject?.territory?.name || '-'}
              </Styled.ProjectDataValue>
            </Styled.ProjectDataItem>
          </Styled.ProjectDataSection>
          <Styled.Row>
            <Select
              label={modalCopy.selectPitch}
              value={String(selectedPitchVersion)}
              onValueChange={v =>
                v ? setSelectedPitchVersion(Number(v)) : undefined
              }
            >
              {currentProject?.versions
                .filter(({ mode }) => mode === ProjectMode.pitch)
                .map(({ id, name }) => (
                  <SelectItem key={id} value={String(id)}>
                    {name}
                  </SelectItem>
                ))}
            </Select>
            <Select
              label={copy.project.requestJobNumber.dropdownLabel}
              value={String(activeModeId)}
              onValueChange={value => {
                if (Object.values(ProjectMode).includes(value as ProjectMode)) {
                  setActiveModeId(
                    value as ProjectMode.production | ProjectMode.hosting
                  );
                }
              }}
            >
              {modeItems.map(({ id, displayName }) => (
                <SelectItem key={id} value={String(id)}>
                  {displayName}
                </SelectItem>
              ))}
            </Select>
          </Styled.Row>
          <Styled.Row>
            <Datepicker
              onChange={setKickoffDate}
              closeOnChange
              color={currentColor}
              value={kickoffDate}
              fieldLabel={modalCopy.kickoffDate}
            />
            <Datepicker
              onChange={setDeliveryDate}
              closeOnChange
              color={currentColor}
              value={deliveryDate}
              fieldLabel={modalCopy.deliveryDate}
            />
          </Styled.Row>
          <Styled.ButtonsWrapper>
            <Button
              label={modalCopy.cancelButton}
              icon="arrow_left"
              color={currentColor}
              onClick={() => closeModal('requestJobNumberModal')}
            />
            <Button
              label={modalCopy.confirmButton}
              icon="check"
              color={currentColor}
              onClick={handleConfirm}
            />
          </Styled.ButtonsWrapper>
        </Styled.Wrapper>
      )}
    </ConfigModal>
  );
};

export default memo(RequestJobNumberModal);
