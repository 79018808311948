import { useOrganization } from 'queries/organizations';
import { useCopyStore, usePerformanceDashboardStore } from 'store';
import { formatDateInline, formatMoney, formatPercent } from 'utils/formatters';
import { replaceString, replaceStrings } from 'utils/replace';

export enum FilterId {
  startDate,
  endDate,
  includeStart,
  includeEnd,
  excludeClosed,
  verticals,
  budgetFrom,
  budgetTo,
  marginVariation,
  timeframe,
  intExt,
  similarProjectId,
  icons,
  includeNonIcon,
  resultsByDate,
}

export type Filter = {
  id: FilterId;
  text: string;
  onCloseClick?: () => void;
};

export const useFilters = () => {
  const { data: organization } = useOrganization();
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const startDate = usePerformanceDashboardStore(s => s.startDate);
  const endDate = usePerformanceDashboardStore(s => s.endDate);
  const includeStart = usePerformanceDashboardStore(s => s.includeStart);
  const includeEnd = usePerformanceDashboardStore(s => s.includeEnd);
  const excludeClosed = usePerformanceDashboardStore(s => s.excludeClosed);
  const verticals = usePerformanceDashboardStore(s => s.verticals);
  const budgetFrom = usePerformanceDashboardStore(s => s.budgetFrom);
  const budgetTo = usePerformanceDashboardStore(s => s.budgetTo);
  const marginVariation = usePerformanceDashboardStore(s => s.marginVariation);
  const timeframe = usePerformanceDashboardStore(s => s.timeframe);
  const similarProjectId = usePerformanceDashboardStore(
    s => s.similarProjectId
  );
  const intExtFilter = usePerformanceDashboardStore(s => s.intExtFilter);
  const icons = usePerformanceDashboardStore(s => s.icons);
  const includeNonIcon = usePerformanceDashboardStore(s => s.includeNonIcon);
  const resultsByDate = usePerformanceDashboardStore(s => s.resultsByDate);
  const internalPercent = usePerformanceDashboardStore(s => s.internalPercent);
  const setIncludeStart = usePerformanceDashboardStore(s => s.setIncludeStart);
  const setIncludeEnd = usePerformanceDashboardStore(s => s.setIncludeEnd);
  const setExcludeClosed = usePerformanceDashboardStore(
    s => s.setExcludeClosed
  );
  const setVerticals = usePerformanceDashboardStore(s => s.setVerticals);
  const setBudgetFrom = usePerformanceDashboardStore(s => s.setBudgetFrom);
  const setBudgetTo = usePerformanceDashboardStore(s => s.setBudgetTo);
  const setMarginVariation = usePerformanceDashboardStore(
    s => s.setMarginVariation
  );
  const setTimeframe = usePerformanceDashboardStore(s => s.setTimeframe);
  const setSimilarProjectId = usePerformanceDashboardStore(
    s => s.setSimilarProjectId
  );
  const setIntExtFilter = usePerformanceDashboardStore(s => s.setIntExtFilter);
  const setIcons = usePerformanceDashboardStore(s => s.setIcons);
  const setIncludeNonIcon = usePerformanceDashboardStore(
    s => s.setIncludeNonIcon
  );
  const setResultsByDate = usePerformanceDashboardStore(
    s => s.setResultsByDate
  );

  const filtersCopy = copy.performanceDashboard.filters;

  const generalFiltersMap: Filter[] = [
    {
      id: FilterId.startDate,
      text: replaceString(
        filtersCopy.dateFrom,
        '{name}',
        formatDateInline(startDate)
      ),
    },
    {
      id: FilterId.endDate,
      text: replaceString(
        filtersCopy.dateTo,
        '{name}',
        formatDateInline(endDate)
      ),
    },
    {
      id: FilterId.resultsByDate,
      text: filtersCopy.resultsByDate,
      onCloseClick: () => setResultsByDate(false),
    },
    {
      id: FilterId.includeStart,
      text: filtersCopy.includeStart,
      onCloseClick: () => setIncludeStart(false),
    },
    {
      id: FilterId.includeEnd,
      text: filtersCopy.includeEnd,
      onCloseClick: () => setIncludeEnd(false),
    },
    {
      id: FilterId.excludeClosed,
      text: filtersCopy.closed,
      onCloseClick: () => setExcludeClosed(false),
    },
    {
      id: FilterId.verticals,
      text: replaceString(
        filtersCopy.verticals,
        '{name}',
        String(verticals?.length)
      ),
      onCloseClick: () => setVerticals([]),
    },
    {
      id: FilterId.budgetFrom,
      text: replaceString(
        filtersCopy.budgetFrom,
        '{name}',
        formatMoney(Number(budgetFrom), organization?.currency, locale)
      ),
      onCloseClick: () => setBudgetFrom(''),
    },
    {
      id: FilterId.budgetTo,
      text: replaceString(
        filtersCopy.budgetTo,
        '{name}',
        formatMoney(Number(budgetTo), organization?.currency, locale)
      ),
      onCloseClick: () => setBudgetTo(''),
    },
    {
      id: FilterId.icons,
      text: replaceString(filtersCopy.icons, '{name}', String(icons?.length)),
      onCloseClick: () => setIcons([]),
    },
    {
      id: FilterId.includeNonIcon,
      text: filtersCopy.includeNonIcon,
      onCloseClick: () => setIncludeNonIcon(false),
    },
  ];

  const kpiFiltersMap: Filter[] = [
    {
      id: FilterId.marginVariation,
      text: replaceString(
        filtersCopy.marginVariation,
        '{name}',
        marginVariation
      ),
      onCloseClick: () => setMarginVariation(''),
    },
    {
      id: FilterId.timeframe,
      text: replaceString(
        filtersCopy.timeframe,
        '{name}',
        timeframe ? copy.app.timeframe[timeframe] : ''
      ),
      onCloseClick: () => setTimeframe(null),
    },
  ];

  const rolesFiltersMap: Filter[] = [
    {
      id: FilterId.similarProjectId,
      text: filtersCopy.similarProject,
      onCloseClick: () => setSimilarProjectId(null),
    },
    {
      id: FilterId.intExt,
      text: replaceStrings(filtersCopy.intExt, [
        ['{name}', formatPercent(internalPercent?.[0], { precision: 0 })],
        ['{name}', formatPercent(internalPercent?.[1], { precision: 0 })],
      ]),
      onCloseClick: () => setIntExtFilter(false),
    },
  ];

  const generalFilters = generalFiltersMap.filter(({ id }) => {
    if (id === FilterId.startDate) return !!startDate;
    if (id === FilterId.endDate) return !!endDate;
    if (id === FilterId.includeStart) return !!includeStart;
    if (id === FilterId.includeEnd) return !!includeEnd;
    if (id === FilterId.excludeClosed) return !!excludeClosed;
    if (id === FilterId.verticals) return verticals?.length > 0;
    if (id === FilterId.budgetFrom) return !!budgetFrom;
    if (id === FilterId.budgetTo) return !!budgetTo;
    if (id === FilterId.icons) return icons?.length > 0;
    if (id === FilterId.includeNonIcon) return !!includeNonIcon;
    if (id === FilterId.resultsByDate) return !!resultsByDate;
  });

  const kpiFilters = kpiFiltersMap.filter(({ id }) => {
    if (id === FilterId.marginVariation) return !!marginVariation;
    if (id === FilterId.timeframe) return !!timeframe;
  });

  const rolesFilters = rolesFiltersMap.filter(({ id }) => {
    if (id === FilterId.similarProjectId) return !!similarProjectId;
    if (id === FilterId.intExt) return intExtFilter && !!internalPercent;
  });

  return {
    generalFilters,
    kpiFilters,
    rolesFilters,
  };
};
