import { Dispatch, SetStateAction, useCallback } from 'react';

import { BudgetEntry, PaymentStatuses } from 'utils/api.types';

interface UseRowsFields {
  budgetEntries: BudgetEntry[];
  setBudgetEntries: Dispatch<SetStateAction<BudgetEntry[]>>;
}

export const useRowsFields = ({
  budgetEntries,
  setBudgetEntries,
}: UseRowsFields) => {
  const handleChange = useCallback(
    (index: number, field: keyof BudgetEntry) =>
      (value: string | null | PaymentStatuses | Date) => {
        // eslint-disable-next-line
        // @ts-expect-error
        budgetEntries[index][field] = value;
        setBudgetEntries([...budgetEntries]);
      },
    [budgetEntries, setBudgetEntries]
  );

  return {
    handleChange,
  };
};
