import { FC, memo, useCallback } from 'react';

import { useDeleteTerritory } from 'queries/territories';
import { useCopyStore, useGlobalStore } from 'store';
import { GeoArea } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteTerritoryModalProps {
  territory: GeoArea;
}

const DeleteTerritoryModal: FC<DeleteTerritoryModalProps> = ({ territory }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);

  const {
    mutateAsync: deleteTerritoryHandler,
    isLoading: deleteTerritoryLoading,
  } = useDeleteTerritory();

  const handleClose = useCallback(() => {
    closeModal('deleteTerritoryModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    await deleteTerritoryHandler(territory.id);
    handleClose();
  }, [handleClose, deleteTerritoryHandler, territory?.id]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.deleteTerritoryModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
        dataCy: 'delete-territory-confirm',
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteTerritoryLoading,
        disabled: deleteTerritoryLoading,
      }}
      description={replaceString(description, '{territory}', territory?.name)}
      title={title}
    />
  );
};

export default memo(DeleteTerritoryModal);
