import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useCopyStore } from 'store';
import { Project } from 'utils/api.types';

import { useCanEditRndFields } from '../../hooks/useCanEditRndFields';
import { useRndPercentLevelField } from '../../hooks/useRndPercentLevelField';

export interface RndPercentLevelProps {
  rndPercentLevelField: ReturnType<typeof useRndPercentLevelField>;
  project?: Project;
}

const RndPercentLevel: FC<RndPercentLevelProps> = ({
  rndPercentLevelField,
  project,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { rndPercentLevel, setRndPercentLevel } = rndPercentLevelField;

  const { canEditRndFields } = useCanEditRndFields({ project });

  const onRndPercentChange = (value: string) => {
    setRndPercentLevel(String(Math.min(100, Number(value))));
  };

  return (
    <FormInput
      onChange={onRndPercentChange}
      value={rndPercentLevel}
      mask="integer"
      maxCharacters={3}
      placeholder={copy.app.projectModal.rndPercentageLabel}
      dataCy="rnd-percent-level"
      disabled={!canEditRndFields}
    />
  );
};

export default memo(RndPercentLevel);
