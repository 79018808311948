import { FC, Fragment, useState } from 'react';

import {
  Select,
  SelectItem,
  SelectSeparator,
} from 'components/dropdowns/Select/Select';
import { useCopyStore } from 'store';
import { ReconcileItem } from 'utils/api.types';

import { useProjectResources } from '../hooks/useProjectResources';

import * as Styled from './ResourceDropdown.styles';

export enum ResourceTypes {
  resource = 'resource',
  vendor = 'vendor',
  expense = 'expense',
}

const RESOURCE_PREFIX = 'RESOURCE_';
const VENDOR_PREFIX = 'VENDOR_';
const EXPENSE_PREFIX = 'EXPENSE_';

const prefixMap = {
  [ResourceTypes.resource]: RESOURCE_PREFIX,
  [ResourceTypes.vendor]: VENDOR_PREFIX,
  [ResourceTypes.expense]: EXPENSE_PREFIX,
};

const addPrefix = (id: string | number | undefined, type: ResourceTypes) => {
  return `${prefixMap[type]}${id}`;
};

const getFromPrefix = (
  text: string
): { id: number | string; type: ResourceTypes } => {
  const [prefix, id] = text.split('_');
  const resourceType = [
    ResourceTypes.expense,
    ResourceTypes.resource,
    ResourceTypes.vendor,
  ].find(resourceType =>
    prefixMap[resourceType]?.startsWith(prefix)
  ) as ResourceTypes;

  return {
    type: resourceType,
    id: id,
  };
};

export interface ResourceDropdownProps {
  items: ReconcileItem[];
  onResourceSelected: (
    items: {
      id: number | string;
      type: ResourceTypes;
      jasId: ReconcileItem['jas_id'];
    }[]
  ) => void;
  onResetResourceSelected: (
    items: {
      jasId: ReconcileItem['jas_id'];
    }[]
  ) => void;
}

const ResourceDropdown: FC<ResourceDropdownProps> = ({
  items,
  onResourceSelected,
  onResetResourceSelected,
}) => {
  const copy = useCopyStore(s => s.copy);

  const projectResources = useProjectResources();

  const autoMatch = {
    displayName: copy.app.reconcileSuccessModal.auto,
    id: 'AUTO_MATCH',
    divider: true,
  };

  const isExpenseRow = !!items[0].expense_id;

  const dropdownItems = [
    autoMatch,
    ...projectResources
      .filter(item => {
        if (isExpenseRow) {
          return item.type === ResourceTypes.expense;
        } else {
          return [ResourceTypes.resource, ResourceTypes.vendor].includes(
            item.type
          );
        }
      })
      .map(item => ({
        displayName: item.name,
        id: addPrefix(item.id, item.type),
        divider: false,
      })),
  ];

  const resourceId = items[0].resource_id;
  const vendorId = items[0].vendor_id;
  const description = items[0].description;

  const defaultItemId = resourceId
    ? addPrefix(resourceId, ResourceTypes.resource)
    : vendorId
    ? addPrefix(vendorId, ResourceTypes.vendor)
    : description
    ? addPrefix(description, ResourceTypes.expense)
    : null;

  const [activeItemId, setActiveItemId] = useState(defaultItemId);

  const handleDropdownClick = (itemId: string) => {
    if (itemId === 'AUTO_MATCH') {
      setActiveItemId(null);
      onResetResourceSelected(items.map(item => ({ jasId: item.jas_id })));
    } else {
      setActiveItemId(itemId);
      const { id, type } = getFromPrefix(itemId);
      onResourceSelected(items.map(item => ({ id, type, jasId: item.jas_id })));
    }
  };

  return (
    <Styled.Wrapper>
      <Select
        key={activeItemId}
        value={activeItemId || undefined}
        onValueChange={handleDropdownClick}
        styleVariant="table"
      >
        {dropdownItems.map(({ displayName, id, divider }) => (
          <Fragment key={id}>
            <SelectItem value={id}>{displayName}</SelectItem>
            {divider && <SelectSeparator />}
          </Fragment>
        ))}
      </Select>
    </Styled.Wrapper>
  );
};

export default ResourceDropdown;
