import { FC, memo } from 'react';

import FormSlider, {
  DefaultTopHtml,
} from 'components/inputs/FormSlider/FormSlider';
import { useFormatDays } from 'hooks/useFormatDays';
import { useCopyStore } from 'store';

import { useQuantitySlider } from './hooks/useQuantitySlider';

export type QuantitySliderProps = ReturnType<typeof useQuantitySlider>;

const QuantitySlider: FC<QuantitySliderProps> = ({
  quantity,
  maxQuantity,
  minQuantity,
  setQuantity,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { formatDays } = useFormatDays();

  return (
    <FormSlider
      label={copy.app.projectFiltersModal.quantity}
      step={1}
      min={minQuantity === Infinity ? 0 : minQuantity}
      max={maxQuantity === 0 ? 0 : maxQuantity}
      disabled={minQuantity === Infinity && maxQuantity === 0}
      value={quantity}
      onValueChange={setQuantity}
      topHtml={
        <DefaultTopHtml
          value={[formatDays(quantity[0]), formatDays(quantity[1])]}
        />
      }
    />
  );
};

export default memo(QuantitySlider);
