import _get from 'lodash/get';

export const escapeStringRegex = (string: string) => {
  const pattern = /[|\\{}()[\]^$+*?.]/g;
  return string.replace(pattern, '\\$&');
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const basicRegexSearch = <T>(
  record: T,
  field: string,
  searchText: string
) => {
  return new RegExp(escapeStringRegex(searchText), 'gi').test(
    _get(record, field)
  );
};
