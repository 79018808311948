import { memo } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectRow, Resource, Vendor } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';
import { CategoryItems } from './EditResourceModal.types';
import { useEditResource } from './hooks/useEditResource';
import ResourcePartial from './ResourcePartial/ResourcePartial';
import VendorPartial from './VendorPartial/VendorPartial';

import * as Styled from './EditResourceModal.styles';

export interface EditResourceModalProps {
  onSave: (resource: Resource | Vendor) => void;
  row: ProjectRow;
  tempValue: string;
  triggerMarkupWarning: (rowId: ProjectRow['id']) => void;
}

const EditResourceModal = ({
  onSave,
  row,
  tempValue,
  triggerMarkupWarning,
}: EditResourceModalProps) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);

  const modalCopy = copy.project.fillResourceInformation.editResourceModal;

  const {
    isAnyLoading,
    save,
    isAnyInvalid,
    territoryItems,
    handleClose,
    rolesDropdown,
    activeTerritoryField,
    companyField,
    emailErrorMessage,
    emailField,
    nameField,
    vendorServiceDropdown,
    vendorServiceItems,
    addressLine1,
    addressLine2,
    city,
    countryCode,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setCountryCode,
    setZipCode,
    zipCode,
  } = useEditResource({ row, tempValue, triggerMarkupWarning, onSave });

  const [activeTerritoryId, setActiveTerritoryId] = activeTerritoryField;

  return (
    <ConfigModal
      buttonProps={{
        label: modalCopy.saveButton,
        icon: 'check',
        onClick: save,
        disabled: isAnyInvalid,
        loading: isAnyLoading,
        dataCy: 'edit-resource-modal__save-button',
      }}
      title={modalCopy.title}
      onCloseClick={handleClose}
      color={currentColor}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <Select
            label={modalCopy.listLabel}
            dataCy="edit-resource-modal__territory"
            value={activeTerritoryId}
            onValueChange={setActiveTerritoryId}
          >
            {territoryItems.map(({ id, displayName }) => (
              <SelectItem
                key={id}
                value={String(id)}
                dataCy={`${id}__menu-item`}
              >
                {displayName}
              </SelectItem>
            ))}
          </Select>
        </Styled.Row>
        {activeTerritoryId === CategoryItems.Internal ||
        activeTerritoryId === CategoryItems.External ? (
          <ResourcePartial
            rolesDropdown={rolesDropdown}
            companyField={companyField}
            emailErrorMessage={emailErrorMessage}
            emailField={emailField}
            nameField={nameField}
            activeTerritoryField={activeTerritoryField}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            countryCode={countryCode}
            setAddressLine1={setAddressLine1}
            setAddressLine2={setAddressLine2}
            setCity={setCity}
            setCountryCode={setCountryCode}
            setZipCode={setZipCode}
            zipCode={zipCode}
          />
        ) : null}
        {activeTerritoryId === CategoryItems.Vendor ? (
          <VendorPartial
            emailErrorMessage={emailErrorMessage}
            emailField={emailField}
            nameField={nameField}
            vendorServiceDropdown={vendorServiceDropdown}
            vendorServiceItems={vendorServiceItems}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            countryCode={countryCode}
            setAddressLine1={setAddressLine1}
            setAddressLine2={setAddressLine2}
            setCity={setCity}
            setCountryCode={setCountryCode}
            setZipCode={setZipCode}
            zipCode={zipCode}
          />
        ) : null}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditResourceModal);
