import * as Dialog from '@radix-ui/react-alert-dialog';
import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const Content = styled(Dialog.Content)`
  width: 960rem;
  max-height: 80vh;
  border-radius: ${layout.borderRadius.big}rem;
  overflow: hidden;
  padding: 80rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.outerSpace};
  color: ${colors.white};
  animation: ${contentShow} 150ms;
`;

export const Title = styled(Dialog.Title)`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  color: ${rgba(colors.white, 0.4)};
  padding-bottom: 80rem;
  text-align: center;
`;

export const Description = styled(Dialog.Description)`
  text-align: center;
  padding-bottom: 140rem;
  ${({ theme: { locale } }) => setTypography('body3', locale)}
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;

  > button:not(:last-child) {
    margin-right: 20rem;
  }
`;

export const Confirm = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)}
  margin-top: -100rem;
  text-align: center;
  padding-bottom: 120rem;
`;

const overlayShow = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: ${rgba(colors.white, 0.5)};
  animation: ${overlayShow} 150ms;
`;
