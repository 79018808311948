import { useMemo } from 'react';

import { useProject as useFetchProject } from 'queries/project';
import { Project } from 'utils/api.types';

export const useProject = (projectId?: Project['id']) => {
  const { data: project, isLoading: isProjectLoading } = useFetchProject(
    { projectId },
    { enabled: !!projectId }
  );

  return useMemo(
    () => ({
      project,
      isProjectLoading,
    }),
    [isProjectLoading, project]
  );
};
