import { useCallback, useMemo } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useAddClient, useUpdateClient } from 'queries/clients';
import { useCopyStore } from 'store';

import { useCategoryDropdown } from './useCategoryDropdown';

export const useClientPartial = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.clientRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.clientRequired,
      copy.app.validatorMessages.nameNoAtSign,
    ]
  );
  const nameField = useField(item?.name ?? '', validators);

  const emailValidators = useMemo(
    () => ({
      email: { enabled: true, message: copy.app.validatorMessages.validEmail },
    }),
    [copy.app.validatorMessages.validEmail]
  );
  const emailField = useField(item?.email ?? '', emailValidators);

  const categoryDropdown = useCategoryDropdown(item);

  const isPartialValid = useMemo(() => {
    const [, , isNameValid] = nameField;
    const [, , isEmailValid] = emailField;
    return isNameValid && isEmailValid;
  }, [emailField, nameField]);

  const { mutateAsync: handleAdd } = useAddClient();
  const { mutateAsync: handleUpdate } = useUpdateClient();

  const categoryToCreate = categoryDropdown.categoryToCreate;
  const activeCategoryId = categoryDropdown.activeCategoryId;

  const save = useCallback(
    async (callback: () => void) => {
      const [name] = nameField;
      const [email] = emailField;

      await handleAdd({
        ...(email && { email }),
        name,
        category: categoryToCreate || activeCategoryId || '',
      });

      callback?.();
    },
    [activeCategoryId, categoryToCreate, emailField, handleAdd, nameField]
  );

  const update = useCallback(
    async (callback: () => void) => {
      const [name] = nameField;
      const [email] = emailField;

      if (item?.clientId) {
        await handleUpdate({
          id: item.clientId,
          ...(email && { email }),
          name,
          category: categoryToCreate || activeCategoryId || '',
        });
      }

      callback?.();
    },
    [
      activeCategoryId,
      categoryToCreate,
      emailField,
      handleUpdate,
      item?.clientId,
      nameField,
    ]
  );

  return {
    nameField,
    emailField,
    isPartialValid,
    categoryDropdown,
    save,
    update,
  };
};
