import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { ColorNames, colors } from 'utils/styles/theme';

export const Wrapper = styled.div<{ disabled: boolean }>`
  flex: 1;
  width: 100%;
  color: var(--editable-label-default-color);

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--editable-label-disabled-color);
        `
      : ''}
`;

export const Label = styled.div<{
  disabled: boolean;
}>`
  cursor: text;
  color: currentColor;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
        `
      : ''};
`;

export const LabelPlaceholder = styled.div<{ color?: string }>`
  color: ${({ color = rgba(colors[ColorNames.white], 0.5) }) => color};
`;

export const LoadingWrapper = styled.div``;
