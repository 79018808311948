import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { ClientRequestRejectedNotification as ClientRequestRejectedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface ClientRequestRejectedNotificationProps {
  notification: ClientRequestRejectedNotificationType;
}

const ClientRequestRejectedNotification: FC<
  ClientRequestRejectedNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.clientRequestRejected}
        replacements={[
          [
            '{user}',
            `${notification.extra_info.fullName} (${notification.extra_info.username})`,
          ],
          ['{client}', notification.extra_info.clientName],
        ]}
      />
      {notification.extra_info.message && (
        <span>
          <Copy
            text={copy.app.notifications.clientRequestRejectedMessage}
            replacements={[['{message}', notification.extra_info.message]]}
          />
        </span>
      )}
    </Styled.Wrapper>
  );
};

export default memo(ClientRequestRejectedNotification);
