import { Dispatch, FC, memo, SetStateAction } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import Switch from 'components/Switch/Switch';
import { useHideProjectVisibility } from 'hooks/useHideProjectVisibility';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCopyStore } from 'store';
import { Brand, Client, GeoArea, Project, Vertical } from 'utils/api.types';

import { useJobNumber } from '../hooks/useJobNumber';
import BrandField from './Brand/Brand';
import BudgetStatus from './BudgetStatus/BudgetStatus';
import Clients from './Client/Client';
import JobNumberField from './JobNumber/JobNumber';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import Territory from './Territory/Territory';
import VerticalField from './Vertical/Vertical';

import * as Styled from '../ProjectModal.styles';

export interface MainTabProps {
  project?: Project;
  activeVerticalId?: Vertical['id'];
  setActiveVerticalId: Dispatch<SetStateAction<Vertical['id'] | undefined>>;
  activeBrandId?: Brand['id'];
  setActiveBrandId: Dispatch<SetStateAction<Brand['id'] | undefined>>;
  activeClientId?: Client['id'];
  setActiveClientId: Dispatch<SetStateAction<Client['id'] | undefined>>;
  activeTerritoryId?: GeoArea['id'];
  setActiveTerritoryId: Dispatch<SetStateAction<GeoArea['id'] | undefined>>;
  activeStatus: Project['status'];
  activeMode: Project['mode'];
  projectNameField: ReturnType<typeof useField>;
  isSensitiveField: [boolean, Dispatch<SetStateAction<boolean>>];
  isTestField: [boolean, Dispatch<SetStateAction<boolean>>];
  jobNumberField: ReturnType<typeof useJobNumber>;
}

const MainTab: FC<MainTabProps> = ({
  project,
  activeBrandId,
  activeVerticalId,
  activeClientId,
  activeStatus,
  activeMode,
  activeTerritoryId,
  setActiveBrandId,
  setActiveVerticalId,
  setActiveClientId,
  setActiveTerritoryId,

  projectNameField,
  jobNumberField,
  isSensitiveField,
  isTestField,
}) => {
  const copy = useCopyStore(s => s.copy);
  const projectId = project?.id;

  const { getCanEditProject } = useProjectAccess();
  const canEditProject = getCanEditProject(project) || !projectId;

  const { isHideButtonVisible, isTestButtonVisible } = useHideProjectVisibility(
    { project }
  );

  const {
    duplicateError,
    isJobNumberRequired,
    jobNumber,
    jobNumberError,
    setJobNumber,
  } = jobNumberField;

  const [isSensitive, setIsSensitive] = isSensitiveField;
  const [isTest, setIsTest] = isTestField;

  const [projectName, setProjectName, , projectNameError] = projectNameField;

  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <BudgetStatus activeStatus={activeStatus} activeMode={activeMode} />
      </Styled.Row>
      <Styled.Row
        data-cy="project-modal__job-number-wrapper"
        hasMorePadding={!!duplicateError}
      >
        <JobNumberField
          projectId={projectId}
          activeMode={activeMode}
          jobNumber={jobNumber}
          setJobNumber={setJobNumber}
          isJobNumberRequired={isJobNumberRequired}
          duplicateError={duplicateError}
          jobNumberError={jobNumberError}
          canEditProject={canEditProject}
        />
        <Styled.FieldSpace />
        <VerticalField
          projectId={projectId}
          activeVerticalId={activeVerticalId}
          setActiveVerticalId={setActiveVerticalId}
        />
        <Styled.FieldSpace />
        <Territory
          projectId={projectId}
          activeTerritoryId={activeTerritoryId}
          setActiveTerritoryId={setActiveTerritoryId}
          canEditProject={canEditProject}
        />
      </Styled.Row>
      <Styled.Row>
        <Clients
          projectId={projectId}
          activeClientId={activeClientId}
          setActiveClientId={setActiveClientId}
          canEditProject={canEditProject}
        />
        <Styled.FieldSpace />
        <BrandField
          projectId={projectId}
          activeBrandId={activeBrandId}
          setActiveBrandId={setActiveBrandId}
          canEditProject={canEditProject}
        />
      </Styled.Row>
      <Styled.Row>
        <FormInput
          value={projectName}
          onChange={setProjectName}
          placeholder={copy.app.projectModal.projectName}
          required={true}
          error={projectNameError}
          dataCy="project-modal__name-field"
          disabled={!canEditProject}
        />
        {isTestButtonVisible && (
          <>
            <Styled.FieldSpace />
            <Styled.SwitchWrapper
              title={copy.app.projectModal.testProjectTooltip}
            >
              <Switch
                label={copy.app.projectModal.testProjectLabel}
                checked={isTest}
                onCheckedChange={setIsTest}
                labelPlacement="top"
                dataCy="project-modal__test-switch"
              />
            </Styled.SwitchWrapper>
          </>
        )}
        {isHideButtonVisible && (
          <>
            <Styled.FieldSpace />
            <Styled.SwitchWrapper
              title={copy.app.projectModal.hideProjectTooltip}
            >
              <Switch
                label={copy.app.projectModal.hideProjectLabel}
                checked={isSensitive}
                onCheckedChange={setIsSensitive}
                labelPlacement="top"
                dataCy="project-modal__sensitive-switch"
              />
            </Styled.SwitchWrapper>
          </>
        )}
      </Styled.Row>
      {project && <ProjectInfo projectId={projectId} />}
    </Styled.TabWrapper>
  );
};

export default memo(MainTab);
