import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const Field = styled.textarea`
  resize: none;
  color: ${colors.white};
  ${({ theme: { locale } }) => setTypography('body5', locale)}
  padding-right: 20rem;
`;
