import { useEffect, useRef, useState } from 'react';

const DEFAULT_SPINNER_TIME = 1000;

export const useSpinner = (
  isLoading?: boolean,
  spinnerTime = DEFAULT_SPINNER_TIME
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (isLoading && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        setSpinner(true);
      }, spinnerTime);
    }
    if (!isLoading) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = null;
      setSpinner(false);
    }

    const timeout = timeoutRef.current;
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeoutRef.current = null;
    };
  }, [isLoading, spinnerTime]);

  return spinner;
};
