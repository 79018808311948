import React from 'react';

import { ReactComponent as AlertIcon } from 'svgs/alert.svg';

import * as Styled from './ErrorIcon.styles';

export interface ErrorIconProps {
  isActive?: boolean;
}
const ErrorIcon = ({ isActive = true }: ErrorIconProps) => (
  <Styled.Wrapper>
    <Styled.ErrorIcon isActive={isActive}>
      <Styled.IconWrapper>
        <AlertIcon />
      </Styled.IconWrapper>
    </Styled.ErrorIcon>
  </Styled.Wrapper>
);
export default React.memo(ErrorIcon);
