import React, {
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
} from 'react';

import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import { ReactComponent as Lock } from 'svgs/lock.svg';
import { removeEmoji } from 'utils/string';
import { ColorNames } from 'utils/styles/theme';

import { MasksType, useMask } from '../hooks/useMask';

import * as Styled from './BaseInput.styles';

export interface BaseInputProps {
  value: string | number;
  placeholder?: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  onEnterDown?: () => void;
  onBackspace?: () => void;
  onBackspaceDown?: () => void;
  onEscape?: () => void;
  onEscapeDown?: () => void;
  onBlur?: (event?: React.FocusEvent) => void;
  onFocus?: (event?: React.FocusEvent) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholderColor?: string;
  styles?: Record<string, any>;
  dataCy?: string;
  mask?: MasksType;
  startHtml?: React.ReactNode | React.ReactNode[];
  endHtml?: React.ReactNode | React.ReactNode[];
  disabledIcon?: boolean;
  maxCharacters?: number;
  type?: HTMLInputTypeAttribute;
}

const BaseInput = forwardRef(
  (
    {
      value,
      placeholder,
      onChange,
      onEnter,
      onEnterDown,
      onBackspace,
      onBackspaceDown,
      onEscape,
      onEscapeDown,
      onBlur,
      onFocus,
      disabled = false,
      loading,
      placeholderColor,
      styles,
      dataCy = '',
      startHtml = null,
      endHtml = null,
      disabledIcon,
      mask,
      maxCharacters,
      type,
    }: BaseInputProps,
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const { sanitize, maskResetHelper } = useMask(mask, value);
    const handleKeyUp = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          onEnter && onEnter();
        }
        if (event.key === 'Backspace') {
          onBackspace && onBackspace();
        }
        if (event.key === 'Escape') {
          onEscape && onEscape();
        }
      },
      [onBackspace, onEnter, onEscape]
    );

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          onEnterDown && onEnterDown();
        }
        if (event.key === 'Backspace') {
          onBackspaceDown && onBackspaceDown();
        }
        if (event.key === 'Escape') {
          onEscapeDown && onEscapeDown();
        }
      },
      [onBackspaceDown, onEnterDown, onEscapeDown]
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(sanitize(removeEmoji(event.currentTarget.value)));
      },
      [onChange, sanitize]
    );

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onChange &&
          onChange(event.currentTarget.value.trim().replace(/\s+/g, ' '));
        onBlur && onBlur(event);
      },
      [onBlur, onChange]
    );

    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onFocus && onFocus(event);
      },
      [onFocus]
    );

    const handleClick = useCallback((event: React.MouseEvent) => {
      event.stopPropagation();
    }, []);

    useEffect(() => {
      maskResetHelper(String(value));
    }, [maskResetHelper, value]);

    return (
      <Styled.Wrapper>
        {startHtml && (
          <Styled.StartHtmlWrapper>{startHtml}</Styled.StartHtmlWrapper>
        )}
        <Styled.Field
          disabled={disabled}
          isDisabled={disabled}
          ref={forwardedRef}
          value={value}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={handleClick}
          style={styles}
          data-cy={dataCy}
          maxLength={maxCharacters}
          type={type}
        />
        {disabledIcon && (
          <Styled.DisabledIcon>
            <Styled.IconWrapper>
              <Lock />
            </Styled.IconWrapper>
          </Styled.DisabledIcon>
        )}
        {loading && (
          <Styled.LoadingWrapper>
            <Spinner color={ColorNames.white} size={30} />
          </Styled.LoadingWrapper>
        )}
        {endHtml && <Styled.EndHtmlWrapper>{endHtml}</Styled.EndHtmlWrapper>}
      </Styled.Wrapper>
    );
  }
);
BaseInput.displayName = 'BaseInput';

export default React.memo(BaseInput);
