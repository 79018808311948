import { useMemo } from 'react';

import { useCurrentProject } from 'queries/project';
import { ProjectMode } from 'utils/api.types';

import { useCurrentVersionId } from './useCurrentVersionId';

export const useCurrentVersionMode = () => {
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();

  const currentVersionMode = useMemo(() => {
    if (
      currentVersionId &&
      currentProject?.versions.find(({ id }) => id === currentVersionId)?.mode
    ) {
      return currentProject?.versions.find(({ id }) => id === currentVersionId)
        ?.mode;
    }
    return ProjectMode.pitch;
  }, [currentProject?.versions, currentVersionId]);

  return {
    currentVersionMode,
  };
};
