import { useState } from 'react';

import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useCurrentProjectVersion } from 'queries/projectRows';

export const useRateSlider = () => {
  let minRate = Infinity;
  let maxRate = 0;
  const { data: projectVersion } = useCurrentProjectVersion();
  const queryString = useParseProjectQueryString();

  projectVersion?.rows.forEach(row => {
    const rate = row.rate;
    if (rate < minRate) {
      minRate = Math.floor(rate);
    }
    if (rate > maxRate) {
      maxRate = Math.ceil(rate);
    }
  });

  const [isRateDirty, setIsRateDirty] = useState(false);
  const [rate, setRate] = useState([
    queryString.minRate && queryString.minRate >= minRate
      ? queryString.minRate
      : minRate,
    queryString.maxRate && queryString.maxRate <= maxRate
      ? queryString.maxRate
      : maxRate,
  ]);

  const onRateChange: typeof setRate = (...args) => {
    setIsRateDirty(true);
    setRate(...args);
  };

  return {
    rate,
    setRate: onRateChange,
    minRate,
    maxRate,
    isRateDirty,
  };
};
