import React from 'react';

import Toolbar, { ToolbarItem } from 'components/Toolbar/Toolbar';
import { useCopyStore, useGlobalStore } from 'store';
import { ColorNames } from 'utils/styles/theme';

interface ContractsLinkToolbarProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const ContractsLinkToolbar = ({
  onEditClick,
  onDeleteClick,
}: ContractsLinkToolbarProps) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);

  const toolbarItems: ToolbarItem[] = [
    {
      icon: 'edit',
      onClick: onEditClick,
      backgroundColor: ColorNames.white,
      iconColor: ColorNames.outerSpace,
      tooltip: copy.app.toolbarTooltips.edit,
    },
    {
      icon: 'trash',
      onClick: onDeleteClick,
      tooltip: copy.app.toolbarTooltips.delete,
    },
  ];

  return <Toolbar items={toolbarItems} backgroundColor={currentColor} />;
};

export default React.memo(ContractsLinkToolbar);
