import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ProjectPermission } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';
import { PROJECTS_KEY } from './project';
import { PROJECT_ACCESS_REQUESTS_KEY } from './projectAccessRequests';

export const PROJECT_PERMISSION_KEY = 'PROJECT_PERMISSION';

export const useProjectPermissions = (
  variables: Parameters<typeof getProjectPermissions>[0],
  options?: UseQueryOptions<ProjectPermission[]>
) => {
  const getProjectPermissions = useApiStore(
    s => s.apiClient.getProjectPermissions
  );
  return useQuery({
    queryKey: [PROJECT_PERMISSION_KEY, String(variables.projectId)],
    queryFn: async () => (await getProjectPermissions(variables)).data,
    ...options,
  });
};

export const useGrantProjectPermission = () => {
  const grantProjectPermission = useApiStore(
    s => s.apiClient.grantProjectPermission
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof grantProjectPermission>[0]) =>
      grantProjectPermission(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_ACCESS_REQUESTS_KEY);
        queryClient.invalidateQueries(PROJECT_PERMISSION_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useDeclineProjectPermission = () => {
  const declineProjectPermission = useApiStore(
    s => s.apiClient.declineProjectPermission
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof declineProjectPermission>[0]) =>
      declineProjectPermission(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_ACCESS_REQUESTS_KEY);
        queryClient.invalidateQueries(PROJECT_PERMISSION_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};
