import { useCallback, useEffect, useRef } from 'react';

export enum UploadFileTypes {
  xlsx = '.xlsx',
  xls = '.xls',
}

interface UseUploadFileProps {
  // eslint-disable-next-line
  // @ts-ignore
  onFileSelected: (event) => void;
  accept?: UploadFileTypes[];
}

export const useUploadFile = ({
  onFileSelected,
  accept,
}: UseUploadFileProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openUploadPopup = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }, []);

  useEffect(() => {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'file';
    hiddenInput.hidden = true;
    hiddenInput.onchange = onFileSelected;
    if (accept) {
      hiddenInput.accept = accept.join(',');
    }

    document.body.appendChild(hiddenInput);
    inputRef.current = hiddenInput;

    return () => {
      hiddenInput.onchange = null;
      hiddenInput.parentElement?.removeChild(hiddenInput);
    };
  }, [accept, onFileSelected]);

  return {
    openUploadPopup,
  };
};
