import { FC, memo } from 'react';

import { useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './FinancePaymentModal.styles';

export interface FinancePaymentModalProps {}

const FinancePaymentModal: FC<FinancePaymentModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const close = () => {
    closeModal('financePayment');
  };

  return (
    <ConfigModal title="Payment" onCloseClick={close} color={currentColor}>
      <Styled.Wrapper>
        <Styled.Row></Styled.Row>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(FinancePaymentModal);
