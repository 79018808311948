import _uniqBy from 'lodash/uniqBy';
import { FC, Fragment, useMemo, useState } from 'react';

import {
  Select,
  SelectItem,
  SelectSeparator,
} from 'components/dropdowns/Select/Select';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { ReconcileExternalItem, Resource, Vendor } from 'utils/api.types';

import * as Styled from './ResourceDropdownIntegration.styles';

export enum ResourceTypes {
  resource = 'resource',
  vendor = 'vendor',
}

const RESOURCE_PREFIX = 'RESOURCE_';
const VENDOR_PREFIX = 'VENDOR_';

const prefixMap = {
  [ResourceTypes.resource]: RESOURCE_PREFIX,
  [ResourceTypes.vendor]: VENDOR_PREFIX,
};

const addPrefix = (id: string | number | undefined, type: ResourceTypes) => {
  return `${prefixMap[type]}${id}`;
};

const getFromPrefix = (text: string) => {
  const [prefix, id] = text.split('_');
  const resourceType = [ResourceTypes.resource, ResourceTypes.vendor].find(
    resourceType => prefixMap[resourceType]?.startsWith(prefix)
  ) as ResourceTypes;

  return {
    type: resourceType,
    id: Number(id),
  };
};

export interface ResourceDropdownIntegrationProps {
  item: ReconcileExternalItem;
  onResourceSelected: (item: {
    id: string;
    resource_id?: Resource['id'];
    vendor_id?: Vendor['id'];
  }) => void;
  onResetResourceSelected: (item: { id: ReconcileExternalItem['id'] }) => void;
}

const ResourceDropdownIntegration: FC<ResourceDropdownIntegrationProps> = ({
  item,
  onResourceSelected,
  onResetResourceSelected,
}) => {
  const copy = useCopyStore(s => s.copy);

  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const rows = currentProjectVersion?.rows ?? [];
  const resources = _uniqBy(
    rows.map(row => row.resource).filter(Boolean),
    'id'
  );
  const vendors = _uniqBy(rows.map(row => row.vendor).filter(Boolean), 'id');

  const autoMatch = {
    displayName: copy.app.reconcileSuccessModal.auto,
    id: 'AUTO_MATCH',
    divider: true,
  };

  const dropdownItems = [
    autoMatch,
    ...resources.map(item => ({
      displayName: item?.name,
      id: addPrefix(item?.id, ResourceTypes.resource),
      divider: false,
    })),
    ...vendors.map(item => ({
      displayName: item?.name,
      id: addPrefix(item?.id, ResourceTypes.vendor),
      divider: false,
    })),
  ];

  const defaultItemId = useMemo(() => {
    const resourceId = item.resource_id;
    const vendorId = item.vendor_id;

    return resourceId
      ? addPrefix(resourceId, ResourceTypes.resource)
      : vendorId
      ? addPrefix(vendorId, ResourceTypes.vendor)
      : null;
  }, [item?.resource_id, item?.vendor_id]);

  const [activeItemId, setActiveItemId] = useState(defaultItemId);

  const handleDropdownClick = (itemId: string) => {
    if (itemId === 'AUTO_MATCH') {
      setActiveItemId(null);
      onResetResourceSelected({ id: item.id });
    } else {
      setActiveItemId(itemId);
      const { id, type } = getFromPrefix(itemId);
      onResourceSelected({
        id: item.id,
        ...(type === ResourceTypes.resource
          ? {
              resource_id: id,
            }
          : {}),
        ...(type === ResourceTypes.vendor
          ? {
              vendor_id: id,
            }
          : {}),
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Select
        key={activeItemId}
        value={activeItemId || undefined}
        onValueChange={handleDropdownClick}
        styleVariant="table"
      >
        {dropdownItems.map(({ displayName, id, divider }) => (
          <Fragment key={id}>
            <SelectItem value={id}>{displayName}</SelectItem>
            {divider && <SelectSeparator />}
          </Fragment>
        ))}
      </Select>
    </Styled.Wrapper>
  );
};

export default ResourceDropdownIntegration;
