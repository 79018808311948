import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ResourceDuplicate } from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';
import { RESOURCES_KEY } from './resources';

export const RESOURCE_DUPLICATES_KEY = 'RESOURCE_DUPLICATES';

export const useResourceDuplicates = (
  options?: UseQueryOptions<ResourceDuplicate[][]>
) => {
  const getResourceDuplicates = useApiStore(
    s => s.apiClient.getResourceDuplicates
  );
  return useQuery({
    queryKey: RESOURCE_DUPLICATES_KEY,
    queryFn: async () => (await getResourceDuplicates()).data,
    ...options,
  });
};

export const useResolveResourceDuplicates = () => {
  const resolveResourceDuplicates = useApiStore(
    s => s.apiClient.resolveResourceDuplicates
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof resolveResourceDuplicates>[0]) =>
      resolveResourceDuplicates(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RESOURCE_DUPLICATES_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
        queryClient.invalidateQueries(RESOURCES_KEY);
      },
    }
  );
};
