import React, { useContext, useMemo, useState } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useGlobalStore } from 'store';
import { useSvgIconList } from 'u9/hooks';

import { TableContext } from '../Table';
import { useRowMultiSelect } from './hooks/useRowMultiSelect';

import * as Styled from './Row.styles';

export const SHOW_ALL_ID = 'SHOW_ALL_ID';

type DraggableRow =
  | { isDraggable?: undefined | false; index?: number }
  | { isDraggable: true; index: number };

export type RowProps = {
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  border?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  dataCy?: string;
  hover?: boolean;
  textColor?: string;
  id?: string;
  isHeader?: boolean;
  background?: string;
  isGroupRow?: boolean;
  copy?: {
    showAll: string;
  };
} & DraggableRow;

const Row = ({
  children,
  onClick,
  border = false,
  onMouseEnter,
  onMouseLeave,
  index,
  isDraggable,
  dataCy = '',
  hover = true,
  textColor = '',
  id = '',
  isHeader = false,
  background = '',
  isGroupRow,
  copy = { showAll: 'Show all columns' },
}: RowProps) => {
  const {
    tableTheme,
    isDragging,
    isSelectable,
    dynamicColumns,
    onColumnSelect,
    columns,
    activeColumns,
    isDraggable: isDragDropEnabled,
  } = useContext(TableContext);
  const [DragHandleIcon] = useSvgIconList(['dragHandle', 'settings']);
  const [hovered, setHovered] = useState(false);

  const currentColor = useGlobalStore(s => s.currentColor);

  const randomIndex = useMemo(() => {
    return String(Math.random());
  }, []);

  const handleMouseEnter = () => {
    if (!isDragging) {
      setHovered(true);
      onMouseEnter?.();
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
    onMouseLeave?.();
  };

  const {
    SelectionCount,
    checkboxValue,
    childrenWithSelect,
    isSelectedAndDragging,
  } = useRowMultiSelect({ children, dataCy, id, isHeader });

  const items = [
    {
      displayName: copy.showAll,
      id: SHOW_ALL_ID,
      preventClosing: true,
      divider: true,
    },
    ...(columns || []).map(column => ({
      displayName: column.headerLabel as string,
      id: column.id,
      preventClosing: true,
      active: column.id && activeColumns?.includes(column.id),
      disabled:
        column.id &&
        activeColumns?.includes(column.id) &&
        activeColumns?.length === 1,
      hasCheckbox: true,
      textColorOpacity:
        column.id && activeColumns?.includes(column.id) ? 1 : 0.7,
    })),
  ];

  const isAnyColumnHidden = activeColumns?.length !== columns?.length;

  const DynamicColumns = (
    <Styled.SettingsButtonWrapper
      isAnyColumnHidden={isAnyColumnHidden}
      color={currentColor}
    >
      <DropdownMenu data-cy="dynamic-columns">
        <DropdownMenuTrigger />
        <DropdownMenuContent>
          {items.map(({ displayName, id }) =>
            id === SHOW_ALL_ID ? (
              <DropdownMenuItem
                key={id}
                onSelect={event => {
                  event.preventDefault();
                  onColumnSelect?.(id);
                }}
              >
                {displayName}
                <DropdownMenuSeparator />
              </DropdownMenuItem>
            ) : (
              <DropdownMenuCheckboxItem
                key={id}
                onSelect={event => {
                  event.preventDefault();
                  id && onColumnSelect?.(id);
                }}
                checked={id ? activeColumns?.includes(id) : false}
                disabled={
                  activeColumns?.length === 1 && id
                    ? activeColumns.includes(id)
                    : false
                }
              >
                {displayName}
              </DropdownMenuCheckboxItem>
            )
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.SettingsButtonWrapper>
  );

  return isDraggable ? (
    <Draggable draggableId={id || randomIndex} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Styled.Wrapper
          {...provided.draggableProps}
          ref={provided.innerRef}
          onClick={onClick}
          border={border}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
          tableTheme={tableTheme}
          data-cy={dataCy}
          hoverEffect={hover}
          isDragDrop
          isSelect={isSelectable}
          isSelected={checkboxValue}
          isSelectedAndDragging={isSelectedAndDragging}
          isHeader={isHeader}
          currentColor={currentColor}
          background={background}
          textColor={textColor}
          hasDynamicColumns={dynamicColumns}
          isGroupRow={isGroupRow}
        >
          <Styled.DragHandle {...provided.dragHandleProps} visible={hovered}>
            <Styled.DragHandleIcon>
              <DragHandleIcon />
            </Styled.DragHandleIcon>
          </Styled.DragHandle>
          {childrenWithSelect}
          {snapshot.isDragging && <SelectionCount />}
          {dynamicColumns && isHeader && DynamicColumns}
        </Styled.Wrapper>
      )}
    </Draggable>
  ) : (
    <Styled.Wrapper
      onClick={onClick}
      border={border}
      onMouseOver={handleMouseEnter}
      onMouseOut={onMouseLeave}
      tableTheme={tableTheme}
      data-cy={dataCy}
      hoverEffect={hover}
      isSelect={isSelectable}
      isSelected={checkboxValue}
      isHeader={isHeader}
      currentColor={currentColor}
      background={background}
      textColor={textColor}
      hasDynamicColumns={dynamicColumns}
      isGroupRow={isGroupRow}
      isDragDrop={isDragDropEnabled}
    >
      {childrenWithSelect}
      {dynamicColumns && isHeader && DynamicColumns}
    </Styled.Wrapper>
  );
};

export default Row;
