import * as LabelPrimitive from '@radix-ui/react-label';
import { CommandList } from 'cmdk';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

const colorVariantsList = ['dark', 'light'] as const;
export type ColorVariants = (typeof colorVariantsList)[number];

const styleVariantsList = ['table', 'form'] as const;
export type StyleVariants = (typeof styleVariantsList)[number];

const colorVariants: Record<
  ColorVariants,
  { primary: string; secondary: string }
> = {
  dark: {
    primary: colors.white,
    secondary: rgba(colors.white, 0.5),
  },
  light: {
    primary: colors.outerSpace,
    secondary: rgba(colors.outerSpace, 0.5),
  },
};

export const TriggerButton = styled.button<{
  $styleVariant: StyleVariants;
  hasLabel?: boolean;
}>`
  ${({ $styleVariant, hasLabel }) => css`
    min-width: 400rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: var(--primaryColor);
    min-height: 60rem;
    text-align: start;

    ${$styleVariant === 'form' &&
    css`
      padding-top: 20rem;
      padding-bottom: 27rem;
      border-bottom: 1px solid var(--secondaryColor);
      ${({ theme: { locale } }) => setTypography('input', locale)}
    `}

    ${$styleVariant === 'table' &&
    css`
      ${({ theme: { locale } }) => setTypography('body3', locale)}
      text-transform: none;
    `} 

  ${!hasLabel &&
    css`
      color: var(--secondaryColor);
    `}

    &:hover {
      filter: brightness(0.8);
    }

    &:focus-visible {
      ${focusVisible};
      border-bottom: 1px solid transparent;
    }

    &[data-state='open'] {
      ${focusVisible};
      border-bottom: 1px solid transparent;
    }

    &[data-disabled] {
      color: var(--secondaryColor);
    }

    &[data-disabled]:hover {
      filter: none;
    }
  `}
`;

export const TriggerArrow = styled.div`
  width: 24rem;
  height: 24rem;
`;

export const Label = styled(LabelPrimitive.Root)``;

export const Wrapper = styled.div<{
  $colorVariant?: ColorVariants;
}>`
  ${({ $colorVariant = 'dark' }) => css`
    --primaryColor: ${colorVariants[$colorVariant].primary};
    --secondaryColor: ${colorVariants[$colorVariant].secondary};
    width: 100%;
    display: flex;
    flex-direction: column;
    ${({ theme: locale }) => setTypography('caption', locale)};
    color: ${colorVariants[$colorVariant].secondary};
  `}
`;

export const List = styled(CommandList)`
  max-height: calc(var(--radix-popover-content-available-height) - 90rem);
`;
