import React, { Fragment, MouseEvent } from 'react';

import { Tooltip } from 'components/Tooltip/Tooltip';
import { useSvgIconList } from 'u9/hooks/useSvgIcon';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './Toolbar.styles';

export const validIcons = [
  'add',
  'collapse',
  'copy',
  'trash',
  'edit',
  'check',
  'close',
  'hidden',
  'time',
  'trend',
  'revert',
  'flag',
  'passthrough',
  'external_link',
] as const;

export interface ToolbarItem {
  icon?: typeof validIcons[number];
  text?: string;
  tooltip: string;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  backgroundColor?: ColorNames;
  iconColor?: ColorNames;
  appendDivider?: boolean;
}

export interface ToolbarProps {
  backgroundColor?: ColorNames;
  disabled?: boolean;
  items: ToolbarItem[];
}

const Toolbar = ({
  backgroundColor = ColorNames.electricViolet,
  disabled = false,
  items,
}: ToolbarProps) => {
  const iconItems = useSvgIconList(validIcons as unknown as string[]);

  const iconComponents: Partial<Record<typeof validIcons[number], React.FC>> =
    {};
  validIcons.forEach((icon, index) => {
    iconComponents[icon] = iconItems[index];
  });

  return (
    <Styled.Wrapper backgroundColor={backgroundColor} disabled={disabled}>
      {items.map(item => {
        const IconComponent = item.icon && iconComponents[item.icon];
        const backgroundColor = item.backgroundColor || ColorNames.transparent;
        const iconColor = item.iconColor || ColorNames.white;
        return (
          <Fragment key={item.icon || item.text}>
            {item.icon ? (
              <Tooltip
                content={item.tooltip}
                style={{ visibility: item.tooltip ? 'visible' : 'hidden' }}
              >
                <Styled.Item
                  onClick={!disabled ? item.onClick : () => null}
                  backgroundColor={backgroundColor}
                  iconColor={iconColor}
                  data-cy={`toolbar__${item.icon}`}
                >
                  <Styled.IconWrapper>
                    {IconComponent && <IconComponent />}
                  </Styled.IconWrapper>
                </Styled.Item>
              </Tooltip>
            ) : (
              <Tooltip
                content={item.tooltip}
                style={{ visibility: item.tooltip ? 'visible' : 'hidden' }}
              >
                <Styled.TextItem
                  onClick={!disabled ? item.onClick : () => null}
                  backgroundColor={backgroundColor}
                  color={iconColor}
                  data-cy={`toolbar__${item.text}`}
                >
                  {item.text}
                </Styled.TextItem>
              </Tooltip>
            )}

            {item.appendDivider && <Styled.Divider />}
          </Fragment>
        );
      })}
    </Styled.Wrapper>
  );
};

export default React.memo(Toolbar);
