import * as SwitchPrimitive from '@radix-ui/react-switch';
import { FC, memo } from 'react';

import * as Styled from './Switch.styles';

export const switchLabelPlacement = ['top', 'right', 'left'] as const;

export interface SwitchProps extends SwitchPrimitive.SwitchProps {
  label?: string;
  labelPlacement?: typeof switchLabelPlacement[number];
  dataCy?: string;
  colorTheme?: Styled.SwitchTheme;
}

const Switch: FC<SwitchProps> = ({
  label,
  labelPlacement = 'right',
  colorTheme = 'dark',
  dataCy,
  ...props
}) => {
  return (
    <Styled.Wrapper $labelPlacement={labelPlacement} $colorTheme={colorTheme}>
      {label && (
        <Styled.Label labelPlacement={labelPlacement}>{label}</Styled.Label>
      )}
      <Styled.Root
        {...props}
        {...(props.checked ? { 'data-ison': true } : {})}
        data-cy={dataCy}
      >
        <Styled.Thumb />
      </Styled.Root>
    </Styled.Wrapper>
  );
};

export default memo(Switch);
