import React, { Dispatch, SetStateAction } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCopyStore } from 'store';

import AddressFields from '../AddressFields/AddressFields';

import * as AdminStyled from '../AdminItemModal.styles';

interface VendorPartialProps {
  vendorName: ReturnType<typeof useField>;
  emailField: ReturnType<typeof useField>;
  emailUniqueError: boolean;
  activeVendorServiceId: string | number | null;
  setActiveVendorServiceId: Dispatch<SetStateAction<string | number | null>>;
  vendorServiceItems?: {
    id: string | number;
    displayName: string | undefined;
  }[];
  addressLine1: string;
  setAddressLine1: Dispatch<SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  countryCode: string | null;
  setCountryCode: Dispatch<SetStateAction<string | null>>;
}

const VendorPartial = ({
  vendorName: vendorNameField,
  emailField,
  emailUniqueError,
  activeVendorServiceId,
  setActiveVendorServiceId,
  vendorServiceItems,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
}: VendorPartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const [vendorName, setVendorName, , vendorNameInvalidMessage] =
    vendorNameField;
  const [email, setEmail, , emailInvalidMessage] = emailField;

  const { vendorNameLabel, emailLabel, vendorServiceLabel } =
    copy.admin.editModal.vendor;

  const emailUniqueErrorMessage =
    emailUniqueError && copy.app.validatorMessages.emailNotUnique;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={vendorName}
          onChange={setVendorName}
          error={vendorNameInvalidMessage}
          required
          placeholder={vendorNameLabel}
          dataCy="vendor-partial__name"
        />
        <AdminStyled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          error={emailUniqueErrorMessage || emailInvalidMessage}
          required
          placeholder={emailLabel}
          dataCy="vendor-partial__email"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <AdminStyled.FullWidth>
          <Select
            dataCy="vendor-partial__role-dropdown"
            label={vendorServiceLabel}
            value={String(activeVendorServiceId)}
            onValueChange={setActiveVendorServiceId}
          >
            {vendorServiceItems?.map(({ id, displayName }) => (
              <SelectItem key={id} value={String(id)}>
                {displayName}
              </SelectItem>
            ))}
          </Select>
        </AdminStyled.FullWidth>
      </AdminStyled.Row>
      <AddressFields
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        city={city}
        setCity={setCity}
        zipCode={zipCode}
        setZipCode={setZipCode}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </>
  );
};

export default React.memo(VendorPartial);
