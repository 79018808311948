import { useSession } from 'next-auth/react';

import { useCheckAccessRights } from 'utils/accessRights';
import { Project } from 'utils/api.types';

type UseHideProjectVisibilityProps = {
  project?: Project;
};

export const useHideProjectVisibility = ({
  project,
}: UseHideProjectVisibilityProps) => {
  const { data: session } = useSession();
  const { checkAnyAccessRight } = useCheckAccessRights();
  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);
  const isProjectOwner = project?.producer_email === session?.user?.email;

  const isHideButtonVisible = isAdmin || isProjectOwner || !project;
  const isTestButtonVisible = isHideButtonVisible;

  return {
    isHideButtonVisible,
    isTestButtonVisible,
  };
};
