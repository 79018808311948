import { FC, memo, useCallback } from 'react';

import { useGenerateFinancialReport } from 'queries/financialReports';
import { useCopyStore, useGlobalStore } from 'store';
import { downloadBlob } from 'utils/download';
import { formatDateFilename } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface GenerateReportModalProps {}

const GenerateReportModal: FC<GenerateReportModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: handleGenerateFinancialReport, isLoading } =
    useGenerateFinancialReport();

  const handleTriggerDownload = useCallback(async () => {
    const downloadResponse = await handleGenerateFinancialReport();
    if (!downloadResponse?.data) return;
    downloadBlob(
      `Report_${formatDateFilename(new Date(Date.now()))}.xlsx`,
      downloadResponse.data
    );

    closeModal('generateReportModal');
  }, [closeModal, handleGenerateFinancialReport]);

  const handleCancelClick = useCallback(() => {
    closeModal('generateReportModal');
  }, [closeModal]);

  return (
    <ConfirmModal
      title={copy.reportsList.generateReportModal.title}
      description={copy.reportsList.generateReportModal.description}
      cancelButtonProps={{
        label: copy.reportsList.generateReportModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleCancelClick,
      }}
      confirmButtonProps={{
        label: copy.reportsList.generateReportModal.confirmButton,
        icon: 'check',
        color: currentColor,
        loading: isLoading,
        disabled: isLoading,
        onClick: handleTriggerDownload,
        dataCy: 'reports-generate-confirm-button',
      }}
    />
  );
};

export default memo(GenerateReportModal);
