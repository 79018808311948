import { useMemo } from 'react';

import { useGetColumnById } from 'components/Table/hooks/useGetColumnById';
import { Column } from 'components/Table/Table.types';
import { useCopyStore } from 'store';

export enum ColumnId {
  id = 'id',
  description = 'description',
  accItem = 'accItem',
  amount = 'amount',
  resource = 'resource',
  divider = 'divider',
  role = 'role',
  category = 'category',
  quantity = 'quantity',
  total = 'total',
  resourceName = 'resourceName',
  resourceEmail = 'resourceEmail',
  days = 'days',
  booked = 'booked',
  lastBookedDate = 'lastBookedDate',
  lastReportedDate = 'lastReportedDate',
  daysLastWeek = 'daysLastWeek',
  futureBooked = 'futureBooked',
}

export const useColumns = ({
  type,
}: {
  type: 'JAS' | 'float' | 'teamdeck';
}) => {
  const copy = useCopyStore(s => s.copy);

  const columnsCopy = copy.app.reconcileSuccessModal.columns;

  const columns: Column[] = useMemo(
    () => [
      ...(type === 'JAS'
        ? ([
            {
              headerLabel: '',
              id: ColumnId.id,
              flex: 0.7,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.accItem,
              id: ColumnId.accItem,
              flex: 1.5,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.description,
              id: ColumnId.description,
              flex: 2,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.amount,
              id: ColumnId.amount,
              flex: 1.2,
              topBorder: true,
              align: 'right',
            },
          ] as Column[])
        : []),
      ...(type === 'float'
        ? ([
            {
              headerLabel: '',
              id: ColumnId.id,
              flex: 0.3,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.resourceName,
              id: ColumnId.resourceName,
              flex: 3,
              topBorder: true,
              sort: {
                name: 'name',
                sortType: 'string',
              },
            },
            {
              headerLabel: columnsCopy.booked,
              id: ColumnId.booked,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'quantity',
                sortType: 'number',
              },
            },
          ] as Column[])
        : []),
      ...(type === 'teamdeck'
        ? ([
            {
              headerLabel: '',
              id: ColumnId.id,
              flex: 0.3,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.resourceName,
              id: ColumnId.resourceName,
              flex: 3,
              topBorder: true,
              sort: {
                name: 'name',
                sortType: 'string',
              },
            },
            {
              headerLabel: columnsCopy.booked,
              id: ColumnId.booked,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'booked_quantity',
                sortType: 'number',
              },
            },
            {
              headerLabel: columnsCopy.days,
              id: ColumnId.days,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'quantity',
                sortType: 'number',
              },
            },
            {
              headerLabel: columnsCopy.daysLastWeek,
              id: ColumnId.daysLastWeek,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'last_week_quantity',
                sortType: 'number',
              },
            },
            {
              headerLabel: columnsCopy.futureBooked,
              id: ColumnId.futureBooked,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'future_booked_quantity',
                sortType: 'number',
              },
            },
            {
              headerLabel: columnsCopy.lastReportedDate,
              id: ColumnId.lastReportedDate,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'last_reported_date',
                sortType: 'date',
              },
            },
            {
              headerLabel: columnsCopy.lastBookedDate,
              id: ColumnId.lastBookedDate,
              flex: 1,
              topBorder: true,
              align: 'center',
              sort: {
                name: 'last_booked_date',
                sortType: 'date',
              },
            },
          ] as Column[])
        : []),
      {
        headerLabel: '',
        id: ColumnId.divider,
        flex: 1,
        align: 'center',
        topBorder: false,
      },
      {
        headerLabel: columnsCopy.resource,
        id: ColumnId.resource,
        flex: 3,
        topBorder: true,
      },
      ...(type === 'JAS'
        ? [
            {
              headerLabel: columnsCopy.role,
              id: ColumnId.role,
              flex: 1,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.category,
              id: ColumnId.category,
              flex: 1,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.quantity,
              id: ColumnId.quantity,
              flex: 1,
              topBorder: true,
            },
            {
              headerLabel: columnsCopy.total,
              id: ColumnId.total,
              flex: 1,
              topBorder: true,
            },
          ]
        : []),
    ],
    [columnsCopy, type]
  );

  const { getColumnById } = useGetColumnById(columns);

  const rowDropdownTotalFlex =
    getColumnById(ColumnId.role)?.flex +
    getColumnById(ColumnId.category)?.flex +
    getColumnById(ColumnId.quantity)?.flex +
    getColumnById(ColumnId.total)?.flex;

  return { columns, rowDropdownTotalFlex };
};
