import {
  useCurrentProject,
  useReconcile,
  useReconciliationData,
} from 'queries/project';

import {
  ResourceDropdownProps,
  ResourceTypes,
} from '../ResourceDropdown/ResourceDropdown';
import { RowDropdownProps } from '../RowDropdown/RowDropdown';

export const useDropdownActions = () => {
  const { mutateAsync: reconcile } = useReconcile();
  const currentProject = useCurrentProject();

  const { data: reconcileData } = useReconciliationData({
    projectId: currentProject?.id,
  });

  const { items: reconcileItems } = reconcileData ?? { items: null };

  const handleResourceSelected: ResourceDropdownProps['onResourceSelected'] =
    async items => {
      if (!currentProject?.id) return;
      await reconcile({
        project_id: currentProject?.id,
        report_type: 'base',
        items: items.map(item => ({
          jas_id: item.jasId,
          ...(item.type === ResourceTypes.resource
            ? { resource_id: Number(item.id) }
            : {}),
          ...(item.type === ResourceTypes.vendor
            ? { vendor_id: Number(item.id) }
            : {}),
          ...(item.type === ResourceTypes.expense
            ? { description: String(item.id) }
            : {}),
        })),
      });
    };

  const handleResetResourceSelected: ResourceDropdownProps['onResetResourceSelected'] =
    async items => {
      if (!currentProject?.id) return;
      await reconcile({
        project_id: currentProject?.id,
        report_type: 'base',
        items: items.map(item => {
          const dataItem = reconcileItems?.find(
            ({ jas_id }) => jas_id === item.jasId
          );
          return {
            jas_id: item.jasId,
            ...(dataItem?.resource_id
              ? { resource_id: 0 }
              : dataItem?.description
              ? { description: '' }
              : { vendor_id: 0 }),
          };
        }),
      });
    };

  const handleRowSelected: RowDropdownProps['onRowSelected'] = async items => {
    if (!currentProject?.id) return;
    await reconcile({
      project_id: currentProject?.id,
      report_type: 'base',
      items: items.map(({ jasId, rowId }) => ({
        jas_id: jasId,
        row_id: rowId,
      })),
    });
  };

  return {
    handleResetResourceSelected,
    handleResourceSelected,
    handleRowSelected,
  };
};
