import { SortingState } from '@tanstack/react-table';
import _merge from 'lodash/merge';
import { QueryClient, QueryClientConfig } from 'react-query';

import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';

export const getPaginationQueryKey = (limit: number, page: number) => {
  return `pagination-${limit}-${page}`;
};

export const getSortingQueryKey = (sorting: SortingState) => {
  return `sorting-${JSON.stringify(sorting)}`;
};

export const useGetOptimisticConfigUpdate = () => {
  const { commonErrorHandler } = useCommonErrorHandling();
  const getOptimisticUpdateConfig = ({
    queryClient,
    queryKey,
    updateRef,
  }: {
    queryClient: QueryClient;
    queryKey: string[];
    updateRef: React.MutableRefObject<any>;
  }) => {
    return {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey });
        const previousProjectSnapshot = queryClient.getQueryData(queryKey);
        if (updateRef.current) {
          queryClient.setQueryData(queryKey, updateRef.current);
          updateRef.current = null;
        }

        return { previousProjectSnapshot };
      },
      onError: (error: any, newTodo: any, context: any) => {
        commonErrorHandler({
          error,
          handledLocally: [],
          localHandler() {
            return false;
          },
        });
        queryClient.setQueryData(queryKey, context.previousProjectSnapshot);
      },
    };
  };

  return {
    getOptimisticUpdateConfig,
  };
};

export const getQueryClient = (options?: QueryClientConfig) => {
  return new QueryClient(
    _merge(
      {
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      },
      options || {}
    )
  );
};
