import { useCallback } from 'react';

import { Column } from '../Table.types';

export const useGetColumnWidth = () => {
  const getColumnWidth = useCallback((flex: number, columns: Column[]) => {
    const flexSum = columns.reduce((a, b) => {
      return a + b?.flex || 0;
    }, 0);

    const percent = (flex / flexSum) * 100;
    return `${percent}%`;
  }, []);

  return {
    getColumnWidth,
  };
};
