import React, { Dispatch, SetStateAction } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCopyStore } from 'store';
import { replaceString } from 'utils/replace';

import AddressFields from '../AddressFields/AddressFields';
import { useDepartmentDropdown } from '../hooks/useDepartmentDropdown';
import { useRolesDropdown } from '../hooks/useRolesDropdown';

import * as AdminStyled from '../AdminItemModal.styles';

interface ExternalPartialProps {
  resourceNameField: ReturnType<typeof useField>;
  emailField: ReturnType<typeof useField>;
  companyField: ReturnType<typeof useField>;
  departmentDropdown: ReturnType<typeof useDepartmentDropdown>;
  emailUniqueError: boolean;
  rolesDropdown: ReturnType<typeof useRolesDropdown>;
  addressLine1: string;
  setAddressLine1: Dispatch<SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  countryCode: string | null;
  setCountryCode: Dispatch<SetStateAction<string | null>>;
}

const ExternalPartial = ({
  resourceNameField,
  emailField,
  companyField,
  rolesDropdown,
  departmentDropdown,
  emailUniqueError,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
}: ExternalPartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const [resourceName, setResourceName, , resourceNameInvalidMessage] =
    resourceNameField;
  const [email, setEmail, , emailInvalidMessage] = emailField;
  const [company, setCompany] = companyField;

  const { activeRoleId, rolesItems, rolesOpen, setActiveRoleId, setRolesOpen } =
    rolesDropdown;

  const {
    resourceNameLabel,
    emailLabel,
    companyLabel,
    roleLabel,
    departmentDropdownLabel,
  } = copy.admin.editModal.external;

  const {
    activeDepartmentId,
    departmentItems,
    departmentOpen,
    departmentToCreate,
    departmentSearchValue,
    setActiveDepartmentId,
    setDepartmentOpen,
    setDepartmentToCreate,
    setDepartmentSearchValue,
  } = departmentDropdown;

  const emailUniqueErrorMessage =
    emailUniqueError && copy.app.validatorMessages.emailNotUnique;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={resourceName}
          onChange={setResourceName}
          error={resourceNameInvalidMessage || ''}
          required
          placeholder={resourceNameLabel}
          dataCy="external-partial__name"
        />
        <AdminStyled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          error={emailUniqueErrorMessage || emailInvalidMessage || ''}
          forceError={emailUniqueError}
          required
          placeholder={emailLabel}
          dataCy="external-partial__email"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <FormInput
          value={company}
          onChange={setCompany}
          placeholder={companyLabel}
          dataCy="external-partial__company"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <AdminStyled.FullWidth>
          <Combobox
            open={rolesOpen}
            onOpenChange={setRolesOpen}
            label={roleLabel}
          >
            <ComboboxTrigger
              label={
                rolesItems?.find(({ id }) => id === activeRoleId)?.displayName
              }
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  {rolesItems?.map(({ displayName, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeRoleId === id}
                      onSelect={() => {
                        setActiveRoleId(id ?? null);
                        setRolesOpen(false);
                      }}
                    >
                      {displayName}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </AdminStyled.FullWidth>
      </AdminStyled.Row>
      <AdminStyled.Row>
        <AdminStyled.FullWidth>
          <Combobox
            open={departmentOpen}
            onOpenChange={isOpen => {
              setDepartmentOpen(isOpen);
              setDepartmentSearchValue('');
            }}
            label={departmentDropdownLabel}
          >
            <ComboboxTrigger
              label={
                departmentToCreate ||
                departmentItems.find(({ id }) => id === activeDepartmentId)
                  ?.displayName
              }
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput
                  value={departmentSearchValue}
                  onValueChange={setDepartmentSearchValue}
                />
                <ComboboxEmpty />
                <ComboboxList>
                  {departmentSearchValue.length > 0 && (
                    <ComboboxItem
                      value={departmentSearchValue}
                      onSelect={() => {
                        setDepartmentToCreate(departmentSearchValue);
                        setDepartmentOpen(false);
                      }}
                    >
                      {replaceString(
                        copy.app.comboboxCreateButton,
                        '{name}',
                        departmentSearchValue
                      )}
                    </ComboboxItem>
                  )}
                  {departmentItems.map(({ displayName, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeDepartmentId === id}
                      onSelect={() => {
                        setDepartmentToCreate('');
                        setActiveDepartmentId(prev =>
                          prev === id ? null : id ?? null
                        );
                        setDepartmentOpen(false);
                      }}
                    >
                      {displayName}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </AdminStyled.FullWidth>
      </AdminStyled.Row>
      <AddressFields
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        city={city}
        setCity={setCity}
        zipCode={zipCode}
        setZipCode={setZipCode}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </>
  );
};

export default React.memo(ExternalPartial);
