import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import { ColorNames } from 'utils/styles/theme';

import { ModalId } from './global.types';

interface GlobalStoreProps {
  searchbarValue: string;
  subdomain: string;
}

interface GlobalStoreType extends GlobalStoreProps {
  setSubdomain: (subdomain: string) => void;

  currentColor: ColorNames;
  setCurrentColor: (color: ColorNames) => void;

  searchbarValue: string;
  setSearchbarValue: (value: string) => void;

  openModalIds: ModalId[];
  modalsProps: Partial<Record<ModalId, Record<string, unknown>>>;
  openModal: <T>(id: ModalId, props?: T) => void;
  closeModal: (id: ModalId) => void;
  closeModals: (id: ModalId[]) => void;
  closeAllModals: () => void;
}

type GlobalStore = ReturnType<typeof createGlobalStore>;

const createGlobalStore = (initProps: GlobalStoreProps) => {
  return createStore<GlobalStoreType>()((set, get) => ({
    subdomain: initProps.subdomain,
    setSubdomain: subdomain => {
      set(() => ({
        subdomain: subdomain,
      }));
    },

    currentColor: ColorNames.transparent,
    setCurrentColor: color => {
      set(() => ({
        currentColor: color,
      }));
    },

    searchbarValue: initProps.searchbarValue,
    setSearchbarValue: value => {
      set(() => ({
        searchbarValue: value,
      }));
    },

    openModalIds: [],
    modalsProps: {},
    openModal: (id, props) => {
      if (!id) return;

      const openModalIds = [
        ...Array.from(new Set([...get().openModalIds, id])),
      ];

      set(({ modalsProps }) => ({
        openModalIds,
        modalsProps: {
          ...modalsProps,
          [id]: props,
        },
      }));
    },
    closeModals: ids => {
      set(({ openModalIds }) => ({
        openModalIds: openModalIds.filter(
          openModalIds => !ids.includes(openModalIds)
        ),
      }));
    },
    closeModal: id => {
      set(({ openModalIds }) => ({
        openModalIds: openModalIds.filter(openModalIds => openModalIds !== id),
      }));
    },
    closeAllModals: () => {
      set(() => ({
        openModalIds: [],
      }));
    },
  }));
};

export const GlobalStoreContext = createContext<GlobalStore | null>(null);

export default function useGlobalStore<T>(
  selector: (state: GlobalStoreType) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(GlobalStoreContext);
  if (!store) throw new Error('Missing GlobalStore.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

export const useInitGlobalStore = (initProps: GlobalStoreProps) => {
  const storeRef = useRef<GlobalStore>();
  if (!storeRef.current) {
    storeRef.current = createGlobalStore(initProps);
  }

  return storeRef;
};
