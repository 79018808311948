import React, { Ref, useCallback, useEffect, useRef, useState } from 'react';

import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { ColorNames } from 'utils/styles/theme';

import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';

import * as Styled from './EditableLabel.styles';

export interface EditableLabelProps extends BaseInputProps {
  labelValue?: string | number;
  labelRef?: Ref<HTMLDivElement>;
}

const EditableLabel = ({
  onBlur,
  onEnter,
  onEscape,
  value,
  labelValue,
  placeholder,
  placeholderColor,
  dataCy,
  labelRef,
  disabled = false,
  loading = false,
  ...rest
}: EditableLabelProps) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = useCallback(() => {
    !disabled && setIsEditing(true);
  }, [disabled]);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    onBlur && onBlur();
  }, [onBlur]);

  const handleEnter = useCallback(() => {
    setIsEditing(false);
    onEnter && onEnter();
  }, [onEnter]);

  const handleEscape = useCallback(() => {
    setIsEditing(false);
    onEscape && onEscape();
  }, [onEscape]);

  useEffect(() => {
    if (isEditing) {
      inputEl.current?.focus();
      inputEl.current?.select();
    }
  }, [isEditing]);

  return (
    <Styled.Wrapper data-cy={dataCy} disabled={disabled}>
      {isEditing ? (
        <BaseInput
          ref={inputEl}
          onBlur={handleBlur}
          onEnter={handleEnter}
          onEscape={handleEscape}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
      ) : loading ? (
        <Styled.LoadingWrapper>
          <Spinner color={ColorNames.black} size={30} />
        </Styled.LoadingWrapper>
      ) : (
        <Styled.Label
          onClick={startEditing}
          tabIndex={0}
          onFocus={startEditing}
          ref={labelRef}
          disabled={disabled}
          {...(disabled ? { 'data-disabled': true } : {})}
        >
          {labelValue ? (
            <OverflowEllipsis text={labelValue} />
          ) : value ? (
            <OverflowEllipsis text={value} />
          ) : (
            <Styled.LabelPlaceholder color={placeholderColor}>
              {placeholder}
            </Styled.LabelPlaceholder>
          )}
        </Styled.Label>
      )}
    </Styled.Wrapper>
  );
};

export default EditableLabel;
