import { FC, memo, useEffect } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/Accordion/Accordion';
import { useSetNewQueryParams } from 'containers/PerformanceDashboardPage/hooks/useSetNewQueryParams';
import {
  useCopyStore,
  useGlobalStore,
  usePerformanceDashboardStore,
} from 'store';
import {
  BUDGET_FROM_DEFAULT,
  BUDGET_TO_DEFAULT,
  END_DATE_DEFAULT,
  EXCLUDE_CLOSED_DEFAULT,
  ICONS_DEFAULT,
  INCLUDE_END_DEFAULT,
  INCLUDE_NON_ICON_DEFAULT,
  INCLUDE_START_DEFAULT,
  INT_EXT_FILTER_DEFAULT,
  INTERNAL_PERCENT_DEFAULT,
  MARGIN_VARIATION_DEFAULT,
  RESULTS_BY_DATE_DEFAULT,
  SIMILAR_PROJECT_ID_DEFAULT,
  START_DATE_DEFAULT,
  TIMEFRAME_DEFAULT,
  VERTICALS_DEFAULT,
} from 'store/performanceDashboard';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import GeneralSettings from './GeneralSettingsTab/GeneralSettings';
import KpisTab from './KpisTab/KpisTab';
import RolesTab from './RolesTab/RolesTab';
import { useFilters } from './TabTitle/hooks/useFilters';
import TabTitle from './TabTitle/TabTitle';

import * as Styled from './PerformanceFiltersModal.styles';

export interface PerformanceFiltersModalProps {}

enum AccordionTabs {
  GENERAL = 'GENERAL',
  PROJECT_KPIS = 'PROJECT_KPIS',
  ROLES_MIX = 'ROLES_MIX',
}

const PerformanceFiltersModal: FC<PerformanceFiltersModalProps> = () => {
  const setExcludeClosed = usePerformanceDashboardStore(
    s => s.setExcludeClosed
  );
  const setBudgetFrom = usePerformanceDashboardStore(s => s.setBudgetFrom);
  const setBudgetTo = usePerformanceDashboardStore(s => s.setBudgetTo);
  const setEndDate = usePerformanceDashboardStore(s => s.setEndDate);
  const setIcons = usePerformanceDashboardStore(s => s.setIcons);
  const setIncludeEnd = usePerformanceDashboardStore(s => s.setIncludeEnd);
  const setIncludeNonIcon = usePerformanceDashboardStore(
    s => s.setIncludeNonIcon
  );
  const setIncludeStart = usePerformanceDashboardStore(s => s.setIncludeStart);
  const setIntExtFilter = usePerformanceDashboardStore(s => s.setIntExtFilter);
  const setInternalPercent = usePerformanceDashboardStore(
    s => s.setInternalPercent
  );
  const setMarginVariation = usePerformanceDashboardStore(
    s => s.setMarginVariation
  );
  const setResultsByDate = usePerformanceDashboardStore(
    s => s.setResultsByDate
  );
  const setSimilarProjectId = usePerformanceDashboardStore(
    s => s.setSimilarProjectId
  );
  const setStartDate = usePerformanceDashboardStore(s => s.setStartDate);
  const setTimeframe = usePerformanceDashboardStore(s => s.setTimeframe);
  const setVerticals = usePerformanceDashboardStore(s => s.setVerticals);
  const closeModal = useGlobalStore(s => s.closeModal);
  const openModalIds = useGlobalStore(s => s.openModalIds);
  const currentColor = useGlobalStore(s => s.currentColor);
  const { generalFilters, kpiFilters, rolesFilters } = useFilters();
  const copy = useCopyStore(s => s.copy);
  const setNewQueryParams = useSetNewQueryParams();

  const modalCopy = copy.performanceDashboard.filtersModal;

  const applyFilters = () => {
    closeModal('performanceFiltersModal');
  };

  const resetFilters = () => {
    setStartDate(START_DATE_DEFAULT);
    setEndDate(END_DATE_DEFAULT);
    setIncludeStart(INCLUDE_START_DEFAULT);
    setIncludeEnd(INCLUDE_END_DEFAULT);
    setExcludeClosed(EXCLUDE_CLOSED_DEFAULT);
    setBudgetFrom(BUDGET_FROM_DEFAULT);
    setBudgetTo(BUDGET_TO_DEFAULT);
    setIcons(ICONS_DEFAULT);
    setIncludeNonIcon(INCLUDE_NON_ICON_DEFAULT);
    setTimeframe(TIMEFRAME_DEFAULT);
    setResultsByDate(RESULTS_BY_DATE_DEFAULT);
    setIntExtFilter(INT_EXT_FILTER_DEFAULT);
    setInternalPercent(INTERNAL_PERCENT_DEFAULT);
    setMarginVariation(MARGIN_VARIATION_DEFAULT);
    setSimilarProjectId(SIMILAR_PROJECT_ID_DEFAULT);
    setVerticals(VERTICALS_DEFAULT);

    closeModal('performanceFiltersModal');
  };

  useEffect(() => {
    return () => {
      if (!openModalIds.includes('performanceFiltersModal')) {
        setNewQueryParams();
      }
    };
  }, [openModalIds, setNewQueryParams]);

  return (
    <ConfigModal
      title={modalCopy.title}
      zeroPadding
      onCloseClick={() => closeModal('performanceFiltersModal')}
    >
      <Styled.Wrapper data-cy="performance-filters-modal">
        <AccordionRoot
          type="single"
          defaultValue={AccordionTabs.GENERAL}
          colorScheme="dark"
          style={{ paddingBottom: '200rem' }}
        >
          <AccordionItem value={AccordionTabs.GENERAL}>
            <AccordionTrigger>
              <TabTitle
                text={modalCopy.generalTab.title}
                filters={generalFilters}
              />
            </AccordionTrigger>
            <AccordionContent>
              <Styled.ContentWrapper>
                <GeneralSettings />
              </Styled.ContentWrapper>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value={AccordionTabs.PROJECT_KPIS}>
            <AccordionTrigger>
              <TabTitle text={modalCopy.kpisTab.title} filters={kpiFilters} />
            </AccordionTrigger>
            <AccordionContent>
              <Styled.ContentWrapper>
                <KpisTab />
              </Styled.ContentWrapper>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value={AccordionTabs.ROLES_MIX}>
            <AccordionTrigger>
              <TabTitle
                text={modalCopy.rolesMix.title}
                filters={rolesFilters}
              />
            </AccordionTrigger>
            <AccordionContent>
              <Styled.ContentWrapper>
                <RolesTab />
              </Styled.ContentWrapper>
            </AccordionContent>
          </AccordionItem>
        </AccordionRoot>
        <Styled.Buttons>
          <Styled.ResetButton>
            <Button
              label={modalCopy.resetButton}
              icon="close"
              onClick={resetFilters}
              color={ColorNames.nandor}
              dataCy="performance-filters-modal-reset"
            />
          </Styled.ResetButton>
          <Button
            label={modalCopy.button}
            icon={'check'}
            onClick={applyFilters}
            color={currentColor}
            dataCy="performance-filters-modal-save"
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(PerformanceFiltersModal);
