import * as LabelPrimitive from '@radix-ui/react-label';
import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  padding: 80rem;
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 30rem;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: flex-end;
  padding-top: 60rem;
`;

export const DropdownLabel = styled(LabelPrimitive.Root)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: locale }) => setTypography('caption', locale)};
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    padding: 20rem 0;
    flex: 1;
    border-bottom: 1px solid ${rgba(colors.white, 0.5)};
    ${({ theme: { locale } }) => setTypography('input', locale)}
    text-align: left;
  }
`;
