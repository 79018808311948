import { FC, memo, useCallback } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useAddProjectRows } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectRow } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface ConfirmDuplicateProjectRowsModalProps {
  projectId: Project['id'];
  rowIds: ProjectRow['id'][];
  onSuccess: () => void;
}

const ConfirmDuplicateProjectRowsModal: FC<
  ConfirmDuplicateProjectRowsModalProps
> = ({ projectId, rowIds, onSuccess }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const currentVersionId = useCurrentVersionId();

  const { mutateAsync: addProjectRows, isLoading } = useAddProjectRows();

  const handleClose = useCallback(() => {
    closeModal('confirmDuplicateProjectRowsModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (currentVersionId) {
      await addProjectRows({
        projectId,
        versionId: currentVersionId,
        input: {
          bulk_duplicate: rowIds,
        },
      });
      handleClose();
      onSuccess?.();
    }
  }, [
    currentVersionId,
    addProjectRows,
    projectId,
    rowIds,
    handleClose,
    onSuccess,
  ]);

  const { title, description, cancelButton, confirmButton } =
    copy.project.confirmDuplicateProjectRowsModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
        disabled: isLoading,
        dataCy: 'duplicate-project-rows-confirm',
      }}
      description={replaceString(description, '{name}', String(rowIds.length))}
      title={title}
    />
  );
};

export default memo(ConfirmDuplicateProjectRowsModal);
