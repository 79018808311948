import { useMemo } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore, useCopyStore } from 'store';
import { VendorService } from 'utils/api.types';

export const VENDOR_SERVICES_KEY = 'VENDOR_SERVICES';

export const VENDOR_SERVICE_OTHER_ID = -1;

export const useVendorServices = (
  options?: UseQueryOptions<VendorService[]>
) => {
  const getVendorServices = useApiStore(s => s.apiClient.getVendorServices);
  return useQuery({
    queryKey: VENDOR_SERVICES_KEY,
    queryFn: async () => (await getVendorServices()).data,
    ...options,
  });
};

export const useVendorServicesWithOther = (
  options?: UseQueryOptions<VendorService[]>
) => {
  const {
    data: vendorServices,
    isLoading,
    ...rest
  } = useVendorServices(options);
  const copy = useCopyStore(s => s.copy);
  const vendorServicesWithOther = useMemo(() => {
    return [
      ...(vendorServices ?? []),
      {
        id: VENDOR_SERVICE_OTHER_ID,
        name: `${copy?.app?.vendorPrefix} [${copy?.app?.vendorServiceOther}]`,
      },
    ];
  }, [copy?.app?.vendorPrefix, copy?.app?.vendorServiceOther, vendorServices]);
  return useMemo(
    () => ({
      data: !isLoading ? vendorServicesWithOther : [],
      isLoading,
      ...rest,
    }),
    [isLoading, rest, vendorServicesWithOther]
  );
};

export const useAddVendorService = () => {
  const addVendorService = useApiStore(s => s.apiClient.addVendorService);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addVendorService>[0]) =>
      addVendorService(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDOR_SERVICES_KEY);
      },
    }
  );
};

export const useUpdateVendorService = () => {
  const updateVendorService = useApiStore(s => s.apiClient.updateVendorService);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateVendorService>[0]) =>
      updateVendorService(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDOR_SERVICES_KEY);
      },
    }
  );
};

export const useDeleteVendorService = () => {
  const deleteVendorService = useApiStore(s => s.apiClient.deleteVendorService);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteVendorService>[0]) =>
      deleteVendorService(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDOR_SERVICES_KEY);
      },
    }
  );
};
