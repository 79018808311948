import { FC, memo } from 'react';

import { useDeletePermission } from 'queries/permissions';
import { useCopyStore, useGlobalStore } from 'store';
import { Permission } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface ConfirmDeletePermissionModalProps {
  permission: Permission;
}

const ConfirmDeletePermissionModal: FC<ConfirmDeletePermissionModalProps> = ({
  permission,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: deletePermission, isLoading } = useDeletePermission();

  const close = () => {
    closeModal('confirmDeletePermissionModal');
  };

  const handleConfirm = async () => {
    await deletePermission(permission.id);
    closeModal('confirmDeletePermissionModal');
  };

  return (
    <ConfirmModal
      title={copy.admin.deletePermissionModal.title}
      description={replaceString(
        copy.admin.deletePermissionModal.description,
        '{name}',
        permission.name
      )}
      cancelButtonProps={{
        label: copy.admin.deletePermissionModal.cancelButton,
        color: ColorNames.nandor,
        icon: 'close',
        onClick: close,
      }}
      confirmButtonProps={{
        label: copy.admin.deletePermissionModal.confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleConfirm,
        disabled: isLoading,
        loading: isLoading,
      }}
    />
  );
};

export default memo(ConfirmDeletePermissionModal);
