import { rgba } from 'polished';
import styled from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { hover, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Title = styled.div`
  color: ${rgba(colors.white, 0.4)};
  ${({ theme: { locale } }) => setTypography('body2', locale)}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalRow = styled.div`
  display: flex;
`;

export const UserLabel = styled.div``;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin: 0 5rem;
`;

export const CloseButton = styled.div`
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background: ${rgba(colors.white, 0.2)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80rem;
  right: 80rem;
  cursor: pointer;

  ${hover(brightnessFilter)}
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeedbackDescription = styled.div``;

export const FeedbackButton = styled.div`
  display: flex;
  justify-content: center;
`;
