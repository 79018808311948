import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useCopyStore, usePerformanceDashboardStore } from 'store';

import TimeFrame from './TimeFrame/TimeFrame';

import * as Styled from '../PerformanceFiltersModal.styles';
import * as KpiStyles from './KpisTab.styles';

export interface KpisTabProps {}

const KpisTab: FC<KpisTabProps> = () => {
  const copy = useCopyStore(s => s.copy);

  const marginVariation = usePerformanceDashboardStore(s => s.marginVariation);
  const setMarginVariation = usePerformanceDashboardStore(
    s => s.setMarginVariation
  );

  const tabCopy = copy.performanceDashboard.filtersModal.kpisTab;

  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <Styled.FieldWrapper>
          <FormInput
            value={marginVariation ?? ''}
            onChange={value => setMarginVariation(value)}
            startHtml={
              <KpiStyles.MarginFieldStartHtml>
                {tabCopy.marginStart}
              </KpiStyles.MarginFieldStartHtml>
            }
            endHtml={
              <KpiStyles.MarginFieldEndHtml>%</KpiStyles.MarginFieldEndHtml>
            }
            placeholder={tabCopy.netMarginVariation}
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <TimeFrame />
        </Styled.FieldWrapper>
      </Styled.Row>
    </Styled.TabWrapper>
  );
};

export default memo(KpisTab);
