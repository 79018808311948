import { rgba } from 'polished';
import { FC, memo } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useVerticals } from 'queries/verticals';
import { useCopyStore, usePerformanceDashboardStore } from 'store';
import { Vertical } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from './VerticalDropdown.styles';

export interface VerticalDropdownProps {}

const VerticalDropdown: FC<VerticalDropdownProps> = () => {
  const { data: verticals } = useVerticals();
  const activeItems = usePerformanceDashboardStore(s => s.verticals);
  const setVerticals = usePerformanceDashboardStore(s => s.setVerticals);
  const copy = useCopyStore(s => s.copy);

  const items = verticals?.map(vertical => ({
    displayName: vertical.name,
    id: vertical.id,
  }));

  const toggleActive = (activeId: Vertical['id']) => {
    if (activeItems.includes(activeId)) {
      setVerticals(activeItems.filter(prevItem => prevItem !== activeId));
    } else {
      setVerticals([...activeItems, activeId]);
    }
  };

  const label = activeItems?.map(
    id => items?.find(item => item.id === id)?.displayName
  );

  return (
    <Styled.Wrapper>
      <Styled.Label>
        {copy.performanceDashboard.filtersModal.generalTab.verticalsLabel}
      </Styled.Label>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items?.map(({ id, displayName }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={activeItems?.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleActive(id);
              }}
            >
              {displayName}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.Wrapper>
  );
};

export default memo(VerticalDropdown);
