import { useCallback } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { Project } from 'utils/api.types';
import { copyToClipboard } from 'utils/copyToClipboard';
import { ROUTES } from 'utils/routes';

interface UseGetProjectVersionLinkAction {
  currentProjectId?: Project['id'];
}

export const useGetProjectVersionLinkAction = ({
  currentProjectId,
}: UseGetProjectVersionLinkAction) => {
  const currentVersionId = useCurrentVersionId();

  const copyAction = useCallback(() => {
    if (!currentProjectId || !currentVersionId) return;
    copyToClipboard(
      `${document.location.origin}${ROUTES.PROJECTS_LIST}/${currentProjectId}/${currentVersionId}`
    );
  }, [currentProjectId, currentVersionId]);

  return [copyAction];
};
