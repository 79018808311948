export const replaceString = (
  text: string,
  valuePlaceholder: string,
  value?: string | null
): string => text.replace(valuePlaceholder, value ?? '');

export const replaceStrings = (
  text: string,
  replacements: Array<[string, string]>
): string => {
  let parsedText = text;
  replacements.forEach(
    element => (parsedText = parsedText.replace(element[0], element[1]))
  );

  return parsedText;
};
