import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useUpdateSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore, useGlobalStore } from 'store';
import { MfrRate } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './EditMfrRateColumnModal.styles';

export interface EditMfrRateColumnModalProps {
  mfrRate: MfrRate;
}

const EditMfrRateColumnModal: FC<EditMfrRateColumnModalProps> = ({
  mfrRate,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: updateMfrRate, isLoading } = useUpdateSupportedMfrRate();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [
    columnName,
    setColumnName,
    isColumnNameValid,
    columnNameInvalidMessage,
  ] = useField(mfrRate.code, validators);

  const close = () => {
    closeModal('editMfrRateColumnModal');
  };

  const handleSave = async () => {
    await updateMfrRate({ name: columnName, id: mfrRate.id });
    closeModal('editMfrRateColumnModal');
  };

  return (
    <ConfigModal
      buttonProps={{
        label: copy.admin.editMfrModal.confirmButton,
        icon: 'check',
        color: currentColor,
        dataCy: 'edit-mfr-rate-confirm',
        disabled: !isColumnNameValid || isLoading,
        loading: isLoading,
        onClick: handleSave,
      }}
      title={copy.admin.editMfrModal.title}
      onCloseClick={close}
      color={currentColor}
    >
      <Styled.Wrapper>
        <FormInput
          onChange={value => setColumnName(value)}
          value={columnName}
          dataCy="edit-mfr-rate-input"
          required
          error={!isColumnNameValid ? columnNameInvalidMessage : ''}
          placeholder={copy.admin.editMfrModal.mfrFieldLabel}
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditMfrRateColumnModal);
