import { FC, memo, MouseEvent } from 'react';

import { AddProjectGroupProps } from 'components/modals/AddProjectGroupModal/AddProjectGroup';
import { useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { useCheckAccessRights } from 'utils/accessRights';

import * as Styled from './AddProjectGroupButton.styles';

export interface AddProjectGroupButtonProps {}

const AddProjectGroupButton: FC<AddProjectGroupButtonProps> = () => {
  const { SvgIcon: AddIcon } = useSvgIcon('add');
  const openModal = useGlobalStore(s => s.openModal);

  const { checkAnyAccessRight } = useCheckAccessRights();

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    openModal<AddProjectGroupProps>('addProjectGroup');
  };

  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  if (!isAdmin) {
    return null;
  }

  return (
    <Styled.Wrapper
      onClick={handleClick}
      tabIndex={0}
      data-cy="add-pitch-version"
    >
      <Styled.IconWrapper>
        <AddIcon />
      </Styled.IconWrapper>
    </Styled.Wrapper>
  );
};

export default memo(AddProjectGroupButton);
