import { useProject, useUpdateProject } from 'queries/project';
import { Project } from 'utils/api.types';

type UseToggleTestProject = {
  currentProjectId?: Project['id'];
};

export const useToggleTestProject = ({
  currentProjectId,
}: UseToggleTestProject) => {
  const { mutateAsync: updateProject } = useUpdateProject();
  const { data: project } = useProject(
    { projectId: currentProjectId },
    { enabled: !!currentProjectId }
  );

  const toggleTestProject = async () => {
    if (currentProjectId) {
      updateProject({
        projectId: currentProjectId,
        project: {
          test_flag: !project?.test_flag,
        },
      });
    }
  };

  return [toggleTestProject];
};
