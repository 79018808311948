import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
  padding: 80rem;
  ${({ theme: { locale } }) => setTypography('body3', locale)}
`;

export const Description = styled.div`
  padding-bottom: 40rem;
`;

export const Field = styled.div``;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40rem;
  padding-top: 40rem;
`;
