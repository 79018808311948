import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentWrapper = styled.div`
  width: 70%;
  display: flex;
`;

export const TargetWrapper = styled.div`
  width: 25%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80rem;
`;

export const SliderWrapper = styled.div`
  width: 100%;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
`;

export const WarningWrapper = styled.div`
  margin-top: 20rem;
  user-select: none;
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  color: ${colors.sunsetOrange};
  height: 40rem;
`;
