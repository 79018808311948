import * as Accordion from '@radix-ui/react-accordion';
import styled, { css, keyframes } from 'styled-components';

import { focusVisible } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export type AccordionTheme = 'light' | 'dark';

const itemColors: Record<
  AccordionTheme,
  {
    background: string;
    hover: string;
  }
> = {
  light: {
    background: colors.grayNurse,
    hover: colors.alto,
  },
  dark: {
    background: colors.outerSpace,
    hover: colors.black,
  },
};

export const Root = styled(Accordion.Root)<{ $colorScheme: AccordionTheme }>`
  ${({ $colorScheme }) => css`
    --accordion-background: ${itemColors[$colorScheme].background};
    --accordion-hover: ${itemColors[$colorScheme].hover};
  `};
`;

export const IndicatorWrapper = styled.div`
  width: 20rem;
  height: 20rem;
  margin-right: 16rem;
  margin-left: 10rem;
  transition: transform 300ms;
`;

export const Trigger = styled(Accordion.Trigger)`
  display: flex;
  align-items: center;
  width: fill-available;
  min-height: 80rem;

  border-top-left-radius: 20rem;
  border-top-right-radius: 20rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &[data-state='open'] {
    background: var(--accordion-background);

    ${IndicatorWrapper} {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: var(--accordion-hover);
    border-radius: 20rem;
  }

  &:focus-visible {
    ${focusVisible};
  }
`;

export const Item = styled(Accordion.Item)``;

export const Header = styled(Accordion.Header)``;

const slideDown = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
  }
`;

export const Content = styled(Accordion.Content)`
  &[data-state='open'] {
    padding: 5rem 0;
    background: var(--accordion-background);
    animation: ${slideDown} 150ms;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 150ms;
  }
`;
