import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex: 1;
  position: relative;
  padding-right: 10rem;
`;

export const SectionWrapper = styled.div`
  width: 25%;

  &:first-child {
    text-align: start;
  }

  &:last-child {
    text-align: end;
  }
`;
