import { AccessRight } from './api.types';
import { removeTrailingSlash } from './string';

export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/404',
  MAINTENANCE: '/maintenance',
  PROJECTS_LIST: '/projects',
  PROJECT: '/projects/[...projectSlug]',
  REPORTS_LIST: '/reports/[[...reportsRoute]]',
  LOGIN: '/login',
  FORBIDDEN: '/forbidden',
  ADMIN: '/admin/[[...adminRoute]]',
  AUTO_LOGOUT: '/autoLogout',
  AUTH: '/auth',
  PERFORMANCE: '/performance/[[...performanceRoute]]',
  FINANCE: '/finance/[[...financeRoute]]',
} as const;

export const isRouteType = (route: string): route is keyof typeof ROUTES => {
  return Object.keys(ROUTES).includes(route);
};

export const RESTRICTED_PATHS: { path: string; access: AccessRight[] }[] = [
  {
    path: '/admin',
    access: ['administrator', 'owner', 'super admin'],
  },
  {
    path: '/reports',
    access: ['administrator', 'owner', 'super admin'],
  },
  {
    path: '/projects',
    access: [
      'administrator',
      'owner',
      'executive producer',
      'producer',
      'super admin',
    ],
  },
  {
    path: '/performance',
    access: ['super admin', 'owner', 'executive producer', 'administrator'],
  },
  {
    path: '/performance/overview',
    access: ['super admin', 'owner'],
  },
  {
    path: '/performance/pulse',
    access: ['super admin', 'owner'],
  },
  {
    path: '/performance/insight',
    access: ['super admin', 'owner'],
  },
  {
    path: '/admin/workspaceSettings',
    access: ['owner', 'super admin'],
  },
  {
    path: '/finance',
    access: ['owner', 'super admin'],
  },
];

export const ADMIN_ROUTES = {
  HOME: '',
  USERS: 'users',
  PROJECT_ACCESS: 'users/projectAccess',
  INTERNAL: 'internal',
  INTERNAL_UNVERIFIED: 'internal/verificationPending',
  INTERNAL_UNUSED: 'internal/unused',
  EXTERNAL: 'external',
  EXTERNAL_UNVERIFIED: 'external/verificationPending',
  EXTERNAL_UNUSED: 'external/unused',
  CLIENTS: 'clients',
  CLIENTS_REQUESTS: 'clients/requests',
  BRANDS: 'brands',
  BRANDS_REQUESTS: 'brands/requests',
  ROLES: 'roles',
  ROLES_REQUESTS: 'roles/requests',
  TERRITORY: 'territory',
  VERTICAL: 'vertical',
  VENDOR_SERVICE: 'vendorService',
  VENDOR: 'vendor',
  WORKSPACE_SETTINGS: 'workspaceSettings',
  BOB_ACCESS: 'users/bobAccess',
  ARCHIVE: 'archive',
  HISTORY: 'history',
  FIND_UNVERIFIED_RESOURCE_BY_ID: 'findUnverifiedResourceById',
  DUPLICATES: 'duplicates',
  INTEGRATIONS: 'integrations',
  REPORTS_CONFIGURATION: 'reportsConfiguration',
  TEAMDECK_CONFIGURATION: 'teamdeckConfiguration',
  USER_PERMISSIONS: 'userPermissions',
} as const;

export const REPORTS_ROUTES = {
  ALL: '',
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
  TEAMDECK: 'teamdeck',
} as const;

export const PERFORMANCE_ROUTES = {
  OVERVIEW: 'overview',
  LIVE: 'live',
  PULSE: 'pulse',
  INSIGHT: 'insight',
} as const;

export const FINANCE_ROUTES = {
  HOME: '',
  PAYMENTS: 'payments',
};

export const getProjectRoute = (
  projectSlug: string | number,
  versionId?: string | number
): string => {
  if (!projectSlug) return ROUTES.PROJECTS_LIST;
  let url: string = ROUTES.PROJECT;
  if (versionId) {
    url = url.replace('[...projectSlug]', `${projectSlug}/${versionId}`);
  } else {
    url = url.replace('[...projectSlug]', `${projectSlug}`);
  }

  return removeTrailingSlash(url);
};

export const getAdminRoute = (
  adminRoute: keyof typeof ADMIN_ROUTES
): string => {
  return removeTrailingSlash(
    ROUTES.ADMIN.replace('[[...adminRoute]]', ADMIN_ROUTES[adminRoute])
  );
};

export const getReportsRoute = (
  reportsRoute: keyof typeof REPORTS_ROUTES
): string => {
  return removeTrailingSlash(
    ROUTES.REPORTS_LIST.replace(
      '[[...reportsRoute]]',
      REPORTS_ROUTES[reportsRoute]
    )
  );
};

export const getPerformanceRoute = (
  performanceRoute: keyof typeof PERFORMANCE_ROUTES
): string => {
  return removeTrailingSlash(
    ROUTES.PERFORMANCE.replace(
      '[[...performanceRoute]]',
      PERFORMANCE_ROUTES[performanceRoute]
    )
  );
};

export const getFinanceRoute = (
  financeRoute: keyof typeof FINANCE_ROUTES
): string => {
  return removeTrailingSlash(
    ROUTES.FINANCE.replace('[[...financeRoute]]', FINANCE_ROUTES[financeRoute])
  );
};

export enum Modules {
  projectsList = 'projectsList',
  project = 'project',
  reportsList = 'reportsList',
  admin = 'admin',
  performanceDashboard = 'performanceDashboard',
  finance = 'finance',
}

export const isModuleType = (module: string): module is Modules => {
  return Object.keys(Modules).includes(module);
};

export enum NonModulesPages {
  login = 'login',
  notFound = 'notFound',
  forbidden = 'forbidden',
  maintenance = 'maintenance',
  autoLogout = 'autoLogout',
  auth = 'auth',
}

export const isNonModulePage = (page: string): page is NonModulesPages => {
  return Object.keys(NonModulesPages).includes(page);
};

export const MODULES_MAP: Partial<Record<string, Modules>> = {
  [ROUTES.PROJECTS_LIST]: Modules.projectsList,
  [ROUTES.PROJECT]: Modules.project,
  [ROUTES.REPORTS_LIST]: Modules.reportsList,
  [ROUTES.ADMIN]: Modules.admin,
  [ROUTES.PERFORMANCE]: Modules.performanceDashboard,
};
