import { useCallback } from 'react';

import { Currencies } from '@u9/bob3-shared/lib/types/api.types';
import { BudgetInvoiceDataFillModalProps } from 'components/modals/BudgetInvoiceDataFillModal/BudgetInvoiceDataFillModal';
import { useGlobalStore } from 'store';
import { PaymentStatuses } from 'utils/api.types';

import { useHandleDateChange } from '../hooks/useHandleDateChange';
import { useBudgets } from './useBudgets';
import { useRowsFields } from './useRowsFields';
import { useSaveBudgetEntry } from './useSaveBudgetEntry';

export interface UseBudgetTableProps {}

export const useBudgetTable = () => {
  const openModal = useGlobalStore(s => s.openModal);
  const { budgetEntries, setBudgetEntries, loading, error } = useBudgets();

  const { saveBudgetEntry, errorType } = useSaveBudgetEntry();
  const { handleDateChange } = useHandleDateChange({
    setBudgetEntries,
  });

  const { handleChange } = useRowsFields({
    budgetEntries,
    setBudgetEntries,
  });

  const onDateChange = useCallback(
    (index: number) => (date: Date) => {
      handleDateChange(index)(date);
      saveBudgetEntry(index)();
    },
    [handleDateChange, saveBudgetEntry]
  );

  const handlePaymentStatusChange = useCallback(
    (index: number) => (value: PaymentStatuses) => {
      const isStatusRequiresData = [
        PaymentStatuses.invoiced,
        PaymentStatuses.received,
      ].includes(value);
      const hasDate = budgetEntries[index].date;
      const isRowHasRequiredData = hasDate;

      if (isStatusRequiresData && !isRowHasRequiredData) {
        openModal<BudgetInvoiceDataFillModalProps>('budgetInvoiceDataFill', {
          budgetEntries,
          index,
          value,
          handleChange,
          saveBudgetEntry,
        });
      } else {
        handleChange(index, 'payment_status')(value);
        setTimeout(() => {
          saveBudgetEntry(index)();
        }, 0);
      }
    },
    [budgetEntries, handleChange, openModal, saveBudgetEntry]
  );

  const handleCurrencyChange = useCallback(
    (index: number) => (value: Currencies) => {
      handleChange(index, 'invoice_currency')(value);
      setTimeout(() => {
        saveBudgetEntry(index)();
      }, 0);
    },
    [handleChange, saveBudgetEntry]
  );

  return {
    budgetEntries,
    loading,
    onDateChange,
    handleChange,
    saveBudgetEntry,
    handlePaymentStatusChange,
    handleCurrencyChange,
    error,
    errorType,
  };
};
