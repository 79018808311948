import { FC, memo } from 'react';

import * as Styled from './RndRange.styles';

export interface RndRangeProps {
  rndRange: [number, number];
}

const RndRange: FC<RndRangeProps> = ({ rndRange }) => {
  const formattedRndRange =
    rndRange?.map(rndRange => `${rndRange}%`).join(' - ') || '';

  return (
    <Styled.Wrapper data-cy="rnd-range">{formattedRndRange}</Styled.Wrapper>
  );
};

export default memo(RndRange);
