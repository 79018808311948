export const checkThirdParty = (
  scriptName: string,
  interval: number | null,
  callback: (...params: any[]) => any
) => {
  const checkScript = () => {
    if ((<any>window)[scriptName]) {
      if (interval) {
        window.clearInterval(interval);
        interval = null;
      }

      callback();
    }

    return !!(<any>window)[scriptName];
  };

  if (!checkScript()) interval = window.setInterval(checkScript, 250);
};
