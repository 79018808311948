import { useContext, useMemo } from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import { SELECT_ALL_ID } from 'components/Table/hooks/useMultiSelect';
import { TableContext } from 'components/Table/Table';

import { RowProps } from '../Row';

import * as Styled from '../Row.styles';

type UseRowMultiSelectProps = Pick<
  RowProps,
  'children' | 'id' | 'dataCy' | 'isHeader'
>;

export const useRowMultiSelect = ({
  children,
  id,
  isHeader,
  dataCy,
}: UseRowMultiSelectProps) => {
  const {
    isDragging,
    isSelectable,
    onSelectChange,
    selectedItemsState = {},
  } = useContext(TableContext);

  const isAnySelected = useMemo(
    () =>
      isSelectable
        ? Object.values(selectedItemsState).some(value => value)
        : false,
    [isSelectable, selectedItemsState]
  );

  const checkboxValue = id
    ? isSelectable &&
      (id === SELECT_ALL_ID ? isAnySelected : selectedItemsState[id] === true)
    : false;

  const selectedItemsCount = isSelectable
    ? Object.values(selectedItemsState).filter(Boolean).length
    : 0;

  const isSelectedAndDragging = isDragging && checkboxValue;

  const childrenWithSelect =
    isSelectable && id ? (
      <>
        <Styled.CheckboxWrapper>
          <Checkbox
            value={checkboxValue ?? false}
            setValue={value => onSelectChange?.(id, value)}
            variant="square"
            icon={isHeader ? 'dash' : 'check'}
            dataCy={`${dataCy}__select-checkbox`}
          />
        </Styled.CheckboxWrapper>
        {children}
      </>
    ) : (
      children
    );

  const SelectionCount = () =>
    selectedItemsCount > 0 ? (
      <Styled.SelectionCountWrapper>
        {selectedItemsCount}
      </Styled.SelectionCountWrapper>
    ) : null;

  return {
    checkboxValue,
    isSelectedAndDragging,
    SelectionCount,
    childrenWithSelect,
  };
};
