import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import {
  focusVisible,
  setTypography,
  slideDownAnimation,
} from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const CaretIconWrapper = styled.div`
  width: 24rem;
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Trigger = styled(DropdownMenuPrimitive.Trigger)`
  display: flex;
  align-items: center;

  ${({ children }) =>
    !!children &&
    css`
      ${TriggerIconButton} {
        &:hover {
          background: transparent;
          color: currentColor;
        }
      }
    `}

  &:focus-visible {
    ${focusVisible};
  }

  &[data-state='open'] {
    ${focusVisible};
  }
`;

export const TriggerTextContent = styled.div`
  flex: 1;
`;

export const TriggerIconButton = styled.div`
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background 150ms,
    color 150ms;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${colors.outerSpace};
    color: ${colors.white};
  }
`;

export const Content = styled(DropdownMenuPrimitive.Content)`
  background: ${colors.black};
  color: ${colors.white};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 30rem;
  ${slideDownAnimation};

  min-width: 440rem;
  width: var(--radix-dropdown-menu-trigger-width);
  max-height: var(--radix-dropdown-menu-content-available-height);
  max-width: 30vw;
`;

export const CheckIconWrapper = styled.div`
  width: 34rem;
  height: 34rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.white};
  border-radius: 10rem;
  padding: 5rem;
`;

const item = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 18rem 40rem;
  color: ${colors.white};
  cursor: pointer;
  position: relative;
  ${({ theme: { locale } }) => setTypography('body5', locale)};

  &:focus-visible {
    ${focusVisible};
  }

  &[data-disabled] {
    color: ${rgba(colors.white, 0.5)};
  }

  &[data-highlighted] {
    color: ${rgba(colors.white, 0.5)};

    ${CheckIconWrapper} {
      border-color: ${rgba(colors.white, 0.5)};
    }
  }
`;

export const Item = styled(DropdownMenuPrimitive.Item)`
  ${item};
`;

export const Label = styled(DropdownMenuPrimitive.Label)`
  ${item};
  color: ${rgba(colors.white, 0.5)};
`;

export const Separator = styled(DropdownMenuPrimitive.Separator)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: ${rgba(colors.white, 0.3)};
`;

export const Group = styled(DropdownMenuPrimitive.Group)``;

export const ItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator)``;

export const CheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem)`
  ${item};
`;

export const RadioGroup = styled(DropdownMenuPrimitive.RadioGroup)``;

export const RadioItem = styled(DropdownMenuPrimitive.RadioItem)`
  ${item};

  &[data-state='checked'] {
    font-weight: 700;
  }
`;

export const ItemIconWrapper = styled.div`
  width: 32rem;
  height: 32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20rem;
`;

export const ItemTextContent = styled.div`
  flex: 1;
  max-width: -webkit-fill-available;
`;

export const SubTrigger = styled(DropdownMenuPrimitive.SubTrigger)`
  ${item};
`;

export const SubContent = styled(DropdownMenuPrimitive.SubContent)`
  background: ${colors.black};
  color: ${colors.white};
  overflow: hidden;
  border-radius: 30rem;
  max-width: 30vw;
`;

export const SubMenuTriggerRightSlot = styled.div`
  width: 24rem;
  height: 24rem;
`;

export const TriggerCaretIconWrapper = styled.div`
  transform: rotate(90deg);
`;

export const RadioItemIndicator = styled.div`
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  background: ${colors.white};
`;
