import { useSession } from 'next-auth/react';
import { FC, memo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useUsers } from 'queries/users';
import { useCopyStore } from 'store';
import { User } from 'utils/api.types';

import * as Styled from './UserPicker.styles';

export interface UserPickerProps {
  onUserPicked: (user: User) => void;
}

const UserPicker: FC<UserPickerProps> = ({ onUserPicked }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: users } = useUsers();
  const { data: session } = useSession();

  const [open, setOpen] = useState(false);

  const items = users
    ?.filter(({ permission_set }) => {
      const allAccessSets = permission_set.flatMap(
        ({ access_set }) => access_set
      );
      return !(
        allAccessSets.includes('super admin') ||
        allAccessSets.includes('owner') ||
        allAccessSets.includes('administrator')
      );
    })
    .filter(({ username }) => username !== session?.user?.email);

  return (
    <Styled.Wrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.shareProjectModal.inviteUsers}
      >
        <ComboboxTrigger />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput />
            <ComboboxEmpty />
            <ComboboxList>
              {items?.map(user => (
                <ComboboxItem
                  key={user.id}
                  onSelect={() => {
                    onUserPicked(user);
                    setOpen(false);
                  }}
                >
                  {`${user.name} - ${user.username}`}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.Wrapper>
  );
};

export default memo(UserPicker);
