import { Table } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { TabItem } from 'components/Tabs/Tabs';
import { useFilter } from 'hooks/userFilterData';
import { useCurrentProject } from 'queries/project';
import { useAddProjectRows } from 'queries/projectRows';
import { useRoles } from 'queries/roles';
import { VENDOR_SERVICE_OTHER_ID } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectRow, projectRowTypes } from 'utils/api.types';
import { basicRegexSearch } from 'utils/regex';

import { useItems } from './useItems';

export interface UseAddProjectProps {
  searchValue: string;
  tableScrollRef?: React.RefObject<HTMLDivElement>;
  table?: Table<ProjectRow>;
  rowNo?: ProjectRow['row_no'];
}

export const useAddProject = ({
  searchValue,
  tableScrollRef,
  table,
  rowNo,
}: UseAddProjectProps) => {
  const MAX_RESOURCE_COUNT = 9;

  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const copy = useCopyStore(s => s.copy);
  const { data: roles, isLoading: isRolesLoading } = useRoles();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [counters, setCounters] = useState<{ [skillId: string]: number }>({});
  const [editingSkillId, setEditingSkillId] = useState<string | null>(null);
  const [closing, setClosing] = useState(false);
  const closeModals = useGlobalStore(s => s.closeModals);

  const tabItems: TabItem[] = useMemo(() => {
    if (!roles) return [];
    const uniqueCategories = [
      ...Array.from(new Set(roles.map(({ category }) => category))).filter(
        Boolean
      ),
      copy.project.resourcesModal.vendors,
    ];
    return uniqueCategories.map(category => ({
      label: category,
    }));
  }, [copy.project.resourcesModal.vendors, roles]);

  const { mutateAsync: addProjectRows, isLoading: addProjectRowsLoading } =
    useAddProjectRows();

  const { getItemsForCategory } = useItems();

  const allItems = tabItems.flatMap(tab => {
    return getItemsForCategory(tab.label);
  });

  const filteredItems = useFilter(getItemsForCategory(activeTab), searchValue, [
    'label',
  ]);
  const filteredTabItems = tabItems.filter(tabItem => {
    const itemsForCategory = getItemsForCategory(tabItem.label);
    const filteredItemsForCategory = itemsForCategory.filter(item =>
      basicRegexSearch(item, 'label', searchValue)
    );
    return filteredItemsForCategory.length;
  });

  const numberOfRowsToRender = Math.ceil((filteredItems?.length || 0) / 3);

  const maxNumberOfRows = useMemo(() => {
    const rowsForEachCategory = tabItems.map(({ label }) =>
      Math.ceil(getItemsForCategory(label).length / 3)
    );
    return Math.max(...rowsForEachCategory);
  }, [getItemsForCategory, tabItems]);

  const handleResourceClick = useCallback(
    (skillId: string) => () => {
      counters[skillId] = counters[skillId]
        ? Math.min(counters[skillId] + 1, MAX_RESOURCE_COUNT)
        : 1;
      setCounters({ ...counters });
    },
    [counters]
  );

  const handleEditCounter = useCallback(
    (skillId: string | null) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setEditingSkillId(skillId);
    },
    []
  );

  const handleCounterChange = useCallback(
    (skillId: string, value: string) => {
      const val = Number(value);
      const valueWithinConstraints = Math.min(
        Math.max(0, val),
        MAX_RESOURCE_COUNT
      );
      counters[skillId] = valueWithinConstraints;
      setCounters({ ...counters });
    },
    [counters]
  );

  const rows = table?.getSelectedRowModel().rows;
  const applyAtHoveredPosition = rowNo !== undefined;
  const applyAtSelectedPosition = rows?.length === 1;
  const applyPosition = (() => {
    if (applyAtHoveredPosition) return rowNo + 1;
    if (applyAtSelectedPosition) return rows[0].original.row_no + 1;
    return 0;
  })();
  const canApplyAtPosition = applyAtHoveredPosition || applyAtSelectedPosition;

  const handleAdd = async () => {
    const resources = Object.entries(counters)
      .map(([itemId, count]) => {
        const item = allItems.find(({ id }) => String(id) === itemId);

        if (item?.category === 'vendorService') {
          return {
            ...(item.id !== VENDOR_SERVICE_OTHER_ID && {
              vendor_service_id: item.id,
            }),
            count,
            type: projectRowTypes.vendorService,
          };
        } else if (item?.category === 'role') {
          return {
            // eslint-disable-next-line
            // @ts-ignore
            role_id: Number(item.roleId),
            count,
            type: projectRowTypes.resource,
          };
        }
      })
      .flatMap(resource => (resource ? [resource] : []))
      .filter(resource => resource && !!resource.count);
    if (currentProject?.id && currentVersionId) {
      const response = await addProjectRows({
        projectId: currentProject?.id,
        versionId: currentVersionId,
        input: {
          ...(canApplyAtPosition ? { row_no: applyPosition } : {}),
          rows: resources,
        },
      });
      if (response?.data) {
        setClosing(true);
      }

      if (canApplyAtPosition) table?.resetRowSelection();
      if (!canApplyAtPosition) {
        setTimeout(() => {
          if (tableScrollRef?.current) {
            tableScrollRef.current.scrollTo({
              top: tableScrollRef.current?.scrollHeight,
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    }
  };

  const handleClose = useCallback(() => {
    closeModals(['addProjectRow']);
  }, [closeModals]);

  return {
    tabItems: filteredTabItems,
    isRolesLoading,
    addLoading: addProjectRowsLoading,
    activeTab,
    setActiveTab,
    items: filteredItems,
    numberOfRowsToRender,
    maxNumberOfRows,
    handleResourceClick,
    counters,
    handleEditCounter,
    editingSkillId,
    setEditingSkillId,
    handleCounterChange,
    handleAdd,
    handleClose,
    closing,
  };
};
