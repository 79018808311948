import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
  width: 1200rem;
`;

export const Description = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  color: ${rgba(colors.white, 0.5)};
  padding-bottom: 60rem;
`;
