import { useMemo } from 'react';

import { useCopyStore } from 'store';

export const MIN_PROJECT_NAME_LENGTH = 5;

export const useProjectNameValidators = () => {
  const copy = useCopyStore(s => s.copy);

  return useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.projectNameRequired,
      },
      minLength: {
        atLeast: MIN_PROJECT_NAME_LENGTH,
        message: `${copy.app.validatorMessages.projectNameLength1}${MIN_PROJECT_NAME_LENGTH}${copy.app.validatorMessages.projectNameLength2}`,
      },
    }),
    [copy]
  );
};
