import _get from 'lodash/get';

import { ProjectMode } from 'utils/api.types';

export const sortDirections = ['asc', 'desc'] as const;
export type SortDirection = typeof sortDirections[number];

export const sort = <T>(
  data: T[] = [],
  field: string,
  sortFn: (
    a: string | number,
    b: string | number,
    direction?: SortDirection,
    locale?: string
  ) => number,
  direction: SortDirection = 'asc',
  locale?: string
): T[] => {
  return locale
    ? [...data].sort((a, b) =>
        sortFn(_get(a, field), _get(b, field), direction, locale)
      )
    : [...data].sort((a, b) =>
        sortFn(_get(a, field), _get(b, field), direction)
      );
};

// This function uses hardcoded names for "job_number", "pitch_number" and "status" fields.
export const sortJobNumber = <T>(
  data: T[] = [],
  direction: SortDirection = 'asc'
) => {
  const tempData = data
    .map(item => {
      const mode = _get(item, 'mode');

      return {
        ...item,
        tempJobNumber:
          mode === ProjectMode.pitch
            ? _get(item, 'pitch_number')
            : _get(item, 'job_number'),
      };
    })
    .map(item => ({
      ...item,
      tempJobNumber: item.tempJobNumber
        ? isNaN(Number(item.tempJobNumber))
          ? -1
          : item.tempJobNumber
        : 0,
    }));

  tempData.sort((a, b) =>
    sortNumbers(
      _get(a, 'tempJobNumber') as number,
      _get(b, 'tempJobNumber') as number,
      direction
    )
  );

  return tempData.map(item => {
    // eslint-disable-next-line
    // @ts-ignore
    delete item.tempJobNumber;
    return item;
  });
};

export const sortString = (
  a?: string | number,
  b?: string | number,
  sortDirection?: SortDirection,
  locale?: string
) => {
  a = a ?? '';
  b = b ?? '';
  // Additional checks push empty strings to the end of the list in ascending sort
  if (sortDirection === 'asc') {
    if (a === '' && b === '') return 0;
    if (a === '') return 1;
    if (b === '') return -1;
    return String(a).localeCompare(String(b), locale);
  } else {
    if (a === '' && b === '') return 0;
    if (a === '') return -1;
    if (b === '') return 1;
    return String(b).localeCompare(String(a), locale);
  }
};

export const sortNumbers = (
  a: number | string,
  b: number | string,
  sortDirection?: SortDirection
) => {
  return sortDirection === 'asc'
    ? Number(b) - Number(a)
    : Number(a) - Number(b);
};

export const sortDates = (
  a: string | number,
  b: string | number,
  sortDirection?: SortDirection
) => {
  const newA = Date.parse(String(a));
  const newB = Date.parse(String(b));

  return sortNumbers(newA, newB, sortDirection);
};
