import { FC } from 'react';

import * as Styled from './ScrollArea.styles';

export interface ScrollAreaProps {
  children: React.ReactNode | React.ReactNode[];
}

const ScrollArea: FC<ScrollAreaProps> = ({ children }) => {
  return (
    <Styled.Root>
      <Styled.Viewport>{children}</Styled.Viewport>
      <Styled.Scrollbar orientation="vertical">
        <Styled.Thumb />
      </Styled.Scrollbar>
      <Styled.Scrollbar orientation="horizontal">
        <Styled.Thumb />
      </Styled.Scrollbar>
      <Styled.Corner />
    </Styled.Root>
  );
};

export default ScrollArea;
