import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div``;

export const Description = styled.span``;

export const DescriptionNormal = styled.span`
  ${({ theme: { locale } }) => setTypography('body3', locale)}
`;

export const DescriptionBold = styled.span`
  ${({ theme: { locale } }) => setTypography('heading4', locale)}
`;
