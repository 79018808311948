import { useState } from 'react';

import { useProject } from 'queries/project';
import { Project } from 'utils/api.types';

export const useRndExcludeField = (projectId?: Project['id']) => {
  const { data: project } = useProject(
    { projectId },
    {
      enabled: !!projectId,
    }
  );

  const [isExcludeRnd, setIsExcludeRnd] = useState<boolean>(
    project?.research_and_development?.exclude || false
  );

  return {
    isExcludeRnd,
    setIsExcludeRnd,
  };
};
