import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { BrandRequestModalProps } from 'components/modals/BrandRequestModal/BrandRequestModal';
import { useBrands } from 'queries/brands';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Brand as BrandType, Project, ProjectStatus } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface BrandProps {
  projectId?: Project['id'];
  canEditProject: boolean;
  activeBrandId?: BrandType['id'];
  setActiveBrandId: Dispatch<SetStateAction<BrandType['id'] | undefined>>;
}

export const Brand = ({
  projectId,
  activeBrandId,
  canEditProject,
  setActiveBrandId,
}: BrandProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const [searchValue, setSearchValue] = useState('');
  const { data: brands } = useBrands();
  const { checkAnyAccessRight } = useCheckAccessRights();

  const items = useMemo(
    () =>
      sort(
        brands?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [brands, locale]
  );

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.brand?.closed &&
    !activeBrandId;

  const modalCopy = copy.app.projectModal;

  const [open, setOpen] = useState(false);

  const canSeeAdminPanel = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  return (
    <Styled.FormDropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.brand}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.brandClosedWarningTitle}
                text={modalCopy.brandClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
      >
        <ComboboxTrigger
          disabled={!canEditProject}
          label={items.find(({ id }) => id === activeBrandId)?.displayName}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {searchValue.length > 0 && !canSeeAdminPanel && (
                <ComboboxItem
                  value={searchValue}
                  onSelect={() => {
                    openModal<BrandRequestModalProps>('brandRequest', {
                      brandName: searchValue,
                    });
                    setOpen(false);
                  }}
                >
                  {replaceString(
                    copy.app.comboboxSuggestButton,
                    '{name}',
                    searchValue
                  )}
                </ComboboxItem>
              )}
              {items.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeBrandId === id}
                  onSelect={() => {
                    setActiveBrandId(prev => (prev === id ? undefined : id));
                    setOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Brand);
