import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Start = styled.div`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${rgba(colors.outerSpace, 0.5)};
  }
`;

export const HeaderText = styled.div`
  max-width: 800rem;
  margin-right: 15rem;
`;

export const Counter = styled.div`
  margin-left: 20rem;
`;
