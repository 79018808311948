import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const DropdownWarningWrapper = styled.div`
  margin: 0 10rem;
`;

export const DropdownWarningIcon = styled.div`
  width: 24rem;
  height: 24rem;
  color: ${colors.selectiveYellow};
`;
