import { useCallback } from 'react';

import { DuplicateProjectModalProps } from 'components/modals/DuplicateProjectModal/DuplicateProjectModal';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

interface UseDuplicateActionProps {
  currentProjectId?: Project['id'];
}

export const useDuplicateAction = ({
  currentProjectId,
}: UseDuplicateActionProps) => {
  const openModal = useGlobalStore(s => s.openModal);

  const duplicateAction = useCallback(() => {
    // https://github.com/radix-ui/primitives/issues/2122
    setTimeout(() => {
      openModal<DuplicateProjectModalProps>('duplicateProject', {
        projectId: currentProjectId,
      });
    }, 0);
  }, [currentProjectId, openModal]);

  return [duplicateAction];
};
