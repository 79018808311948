import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { colors } from 'utils/styles/theme';

export interface HeadProps {
  title: string;
  description: string;
  ogImage: string;
}

// eslint-disable-next-line prettier/prettier
const defaultViewport = `width=device-width, initial-scale=1.0, shrink-to-fit=no${
  process.env.ALLOW_USER_ZOOM
    ? ''
    : ',minimum-scale=1.0,maximum-scale=1.0,user-scalable=0'
}, viewport-fit=cover`;

const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  ogImage,
}) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setMounted(true);
  }, []);

  const fullPath = useMemo(
    () => (isMounted && window.location.href) || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router.asPath]
  );

  const domain = useMemo(
    () => (isMounted && window.location.host) || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router.asPath]
  );

  return (
    <NextHead>
      <title>{title || ''}</title>
      <meta name="description" content={description || ''} />

      <meta name="theme-color" content={colors.black} />
      <meta name="msapplication-TileColor" content={colors.black} />
      <link rel="icon" href={'/favicon.ico'} />
      <link
        rel="manifest"
        href={'/manifest.json'}
        crossOrigin="use-credentials"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={'/favicon-32x32.png'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={'/favicon-16x16.png'}
      />
      <link
        rel="mask-icon"
        href={'/safari-pinned-tab.svg'}
        color={colors.black}
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <link rel="apple-touch-icon" href={'/apple-touch-icon.png'} />

      <meta property="og:url" content={fullPath} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || ''} />
      <meta property="og:description" content={description || ''} />
      {ogImage && <meta property="og:image" content={ogImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={fullPath} />
      <meta property="twitter:title" content={title || ''} />
      <meta property="twitter:description" content={description || ''} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}

      <meta name="viewport" content={defaultViewport} />
    </NextHead>
  );
};

export default Head;
