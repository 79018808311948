import { useQuery, UseQueryOptions } from 'react-query';

import { useApiStore } from 'store';
import { ProjectFieldLists } from 'utils/api.types';

export const PROJECT_FIELD_LISTS_KEY = 'PROJECT_FIELD_LISTS';

export const useProjectFieldLists = (
  options?: UseQueryOptions<ProjectFieldLists>
) => {
  const getProjectFieldLists = useApiStore(
    s => s.apiClient.getProjectFieldLists
  );
  return useQuery({
    queryKey: PROJECT_FIELD_LISTS_KEY,
    queryFn: async () => (await getProjectFieldLists()).data,
    ...options,
  });
};
