import { useCallback, useMemo } from 'react';

import { useDeleteBrand } from 'queries/brands';
import { useDeleteClient } from 'queries/clients';
import { useDeleteResource } from 'queries/resources';
import { useDeleteUser } from 'queries/users';
import { useDeleteVendor } from 'queries/vendors';
import { Resource } from 'utils/api.types';

import { AdminListItem, CategoryIdentifier } from './useListItems';

interface DeleteFunction {
  (item: AdminListItem): void;
}

interface ResourceDeleteFunction {
  (item: AdminListItem & { parent_id?: Resource['id'] }): void;
}

interface ClientDeleteFunction {
  (item: AdminListItem & { parent_id?: Resource['id'] }): void;
}

export const useDeleteAdminItem = () => {
  const { mutateAsync: handleDeleteUser, isLoading: deleteUserLoading } =
    useDeleteUser();
  const { mutateAsync: handleDeleteClient, isLoading: deleteClientLoading } =
    useDeleteClient();
  const {
    mutateAsync: handleDeleteResource,
    isLoading: deleteResourceLoading,
  } = useDeleteResource();
  const { mutateAsync: handleDeleteVendor, isLoading: deleteVendorLoading } =
    useDeleteVendor();
  const { mutateAsync: handleDeleteBrand, isLoading: deleteBrandLoading } =
    useDeleteBrand();

  const anyDeleteRunning = useMemo(
    () =>
      deleteUserLoading ||
      deleteClientLoading ||
      deleteResourceLoading ||
      deleteVendorLoading ||
      deleteBrandLoading,
    [
      deleteBrandLoading,
      deleteClientLoading,
      deleteResourceLoading,
      deleteUserLoading,
      deleteVendorLoading,
    ]
  );

  const userDelete = useCallback<DeleteFunction>(
    async item => {
      if (item.email) {
        await handleDeleteUser(item.email);
      }
    },
    [handleDeleteUser]
  );

  const clientDelete = useCallback<ClientDeleteFunction>(
    async item => {
      if (item.clientId) {
        handleDeleteClient({
          id: item.clientId,
          ...(item?.parent_id && { parent_id: item.parent_id }),
        });
      }
    },
    [handleDeleteClient]
  );

  const resourceDelete = useCallback<ResourceDeleteFunction>(
    async item => {
      if (item.resourceId) {
        await handleDeleteResource({
          id: item.resourceId,
          ...(item?.parent_id && { parent_id: item.parent_id }),
        });
      }
    },
    [handleDeleteResource]
  );

  const vendorDelete = useCallback<DeleteFunction>(
    async item => {
      if (item.vendorId) {
        await handleDeleteVendor(item.vendorId);
      }
    },
    [handleDeleteVendor]
  );

  const brandDelete = useCallback<DeleteFunction>(
    async item => {
      if (item.brandId) {
        await handleDeleteBrand(item.brandId);
      }
    },
    [handleDeleteBrand]
  );

  const deleteFunction: Record<
    CategoryIdentifier,
    DeleteFunction | ResourceDeleteFunction
  > = useMemo(
    () => ({
      [CategoryIdentifier.User]: userDelete,
      [CategoryIdentifier.Client]: clientDelete,
      [CategoryIdentifier.Brand]: brandDelete,
      [CategoryIdentifier.Internal]: resourceDelete,
      [CategoryIdentifier.External]: resourceDelete,
      [CategoryIdentifier.Mfr]: resourceDelete,
      [CategoryIdentifier.Vendor]: vendorDelete,
      [CategoryIdentifier.ClientRequest]: () => null,
      [CategoryIdentifier.BrandRequest]: () => null,
    }),
    [clientDelete, resourceDelete, userDelete, vendorDelete, brandDelete]
  );

  const handleDelete = useCallback(
    async (
      item: AdminListItem & { parent_id?: Resource['id'] },
      callback?: () => void
    ) => {
      if (!anyDeleteRunning) {
        await deleteFunction[item.categoryIdentifier](item);
        callback?.();
      }
    },
    [anyDeleteRunning, deleteFunction]
  );

  return {
    handleDelete,
    anyDeleteRunning,
  };
};
