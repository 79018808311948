import { FC, memo, useCallback } from 'react';

import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteContractsLinkModalProps {}

const DeleteContractsLinkModal: FC<DeleteContractsLinkModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const copy = useCopyStore(s => s.copy);

  const { mutateAsync: updateProjectHandler, isLoading: updateProjectLoading } =
    useUpdateProject();

  const handleCancelClick = useCallback(() => {
    closeModal('deleteContractsLinkModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (currentProject?.id) {
      const response = await updateProjectHandler({
        projectId: currentProject?.id,
        project: { contract_url: '' },
      });
      if (response?.data) {
        closeModal('deleteContractsLinkModal');
      }
    }
  }, [closeModal, currentProject?.id, updateProjectHandler]);

  const { title, description, cancelButton, confirmButton } =
    copy.project.budgetModal.deleteContractsModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleCancelClick,
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: updateProjectLoading,
        disabled: updateProjectLoading,
        dataCy: 'folder-link__delete-confirm',
      }}
      description={description}
      title={title}
    />
  );
};

export default memo(DeleteContractsLinkModal);
