import { FC, memo } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useCurrentProject, useReconciliationData } from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { formatMoney, formatPercent } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import { useHandleSelectIntegrationProject } from '../hooks/useHandleSelectIntegrationProject';
import { useHandleUploadJas } from '../hooks/useHandleUploadJas';

import * as Styled from './ReconcileStats.styles';

export type ReconcileStatsProps = {
  view: 'JAS' | 'float' | 'teamdeck';
  onContinueClick: () => void;
};

const ReconcileStats: FC<ReconcileStatsProps> = ({ view, onContinueClick }) => {
  const currentProject = useCurrentProject();
  const { data: { items } = { data: { items: [] } } } = useReconciliationData({
    projectId: currentProject?.id,
  });
  // const { onHandleContinue } = useHandleContinue({ viewsSelected });
  const { onHandleUploadJas } = useHandleUploadJas();
  const { handleSelectIntegrationProject } =
    useHandleSelectIntegrationProject();
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const budget = currentProjectVersion?.budget;

  const totalInvoice =
    items?.reduce((prev, { jas_amount }) => prev + jas_amount, 0) ?? 0;

  const invoiceAllocatedTotal =
    items
      ?.filter(({ row_id }) => !!row_id)
      .reduce((prev, { jas_amount }) => prev + jas_amount, 0) ?? 0;

  const modalCopy = copy.app.reconcileSuccessModal;

  return (
    <Styled.StatsWrapper>
      <Styled.StartWrapper></Styled.StartWrapper>
      <Styled.EndWrapper>
        {view === 'JAS' && (
          <>
            <Styled.StatWrapper>
              <Styled.StatLabel>{modalCopy.externalTotal}</Styled.StatLabel>
              <Styled.StatValue>
                {formatMoney(budget?.external_total_cost)} (
                {formatPercent(
                  ((budget?.external_total_cost ?? 0) / totalInvoice) * 100
                )}
                )
              </Styled.StatValue>
            </Styled.StatWrapper>
            <Styled.StatWrapper>
              <Styled.StatLabel>{modalCopy.invoicesTotal}</Styled.StatLabel>
              <Styled.StatValue>{formatMoney(totalInvoice)}</Styled.StatValue>
            </Styled.StatWrapper>
            <Styled.StatWrapper>
              <Styled.StatLabel>{modalCopy.allocatedTotal}</Styled.StatLabel>
              <Styled.StatValue>
                {formatMoney(invoiceAllocatedTotal)} (
                {formatPercent(
                  (invoiceAllocatedTotal / (budget?.external_total_cost ?? 0)) *
                    100
                )}
                )
              </Styled.StatValue>
            </Styled.StatWrapper>
          </>
        )}

        <Styled.ButtonWrapper>
          {view === 'JAS' && (
            <Button
              label={modalCopy.uploadCta}
              color={ColorNames.doveGray}
              icon="add"
              onClick={onHandleUploadJas}
            />
          )}
          {view === 'float' && (
            <Button
              label={modalCopy.selectFloatButton}
              color={ColorNames.doveGray}
              icon="add"
              onClick={() => handleSelectIntegrationProject('float')}
            />
          )}
          {view === 'teamdeck' && (
            <Button
              label={modalCopy.selectTeamdeckButton}
              color={ColorNames.doveGray}
              icon="add"
              onClick={() => handleSelectIntegrationProject('teamdeck')}
            />
          )}
        </Styled.ButtonWrapper>
        <Styled.ButtonWrapper>
          <Button
            label={modalCopy.cta}
            color={currentColor}
            icon="check"
            onClick={onContinueClick}
          />
        </Styled.ButtonWrapper>
      </Styled.EndWrapper>
    </Styled.StatsWrapper>
  );
};

export default memo(ReconcileStats);
