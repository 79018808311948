import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled, { css } from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Root = styled(ScrollArea.Root)<{
  width?: string;
  height?: string;
}>`
  overflow: hidden;
  --scrollbar-size: 10px;

  ${({ width = '100%', height = '100%' }) => css`
    width: ${width};
    height: ${height};
  `}
`;

export const Viewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 100%;
  }
`;

export const Scrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 2px;
  background: ${colors.concrete};
  transition: background 160ms ease-out;

  &:hover {
    background: ${colors.doveGray};
  }

  &[data-orientation='vertical'] {
    width: var(--scrollbar-size);
  }

  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: var(--scrollbar-size);
  }
`;

export const Thumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: ${colors.dustyGray};
  border-radius: var(--scrollbar-size);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`;

export const Corner = styled(ScrollArea.Corner)`
  background: ${colors.doveGray};
`;
