import { FC, memo, useState } from 'react';

import {
  useDeclineProjectPermission,
  useGrantProjectPermission,
} from 'queries/projectPermission';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ProjectAccessRequest } from 'utils/api.types';

import * as Styled from './PermissionRequestsList.styles';

export interface PermissionRequestsListProps {
  items?: ProjectAccessRequest[];
}

const PermissionRequestsList: FC<PermissionRequestsListProps> = ({ items }) => {
  const copy = useCopyStore(s => s.copy);
  const { SvgIcon: CloseIcon } = useSvgIcon('close');
  const currentColor = useGlobalStore(s => s.currentColor);
  const [hoveredItem, setHoveredItem] = useState<
    ProjectAccessRequest['username'] | null
  >(null);

  const { mutateAsync: declineProjectPermission } =
    useDeclineProjectPermission();
  const { mutateAsync: grantProjectPermission } = useGrantProjectPermission();

  const handleItemMouseEnter = (username: ProjectAccessRequest['username']) => {
    setHoveredItem(username);
  };

  const handleItemMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDecline = async (item: ProjectAccessRequest) => {
    declineProjectPermission({
      projectId: item.project_id,
      username: item.username,
    });
  };

  const handleConfirm = async (item: ProjectAccessRequest) => {
    try {
      grantProjectPermission({
        projectId: item.project_id,
        username: item.username,
      });
    } catch (err) {}
  };

  const hasRequestActive = (items?.length ?? 0) > 0;

  return (
    <Styled.Wrapper hasRequestActive={hasRequestActive}>
      {hasRequestActive && (
        <Styled.Label>
          {copy.app.shareProjectModal.permissionRequests}
        </Styled.Label>
      )}

      {items?.map(item => (
        <Styled.ItemWrapper
          key={item.username}
          onMouseEnter={() => handleItemMouseEnter(item.username)}
          onMouseLeave={handleItemMouseLeave}
        >
          <Styled.UserWrapper>
            <Styled.NameWrapper>{item.name}</Styled.NameWrapper>
            <Styled.EmailWrapper>{item.username}</Styled.EmailWrapper>
          </Styled.UserWrapper>
          <Styled.ActionButtons>
            {hoveredItem === item.username && (
              <Styled.DeclineButtonWrapper
                tabIndex={0}
                onClick={() => handleDecline(item)}
              >
                <Styled.CloseIconWrapper>
                  <CloseIcon />
                </Styled.CloseIconWrapper>
              </Styled.DeclineButtonWrapper>
            )}
            <Styled.GrantAccessCta
              tabIndex={0}
              onClick={() => handleConfirm(item)}
              color={currentColor}
            >
              {copy.app.shareProjectModal.grantCta}
            </Styled.GrantAccessCta>
          </Styled.ActionButtons>
        </Styled.ItemWrapper>
      ))}
    </Styled.Wrapper>
  );
};

export default memo(PermissionRequestsList);
