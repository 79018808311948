import { FC, memo, useEffect, useState } from 'react';

import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import {
  ToggleGroup,
  ToggleGroupItem,
} from 'components/ToggleGroup/ToggleGroup';
import {
  useCurrentProject,
  useReconcile,
  useReconciliationData,
} from 'queries/project';
import { useCopyStore, useGlobalStore, useProjectStore } from 'store';
import gtm from 'u9/utils/gtm';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import ReconcileFloat from './ReconcileFloat';
import ReconcileJAS from './ReconcileJAS';
import ReconcileTeamdeck from './ReconcileTeamdeck';
import SelectIntegrationsView from './SelectIntegrationsView/SelectIntegrationsView';

import * as Styled from './ReconcileModal.styles';

export interface ReconcileModalProps {}

type View = 'jas' | 'float' | 'teamdeck';
export type SelectedViewsType = { [K in View]: boolean } | null;

export const isReconcileViewType = (view: string): view is View =>
  ['jas', 'float', 'teamdeck'].includes(view);

const ReconcileModal: FC<ReconcileModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const { mutateAsync: reconcile, isLoading } = useReconcile();
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const [view, setView] = useState<View>('float');
  const [selectingIntegrationsView, setSelectingIntegrationsView] =
    useState(false);
  const wrapResourcesFloat = useProjectStore(s => s.wrapResourcesFloat);
  const setWrapResourcesFloat = useProjectStore(s => s.setWrapResourcesFloat);
  const wrapResourcesTeamdeck = useProjectStore(s => s.wrapResourcesTeamdeck);
  const setWrapResourcesTeamdeck = useProjectStore(
    s => s.setWrapResourcesTeamdeck
  );
  const { data: reconcileData } = useReconciliationData({
    projectId: currentProject?.id,
  });

  useEffect(() => {
    if (wrapResourcesFloat === null && reconcileData) {
      setWrapResourcesFloat(
        reconcileData.external_items
          .filter(({ type, wrap }) => type === 'float' && wrap)
          .map(({ id }) => id)
      );
    }
    if (wrapResourcesTeamdeck === null && reconcileData) {
      setWrapResourcesTeamdeck(
        reconcileData.external_items
          .filter(({ type, wrap }) => type === 'teamdeck' && wrap)
          .map(({ id }) => id)
      );
    }
  }, [
    reconcileData,
    setWrapResourcesFloat,
    setWrapResourcesTeamdeck,
    wrapResourcesFloat,
    wrapResourcesTeamdeck,
  ]);

  useEffect(() => {
    (async () => {
      if (!currentProject?.id) return;
      await reconcile({
        project_id: currentProject?.id,
        report_type: 'base',
      });
    })();
  }, [currentProject?.id, reconcile]);

  const modalCopy = copy.app.reconcileSuccessModal;

  const handleClose = () => {
    closeModal('reconcileModal');
  };

  const handleContinue = () => {
    gtm.trackEvent('Action', 'reconciliation_continue_clicked');
    setSelectingIntegrationsView(true);
  };

  return (
    <ConfigModal
      onCloseClick={handleClose}
      title={modalCopy.title}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        {selectingIntegrationsView ? (
          <SelectIntegrationsView
            setSelectingIntegrationsView={setSelectingIntegrationsView}
          />
        ) : isLoading ? (
          <Styled.LoaderWrapper>
            <Spinner color={ColorNames.white} />
          </Styled.LoaderWrapper>
        ) : (
          <>
            <Styled.ToggleWrapper>
              <ToggleGroup
                type="single"
                value={view}
                onValueChange={value => {
                  if (value) setView(value as View);
                }}
              >
                <ToggleGroupItem
                  value="float"
                  colorScheme="dark"
                  style={{ gap: '20rem' }}
                >
                  {modalCopy.toggleFloat}
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="teamdeck"
                  colorScheme="dark"
                  style={{ gap: '20rem' }}
                >
                  {modalCopy.toggleTeamdeck}
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="jas"
                  colorScheme="dark"
                  style={{ gap: '20rem' }}
                >
                  {modalCopy.toggleJas}
                </ToggleGroupItem>
              </ToggleGroup>
            </Styled.ToggleWrapper>
            <Styled.Content>
              {view === 'jas' && (
                <ReconcileJAS onContinueClick={handleContinue} />
              )}
              {view === 'float' && (
                <ReconcileFloat onContinueClick={handleContinue} />
              )}
              {view === 'teamdeck' && (
                <ReconcileTeamdeck onContinueClick={handleContinue} />
              )}
            </Styled.Content>
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ReconcileModal);
