import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { RoleRequestRejectedNotification as RoleRequestRejectedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface RoleRequestRejectedNotificationProps {
  notification: RoleRequestRejectedNotificationType;
}

const RoleRequestRejectedNotification: FC<
  RoleRequestRejectedNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.roleRequestRejected}
        replacements={[
          [
            '{user}',
            `${notification.extra_info.fullName} (${notification.extra_info.username})`,
          ],
          ['{role}', notification.extra_info.roleName],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(RoleRequestRejectedNotification);
