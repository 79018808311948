import { useCallback } from 'react';

import { DeleteProjectVersionModalProps } from 'components/modals/DeleteProjectVersion/DeleteProjectVersion';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

export const useDeletePitchAction = ({
  currentProjectId,
}: {
  currentProjectId?: Project['id'];
}) => {
  const currentVersionId = useCurrentVersionId();
  const openModal = useGlobalStore(s => s.openModal);

  const deletePitchAction = useCallback(() => {
    if (!currentProjectId || !currentVersionId) return;
    openModal<DeleteProjectVersionModalProps>('deleteProjectVersion', {
      projectId: currentProjectId,
      versionId: currentVersionId,
    });
  }, [currentProjectId, currentVersionId, openModal]);

  return [deletePitchAction];
};
