import React, { useContext } from 'react';

import { ReactComponent as Caret } from 'svgs/caret.svg';
import { SortDirection } from 'utils/sort';

import { TableContext } from '../Table';
import { Column } from '../Table.types';

import * as Styled from './THeadCell.styles';

interface THeadCellProps {
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  divider?: boolean;
  topBorder?: boolean;
  sort?: SortDirection;
  width?: string;
  minWidth?: string;
  align?: Column['align'];
  dataCy?: string;
}

const THeadCell = ({
  children,
  divider = true,
  topBorder = false,
  sort,
  onClick,
  onMouseEnter,
  onMouseLeave,
  width = 'auto',
  minWidth = 'auto',
  align = 'left',
  dataCy,
}: THeadCellProps) => {
  const { tableTheme, size } = useContext(TableContext);

  return (
    <Styled.Wrapper
      onClick={onClick}
      clickable={!!onClick}
      width={width}
      minWidth={minWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      align={align}
      data-cy={dataCy}
      tableTheme={tableTheme}
      topBorder={topBorder}
    >
      <Styled.ContentWrapper size={size}>{children}</Styled.ContentWrapper>
      {sort && (
        <Styled.SortIcon rotated={sort === 'asc'} hasDivider={divider}>
          <Caret />
        </Styled.SortIcon>
      )}
      {divider && <Styled.Divider tableTheme={tableTheme} />}
    </Styled.Wrapper>
  );
};

export default THeadCell;
