import { useRouter } from 'next/router';
import { FC, memo, useCallback } from 'react';

import { useDeleteProject, useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project } from 'utils/api.types';
import { ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteProjectModalProps {
  projectId: Project['id'];
}

const DeleteProjectModal: FC<DeleteProjectModalProps> = ({ projectId }) => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const { data: project } = useProject({ projectId });
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: handleDeleteProject, isLoading: deleteLoading } =
    useDeleteProject();

  const handleClose = useCallback(() => {
    closeModal('deleteProject');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    const deleted = await handleDeleteProject(projectId);
    if (deleted?.data?.deleted) {
      router.push(ROUTES.HOME);
      handleClose();
    }
  }, [handleDeleteProject, projectId, router, handleClose]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: deleteLoading,
        label: copy.projectsList.deleteProjectModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: deleteLoading,
        label: copy.projectsList.deleteProjectModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteLoading,
        dataCy: 'delete-project__confirm-button',
      }}
      description={`${copy.projectsList.deleteProjectModal.description1}'${project?.name}'${copy.projectsList.deleteProjectModal.description2}`}
      title={copy.projectsList.deleteProjectModal.title}
    />
  );
};

export default memo(DeleteProjectModal);
