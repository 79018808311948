import styled, { css } from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const Percent = styled.div`
  margin-left: 20rem;
  user-select: none;
`;

export const TopHtmlWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40rem;
  color: ${colors.white};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ''}
`;

export const Start = styled.div`
  display: flex;
`;

export const End = styled.div`
  display: flex;
`;
