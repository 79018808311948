import { useState } from 'react';

import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useCurrentProjectVersion } from 'queries/projectRows';

export const useBurnSlider = () => {
  let minBurn = Infinity;
  let maxBurn = 0;
  const { data: projectVersion } = useCurrentProjectVersion();
  const queryString = useParseProjectQueryString();

  projectVersion?.rows.forEach(row => {
    const burn = row.burn?.value ?? 0;
    if (burn < minBurn) {
      minBurn = Math.floor(burn);
    }
    if (burn > maxBurn) {
      maxBurn = Math.ceil(burn);
    }
  });

  const [isBurnDirty, setIsBurnDirty] = useState(false);
  const [burn, setBurn] = useState([
    queryString.minBurn && queryString.minBurn >= minBurn
      ? queryString.minBurn
      : minBurn,
    queryString.maxBurn && queryString.maxBurn <= maxBurn
      ? queryString.maxBurn
      : maxBurn,
  ]);

  const onBurnChange: typeof setBurn = (...args) => {
    setIsBurnDirty(true);
    setBurn(...args);
  };

  return {
    burn,
    setBurn: onBurnChange,
    minBurn,
    maxBurn,
    isBurnDirty,
  };
};
