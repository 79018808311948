import axios, { AxiosResponse } from 'axios';

export const processJwt = ({ jwt }: { jwt: string }) => {
  return axios.post('/api/processJwt', { jwt });
};

export const uploadFile = ({
  file,
}: {
  file: File;
}): Promise<AxiosResponse<{ fileUrl: string }>> => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/api/storageUpload', formData);
};
