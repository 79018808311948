import { FC, memo } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface ConfirmMainVersionChangeModalProps {}

const ConfirmMainVersionChangeModal: FC<
  ConfirmMainVersionChangeModalProps
> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const close = () => {
    closeModal('confirmMainVersionChange');
  };
  const currentProject = useCurrentProject();
  const { mutateAsync: updateProject, isLoading } = useUpdateProject();
  const currentVersionId = useCurrentVersionId();

  const changeMainVersion = async () => {
    if (currentProject?.id && currentVersionId) {
      await updateProject({
        projectId: currentProject.id,
        project: {
          main_version_id: currentVersionId,
        },
      });
      close();
    }
  };

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: copy.app.confirmMainVersionChangeModal.cancelButton,
        color: ColorNames.nandor,
        icon: 'close',
        onClick: close,
      }}
      confirmButtonProps={{
        label: copy.app.confirmMainVersionChangeModal.confirmButton,
        color: currentColor,
        icon: 'check',
        disabled: isLoading,
        loading: isLoading,
        onClick: changeMainVersion,
        dataCy: 'confirm-main-version-change__confirm-button',
      }}
      title={copy.app.confirmMainVersionChangeModal.title}
      description={copy.app.confirmMainVersionChangeModal.description}
    />
  );
};

export default memo(ConfirmMainVersionChangeModal);
