import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { ProjectAccessRequestNotification as ProjectAccessRequestNotificationType } from 'utils/api.types';
import { getProjectRoute } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface ProjectAccessRequestNotificationProps {
  notification: ProjectAccessRequestNotificationType;
}

const ProjectAccessRequestNotification: FC<
  ProjectAccessRequestNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.projectAccessRequest}
        replacements={[
          [
            '{user}',
            `${notification.extra_info.fullName} (${notification.extra_info.username})`,
          ],
          [
            '{project}',
            <Link
              key={'{project}'}
              href={getProjectRoute(notification.extra_info.projectId)}
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {notification.extra_info.projectName}
              </Styled.NotificationInlineButton>
            </Link>,
          ],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(ProjectAccessRequestNotification);
