import { FC } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useGetColumnById } from 'components/Table/hooks/useGetColumnById';
import { useGetColumnWidth } from 'components/Table/hooks/useGetColumnWidth';
import Row from 'components/Table/Row/Row';
import TBodyCell from 'components/Table/TBodyCell/TBodyCell';
import { useCopyStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ReconcileItem } from 'utils/api.types';
import { formatMoney } from 'utils/formatters';
import { replaceString } from 'utils/replace';

import { ColumnId, useColumns } from '../hooks/useColumns';
import { useCounters } from '../hooks/useCounters';
import { useGetColorMatch } from '../hooks/useGetColorMatch';
import ResourceDropdown, {
  ResourceDropdownProps,
} from '../ResourceDropdown/ResourceDropdown';
import RowDropdown, { RowDropdownProps } from '../RowDropdown/RowDropdown';

import * as Styled from '../ReconcileModal.styles';

export interface ReconcileGroupItemProps {
  groupKey: string;
  toggleExpand: (key: ReconcileGroupItemProps['groupKey']) => void;
  isExpanded: boolean;
  items: ReconcileItem[];
  handleResourceSelected: ResourceDropdownProps['onResourceSelected'];
  handleResetResourceSelected: ResourceDropdownProps['onResetResourceSelected'];
  handleRowSelected: RowDropdownProps['onRowSelected'];
  isExpenseItem: boolean;
  overspentRowIds: number[];
  crossItemGroupOverspendRowIds: number[];
}

const ReconcileGroupItem: FC<ReconcileGroupItemProps> = ({
  toggleExpand,
  groupKey,
  isExpanded,
  items,
  handleResetResourceSelected,
  handleResourceSelected,
  handleRowSelected,
  isExpenseItem,
  overspentRowIds,
  crossItemGroupOverspendRowIds,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { columns, rowDropdownTotalFlex } = useColumns({ type: 'JAS' });
  const { getColumnById } = useGetColumnById(columns);
  const { getColumnWidth } = useGetColumnWidth();
  const { SvgIcon: LongArrowright } = useSvgIcon('transition_arrow_right');
  const { SvgIcon: CaretIcon } = useSvgIcon('caret');
  const { SvgIcon: IndicatorIcon } = useSvgIcon('indicator');

  const { getMatchTypeBulk, getMatchColorByType } = useGetColorMatch({
    overspentRowIds,
  });

  const counters = useCounters({ items, overspentRowIds });

  const matchType = getMatchTypeBulk(items);
  const matchColor = getMatchColorByType(matchType);

  const groupTotal = items.reduce(
    (prev, { jas_amount }) => prev + jas_amount,
    0
  );

  return (
    <Row background={matchColor.secondary} hover={false} border>
      <Styled.GroupRowHeight />
      <TBodyCell
        align={getColumnById(ColumnId.id).align || 'left'}
        width={getColumnWidth(getColumnById(ColumnId.id).flex, columns)}
        background={matchColor.primary}
      >
        {counters.greenMatchCount}/{counters.total}
      </TBodyCell>
      <TBodyCell
        align={getColumnById(ColumnId.accItem).align || 'left'}
        width={getColumnWidth(getColumnById(ColumnId.accItem).flex, columns)}
      >
        <OverflowEllipsis
          text={
            isExpenseItem
              ? replaceString(
                  copy.app.reconcileSuccessModal.expenseGroupKey,
                  '{name}',
                  groupKey
                )
              : groupKey
          }
        />
      </TBodyCell>
      <TBodyCell
        align={getColumnById(ColumnId.description).align || 'left'}
        width={getColumnWidth(
          getColumnById(ColumnId.description).flex,
          columns
        )}
      >
        <Styled.ExpandButton onClick={() => toggleExpand(groupKey)}>
          <Styled.ExpandButtonStartHtml>
            <Styled.ExpandButtonIcon rotated={!isExpanded}>
              <CaretIcon />
            </Styled.ExpandButtonIcon>
          </Styled.ExpandButtonStartHtml>
          <Styled.ExpandButtonText>
            {isExpanded
              ? copy.app.reconcileSuccessModal.collapseButton
              : copy.app.reconcileSuccessModal.expandButton}
          </Styled.ExpandButtonText>
        </Styled.ExpandButton>
      </TBodyCell>
      <TBodyCell
        align={getColumnById(ColumnId.amount).align || 'left'}
        width={getColumnWidth(getColumnById(ColumnId.amount).flex, columns)}
      >
        {formatMoney(groupTotal)}
        {isExpanded && (
          <Styled.IndicatorWrapper>
            <IndicatorIcon />
          </Styled.IndicatorWrapper>
        )}
      </TBodyCell>
      <TBodyCell
        align={getColumnById(ColumnId.divider).align || 'left'}
        width={getColumnWidth(getColumnById(ColumnId.divider).flex, columns)}
      >
        <Styled.RightArrowWrapper>
          <LongArrowright />
        </Styled.RightArrowWrapper>
      </TBodyCell>
      <TBodyCell
        align={getColumnById(ColumnId.resource).align || 'left'}
        width={getColumnWidth(getColumnById(ColumnId.resource).flex, columns)}
      >
        <ResourceDropdown
          items={items}
          onResourceSelected={handleResourceSelected}
          onResetResourceSelected={handleResetResourceSelected}
        />
      </TBodyCell>
      <TBodyCell width={getColumnWidth(rowDropdownTotalFlex, columns)}>
        <RowDropdown
          items={items}
          onRowSelected={handleRowSelected}
          crossItemGroupOverspendRowIds={crossItemGroupOverspendRowIds}
        />
      </TBodyCell>
    </Row>
  );
};

export default ReconcileGroupItem;
