import {
  AccordionContentProps,
  AccordionItemProps,
  AccordionMultipleProps,
  AccordionSingleProps,
  AccordionTriggerProps,
} from '@radix-ui/react-accordion';
import { AnimatePresence } from 'framer-motion';
import React, { FC, ForwardedRef } from 'react';

import { useSvgIcon } from 'u9/hooks';

import * as Styled from './Accordion.styles';

export type AccordionProps = (AccordionMultipleProps | AccordionSingleProps) & {
  colorScheme?: Styled.AccordionTheme;
};

export const AccordionRoot: FC<AccordionProps> = ({
  children,
  colorScheme = 'light',
  ...props
}) => {
  return (
    <Styled.Root {...props} $colorScheme={colorScheme}>
      {children}
    </Styled.Root>
  );
};

interface TriggerProps extends AccordionTriggerProps {
  dataCy?: string;
}

export const AccordionTrigger = React.forwardRef(
  (
    { children, dataCy, ...props }: TriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) => {
    const { SvgIcon: CaretIcon } = useSvgIcon('caret');
    return (
      <Styled.Header>
        <Styled.Trigger {...props} ref={forwardedRef} data-cy={dataCy}>
          <Styled.IndicatorWrapper>
            <CaretIcon />
          </Styled.IndicatorWrapper>
          {children}
        </Styled.Trigger>
      </Styled.Header>
    );
  }
);

interface ContentProps extends AccordionContentProps {}

export const AccordionContent = React.forwardRef(
  (
    { children, ...props }: ContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Styled.Content {...props} ref={forwardedRef}>
      <AnimatePresence>{children}</AnimatePresence>
    </Styled.Content>
  )
);

interface ItemProps extends AccordionItemProps {}

export const AccordionItem = React.forwardRef(
  (
    { children, ...props }: ItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <Styled.Item {...props} ref={forwardedRef}>
      {children}
    </Styled.Item>
  )
);

AccordionTrigger.displayName = 'AccordionTrigger';
AccordionContent.displayName = 'AccordionContent';
AccordionItem.displayName = 'AccordionItem';
