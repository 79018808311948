import React, { useState } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCurrentProject } from 'queries/project';
import { useCopyStore } from 'store';
import { PaymentStatuses } from 'utils/api.types';

import * as Styled from './PaymentStatusDropdown.styles';

interface PaymentStatusDropdownProps {
  onChange?: (status: PaymentStatuses) => void;
  status?: PaymentStatuses;
  isDateFilled: boolean;
  isInvoiceNumberFilled: boolean;
}

const PaymentStatusDropdown = ({
  status = PaymentStatuses.not_invoiced,
  onChange = () => null,
  isDateFilled,
  isInvoiceNumberFilled,
}: PaymentStatusDropdownProps) => {
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const { getCanEditProject } = useProjectAccess();

  const isProjectEditDisabled = !getCanEditProject(currentProject);

  const statusItems = [
    {
      id: PaymentStatuses.not_invoiced,
      displayName: copy.project.paymentStatuses.notInvoiced,
    },
    {
      id: PaymentStatuses.invoiced,
      displayName: copy.project.paymentStatuses.invoiced,
    },
    {
      id: PaymentStatuses.received,
      displayName: copy.project.paymentStatuses.received,
    },
  ];

  const [paymentStatusId, setPaymentStatusId] = useState(status);

  const handleDropdownClick = (id: PaymentStatuses) => {
    // Status other than not_invoiced have 'date' as required field
    // If date is not provided for those statuses then we don't update UI
    // We only notify parent component with onChange(id)
    if (
      (isDateFilled && isInvoiceNumberFilled) ||
      id === PaymentStatuses.not_invoiced
    ) {
      setPaymentStatusId(id);
    }

    onChange(id);
  };

  return (
    <Styled.Wrapper>
      <Select
        value={String(paymentStatusId)}
        onValueChange={value => handleDropdownClick(Number(value))}
        disabled={isProjectEditDisabled}
        styleVariant="table"
        dataCy="budget-modal__payment-status"
      >
        {statusItems.map(item => (
          <SelectItem
            key={item.id}
            value={String(item.id)}
            dataCy={`budget-modal__menu-item-${String(item.id)}`}
          >
            {item.displayName}
          </SelectItem>
        ))}
      </Select>
    </Styled.Wrapper>
  );
};

export default React.memo(PaymentStatusDropdown);
