import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Resource } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface ResourceDropdownProps {
  resourceIds: Resource['id'][];
  setResourceIds: Dispatch<SetStateAction<Resource['id'][]>>;
}

const ResourceDropdown: FC<ResourceDropdownProps> = ({
  resourceIds,
  setResourceIds,
}) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);

  const allResourceItems = currentProjectVersion?.rows
    ?.map(row => row?.resource)
    .flatMap(v => (v ? [v] : []));

  const uniqueResourceIds = Array.from(
    new Set(allResourceItems?.map(resource => resource.id))
  );
  const items = uniqueResourceIds
    ?.map(id => allResourceItems?.find(item => item.id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: Resource['id']) => {
    if (resourceIds.includes(activeId)) {
      setResourceIds(resourceIds.filter(prevItem => prevItem !== activeId));
    } else {
      setResourceIds([...resourceIds, activeId]);
    }
  };

  const label = resourceIds?.map(
    id => items?.find(item => item.id === id)?.name
  );

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectFiltersModal.resourceDropdown}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items?.map(({ id, name }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={resourceIds?.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleActive(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(ResourceDropdown);
