import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8rem;
  ${({ theme: locale }) => setTypography('body3', locale)}
  color: ${rgba(colors.white, 0.5)};
  white-space: nowrap;
`;
