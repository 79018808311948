import { FC, memo, useCallback } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { PerformanceFiltersModalProps } from 'components/modals/PerformanceFiltersModal/PerformanceFiltersModal';
import { useCopyStore, useGlobalStore } from 'store';

export interface PerformanceFilterButtonProps {}

const PerformanceFilterButton: FC<PerformanceFilterButtonProps> = () => {
  const openModal = useGlobalStore(s => s.openModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);

  const handleOpenPerformanceDashboardFilters = useCallback(() => {
    openModal<PerformanceFiltersModalProps>('performanceFiltersModal');
  }, [openModal]);

  return (
    <Button
      dataCy="performance-dashboard-filters-button"
      label={copy.performanceDashboard.filterButton}
      color={currentColor}
      onClick={handleOpenPerformanceDashboardFilters}
      icon="filter"
      style={{
        maxHeight: '80rem',
      }}
    />
  );
};

export default memo(PerformanceFilterButton);
