import { useCallback, useState } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import {
  useCurrentProjectBudgetEntries,
  useUpdateProjectBudgetEntry,
} from 'queries/budgetEntries';
import { useCurrentProject } from 'queries/project';
import { getErrorCode } from 'utils/api.helpers';

export const useSaveBudgetEntry = () => {
  const [errorType, setErrorType] = useState<unknown>();
  const currentProject = useCurrentProject();
  const { commonErrorHandler } = useCommonErrorHandling();

  const currentVersionId = useCurrentVersionId();

  const { data } = useCurrentProjectBudgetEntries();

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: ['fx.error', 'fx.runtime_error'],
        localHandler(error) {
          if (getErrorCode(error) === 'fx.error') {
            setErrorType('notSupported');
          } else if (getErrorCode(error) === 'fx.runtime_error') {
            setErrorType('tryAgain');
          }
        },
      });
    },
    [commonErrorHandler]
  );

  const { mutateAsync: updateProjectEntry } = useUpdateProjectBudgetEntry({
    onError: handleError,
  });

  const saveBudgetEntry = useCallback(
    (index?: number) => () => {
      const entry = index !== undefined && data?.[index];
      if (entry && currentProject?.id && currentVersionId) {
        const payload = {
          projectId: currentProject?.id,
          projectVersionId: currentVersionId,
          budgetEntry: {
            invoice_amount: Number(entry.invoice_amount),
            label: entry.label,
            terms: entry.terms,
            ...(entry.date ? { date: entry.date } : {}),
            invoice_currency: entry.invoice_currency,
            payment_status: entry.payment_status,
            po_number: entry.po_number,
            pos: Number(entry.row_no),
            invoice_number: entry.invoice_number,
          },
        };
        try {
          updateProjectEntry(payload);
        } catch (error) {}
      }
    },
    [currentProject?.id, currentVersionId, data, updateProjectEntry]
  );

  return {
    saveBudgetEntry,
    errorType,
  };
};
