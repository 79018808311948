import * as LabelPrimitive from '@radix-ui/react-label';
import * as Switch from '@radix-ui/react-switch';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { switchLabelPlacement } from './Switch';

const transitionTime = '0.1s';

export type SwitchTheme = 'light' | 'dark';

const itemColors: Record<
  SwitchTheme,
  {
    background: string;
    backgroundChecked: string;
    thumbChecked: string;
    label: string;
    labelHover: string;
  }
> = {
  light: {
    background: colors.gallery,
    backgroundChecked: colors.silver,
    thumbChecked: colors.white,
    label: rgba(colors.outerSpace, 0.8),
    labelHover: rgba(colors.outerSpace, 1),
  },
  dark: {
    background: colors.doveGray,
    backgroundChecked: colors.white,
    thumbChecked: colors.black,
    label: rgba(colors.white, 0.5),
    labelHover: rgba(colors.white, 0.35),
  },
};

export const Root = styled(Switch.Root)`
  position: relative;
  width: 120rem;
  height: 60rem;
  background-color: var(--background-color);
  display: flex;
  border-radius: 40rem;
  cursor: pointer;
  transition: background-color ${transitionTime};

  &:focus-visible {
    ${focusVisible};
  }

  &[data-disabled] {
    filter: brightness(0.7);
  }

  &[data-state='checked'] {
    transition: background-color ${transitionTime};
    background-color: var(--background-color-checked);
  }
`;

export const Thumb = styled(Switch.Thumb)`
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background-color: var(--background-color-checked);
  border: 20rem solid var(--background-color);
  transform: translateX(0);
  transition: background-color ${transitionTime}, border ${transitionTime},
    transform ${transitionTime};

  &[data-state='checked'] {
    border: 20rem solid var(--background-color-checked);
    background-color: var(--thumb-color-checked);
    transform: translateX(100%);
  }
`;

export const Label = styled.div<{
  labelPlacement: typeof switchLabelPlacement[number];
}>`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  transition: color ${transitionTime};
  color: var(--label-color);

  ${({ labelPlacement }) =>
    labelPlacement === 'top'
      ? css`
          margin-bottom: 25rem;
        `
      : ''}

  ${({ labelPlacement }) =>
    labelPlacement === 'right'
      ? css`
          margin-left: 25rem;
        `
      : ''}

${({ labelPlacement }) =>
    labelPlacement === 'left'
      ? css`
          margin-right: 25rem;
        `
      : ''}
`;

export const Wrapper = styled(LabelPrimitive.Root)<{
  $labelPlacement: typeof switchLabelPlacement[number];
  $colorTheme: SwitchTheme;
}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: filter ${transitionTime};

  &:hover {
    filter: brightness(0.85);

    ${Label} {
      color: var(--label-color-hover);
    }
  }

  ${({ $colorTheme }) => css`
    --background-color: ${itemColors[$colorTheme].background};
    --background-color-checked: ${itemColors[$colorTheme].backgroundChecked};
    --label-color: ${itemColors[$colorTheme].label};
    --thumb-color-checked: ${itemColors[$colorTheme].thumbChecked};
    --label-color-hover: ${itemColors[$colorTheme].labelHover};
  `}

  ${({ $labelPlacement }) =>
    $labelPlacement === 'top'
      ? css`
          align-items: center;
          flex-direction: column;
        `
      : ''}

  ${({ $labelPlacement }) =>
    $labelPlacement === 'left'
      ? css`
          flex-direction: row;
        `
      : ''}
`;
