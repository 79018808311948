import { ChangeEvent, useCallback, useMemo } from 'react';

import { UploadFileTypes, useUploadFile } from 'hooks/useUploadFile';
import { useCurrentProject, useReconcile } from 'queries/project';
import { fileToBase64 } from 'utils/file';

export const useHandleUploadJas = () => {
  const currentProject = useCurrentProject();
  const { mutateAsync: reconcile } = useReconcile();

  const onFileSelected = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files?.[0];
      if (file && currentProject) {
        const fileBase64 = await fileToBase64(file);
        await reconcile({
          project_id: currentProject?.id,
          base64: fileBase64,
          report_type: 'jas_report',
        });
      }
    },
    [currentProject, reconcile]
  );

  const accept = useMemo(() => [UploadFileTypes.xlsx, UploadFileTypes.xls], []);

  const { openUploadPopup } = useUploadFile({
    onFileSelected,
    accept: accept,
  });

  return { onHandleUploadJas: openUploadPopup };
};
