import { useRouter } from 'next/router';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import {
  basicPrefix,
  mfrPrefix,
} from 'components/modals/ProjectFiltersModal/ResourceTypeDropdown/ResourceTypeDropdown';
import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useApiStore, useCopyStore } from 'store';
import { Project, ProjectVersion, ResourceType } from 'utils/api.types';

import { PROJECTS_KEY } from './project';
import { useSupportedMfrRate } from './supportedMfrRates';

export const useAddProjectRows = () => {
  const addProjectRows = useApiStore(s => s.apiClient.addProjectRows);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addProjectRows>[0]) =>
      addProjectRows(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          PROJECTS_KEY,
          String(variables.projectId),
          String(variables.versionId),
        ]);
      },
    }
  );
};

export const useUpdateProjectRows = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProjectRows>>,
    unknown,
    Parameters<typeof updateProjectRows>[0]
  >
) => {
  const updateProjectRows = useApiStore(s => s.apiClient.updateProjectRows);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateProjectRows>[0]) =>
      updateProjectRows(variables),
    {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries([
          PROJECTS_KEY,
          String(variables.projectId),
          String(variables.versionId),
        ]);
      },
      ...options,
    }
  );
};

export const useDeleteProjectRow = () => {
  const deleteProjectRow = useApiStore(s => s.apiClient.deleteProjectRow);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteProjectRow>[0]) =>
      deleteProjectRow(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          PROJECTS_KEY,
          String(variables.projectId),
          String(variables.versionId),
        ]);
      },
    }
  );
};

export const useProjectVersion = (
  variables: {
    projectId: Project['id'] | undefined;
    projectVersionId: ProjectVersion['id'] | undefined;
  },
  options?: UseQueryOptions<ProjectVersion>
) => {
  const getProjectVersion = useApiStore(s => s.apiClient.getProjectVersion);
  return useQuery<ProjectVersion>(
    [
      PROJECTS_KEY,
      String(variables?.projectId),
      String(variables?.projectVersionId),
    ],
    async () => {
      if (
        variables?.projectId === undefined ||
        variables?.projectVersionId === undefined
      ) {
        return Promise.reject(
          new Error('projectId or projectVersionId is undefined')
        );
      }
      return (
        await getProjectVersion({
          projectId: variables?.projectId,
          projectVersionId: variables?.projectVersionId,
        })
      ).data;
    },
    options
  );
};

export const useCurrentProjectVersion = () => {
  const router = useRouter();
  const projectId = router.query.projectSlug?.[0];
  const projectVersionId = router.query.projectSlug?.[1];

  return useProjectVersion(
    {
      projectId: Number(projectId),
      projectVersionId: Number(projectVersionId),
    },
    {
      enabled: !!projectId && !!projectVersionId,
    }
  );
};

export const useCurrentProjectVersionFiltered = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const projectId = router.query.projectSlug?.[0];
  const projectVersionId = router.query.projectSlug?.[1];
  const { data: supportedMfrRates } = useSupportedMfrRate();

  const {
    // maxAllocation,
    maxBurn,
    maxQuantity,
    maxRate,
    maxTotal,
    // minAllocation,
    minBurn,
    minQuantity,
    minRate,
    minTotal,
    resourceIds,
    resourceTypes,
    roleIds,
    search,
  } = useParseProjectQueryString();

  return useProjectVersion(
    {
      projectId: Number(projectId),
      projectVersionId: Number(projectVersionId),
    },
    {
      enabled: !!projectId && !!projectVersionId,
      select: data => {
        const filteredRows = data.rows.filter(row => {
          if (
            roleIds?.length &&
            roleIds.length > 0 &&
            (!row.role_id || !roleIds.includes(row.role_id))
          ) {
            return false;
          }
          if (resourceTypes?.length && resourceTypes.length > 0) {
            const notResourceTypeMatch = resourceTypes.some(resourceType => {
              if (resourceType.includes(basicPrefix)) {
                const typeId = Number(resourceType.split('-')[1]);
                return row.resource_type === typeId;
              } else if (resourceType.includes(mfrPrefix)) {
                const mfrId = Number(resourceType.split('-')[1]);
                return row?.resource?.mfr_id === mfrId;
              }
            });
            if (notResourceTypeMatch) {
              return false;
            }
          }
          if (
            resourceIds?.length &&
            resourceIds.length > 0 &&
            (!row?.resource?.id || !resourceIds.includes(row.resource.id))
          ) {
            return false;
          }
          // if (
          //   minAllocation !== undefined &&
          //   row.allocation < minAllocation
          // ) {
          //   return false;
          // }
          // if (
          //   maxAllocation !== undefined &&
          //   row.allocation > maxAllocation
          // ) {
          //   return false;
          // }
          if (minQuantity !== undefined && row.quantity < minQuantity) {
            return false;
          }
          if (maxQuantity !== undefined && row.quantity > maxQuantity) {
            return false;
          }
          if (minRate !== undefined && row.rate < minRate) {
            return false;
          }
          if (maxRate !== undefined && row.rate > maxRate) {
            return false;
          }
          if (minTotal !== undefined && row.total < minTotal) {
            return false;
          }
          if (maxTotal !== undefined && row.total > maxTotal) {
            return false;
          }
          if (
            minBurn !== undefined &&
            (row?.burn?.value === undefined || row.burn.value < minBurn)
          ) {
            return false;
          }
          if (
            maxBurn !== undefined &&
            (row?.burn?.value === undefined || row.burn.value > maxBurn)
          ) {
            return false;
          }
          if (search !== undefined) {
            const searchValue = String(search).toLowerCase();
            const isResourceMatch =
              String(row?.resource?.name ?? '')
                .toLowerCase()
                .includes(searchValue) ||
              String(row?.resource?.email ?? '')
                .toLowerCase()
                .includes(searchValue);
            const isRoleMatch = String(row?.role_name ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isSpacerMatch = String(row?.spacer_name ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isQuantityMatch = String(row?.quantity ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isRateMatch = String(row?.rate ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isTotalMatch = String(row?.total ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isBurnMatch = String(row?.burn?.value ?? '')
              .toLowerCase()
              .includes(searchValue);
            const isMarkupMatch = String(row?.markup ?? '')
              .toLowerCase()
              .includes(searchValue);

            const resourceTypeMap = {
              [ResourceType.internal]: copy.app.territory.internal,
              [ResourceType.external]: copy.app.territory.external,
              [ResourceType.mfr]: `${copy.app.territoryShort.mfr} ${
                supportedMfrRates?.find(({ id }) => id === row?.mfr_id)?.code ??
                ''
              } `,
            };
            const isTypeMatch =
              row?.resource_type !== undefined &&
              resourceTypeMap[row?.resource_type]
                .toLowerCase()
                .includes(searchValue);

            if (
              !isResourceMatch &&
              !isRoleMatch &&
              !isSpacerMatch &&
              !isTypeMatch &&
              !isQuantityMatch &&
              !isRateMatch &&
              !isTotalMatch &&
              !isBurnMatch &&
              !isMarkupMatch
            ) {
              return false;
            }
          }
          return true;
        });
        return {
          ...data,
          rows: filteredRows,
        };
      },
    }
  );
};
