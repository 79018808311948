import { Dispatch, memo, SetStateAction, useMemo } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useVerticals } from 'queries/verticals';
import { useCopyStore } from 'store';
import {
  Project,
  ProjectStatus,
  Vertical as VerticalType,
} from 'utils/api.types';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface VerticalProps {
  projectId?: Project['id'];
  activeVerticalId?: VerticalType['id'];
  setActiveVerticalId: Dispatch<SetStateAction<VerticalType['id'] | undefined>>;
}

export const Vertical = ({
  projectId,
  activeVerticalId,
  setActiveVerticalId,
}: VerticalProps) => {
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { data: verticals } = useVerticals();

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.vertical?.closed &&
    !activeVerticalId;

  const modalCopy = copy.app.projectModal;

  const { getCanEditVertical } = useProjectAccess();

  const verticalItems = useMemo(
    () =>
      sort(
        verticals?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [verticals, locale]
  );

  return (
    <Styled.FormDropdownWrapper>
      <Select
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.vertical}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.verticalClosedWarningTitle}
                text={modalCopy.verticalClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
        required
        value={activeVerticalId ? String(activeVerticalId) : undefined}
        onValueChange={v => setActiveVerticalId(Number(v))}
        disabled={project && !getCanEditVertical(project)}
        placeholder={displayClosedWarning ? project.vertical.name : undefined}
        dataCy="project-modal__vertical-dropdown"
      >
        {verticalItems.map(({ displayName, id }) => (
          <SelectItem key={id} value={String(id)} dataCy={`${id}__menu-item`}>
            {displayName}
          </SelectItem>
        ))}
      </Select>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Vertical);
