import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FC, memo, useMemo } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import {
  DefaultTableHeader,
  Table,
  TableCell,
  TableRow,
  TBody,
  THead,
  THeadCellContent,
} from 'components/ShadTable/Table';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { usePayments } from 'queries/payments';
import { useCurrentProject } from 'queries/project';
import { useCopyStore } from 'store';
import { Payment } from 'utils/api.types';
import { formatDateInline } from 'utils/formatters';

import * as Styled from './Payments.styles';

export interface PaymentsProps {}

const Payments: FC<PaymentsProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const { formatMoney } = useFormatMoney();
  const currentProject = useCurrentProject();
  const { data: payments } = usePayments(
    { projectId: currentProject?.id },
    { enabled: !!currentProject?.id }
  );

  const columns = useMemo<ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: 'reference',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.project.budgetModal.payments.invoiceNumber}
          />
        ),
        cell: props => (
          <OverflowEllipsis text={props.getValue<Payment['reference']>()} />
        ),
      },
      {
        accessorKey: 'amount',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.project.budgetModal.payments.amount}
            textAlign="right"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={formatMoney(props.getValue<Payment['amount']>())}
            styles={{ textAlign: 'right' }}
          />
        ),
      },
      {
        accessorKey: 'date',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.project.budgetModal.payments.date}
            textAlign="right"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={formatDateInline(props.getValue<Payment['date']>())}
            styles={{ textAlign: 'right' }}
          />
        ),
      },
    ],
    [copy, formatMoney]
  );

  const table = useReactTable({
    columns,
    data: payments ?? [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Styled.Wrapper>
      <Styled.Description>
        {copy.project.budgetModal.payments.description}
      </Styled.Description>
      <Table>
        <THead>
          <DefaultTableHeader table={table} />
        </THead>
        <TBody>
          {table.getRowModel().rows?.length ? (
            <>
              {table.getRowModel().rows.map(row => (
                <TableRow key={row.id} style={{ height: '100rem' }}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      isSelectColumn={
                        cell.column.columnDef.meta?.isSelectColumn
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllColumns().length}
                style={{ textAlign: 'center' }}
              >
                {copy.app.tableEmpty}
              </TableCell>
            </TableRow>
          )}
        </TBody>
      </Table>
    </Styled.Wrapper>
  );
};

export default memo(Payments);
