import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { fixedPageButton, focusVisible, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const headerHeight = '140rem';
export const navigationWidth = '500rem';
export const collapsedNavigationWidth = '80rem';

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Navigation = styled.nav<{ isCollapsed: boolean }>`
  width: ${({ isCollapsed }) =>
    isCollapsed ? collapsedNavigationWidth : navigationWidth};
  height: ${setVh(100)};
  background: ${colors.white};
  border-right: 1px solid ${colors.mercury};
  position: relative;
  transition: width 150ms;
`;

export const SiteWrapper = styled.div<{ isNavbarCollapsed: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.grayNurse};
  height: ${setVh(100)};
  max-height: ${setVh(100)};
  overflow: hidden;
  transition: max-width 150ms;

  max-width: ${({ isNavbarCollapsed }) =>
    isNavbarCollapsed
      ? `calc(100vw - ${collapsedNavigationWidth})`
      : `calc(100vw - ${navigationWidth})`};
`;

export const Header = styled.header`
  height: 140rem;
  min-height: 140rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.mercury};
`;

export const Content = styled.main`
  flex: 1;
  height: 100%;
`;

export const HeaderStart = styled.div`
  margin-left: 32rem;
`;

export const HeaderEnd = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const PageHeaderWrapper = styled.div`
  flex: 1;
  gap: 30rem;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const NotificationsWrapper = styled.div`
  margin-right: 20rem;
`;

export const MenuWrapper = styled.div`
  margin-right: 26rem;
`;

export const SearchWrapper = styled.div`
  width: 850rem;
`;

export const PerformanceFilterButton = styled.div``;

export const WorkspaceSettingsButton = styled.div`
  ${fixedPageButton()}
`;

export const CollapseButtonWrapper = styled.button<{
  isCollapsed: boolean;
}>`
  width: 50rem;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.outerSpace};
  transition: background 100ms;
  border-radius: 50%;
  margin: 10rem;

  &:hover {
    background: ${rgba(colors.outerSpace, 0.1)};
  }

  &:focus-visible {
    ${focusVisible};
  }
`;

export const ArrowIconWrapper = styled.div<{ isCollapsed: boolean }>`
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms;

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          transform: rotate(180deg);
        `
      : ''}
`;

export const ProjectDropdown = styled.div``;
