import { FC, memo } from 'react';

import Switch from 'components/Switch/Switch';
import { useCopyStore } from 'store';
import { Project } from 'utils/api.types';

import { useCanEditRndFields } from '../../hooks/useCanEditRndFields';
import { useRndExcludeField } from '../../hooks/useRndExcludeField';

export interface RndExcludeProps {
  rndExcludeField: ReturnType<typeof useRndExcludeField>;
  project?: Project;
}

const RndExclude: FC<RndExcludeProps> = ({ rndExcludeField, project }) => {
  const { isExcludeRnd, setIsExcludeRnd } = rndExcludeField;
  const copy = useCopyStore(s => s.copy);

  const { canEditRndFields } = useCanEditRndFields({ project });

  return (
    <Switch
      checked={isExcludeRnd}
      onCheckedChange={setIsExcludeRnd}
      label={copy.app.projectModal.rndExcludeLabel}
      labelPlacement="top"
      dataCy="rnd-exclude"
      disabled={!canEditRndFields}
    />
  );
};

export default memo(RndExclude);
