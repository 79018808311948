import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Client } from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';

export const CLIENTS_KEY = 'CLIENTS';

export const useClients = (options?: UseQueryOptions<Client[]>) => {
  const getClients = useApiStore(s => s.apiClient.getClients);
  return useQuery({
    queryKey: CLIENTS_KEY,
    queryFn: async () => (await getClients()).data,
    ...options,
  });
};

export const useAddClient = () => {
  const addClient = useApiStore(s => s.apiClient.addClient);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addClient>[0]) => addClient(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLIENTS_KEY);
      },
    }
  );
};

export const useUpdateClient = () => {
  const updateClient = useApiStore(s => s.apiClient.updateClient);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateClient>[0]) => updateClient(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLIENTS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};

export const useDeleteClient = () => {
  const deleteClient = useApiStore(s => s.apiClient.deleteClient);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteClient>[0]) => deleteClient(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLIENTS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
