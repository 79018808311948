import { FC, Fragment, memo, useEffect, useState } from 'react';

import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import {
  Select,
  SelectItem,
  SelectSeparator,
} from 'components/dropdowns/Select/Select';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import { useCopyStore } from 'store';
import { ReconcileItem } from 'utils/api.types';

import { useDropdownItems } from './hooks/useDropdownItems';

import * as Styled from './RowDropdown.styles';

export interface RowDropdownProps {
  items: ReconcileItem[];
  onRowSelected: (
    items: {
      rowId: number;
      jasId: ReconcileItem['jas_id'];
    }[]
  ) => void;
  crossItemGroupOverspendRowIds: number[];
}

const RowDropdown: FC<RowDropdownProps> = ({
  items,
  onRowSelected,
  crossItemGroupOverspendRowIds,
}) => {
  const copy = useCopyStore(s => s.copy);

  const isAnyRowUnassigned = items.some(({ row_id }) => !row_id);
  const uniqueSelectedRowIds = Array.from(
    new Set(items.map(({ row_id }) => row_id))
  ).filter(Boolean);

  const defaultItemId =
    !isAnyRowUnassigned && uniqueSelectedRowIds.length === 1
      ? uniqueSelectedRowIds[0]
      : undefined;

  const dropdownItems = useDropdownItems();
  const [activeItemId, setActiveItemId] = useState<string | number | undefined>(
    defaultItemId
  );

  const handleDropdownClick = (itemId: number | string) => {
    setActiveItemId(itemId);
    onRowSelected(
      items.map(item => ({
        jasId: item.jas_id,
        rowId: itemId === 'AUTO_MATCH' ? 0 : Number(itemId),
      }))
    );
  };

  useEffect(() => {
    if (!isAnyRowUnassigned && uniqueSelectedRowIds.length === 1) {
      setActiveItemId(uniqueSelectedRowIds[0]);
    } else {
      setActiveItemId(undefined);
    }
  }, [isAnyRowUnassigned, setActiveItemId, uniqueSelectedRowIds]);

  const isCrossItemGroupOverspend = crossItemGroupOverspendRowIds.find(
    rowId => {
      return items.map(({ row_id }) => row_id).includes(rowId);
    }
  );

  const isMixed =
    uniqueSelectedRowIds.length > 1 ||
    (isAnyRowUnassigned && uniqueSelectedRowIds.length === 1);

  return (
    <Styled.Wrapper key={activeItemId}>
      {isCrossItemGroupOverspend && (
        <Styled.WarningWrapper>
          <CardDropdown
            cardProps={{
              text: copy.app.reconcileSuccessModal
                .crossGroupOverspendDescription,
              title: copy.app.reconcileSuccessModal.crossGroupOverspendTitle,
              type: 'error',
              cardStyles: { background: 'black' },
            }}
            button={<ErrorIcon />}
            clickType="hover"
          />
        </Styled.WarningWrapper>
      )}
      <Select
        value={activeItemId ? String(activeItemId) : undefined}
        onValueChange={value => handleDropdownClick(Number(value))}
        styleVariant="table"
        placeholder={isMixed ? copy.app.reconcileSuccessModal.mixed : ''}
      >
        {dropdownItems.map(({ displayName, id, divider, groupItem }) => (
          <Fragment key={id}>
            <SelectItem value={String(id)} disabled={groupItem}>
              {displayName}
            </SelectItem>

            {divider && <SelectSeparator />}
          </Fragment>
        ))}
      </Select>
    </Styled.Wrapper>
  );
};

export default memo(RowDropdown);
