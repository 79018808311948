import {
  DEFAULT_EXTERNAL_MARKUP,
  DEFAULT_INTERNAL_MARKUP,
  ProjectRow,
  ResourceType,
} from 'utils/api.types';

// Logic behind whether we should do auto update on margin to 0% or 20%
// This doesn't take into account simply chaning mfr_id
export const shouldAutoUpdateMargin = (
  row: ProjectRow,
  newResourceType: ProjectRow['resource_type']
) => {
  const resourceTypeChanged = row.resource_type !== newResourceType;

  const hasDefaultMarkup = [
    DEFAULT_INTERNAL_MARKUP,
    DEFAULT_EXTERNAL_MARKUP,
  ].includes(row.markup);

  const shouldSetExternalMarkup =
    newResourceType === ResourceType.external && hasDefaultMarkup;
  const shouldSetInternalMarkup =
    newResourceType !== ResourceType.external && hasDefaultMarkup;

  const markupToSet = shouldSetExternalMarkup
    ? DEFAULT_EXTERNAL_MARKUP
    : shouldSetInternalMarkup
    ? DEFAULT_INTERNAL_MARKUP
    : null;

  const setMarkup = markupToSet !== null;

  return {
    resourceTypeChanged,
    markupToSet,
    setMarkup,
  };
};
