import { FC, memo, useCallback } from 'react';

import { useDeleteVertical } from 'queries/verticals';
import { useCopyStore, useGlobalStore } from 'store';
import { Vertical } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteVerticalModalProps {
  vertical: Vertical;
}

const DeleteVerticalModal: FC<DeleteVerticalModalProps> = ({ vertical }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);

  const {
    mutateAsync: deleteVerticalHandler,
    isLoading: deleteVerticalLoading,
  } = useDeleteVertical();

  const handleClose = useCallback(() => {
    closeModal('deleteVerticalModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    await deleteVerticalHandler(vertical.id);
    handleClose();
  }, [handleClose, deleteVerticalHandler, vertical?.id]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.deleteVerticalModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
        dataCy: 'delete-vertical-confirm',
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteVerticalLoading,
        disabled: deleteVerticalLoading,
      }}
      description={replaceString(description, '{vertical}', vertical?.name)}
      title={title}
    />
  );
};

export default memo(DeleteVerticalModal);
