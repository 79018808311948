import { useCallback } from 'react';

import { useCopyStore } from 'store';
import { formatDateInline } from 'utils/formatters';

export const useGetDefaultComment = () => {
  const copy = useCopyStore(s => s.copy);

  const getDefaultComment = useCallback(() => {
    const date = formatDateInline(new Date());
    return `${copy.project.burnColumn.defaultComment}${date}`;
  }, [copy.project.burnColumn.defaultComment]);

  return { getDefaultComment };
};
