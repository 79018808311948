import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { ClientRequestModalProps } from 'components/modals/ClientRequestModal/ClientRequestModal';
import { useClients } from 'queries/clients';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Client as ClientType, Project, ProjectStatus } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface ClientProps {
  projectId?: Project['id'];
  activeClientId?: ClientType['id'];
  setActiveClientId: Dispatch<SetStateAction<ClientType['id'] | undefined>>;
  canEditProject: boolean;
}

export const Client = ({
  projectId,
  activeClientId,
  setActiveClientId,
  canEditProject,
}: ClientProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const [searchValue, setSearchValue] = useState('');
  const { data: clients } = useClients();

  const { checkAnyAccessRight } = useCheckAccessRights();

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.client?.closed &&
    !activeClientId;

  const canSeeAdminPanel = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  const modalCopy = copy.app.projectModal;

  const items = useMemo(
    () =>
      sort(
        clients?.map(client => ({ id: client.id, displayName: client.name })) ||
          [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [clients, locale]
  );

  const [open, setOpen] = useState(false);

  return (
    <Styled.FormDropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.client}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.clientClosedWarningTitle}
                text={modalCopy.clientClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
      >
        <ComboboxTrigger
          disabled={!canEditProject}
          label={items.find(({ id }) => id === activeClientId)?.displayName}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {searchValue.length > 0 && !canSeeAdminPanel && (
                <ComboboxItem
                  value={searchValue}
                  onSelect={() => {
                    openModal<ClientRequestModalProps>('clientRequest', {
                      clientName: searchValue,
                    });
                    setOpen(false);
                  }}
                >
                  {replaceString(
                    copy.app.comboboxSuggestButton,
                    '{name}',
                    searchValue
                  )}
                </ComboboxItem>
              )}
              {items.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeClientId === id}
                  onSelect={() => {
                    setActiveClientId(prev => (prev === id ? undefined : id));
                    setOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Client);
