import { useRouter } from 'next/router';
import { FC, memo, useCallback } from 'react';

import { useAddProject, useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project } from 'utils/api.types';
import { getProjectRoute } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DuplicateProjectModalProps {
  projectId?: Project['id'];
}

const DuplicateProjectModal: FC<DuplicateProjectModalProps> = ({
  projectId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { data: project } = useProject({ projectId });
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const router = useRouter();

  const { mutateAsync: handleAddProject, isLoading: addLoading } =
    useAddProject();

  const handleClose = useCallback(() => {
    closeModal('duplicateProject');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    const addedProject = await handleAddProject({
      duplicate_id: projectId,
    });
    if (!addedProject?.data) return;
    router.push(
      getProjectRoute(addedProject.data.id, addedProject.data.main_version_id)
    );
  }, [handleAddProject, projectId, router]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: addLoading,
        label: copy.projectsList.duplicateProjectModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: addLoading,
        label: copy.projectsList.duplicateProjectModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: addLoading,
        dataCy: 'duplicate-project__confirm-button',
      }}
      description={`${copy.projectsList.duplicateProjectModal.description}'${project?.name}'`}
      title={copy.projectsList.duplicateProjectModal.title}
    />
  );
};

export default memo(DuplicateProjectModal);
