const emojiRanges = [
  '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
  '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
  '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
];

export const removeEmoji = (text: string) => {
  return text.replace(new RegExp(emojiRanges.join('|'), 'g'), '');
};

export const generateRandomString = (length = 16) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const removeTrailingSlash = (str: string) => {
  return str.replace(/\/+$/, '');
};
