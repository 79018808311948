import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  min-height: 40vh;
  background: ${colors.white};
  border-radius: ${layout.borderRadius.big}rem;
  box-shadow: 10rem 10rem 80rem 0px ${rgba(colors.outerSpace, 0.1)};
  padding: 50rem;
  z-index: 10;
  position: fixed;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
  width: 60rem;
  height: 60rem;
  background: ${rgba(colors.outerSpace, 0.1)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.1s linear;

  ${hover(css`
    background: ${rgba(colors.outerSpace, 0.2)};
  `)}
`;

export const CloseIconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.outerSpace};
`;

export const Title = styled.div`
  ${({ theme: { locale } }) => setTypography('heading3', locale)};
  margin-bottom: 40rem;
`;

export const SearchInputWrapper = styled.div``;

export const List = styled.div`
  max-height: calc(60vh - 175rem);
`;

export const NotificationGroupWrapper = styled.div`
  margin-top: 40rem;
`;

export const NotificationGroupTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40rem;
`;

export const NotificationGroupTitleText = styled.div`
  color: ${rgba(colors.outerSpace, 0.5)};
  ${setTypography('caption')};
`;

export const NotificationGroupTitleLine = styled.div`
  margin-left: 40rem;
  flex: 1;
  height: 1px;
  background: ${rgba(colors.black, 0.05)};
`;

export const NotificationWrapper = styled.div`
  padding-right: 210rem;
  position: relative;
`;

export const NotificationEndWrapper = styled.div`
  position: absolute;
  top: 5rem;
  right: 0;
  width: 190rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NotificationEndWrapperText = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.outerSpace, 0.5)};
`;

export const NotificationEndWrapperIcon = styled.div`
  width: 20rem;
  height: 20rem;
  background: ${colors.blueRibbon};
  border-radius: 50%;
  margin-left: 10rem;
`;

export const SearchBar = styled.div``;

export const NotificationsExpiryLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.outerSpace, 0.5)};
  padding: 60rem 0;
  text-align: center;
`;
