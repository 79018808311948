import { useCopyStore } from 'store';

export const projectIcons = [
  'colored_bell',
  'colored_calendar',
  'colored_checkmark',
  'colored_cool',
  'colored_fire',
  'colored_heart',
  'colored_noentry',
  'colored_ok',
  'colored_warning',
  'colored_wastebasket',
  'colored_world',
  'colored_x',
  'colored_zzz',
] as const;

export const useProjectIcons = () => {
  const copy = useCopyStore(s => s.copy);

  const iconsList: {
    displayName: string;
    icon: typeof projectIcons[number];
  }[] = projectIcons
    .map(icon => ({
      displayName: copy.app.projectIcons[icon],
      icon,
    }))
    // Each icon needs text representative in copy.app.projectIcons.
    .filter(({ displayName }) => !!displayName);

  return iconsList;
};
