import { useRouter } from 'next/router';
import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useCurrentProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Resource, Role } from 'utils/api.types';
import { getProjectRoute } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
// import AllocationSlider from './AllocationSlider/AllocationSlider';
// import { useAllocationSlider } from './AllocationSlider/hooks/useAllocationSlider';
import BurnSlider from './BurnSlider/BurnSlider';
import { useBurnSlider } from './BurnSlider/hooks/useBurnSlider';
import { useQuantitySlider } from './QuantitySlider/hooks/useQuantitySlider';
import QuantitySlider from './QuantitySlider/QuantitySlider';
import { useRateSlider } from './RateSlider/hooks/useRateSlider';
import RateSlider from './RateSlider/RateSlider';
import ResourceDropdown from './ResourceCropdown/ResourceDropdown';
import ResourceTypeDropdown from './ResourceTypeDropdown/ResourceTypeDropdown';
import RoleDropdown from './RoleDropdown/RoleDropdown';
import { useTotalSlider } from './TotalSlider/hooks/useTotalSlider';
import TotalSlider from './TotalSlider/TotalSlider';

import * as Styled from './ProjectFiltersModal.styles';

export interface ProjectFiltersModalProps {}

const ProjectFilters: FC<ProjectFiltersModalProps> = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const queryString = useParseProjectQueryString();

  const [isRolesDirty, setIsRolesDirty] = useState(false);
  const [roleIds, setRoleIds] = useState<Role['role_id'][]>(
    queryString.roleIds ?? []
  );
  const onRoleIdsChange: typeof setRoleIds = (...args) => {
    setIsRolesDirty(true);
    setRoleIds(...args);
  };

  const [isResourceTypesDirty, setIsResourceTypesDirty] = useState(false);
  const [resourceTypes, setResourceTypes] = useState<string[]>(
    queryString.resourceTypes ?? []
  );
  const onResourceTypesChange: typeof setResourceTypes = (...args) => {
    setIsResourceTypesDirty(true);
    setResourceTypes(...args);
  };

  const [isResourceIdsDirty, setIsResourceIdsDirty] = useState(false);
  const [resourceIds, setResourceIds] = useState<Resource['id'][]>(
    queryString.resourceIds ?? []
  );
  const onResourceIdsChange: typeof setResourceIds = (...args) => {
    setIsResourceIdsDirty(true);
    setResourceIds(...args);
  };

  // const {
  //   allocation,
  //   maxAllocation,
  //   minAllocation,
  //   setAllocation,
  //   isAllocationDirty,
  // } = useAllocationSlider();
  const { maxQuantity, minQuantity, quantity, setQuantity, isQuantityDirty } =
    useQuantitySlider();
  const { maxRate, minRate, rate, setRate, isRateDirty } = useRateSlider();
  const { maxTotal, minTotal, setTotal, total, isTotalDirty } =
    useTotalSlider();
  const { burn, maxBurn, minBurn, setBurn, isBurnDirty } = useBurnSlider();

  if (!currentProject) return null;

  const close = () => {
    closeModal('projectFilters');
  };

  const handleResetFilters = () => {
    router.push(
      {
        pathname: getProjectRoute(currentProject.id, currentVersionId),
        query: {
          search: queryString.search,
          cashflow: queryString.cashflow,
        },
      },
      undefined,
      { shallow: true }
    );
    close();
  };

  const handleConfirmFilters = () => {
    router.push(
      {
        pathname: getProjectRoute(currentProject.id, currentVersionId),
        query: {
          ...queryString,
          ...(isRolesDirty && { roleIds: roleIds.join(',') }),
          ...(isResourceTypesDirty && {
            resourceTypes: resourceTypes.join(','),
          }),
          ...(isResourceIdsDirty && { resourceIds: resourceIds.join(',') }),
          // ...(isAllocationDirty && {
          //   minAllocation: allocation[0],
          //   maxAllocation: allocation[1],
          // }),
          ...(isQuantityDirty && {
            minQuantity: quantity[0],
            maxQuantity: quantity[1],
          }),
          ...(isRateDirty && {
            minRate: rate[0],
            maxRate: rate[1],
          }),
          ...(isTotalDirty && {
            minTotal: total[0],
            maxTotal: total[1],
          }),
          ...(isBurnDirty && {
            minBurn: burn[0],
            maxBurn: burn[1],
          }),
          ...(router.query.cashflow === 'true' && { cashflow: 'true' }),
        },
      },
      undefined,
      { shallow: true }
    );
    close();
  };

  return (
    <ConfigModal
      title={copy.app.projectFiltersModal.title}
      color={currentColor}
      onCloseClick={close}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Row>
          <RoleDropdown roleIds={roleIds} setRoleIds={onRoleIdsChange} />
          <ResourceTypeDropdown
            resourceTypes={resourceTypes}
            setResourceTypes={onResourceTypesChange}
          />
        </Styled.Row>
        <Styled.Row>
          <ResourceDropdown
            resourceIds={resourceIds}
            setResourceIds={onResourceIdsChange}
          />
        </Styled.Row>
        <Styled.Row>
          <QuantitySlider
            maxQuantity={maxQuantity}
            minQuantity={minQuantity}
            quantity={quantity}
            setQuantity={setQuantity}
            isQuantityDirty={isQuantityDirty}
          />
          <BurnSlider
            burn={burn}
            maxBurn={maxBurn}
            minBurn={minBurn}
            setBurn={setBurn}
            isBurnDirty={isBurnDirty}
          />
          {/* <AllocationSlider
            allocation={allocation}
            maxAllocation={maxAllocation}
            minAllocation={minAllocation}
            setAllocation={setAllocation}
            isAllocationDirty={isAllocationDirty}
          /> */}
        </Styled.Row>
        <Styled.Row>
          <RateSlider
            maxRate={maxRate}
            minRate={minRate}
            rate={rate}
            setRate={setRate}
            isRateDirty={isRateDirty}
          />
          <TotalSlider
            maxTotal={maxTotal}
            minTotal={minTotal}
            setTotal={setTotal}
            total={total}
            isTotalDirty={isTotalDirty}
          />
        </Styled.Row>

        <Styled.Buttons>
          <Button
            label={copy.app.projectFiltersModal.resetButton}
            color={ColorNames.nandor}
            icon="close"
            onClick={handleResetFilters}
          />
          <Button
            label={copy.app.projectFiltersModal.saveButton}
            icon="check"
            color={currentColor}
            onClick={handleConfirmFilters}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ProjectFilters);
