import React, { useContext } from 'react';

import ScrollArea from 'components/ScrollArea/ScrollArea';
import { StrictModeDroppable } from 'components/StrictModeDroppable/StrictModeDroppable';

import { TableContext } from '../Table';

import * as Styled from './TBody.styles';

interface TBodyProps {
  children: React.ReactNode | React.ReactNode[];
  bottomSafeArea?: boolean;
  topBorderRadius?: boolean;
}

const TBody = ({
  children,
  bottomSafeArea = true,
  topBorderRadius = true,
}: TBodyProps) => {
  const { tableTheme, isDraggable } = useContext(TableContext);

  return (
    <ScrollArea>
      <Styled.Wrapper
        tableTheme={tableTheme}
        bottomSafeArea={bottomSafeArea}
        topBorderRadius={topBorderRadius}
      >
        {isDraggable ? (
          <StrictModeDroppable droppableId="droppableList">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {children}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        ) : (
          children
        )}
      </Styled.Wrapper>
    </ScrollArea>
  );
};

export default TBody;
