import { FC, memo } from 'react';

import FilterCard from 'components/FilterCard/FilterCard';

import { Filter } from './hooks/useFilters';

import * as Styled from './TabTitle.styles';

export interface TabTitleProps {
  text: string;
  filters: Filter[];
}

const TabTitle: FC<TabTitleProps> = ({ text, filters }) => {
  return (
    <Styled.Wrapper>
      <Styled.Text>{text}</Styled.Text>
      <Styled.Filters>
        {filters.map(filter => (
          <FilterCard
            key={filter.id}
            text={filter.text}
            onCloseClick={filter.onCloseClick}
            colorScheme="dark"
          />
        ))}
      </Styled.Filters>
    </Styled.Wrapper>
  );
};

export default memo(TabTitle);
