import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import { FC } from 'react';

import * as Styled from './ToggleGroup.styles';

export type ToggleGroupProps =
  | RadixToggleGroup.ToggleGroupSingleProps
  | RadixToggleGroup.ToggleGroupMultipleProps;

export const ToggleGroup: FC<ToggleGroupProps> = ({ ...props }) => {
  return <Styled.Root {...props} />;
};

export type GroupItemProps = {
  dataCy?: string;
  colorScheme?: Styled.ToggleItemTheme;
} & RadixToggleGroup.ToggleGroupItemProps;

export const ToggleGroupItem: FC<GroupItemProps> = ({
  dataCy,
  colorScheme,
  ...props
}) => {
  return <Styled.Item data-cy={dataCy} $colorScheme={colorScheme} {...props} />;
};
