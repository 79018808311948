import { Dispatch, FC, memo, SetStateAction } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useCopyStore } from 'store';
import { Project } from 'utils/api.types';

import { useCanEditRndFields } from '../../hooks/useCanEditRndFields';

export enum RndCategoryType {
  grant = 'grant',
  game = 'game',
  techResearch = 'techResearch',
  techFirst = 'techFirst',
  techUseCase = 'techUseCase',
  techChallenge = 'techChallenge',
  techServices = 'techServices',
}

export const rndRanges: Record<RndCategoryType, [number, number]> = {
  game: [0, 80],
  grant: [0, 80],
  techResearch: [50, 80],
  techFirst: [40, 70],
  techUseCase: [30, 50],
  techChallenge: [10, 30],
  techServices: [0, 10],
};

export interface RndCategoryProps {
  project?: Project;
  rndCategoryId?: RndCategoryType;
  setRndCategoryId: Dispatch<SetStateAction<RndCategoryType | undefined>>;
}

const RndCategory: FC<RndCategoryProps> = ({
  rndCategoryId,
  setRndCategoryId,
  project,
}) => {
  const copy = useCopyStore(s => s.copy);

  const { canEditRndFields } = useCanEditRndFields({ project });

  const { rndCategories } = copy.app;

  const items = [
    {
      displayName: rndCategories.grant,
      id: RndCategoryType.grant,
    },
    {
      displayName: rndCategories.game,
      id: RndCategoryType.game,
    },
    {
      displayName: rndCategories.techResearch,
      id: RndCategoryType.techResearch,
    },
    {
      displayName: rndCategories.techFirst,
      id: RndCategoryType.techFirst,
    },
    {
      displayName: rndCategories.techUseCase,
      id: RndCategoryType.techUseCase,
    },
    {
      displayName: rndCategories.techChallenge,
      id: RndCategoryType.techChallenge,
    },
    {
      displayName: rndCategories.techServices,
      id: RndCategoryType.techServices,
    },
  ];

  return (
    <Select
      label={copy.app.projectModal.rndCategoryLabel}
      value={rndCategoryId ? String(rndCategoryId) : undefined}
      onValueChange={v =>
        setRndCategoryId(items.find(({ id }) => id === v)?.id)
      }
      disabled={!canEditRndFields}
      dataCy="rnd-category"
    >
      {items.map(({ displayName, id }) => (
        <SelectItem key={id} value={id} dataCy={`${id}__menu-item`}>
          {displayName}
        </SelectItem>
      ))}
    </Select>
  );
};

export default memo(RndCategory);
