import * as SelectPrimitive from '@radix-ui/react-select';
import React, { ForwardedRef } from 'react';

import { useSvgIcon } from 'u9/hooks';

import * as Styled from './Select.styles';

export interface SelectProps extends SelectPrimitive.SelectProps {
  label?: React.ReactNode | React.ReactNode[];
  colorVariant?: Styled.ColorVariants;
  styleVariant?: Styled.StyleVariants;
  placeholder?: string;
  dataCy?: string;
}

export const Select = React.forwardRef(
  (
    {
      children,
      label = null,
      colorVariant = 'dark',
      styleVariant = 'form',
      placeholder,
      dataCy,
      ...props
    }: SelectProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) => {
    const { SvgIcon: CaretIcon } = useSvgIcon('caret_down');

    return (
      <Styled.Wrapper $colorVariant={colorVariant}>
        <Styled.Root {...props} data-cy={dataCy}>
          {label && <Styled.Label>{label}</Styled.Label>}

          <Styled.Trigger
            ref={forwardedRef}
            data-cy={`${dataCy}__button`}
            $styleVariant={styleVariant}
          >
            <Styled.Value placeholder={placeholder || 'Select...'} />
            <Styled.Icon>
              <CaretIcon />
            </Styled.Icon>
          </Styled.Trigger>
          <Styled.Portal>
            <Styled.Content
              position="popper"
              data-cy={`${dataCy}__dropdown`}
              $colorVariant={colorVariant}
              collisionPadding={20}
            >
              <Styled.ScrollUpButton>
                <CaretIcon />
              </Styled.ScrollUpButton>
              <Styled.Viewport>{children}</Styled.Viewport>
              <Styled.ScrollDownButton>
                <CaretIcon />
              </Styled.ScrollDownButton>
            </Styled.Content>
          </Styled.Portal>
        </Styled.Root>
      </Styled.Wrapper>
    );
  }
);
Select.displayName = 'Select';

export interface SelectItemProps extends SelectPrimitive.SelectItemProps {
  dataCy?: string;
}
export const SelectItem = React.forwardRef(
  (
    { children, dataCy, ...props }: SelectItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { SvgIcon: CheckIcon } = useSvgIcon('check');

    return (
      <Styled.Item {...props} ref={forwardedRef} data-cy={dataCy}>
        <Styled.ItemText>{children}</Styled.ItemText>
        <Styled.ItemIndicator>
          <CheckIcon />
        </Styled.ItemIndicator>
      </Styled.Item>
    );
  }
);
SelectItem.displayName = 'SelectItem';

export const SelectSeparator = Styled.Separator;

export const SelectLabel = Styled.SelectLabel;
