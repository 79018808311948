import { FC, memo } from 'react';

import { useSvgIcon } from 'u9/hooks';

import * as Styled from './FilterCard.styles';

export interface FilterCardProps {
  text: string;
  onCloseClick?: (() => void) | null;
  colorScheme?: Styled.FilterCardTheme;
}

const FilterCard: FC<FilterCardProps> = ({
  text,
  onCloseClick,
  colorScheme = 'light',
}) => {
  const { SvgIcon: CloseIcon } = useSvgIcon('close');

  return (
    <Styled.Wrapper colorScheme={colorScheme}>
      <Styled.Text>{text}</Styled.Text>
      {onCloseClick && (
        <Styled.CloseButton onClick={onCloseClick} tabIndex={0}>
          <Styled.IconWrapper>
            <CloseIcon />
          </Styled.IconWrapper>
        </Styled.CloseButton>
      )}
    </Styled.Wrapper>
  );
};

export default memo(FilterCard);
