import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import * as Styled from './CardModal.styles';

export interface CardModalProps {
  type: 'error' | 'networkError';
  title: string;
  text: string;
  subText: string;
  handleCloseClick: () => void;
  buttons: React.ReactNode | React.ReactNode[];
  dataCy?: string;
}

const CardModal = (props: CardModalProps) => {
  return (
    <Dialog.Root defaultOpen open={true}>
      <Dialog.Portal>
        <Styled.Overlay onClick={props.handleCloseClick} />
        <Styled.Content>
          <Styled.Title>{props.title}</Styled.Title>
          <Styled.Text>{props.text}</Styled.Text>
          <Styled.SubText>{props.subText}</Styled.SubText>
          <Styled.Buttons>{props.buttons}</Styled.Buttons>
        </Styled.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CardModal;
