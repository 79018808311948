import { FC, memo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import FormSlider from 'components/inputs/FormSlider/FormSlider';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useProject, useUpdateProjectVersion } from 'queries/project';
import { useProjectFieldLists } from 'queries/projectFieldLists';
import { useProjectVersion } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ResourceType } from 'utils/api.types';
import { replaceString } from 'utils/replace';

import ConfigModal from '../ConfigModal/ConfigModal';
import MfrDiscountPreview from './MfrDiscountPreview/MfrDiscountPreview';

import * as Styled from './MfrDiscountModal.styles';

export interface MfrDiscountModalProps {
  projectId: Project['id'];
}

const MfrDiscountModal: FC<MfrDiscountModalProps> = ({ projectId }) => {
  const { data: currentProject } = useProject({ projectId });
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { data: projectFieldLists } = useProjectFieldLists();
  const currentVersionId = useCurrentVersionId();
  const { data: currentVersion } = useProjectVersion(
    {
      projectId,
      projectVersionId: currentVersionId ?? currentProject?.main_version_id,
    },

    {
      onSuccess(data) {
        setDiscount(
          data.mfr_discount
            ? String(Math.round(data.mfr_discount * 100 * 100) / 100)
            : ''
        );
        setActiveReasonId(data.mfr_discount_reason || '');
      },
    }
  );
  const { mutateAsync: updateProjectVersion, isLoading } =
    useUpdateProjectVersion();
  const [discount, setDiscount] = useState(
    String(
      currentVersion?.mfr_discount
        ? Math.round(currentVersion?.mfr_discount * 100 * 100) / 100
        : ''
    )
  );
  const currentRows = currentVersion?.rows ?? [];

  const handleDiscountChange = (value: string) => {
    setDiscount(value);
  };

  const handleDiscountBlur = () => {
    setDiscount(prev => {
      return String(Math.max(Math.min(Number(prev), 100), 0));
    });
  };

  const items =
    projectFieldLists?.mfr_discount_reason?.map(reason => {
      return { displayName: reason, id: reason };
    }) || [];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeReasonId, setActiveReasonId] = useState<string | null>(
    currentVersion?.mfr_discount_reason || ''
  );
  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [reasonToCreate, setReasonToCreate] = useState('');

  const handleClick = async () => {
    await updateProjectVersion({
      projectId: projectId,
      projectVersion: {
        id: currentVersionId,
        mfr_discount: discount
          ? Math.round((Number(discount) / 100) * 10000) / 10000
          : 0,
        mfr_discount_reason: reasonToCreate || activeReasonId || '',
      },
    });
    closeModal('mfrDiscountModal');
  };

  const sliderValueChange = (value: number) => {
    setDiscount(String(value));
  };

  const undiscountedMfrTotalCost =
    currentRows?.reduce((prev, current) => {
      if (current.resource_type === ResourceType.mfr) {
        return prev + current.rate * current.quantity;
      }
      return prev;
    }, 0) ?? 0;

  const undiscountedMfrExternalCost =
    currentRows?.reduce((prev, current) => {
      if (current.resource_type === ResourceType.mfr) {
        return prev + (current.resource?.rate || 0) * current.quantity;
      }
      return prev;
    }, 0) ?? 0;

  const mfrTotalCost = undiscountedMfrTotalCost * (1 - Number(discount) / 100);
  const mfrNet = mfrTotalCost - undiscountedMfrExternalCost;
  const displayExtremeDiscountWarning = mfrNet < 0;
  const modalCopy = copy.app.mfrDiscountModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      color={currentColor}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        onClick: handleClick,
        icon: 'check',
        loading: isLoading,
        dataCy: 'mfr-discount-modal__save-button',
      }}
      onCloseClick={() => closeModal('mfrDiscountModal')}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <Styled.CommentWrapper>
            <Combobox
              open={dropdownOpen}
              onOpenChange={isOpen => {
                setDropdownOpen(isOpen);
                setDropdownSearchValue('');
              }}
              label={modalCopy.reasonLabel}
            >
              <ComboboxTrigger
                label={
                  reasonToCreate ||
                  items.find(({ id }) => id === activeReasonId)?.displayName
                }
              />
              <ComboboxContentRoot>
                <ComboboxCommandRoot>
                  <ComboboxInput
                    value={dropdownSearchValue}
                    onValueChange={setDropdownSearchValue}
                  />
                  <ComboboxEmpty />
                  <ComboboxList>
                    {dropdownSearchValue.length > 0 && (
                      <ComboboxItem
                        value={dropdownSearchValue}
                        onSelect={() => {
                          setReasonToCreate(dropdownSearchValue);
                          setDropdownOpen(false);
                        }}
                      >
                        {replaceString(
                          copy.app.comboboxCreateButton,
                          '{name}',
                          dropdownSearchValue
                        )}
                      </ComboboxItem>
                    )}
                    {items.map(({ displayName, id }) => (
                      <ComboboxItem
                        key={id}
                        selected={activeReasonId === id}
                        onSelect={() => {
                          setReasonToCreate('');
                          setActiveReasonId(prev =>
                            prev === id ? null : id ?? null
                          );
                          setDropdownOpen(false);
                        }}
                      >
                        {displayName}
                      </ComboboxItem>
                    ))}
                  </ComboboxList>
                </ComboboxCommandRoot>
              </ComboboxContentRoot>
            </Combobox>
          </Styled.CommentWrapper>
          <Styled.TargetWrapper>
            <FormInput
              value={discount}
              onChange={handleDiscountChange}
              onBlur={handleDiscountBlur}
              placeholder={modalCopy.discountLabel}
              mask="float"
              dataCy="mfr-discount-modal__discount-field"
            />
          </Styled.TargetWrapper>
        </Styled.Row>
        <Styled.Row>
          <Styled.SliderWrapper>
            <FormSlider
              min={0}
              max={100}
              step={1}
              label={modalCopy.discountLabel}
              value={[Number(discount)]}
              onValueChange={v => sliderValueChange(v[0])}
            />
            <Styled.WarningWrapper>
              {displayExtremeDiscountWarning ? modalCopy.warning : ''}
            </Styled.WarningWrapper>
          </Styled.SliderWrapper>
        </Styled.Row>
        <Styled.PreviewWrapper>
          <MfrDiscountPreview
            discount={Number(discount) / 100}
            projectId={projectId}
            mfrNet={mfrNet}
          />
        </Styled.PreviewWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(MfrDiscountModal);
