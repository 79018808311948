import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Text = styled.div``;

export const Filters = styled.div`
  max-width: 80%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10rem;
`;
