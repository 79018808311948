import React, { useContext } from 'react';

import { TableContext } from '../Table';
import { Column } from '../Table.types';

import * as Styled from './TBodyCell.styles';

interface TBodyCell {
  children?: React.ReactNode | React.ReactNode[];
  width?: string;
  align?: Column['align'];
  minWidth?: string;
  background?: string;
}

const TBodyCell = ({
  children = null,
  width = 'auto',
  align = 'left',
  minWidth = 'auto',
  background = '',
}: TBodyCell) => {
  const { tableTheme, size } = useContext(TableContext);

  return (
    <Styled.Wrapper
      width={width}
      minWidth={minWidth}
      background={background}
      tableTheme={tableTheme}
    >
      <Styled.ContentWrapper size={size} align={align}>
        {children}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

export default TBodyCell;
