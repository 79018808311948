import { FC, memo, useEffect, useState } from 'react';

import { useOrganization } from 'queries/organizations';

import * as Styled from './OrganizationLogo.styles';

interface OrganizationLogoProps {}

const OrganizationLogo: FC<OrganizationLogoProps> = () => {
  const { data: organization } = useOrganization();
  const [filename, setFilename] = useState('');

  useEffect(() => {
    if (organization?.logo) {
      const url = new URL(organization?.logo);
      const decoded = decodeURI(url.pathname.split('/').pop() ?? '');
      setFilename(decoded);
    }
  }, [organization?.logo]);

  if (!organization?.logo) return null;

  return (
    <Styled.Wrapper
      src={organization?.logo}
      alt={filename}
      data-cy="organization-logo"
      crossOrigin="anonymous"
    />
  );
};

export default memo(OrganizationLogo);
