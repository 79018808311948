import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  border-radius: ${layout.borderRadius.big}rem;
  color: ${colors.white};
  width: 1200rem;
  background: ${colors.outerSpace};
  position: relative;
  display: inline-flex;
  flex-direction: column;
  transition: background 0.2s ease-out, color 0.2s ease-out;
  padding: 80rem 80rem;

  ${({ hidden }) =>
    hidden
      ? css`
          visibility: hidden;
        `
      : ''}
`;

export const Title = styled.div`
  color: ${rgba(colors.white, 0.4)};
  ${({ theme: { locale } }) => setTypography('body2', locale)}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  margin-bottom: 40rem;
`;

export const PermissionRequestsList = styled.div``;

export const UsersDropdown = styled.div``;

export const PermittedUserList = styled.div``;

export const Button = styled.div`
  margin-top: 90rem;
  display: flex;
  justify-content: center;
`;
