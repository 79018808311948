import { useQuery, UseQueryOptions } from 'react-query';

import { useApiStore } from 'store';
import { ArchiveItem } from 'utils/api.types';

export const ARCHIVE_KEY = 'ARCHIVE';

export const useArchive = (options?: UseQueryOptions<ArchiveItem[]>) => {
  const getArchive = useApiStore(s => s.apiClient.getArchive);
  return useQuery({
    queryKey: ARCHIVE_KEY,
    queryFn: async () => (await getArchive()).data,
    ...options,
  });
};
