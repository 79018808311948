import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export type FilterCardTheme = 'light' | 'dark';

const itemColors: Record<
  FilterCardTheme,
  {
    background: string;
    color: string;
    iconBackground: string;
    iconColor: string;
  }
> = {
  light: {
    background: colors.alto,
    color: colors.nandor,
    iconBackground: colors.outerSpace,
    iconColor: colors.white,
  },
  dark: {
    background: rgba(colors.white, 0.15),
    color: colors.white,
    iconBackground: colors.white,
    iconColor: colors.outerSpace,
  },
};

export const Wrapper = styled.div<{ colorScheme: FilterCardTheme }>`
  ${({ colorScheme }) => css`
    --filterCard--background: ${itemColors[colorScheme].background};
    --filterCard--color: ${itemColors[colorScheme].color};
    --filterCard--iconBackground: ${itemColors[colorScheme].iconBackground};
    --filterCard--iconColor: ${itemColors[colorScheme].iconColor};
  `}

  background: var(--filterCard--background);
  display: flex;
  justify-content: space-between;
  border-radius: ${layout.borderRadius.big}rem;
  padding: 6rem 5rem 6rem 20rem;
  align-items: center;
  min-height: 60rem;
`;

export const Text = styled.div`
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  color: var(--filterCard--color);
  user-select: none;
  margin-right: 30rem;
`;

export const CloseButton = styled.button`
  background: var(--filterCard--iconBackground);
  height: 40rem;
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  ${hover(brightnessFilter)};

  &:focus-visible {
    ${focusVisible};
  }
`;

export const IconWrapper = styled.div`
  color: var(--filterCard--iconColor);
  width: 24rem;
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
