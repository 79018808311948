import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80rem;
  max-width: 1000rem;
`;

export const Text = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  color: ${rgba(colors.white, 0.5)};
`;

export const DatepickerWrapper = styled.div`
  align-self: center;
  width: 100%;
  border-bottom: 1px solid ${rgba(colors.white, 0.5)};
  padding-bottom: 10rem;
`;

export const Row = styled.div``;
