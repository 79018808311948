import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';

import EditableLabel from 'components/inputs/EditableLabel/EditableLabel';
import { BudgetEntry } from 'utils/api.types';

export interface InvoiceNumberCellProps {
  props: CellContext<BudgetEntry, unknown>;
  isDisabled: boolean;
  saveBudgetEntry: () => void;
  handleChange: (value: any) => void;
}

const InvoiceNumberCell: FC<InvoiceNumberCellProps> = ({
  props,
  isDisabled,
  saveBudgetEntry,
  handleChange,
}) => {
  const [value, setValue] = useState(props.row.original.invoice_number || '');

  const save = () => {
    handleChange(value);
    setTimeout(() => {
      saveBudgetEntry();
    }, 0);
  };

  return (
    <EditableLabel
      onChange={setValue}
      onBlur={save}
      onEnter={save}
      onEscape={save}
      value={value}
      placeholder={'-'}
      styles={{ width: '100%' }}
      dataCy="budget-modal__invoice_number_field"
      disabled={isDisabled}
    />
  );
};

export default InvoiceNumberCell;
