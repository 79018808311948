import { useEffect, useMemo, useState } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { useOrganization } from 'queries/organizations';
import { useCopyStore } from 'store';
import { Project, ProjectMode } from 'utils/api.types';

interface UseJobNumberFieldProps {
  project?: Project;
  isJobNumberRequired: boolean;
  activeMode: Project['mode'];
}

export const useJobNumberField = ({
  project,
  isJobNumberRequired,
  activeMode,
}: UseJobNumberFieldProps) => {
  const copy = useCopyStore(s => s.copy);
  const [duplicateError, setDuplicateError] = useState('');
  const { data: organization } = useOrganization();

  const initialJobNumber =
    activeMode === ProjectMode.pitch
      ? project?.pitch_number || ''
      : project?.job_number || '';

  const customRegex = useMemo(() => {
    return activeMode === ProjectMode.pitch
      ? organization?.pitch_number_regex
      : organization?.job_number_regex;
  }, [
    activeMode,
    organization?.job_number_regex,
    organization?.pitch_number_regex,
  ]);

  const validators = useMemo(
    () => ({
      required: {
        enabled: isJobNumberRequired,
        message: copy.app.validatorMessages.jobNumberRequired,
      },
      customRegex: {
        enabled: !!customRegex,
        regex: customRegex ? new RegExp(customRegex) : new RegExp(''),
        message: copy.app.validatorMessages.customJobNumber,
      },
    }),
    [
      copy.app.validatorMessages.customJobNumber,
      copy.app.validatorMessages.jobNumberRequired,
      isJobNumberRequired,
      customRegex,
    ]
  );
  const [jobNumber, setJobNumber, isJobNumberValid, jobNumberError] = useField(
    initialJobNumber,
    validators
  );

  useEffect(() => {
    setJobNumber(jobNumber !== '-' ? jobNumber : '');
  }, [isJobNumberRequired, setJobNumber, jobNumber]);

  useEffect(() => {
    setJobNumber(
      activeMode === ProjectMode.pitch
        ? project?.pitch_number || ''
        : project?.job_number || ''
    );
  }, [project?.job_number, project?.pitch_number, activeMode, setJobNumber]);

  useEffect(() => {
    setDuplicateError('');
  }, [jobNumber]);

  return {
    jobNumber,
    setJobNumber,
    isJobNumberValid,
    jobNumberError,
    duplicateError,
    setDuplicateError,
  };
};
