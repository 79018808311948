import { FC, memo } from 'react';

import FormSlider, {
  DefaultTopHtml,
} from 'components/inputs/FormSlider/FormSlider';
import { useFormatDays } from 'hooks/useFormatDays';
import { useCopyStore } from 'store';

import { useBurnSlider } from './hooks/useBurnSlider';

export type BurnSliderProps = ReturnType<typeof useBurnSlider>;

const BurnSlider: FC<BurnSliderProps> = ({
  burn,
  maxBurn,
  minBurn,
  setBurn,
}) => {
  const { formatDays } = useFormatDays();
  const copy = useCopyStore(s => s.copy);

  return (
    <FormSlider
      label={copy.app.projectFiltersModal.burn}
      step={1}
      min={minBurn === Infinity ? 0 : minBurn}
      max={maxBurn === 0 ? 0 : maxBurn}
      disabled={minBurn === Infinity && maxBurn === 0}
      value={burn}
      onValueChange={setBurn}
      topHtml={
        <DefaultTopHtml value={[formatDays(burn[0]), formatDays(burn[1])]} />
      }
    />
  );
};

export default memo(BurnSlider);
