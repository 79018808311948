import create from 'zustand';

import { TimeFrameEnum } from 'components/modals/PerformanceFiltersModal/KpisTab/TimeFrame/TimeFrame';
import { Project, Vertical } from 'utils/api.types';

export const performanceDashboardTraces = [
  'budget',
  'margin',
  'gpPercent',
  'internalCost',
  'externalCost',
  'burnTrace',
  'marginPercent',
  'internalPercent',
  'internalRatio',
  'trueMargin',
  'trueMarginPercent',
] as const;
export type PerformanceDashboardTracesType =
  typeof performanceDashboardTraces[number];

export interface PerformanceDashboardStore {
  tracesVisible: Record<PerformanceDashboardTracesType, boolean>;
  setTraceVisible: (
    trace: PerformanceDashboardTracesType,
    isVisible: boolean
  ) => void;

  // Row expanding settings
  historyExpandedProjectsIds: Project['id'][];
  setHistoryExpandedProjectsIds: (
    historyExpandedProjectsIds: Project['id'][]
  ) => void;
  chartExpandedProjectsIds: Project['id'][];
  setChartExpandedProjectsIds: (
    chartExpandedProjectsIds: Project['id'][]
  ) => void;

  filtersCardHeight: number;
  setFiltersCardHeight: (filtersCardHeight: number) => void;

  // Filters
  resultsByDate: boolean;
  setResultsByDate: (resultsByDate: boolean) => void;
  startDate: string | null;
  setStartDate: (startDate: string | null) => void;
  endDate: string | null;
  setEndDate: (endDate: string | null) => void;
  includeStart: boolean;
  setIncludeStart: (includeStart: boolean) => void;
  includeEnd: boolean;
  setIncludeEnd: (includeEnd: boolean) => void;
  excludeClosed: boolean;
  setExcludeClosed: (excludeClosed: boolean) => void;
  budgetFrom: string | null;
  setBudgetFrom: (budgetFrom: string | null) => void;
  budgetTo: string | null;
  setBudgetTo: (budgetTo: string | null) => void;
  marginVariation: string | null;
  setMarginVariation: (marginVariation: string | null) => void;
  timeframe: TimeFrameEnum | null;
  setTimeframe: (timeframe: TimeFrameEnum | null) => void;
  verticals: Vertical['id'][];
  setVerticals: (verticals: Vertical['id'][]) => void;
  intExtFilter: boolean;
  setIntExtFilter: (intExtFilter: boolean) => void;
  internalPercent: number[] | null;
  setInternalPercent: (value: number[] | null) => void;
  similarProjectId: Project['id'] | null;
  setSimilarProjectId: (projectId: Project['id'] | null) => void;
  icons: string[];
  setIcons: (icons: string[]) => void;
  includeNonIcon: boolean;
  setIncludeNonIcon: (includeNonIcon: boolean) => void;
}

export type PerformanceDashboardFilters = Pick<
  PerformanceDashboardStore,
  | 'resultsByDate'
  | 'startDate'
  | 'endDate'
  | 'includeStart'
  | 'includeEnd'
  | 'excludeClosed'
  | 'budgetFrom'
  | 'budgetTo'
  | 'marginVariation'
  | 'timeframe'
  | 'verticals'
  | 'intExtFilter'
  | 'internalPercent'
  | 'similarProjectId'
  | 'icons'
  | 'includeNonIcon'
>;

export const RESULTS_BY_DATE_DEFAULT = false as const;
export const START_DATE_DEFAULT = null;
export const END_DATE_DEFAULT = null;
export const INCLUDE_START_DEFAULT = false as const;
export const INCLUDE_END_DEFAULT = false as const;
export const EXCLUDE_CLOSED_DEFAULT = false as const;
export const BUDGET_FROM_DEFAULT = null;
export const BUDGET_TO_DEFAULT = null;
export const MARGIN_VARIATION_DEFAULT = null;
export const TIMEFRAME_DEFAULT = null;
export const VERTICALS_DEFAULT = [] as Vertical['id'][];
export const INT_EXT_FILTER_DEFAULT = false as const;
export const INTERNAL_PERCENT_DEFAULT = null;
export const SIMILAR_PROJECT_ID_DEFAULT = null;
export const ICONS_DEFAULT = [] as string[];
export const INCLUDE_NON_ICON_DEFAULT = false as const;

const usePerformanceDashboardStore = create<PerformanceDashboardStore>(set => {
  return {
    tracesVisible: {
      budget: true,
      externalCost: true,
      internalCost: true,
      margin: true,
      burnTrace: false,
      gpPercent: false,
      internalPercent: false,
      internalRatio: false,
      marginPercent: false,
      trueMargin: false,
      trueMarginPercent: false,
    },
    setTraceVisible: (trace, isVisible) => {
      set(state => ({
        tracesVisible: {
          ...state.tracesVisible,
          [trace]: isVisible,
        },
      }));
    },

    historyExpandedProjectsIds: [],
    setHistoryExpandedProjectsIds: historyExpandedProjectsIds => {
      set(() => ({
        historyExpandedProjectsIds,
      }));
    },

    chartExpandedProjectsIds: [],
    setChartExpandedProjectsIds: chartExpandedProjectsIds => {
      set(() => ({
        chartExpandedProjectsIds,
      }));
    },

    filtersCardHeight: 0,
    setFiltersCardHeight: filtersCardHeight => {
      set(() => ({
        filtersCardHeight,
      }));
    },

    resultsByDate: RESULTS_BY_DATE_DEFAULT,
    setResultsByDate: resultsByDate => {
      set(() => ({
        resultsByDate,
      }));
    },

    startDate: START_DATE_DEFAULT,
    setStartDate: startDate => {
      set(() => ({
        startDate,
      }));
    },

    endDate: END_DATE_DEFAULT,
    setEndDate: endDate => {
      set(() => ({
        endDate,
      }));
    },

    includeStart: INCLUDE_START_DEFAULT,
    setIncludeStart: includeStart => {
      set(() => ({
        includeStart,
      }));
    },

    includeEnd: INCLUDE_END_DEFAULT,
    setIncludeEnd: includeEnd => {
      set(() => ({
        includeEnd,
      }));
    },

    excludeClosed: EXCLUDE_CLOSED_DEFAULT,
    setExcludeClosed: excludeClosed => {
      set(() => ({
        excludeClosed,
      }));
    },

    budgetFrom: BUDGET_FROM_DEFAULT,
    setBudgetFrom: budgetFrom => {
      set(() => ({
        budgetFrom,
      }));
    },

    budgetTo: BUDGET_TO_DEFAULT,
    setBudgetTo: budgetTo => {
      set(() => ({
        budgetTo,
      }));
    },

    marginVariation: MARGIN_VARIATION_DEFAULT,
    setMarginVariation: marginVariation => {
      set(() => ({
        marginVariation,
      }));
    },

    timeframe: TIMEFRAME_DEFAULT,
    setTimeframe: timeframe => {
      set(() => ({
        timeframe,
      }));
    },

    verticals: VERTICALS_DEFAULT,
    setVerticals: verticals => {
      set(() => ({
        verticals,
      }));
    },

    internalPercent: INTERNAL_PERCENT_DEFAULT,
    setInternalPercent: internalPercent => {
      set(() => ({
        internalPercent,
      }));
    },

    similarProjectId: SIMILAR_PROJECT_ID_DEFAULT,
    setSimilarProjectId: similarProjectId => {
      set(() => ({
        similarProjectId,
      }));
    },

    intExtFilter: INT_EXT_FILTER_DEFAULT,
    setIntExtFilter: intExtFilter => {
      set(() => ({
        intExtFilter,
      }));
    },

    icons: ICONS_DEFAULT,
    setIcons: icons => {
      set(() => ({
        icons,
      }));
    },

    includeNonIcon: INCLUDE_NON_ICON_DEFAULT,
    setIncludeNonIcon: includeNonIcon => {
      set(() => ({
        includeNonIcon,
      }));
    },
  };
});

export default usePerformanceDashboardStore;
