import React from 'react';

import { ReactComponent as BobLogo } from 'svgs/bob_logo.svg';

import * as Styled from './Logo.styles';

const Logo = () => {
  return (
    <Styled.Logo>
      <BobLogo />
    </Styled.Logo>
  );
};

export default React.memo(Logo);
