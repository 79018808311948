import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const SubPromptWrapper = styled.div`
  margin: -50rem 50rem 120rem;
`;

export const SubPrompt = styled.div`
  margin-bottom: 40rem;
  text-align: center;
  color: ${rgba(colors.white, 0.5)};
`;
