import { FC, memo } from 'react';

import Badge from 'components/Badge/Badge';
import Checkbox from 'components/Checkbox/Checkbox';
import FormSlider from 'components/inputs/FormSlider/FormSlider';
import { useCopyStore, usePerformanceDashboardStore } from 'store';
import { formatPercent } from 'utils/formatters';
import { replaceStrings } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import SimilarProjectsField from './SimilarProjectsField/SimilarProjectsField';

import * as Styled from '../PerformanceFiltersModal.styles';
import * as RolesStyles from './RolesTab.styles';

export interface RolesTabProps {}

const RolesTab: FC<RolesTabProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const internalPercent = usePerformanceDashboardStore(s => s.internalPercent);
  const setInternalPercent = usePerformanceDashboardStore(
    s => s.setInternalPercent
  );
  const intExtFilter = usePerformanceDashboardStore(s => s.intExtFilter);
  const setIntExtFilter = usePerformanceDashboardStore(s => s.setIntExtFilter);

  const tabCopy = copy.performanceDashboard.filtersModal.rolesMix;

  const TopHtml = () => {
    return (
      <RolesStyles.TopHtmlWrapper disabled={!intExtFilter}>
        <RolesStyles.Start>
          <Badge
            color={ColorNames.white}
            textColor={ColorNames.outerSpace}
            styles={{ padding: '0 30rem' }}
          >
            {copy.app.territory.internal}
          </Badge>
          <RolesStyles.Percent>
            {replaceStrings(tabCopy.sliderValueDisplay, [
              [
                '{name}',
                formatPercent(internalPercent?.[0], {
                  precision: 0,
                }),
              ],
              [
                '{name}',
                formatPercent(internalPercent?.[1], {
                  precision: 0,
                }),
              ],
            ])}
          </RolesStyles.Percent>
        </RolesStyles.Start>
      </RolesStyles.TopHtmlWrapper>
    );
  };

  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <SimilarProjectsField />
      </Styled.Row>
      <Styled.Row>
        <Checkbox
          label={tabCopy.intExtToggle}
          value={intExtFilter}
          setValue={setIntExtFilter}
        />
      </Styled.Row>
      <Styled.Row>
        <FormSlider
          label={tabCopy.sliderLabel}
          value={internalPercent ?? undefined}
          onValueChange={setInternalPercent}
          topHtml={<TopHtml />}
          max={100}
          min={0}
          disabled={!intExtFilter}
        />
      </Styled.Row>
    </Styled.TabWrapper>
  );
};

export default memo(RolesTab);
