import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const themes = ['light', 'dark'];

const colorScheme: Record<typeof themes[number], { color: string }> = {
  light: {
    color: rgba(colors.outerSpace, 0.5),
  },
  dark: {
    color: rgba(colors.white, 0.5),
  },
};

export const Wrapper = styled.div<{ theme: typeof themes[number] }>`
  width: 26rem;
  height: 26rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => colorScheme[theme].color};
`;
