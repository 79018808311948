import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Role } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface RoleDropdownProps {
  roleIds: Role['role_id'][];
  setRoleIds: Dispatch<SetStateAction<Role['role_id'][]>>;
}

const RoleDropdown: FC<RoleDropdownProps> = ({ roleIds, setRoleIds }) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);

  const allRowItems = currentProjectVersion?.rows.filter(
    ({ spacer_name }) => !spacer_name
  );

  const uniqueRowItems = Array.from(
    new Set(allRowItems?.map(row => row.role_id))
  );
  const items = uniqueRowItems
    ?.map(id => allRowItems?.find(item => item.role_id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: Role['role_id']) => {
    if (roleIds.includes(activeId)) {
      setRoleIds(roleIds.filter(prevItem => prevItem !== activeId));
    } else {
      setRoleIds([...roleIds, activeId]);
    }
  };

  const label = roleIds?.map(
    id => items?.find(item => item.role_id === id)?.role_name
  );

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectFiltersModal.roleDropdown}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items?.map(({ role_id, role_name }) => (
            <DropdownMenuCheckboxItem
              key={role_id}
              checked={roleIds?.includes(role_id)}
              onSelect={event => {
                event.preventDefault();
                toggleActive(role_id);
              }}
            >
              {role_name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(RoleDropdown);
