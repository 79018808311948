import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useCopyStore } from 'store';
import { ProjectPaymentReceivedNotification as ProjectPaymentReceivedNotificationType } from 'utils/api.types';
import { formatDateInline } from 'utils/formatters';
import { replaceString } from 'utils/replace';
import { getProjectRoute } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface ProjectPaymentReceivedNotificationProps {
  notification: ProjectPaymentReceivedNotificationType;
}

const ProjectPaymentReceivedNotification: FC<
  ProjectPaymentReceivedNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();
  const { formatMoney } = useFormatMoney();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.projectPaymentReceived}
        replacements={[
          [
            '{project}',
            <Link
              key="{project}"
              href={getProjectRoute(notification.extra_info.projectId)}
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {notification.extra_info.projectName}
              </Styled.NotificationInlineButton>
            </Link>,
          ],
        ]}
      />
      <Styled.NotificationItemList>
        <Styled.NotificationItemListItem>
          {replaceString(
            copy.app.notifications.projectPaymentReceivedAmount,
            '{amount}',
            formatMoney(notification.extra_info.amount)
          )}
        </Styled.NotificationItemListItem>
        <Styled.NotificationItemListItem>
          {replaceString(
            copy.app.notifications.projectPaymentReceivedDate,
            '{date}',
            formatDateInline(notification.extra_info.date)
          )}
        </Styled.NotificationItemListItem>
      </Styled.NotificationItemList>
    </Styled.Wrapper>
  );
};

export default memo(ProjectPaymentReceivedNotification);
