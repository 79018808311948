import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50rem;
`;

export const FieldWrapper = styled.div`
  flex: 1;
`;

export const Data = styled.div`
  padding-bottom: 70rem;
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.white, 0.5)};
`;

export const Value = styled.div``;
