import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1000rem;
`;

export const Description = styled.div``;

export const DropdownWrapper = styled.div`
  margin: 50rem 0;
`;
