import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const Row = styled.div`
  display: flex;
  margin-bottom: 50rem;
  width: 100%;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const FieldSpace = styled.div`
  width: 50rem;
`;

export const SuggestedAddress = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

export const SuggestedAddressLabel = styled.div<{ theme: 'light' | 'dark' }>`
  color: ${({ theme }) =>
    theme === 'light' ? rgba(colors.black, 0.5) : rgba(colors.white, 0.5)};
  margin-bottom: 5rem;
`;

export const SuggestedAddressButton = styled.button<{
  theme: 'light' | 'dark';
}>`
  text-decoration: underline;
  text-align: left;

  ${({ theme }) => css`
    ${hover(css`
      color: ${theme === 'light'
        ? rgba(colors.black, 0.8)
        : rgba(colors.white, 0.8)};
    `)}
  `}
`;
