import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { ReactComponent as CloseIcon } from 'svgs/close.svg';
import { ReactComponent as SearchIcon } from 'svgs/search.svg';

import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';

import * as Styled from './SearchInput.styles';

export const searchInputTheme = ['dark', 'light'] as const;
export interface SearchInputProps extends BaseInputProps {
  theme?: (typeof searchInputTheme)[number];
  hijackBrowserSearch?: boolean;
}

const SearchInput = ({
  value,
  placeholder,
  onChange,
  onEnter,
  onEscape,
  onBlur,
  onFocus,
  disabled,
  loading,
  dataCy,
  startHtml,
  theme = 'light',
  hijackBrowserSearch = false,
}: SearchInputProps) => {
  const fieldEl = useRef<HTMLInputElement>(null);

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    onFocus && onFocus();
  }, [onFocus]);

  const handleWrapperClick = useCallback(() => {
    fieldEl.current?.focus();
  }, []);

  const handleClearClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onChange('');
    },
    [onChange]
  );

  const hasValue = useMemo(() => !!value, [value]);

  const handleBrowserSearch = useCallback((event: KeyboardEvent) => {
    if (
      event.key === 'F3' ||
      ((event.ctrlKey || event.metaKey) && event.key.match(/f/i))
    ) {
      event.preventDefault();
      fieldEl.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (hijackBrowserSearch) {
      window.addEventListener('keydown', handleBrowserSearch);
    }

    return () => {
      if (hijackBrowserSearch) {
        window.removeEventListener('keydown', handleBrowserSearch);
      }
    };
  }, [handleBrowserSearch, hijackBrowserSearch]);

  return (
    <Styled.Wrapper onClick={handleWrapperClick} theme={theme}>
      <Styled.StartIcon>
        <SearchIcon />
      </Styled.StartIcon>
      <BaseInput
        startHtml={startHtml}
        loading={loading}
        ref={fieldEl}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChange}
        onEnter={onEnter}
        onEscape={onEscape}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        dataCy={dataCy}
      />
      {hasValue && (
        <Styled.ClearIconWrapper
          onClick={handleClearClick}
          data-cy="search-clear-button"
        >
          <CloseIcon />
        </Styled.ClearIconWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default React.memo(SearchInput);
