import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useState } from 'react';

import SearchInput from 'components/inputs/SearchInput/SearchInput';
import useDebounce from 'hooks/useDebounce';
import { useGlobalStore, useProjectsListStore } from 'store';
import { DEFAULT_PROJECTS_LIST_TABLE_PAGINATION } from 'store/projectsList';
import { ROUTES } from 'utils/routes';

import { useSearchPlaceholder } from './hooks/useSearchPlaceholder';

export interface SearchProps {}

const Search: FC<SearchProps> = () => {
  const setSearchboxValue = useGlobalStore(s => s.setSearchbarValue);
  const searchbarValue = useGlobalStore(s => s.searchbarValue);
  const setProjectsListTablePagination = useProjectsListStore(
    s => s.setTablePagination
  );
  const [localValue, setLocalValue] = useState(searchbarValue);
  const router = useRouter();
  const searchPlaceholder = useSearchPlaceholder();

  const handleChange = useCallback(
    (value: string) => {
      setLocalValue(value);
      if (router.route === ROUTES.PROJECTS_LIST) {
        router.push(
          {
            pathname: ROUTES.PROJECTS_LIST,
            query: {
              ...router.query,
              search: value,
            },
          },
          undefined,
          { shallow: true }
        );
      }
    },
    [router]
  );

  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    setSearchboxValue(debouncedValue);
    setProjectsListTablePagination(DEFAULT_PROJECTS_LIST_TABLE_PAGINATION);
  }, [debouncedValue, setProjectsListTablePagination, setSearchboxValue]);

  return (
    <SearchInput
      placeholder={searchPlaceholder}
      value={localValue}
      onChange={handleChange}
      hijackBrowserSearch
    />
  );
};

export default Search;
