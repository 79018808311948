import { useState } from 'react';

import useHumanRolesItems from 'hooks/useHumanRolesItems';
import { ProjectRow } from 'utils/api.types';

export const useRolesDropdown = (row: ProjectRow) => {
  const rolesItems = useHumanRolesItems();
  const [activeRoleId, setActiveRoleId] = useState(row?.role_id || null);
  const [rolesOpen, setRolesOpen] = useState(false);

  return {
    rolesItems,
    activeRoleId,
    setActiveRoleId,
    rolesOpen,
    setRolesOpen,
  };
};
