import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const MarginFieldStartHtml = styled.div`
  width: 440rem;
`;

export const MarginFieldEndHtml = styled.div`
  color: ${rgba(colors.white, 0.5)};
`;
