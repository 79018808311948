import * as LabelPrimitive from '@radix-ui/react-label';
import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 50rem;
  width: 1200rem;
  padding: 80rem;
  padding-bottom: 250rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 50rem;
  max-width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 50rem;
  justify-content: flex-end;
  position: absolute;
  background: ${colors.outerSpace};
  padding: 50rem;
  width: 100%;
  right: 0;
  bottom: 0;
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    padding: 20rem 0;
    flex: 1;
    border-bottom: 1px solid ${rgba(colors.white, 0.5)};
    ${({ theme: { locale } }) => setTypography('input', locale)}
    text-align: left;
  }
`;

export const DropdownLabel = styled(LabelPrimitive.Root)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: locale }) => setTypography('caption', locale)};
`;
