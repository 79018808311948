import { SortingState } from '@tanstack/react-table';
import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import { DEFAULT_PAGE_SIZE } from 'utils/api';

import { ListFilterType } from './projectsList.types';

interface ProjectsListStoreProps {
  listFilter?: ListFilterType;
}

export interface ProjectsListStoreType extends ProjectsListStoreProps {
  listFilter: ListFilterType;
  setListFilter: (listFilter: ProjectsListStoreType['listFilter']) => void;

  tablePagination: {
    pageIndex: number;
    pageSize: number;
  };
  setTablePagination: (
    tablePagination: ProjectsListStoreType['tablePagination']
  ) => void;

  tableSorting: SortingState;
  setTableSorting: (
    tableSorting: ProjectsListStoreType['tableSorting']
  ) => void;
}

type ProjectsListStore = ReturnType<typeof createProjectsListStore>;

export const DEFAULT_PROJECTS_LIST_TABLE_PAGINATION = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};

const createProjectsListStore = (initProps: ProjectsListStoreProps) => {
  return createStore<ProjectsListStoreType>()(set => ({
    listFilter: initProps.listFilter || 'my',
    setListFilter: listFilter => {
      set(() => ({
        listFilter,
        tablePagination: DEFAULT_PROJECTS_LIST_TABLE_PAGINATION,
      }));
    },

    tablePagination: DEFAULT_PROJECTS_LIST_TABLE_PAGINATION,
    setTablePagination: tablePagination => {
      set(() => ({
        tablePagination,
      }));
    },

    tableSorting: [{ id: 'updated_at', desc: true }],
    setTableSorting: tableSorting => {
      set(() => ({
        tableSorting,
      }));
    },
  }));
};

export const ProjectsListStoreContext = createContext<ProjectsListStore | null>(
  null
);

export default function useProjectsListStore<T>(
  selector: (state: ProjectsListStoreType) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(ProjectsListStoreContext);
  if (!store) throw new Error('Missing GlobalStore.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

export const useInitProjectsListStore = (initProps: ProjectsListStoreProps) => {
  const storeRef = useRef<ProjectsListStore>();
  if (!storeRef.current) {
    storeRef.current = createProjectsListStore(initProps);
  }

  return storeRef;
};
