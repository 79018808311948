import Cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import {
  WORKSPACE_INFO_COOKIE,
  WorkspaceInfo,
} from '@u9/bob3-shared/lib/types/api.types';
import { useOrganization } from 'queries/organizations';
import { useGlobalStore } from 'store';
import { sentryCaptureException } from 'utils/sentryCaptureException';

export const useSaveWorkspaceInfo = () => {
  const subdomain = useGlobalStore(s => s.subdomain);
  const { data: session } = useSession();
  const { data: organization } = useOrganization({
    enabled: !!session && !!subdomain,
  });

  useEffect(() => {
    if (organization && subdomain) {
      const cookie = Cookies.get(WORKSPACE_INFO_COOKIE);
      try {
        const workspacesInfo: Record<string, WorkspaceInfo> = cookie
          ? JSON.parse(cookie)
          : {};
        if (!workspacesInfo[subdomain]) {
          workspacesInfo[subdomain] = {
            link: window.location.origin,
            workspaceName: organization.name,
          };
          Cookies.set(WORKSPACE_INFO_COOKIE, JSON.stringify(workspacesInfo), {
            domain: window.location.hostname.replace(subdomain, ''),
          });
        }
      } catch (err) {
        sentryCaptureException(err);
      }
    }
  }, [organization, subdomain]);
};
