import { Dispatch, FC, memo, SetStateAction } from 'react';

import { useSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore } from 'store';
import { ResourceType } from 'utils/api.types';

export const basicPrefix = 'RESOURCE-';
export const mfrPrefix = 'MFR-';

import { rgba } from 'polished';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface ResourceTypeDropdownProps {
  resourceTypes: string[];
  setResourceTypes: Dispatch<SetStateAction<string[]>>;
}

const ResourceTypeDropdown: FC<ResourceTypeDropdownProps> = ({
  resourceTypes,
  setResourceTypes,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { data: supportedMfrRates } = useSupportedMfrRate();

  const items = [
    {
      id: `${basicPrefix}${ResourceType.internal}`,
      displayName: copy.app.territoryShort.internal,
    },
    {
      id: `${basicPrefix}${ResourceType.external}`,
      displayName: copy.app.territoryShort.external,
    },
    ...(supportedMfrRates?.map(mfrRate => ({
      id: `${mfrPrefix}${mfrRate.id}`,
      displayName: `${copy.app.mfrLabel} (${mfrRate.code})`,
    })) ?? []),
  ];

  const label = resourceTypes?.map(
    id => items?.find(item => item.id === id)?.displayName
  );

  const toggleActive = (activeId: string) => {
    if (resourceTypes.includes(activeId)) {
      setResourceTypes(resourceTypes.filter(prevItem => prevItem !== activeId));
    } else {
      setResourceTypes([...resourceTypes, activeId]);
    }
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectFiltersModal.resourceTypeDropdown}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items?.map(({ id, displayName }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={resourceTypes?.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleActive(id);
              }}
            >
              {displayName}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(ResourceTypeDropdown);
