import styled from 'styled-components';

import { slideDownAnimation } from 'utils/styles/mixins';

export const PopupWrapper = styled.div`
  position: fixed;
  z-index: 15;

  ${slideDownAnimation};
`;

export const Wrapper = styled.div`
  white-space: pre-line;
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
`;
