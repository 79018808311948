import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsMutating } from 'react-query';

import Checkbox from 'components/Checkbox/Checkbox';
import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import {
  DynamicColumnsDropdown,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
  TableToolbar,
  TableToolbarEnd,
  TableToolbarStart,
  TBody,
  THead,
  THeadCell,
  THeadCellContent,
  ToolbarButton,
} from 'components/ShadTable/Table';
import { useOrganization } from 'queries/organizations';
import {
  RECONCILE_MUTATION_KEY,
  useCurrentProject,
  useReconcile,
  useReconciliationData,
} from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore, useProjectStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { useCheckAccessRights } from 'utils/accessRights';
import { ReconcileExternalItem } from 'utils/api.types';
import { formatDateInline } from 'utils/formatters';
import { colors } from 'utils/styles/theme';
import { tableColors } from 'utils/ThemeProvider';

import { useGetColorMatchIntegration } from './hooks/useGetColorMatch';
import ReconcileStats from './ReconcileStats/ReconcileStats';
import ResourceDropdownIntegration, {
  ResourceDropdownIntegrationProps,
} from './RecourceDropdownIntegration/ResourceDropdownIntegration';

import * as Styled from './ReconcileModal.styles';

export interface ReconcileTeamdeckProps {
  onContinueClick: () => void;
}

const ReconcileTeamdeck: FC<ReconcileTeamdeckProps> = ({ onContinueClick }) => {
  const { data: organization } = useOrganization();
  const copy = useCopyStore(s => s.copy);
  const mutationsCount = useIsMutating({
    mutationKey: RECONCILE_MUTATION_KEY,
    exact: true,
  });
  const { checkAnyAccessRight } = useCheckAccessRights();
  const { SvgIcon: LongArrowright } = useSvgIcon('transition_arrow_right');
  const isLoading = mutationsCount > 0;
  const modalCopy = copy.app.reconcileSuccessModal;
  const { mutateAsync: reconcile } = useReconcile();
  const currentProject = useCurrentProject();
  const { data: { external_items } = { data: { external_items: [] } } } =
    useReconciliationData({ projectId: currentProject?.id });
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const { getMatchColor } = useGetColorMatchIntegration();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableWrapperTop, setTableWrapperTop] = useState(0);
  const setWrapResourcesTeamdeck = useProjectStore(
    s => s.setWrapResourcesTeamdeck
  );
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const rows = useMemo(
    () => currentProjectVersion?.rows ?? [],
    [currentProjectVersion?.rows]
  );
  const wrapResourcesTeamdeck = useProjectStore(s => s.wrapResourcesTeamdeck);

  const handleResourceSelected: ResourceDropdownIntegrationProps['onResourceSelected'] =
    useCallback(
      async ({ resource_id, vendor_id, id }) => {
        if (!currentProject?.id) return;
        await reconcile({
          project_id: currentProject?.id,
          external_items: [
            {
              id: String(id),
              ...(resource_id ? { resource_id: Number(resource_id) } : {}),
              ...(vendor_id ? { vendor_id: Number(vendor_id) } : {}),
            },
          ],
          report_type: 'base',
        });
      },
      [currentProject?.id, reconcile]
    );

  const handleResetResource: ResourceDropdownIntegrationProps['onResetResourceSelected'] =
    useCallback(
      async ({ id }) => {
        if (!currentProject?.id) return;
        await reconcile({
          project_id: currentProject?.id,
          external_items: [{ id: String(id), resource_id: 0 }],
          report_type: 'base',
        });
      },
      [currentProject?.id, reconcile]
    );

  const teamdeckItems = useMemo(
    () => external_items?.filter(({ type }) => type === 'teamdeck') ?? [],
    [external_items]
  );

  const columns: ColumnDef<ReconcileExternalItem>[] = useMemo(
    () => [
      {
        id: 'select',
        size: 50,
        enableHiding: false,
        meta: {
          isSelectColumn: true,
        },
        header: props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Checkbox
              value={
                props.table.getIsSomeRowsSelected() ||
                props.table.getIsAllRowsSelected()
              }
              setValue={props.table.toggleAllRowsSelected}
              icon={props.table.getIsAllRowsSelected() ? 'check' : 'dash'}
              variant="square"
            />
          </div>
        ),
        cell: props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '10rem',
            }}
          >
            <Checkbox
              value={props.row.getIsSelected()}
              setValue={props.row.toggleSelected}
              disabled={!props.row.getCanSelect()}
              variant="square"
              dataCy="row-select"
            />
            <div />
          </div>
        ),
      },
      {
        id: 'name',
        accessorKey: 'name',
        size: 250,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.resourceName}
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <Styled.InfoTextWrapper>
            <OverflowEllipsis text={props.row.original.name} />
          </Styled.InfoTextWrapper>
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.resourceName,
        },
      },
      {
        id: 'lastReportedDate',
        accessorKey: 'last_reported_date',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.lastReportedDate}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={formatDateInline(props.row.original.last_reported_date)}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.lastReportedDate,
        },
      },
      {
        id: 'lastBookedDate',
        accessorKey: 'last_booked_date',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.lastBookedDate}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={formatDateInline(props.row.original.last_booked_date)}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.lastBookedDate,
        },
      },
      {
        id: 'lastWeekQuantity',
        accessorKey: 'last_week_quantity',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.daysLastWeek}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.row.original.last_week_quantity}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.daysLastWeek,
        },
      },
      {
        id: 'futureBookedQuantity',
        accessorKey: 'future_booked_quantity',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.futureBooked}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.row.original.future_booked_quantity}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.futureBooked,
        },
      },
      {
        id: 'bookedQuantity',
        accessorKey: 'booked_quantity',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.booked}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.row.original.booked_quantity}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.booked,
        },
      },
      {
        id: 'quantity',
        accessorKey: 'quantity',
        size: 100,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.days}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.row.original.quantity}
            styles={{ textAlign: 'right' }}
          />
        ),
        meta: {
          text: copy.app.reconcileSuccessModal.columns.days,
        },
      },
      {
        id: 'arrow',
        accessorKey: 'id',
        enableSorting: false,
        size: 80,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={''}
            textAlign="center"
          />
        ),
        cell: () => (
          <Styled.RightArrowWrapper>
            <LongArrowright />
          </Styled.RightArrowWrapper>
        ),
        enableHiding: false,
      },
      {
        id: 'bobQuantity',
        size: 100,
        enableSorting: false,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.bobQuantity}
            textAlign="right"
            whiteSpace="unset"
          />
        ),
        cell: props => {
          const resourceId = props.row.original.resource_id;
          const vendorId = props.row.original.vendor_id;
          const quantity = (() => {
            if (resourceId)
              return rows
                .filter(row => row?.resource?.id === resourceId)
                .reduce((prev, { quantity }) => prev + (quantity || 0), 0);
            if (vendorId) {
              return rows
                .filter(row => row?.vendor?.id === vendorId)
                .reduce((prev, { quantity }) => prev + (quantity || 0), 0);
            }
            return 0;
          })();
          return (
            <OverflowEllipsis text={quantity} styles={{ textAlign: 'right' }} />
          );
        },
        meta: {
          text: copy.app.reconcileSuccessModal.columns.bobQuantity,
        },
      },
      {
        id: 'resource',
        accessorKey: 'id',
        enableSorting: false,
        size: 300,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.app.reconcileSuccessModal.columns.resource}
            whiteSpace="unset"
          />
        ),
        cell: props => {
          const item = props.row.original;
          return (
            <ResourceDropdownIntegration
              item={item}
              onResourceSelected={handleResourceSelected}
              onResetResourceSelected={handleResetResource}
            />
          );
        },
        meta: {
          text: copy.app.reconcileSuccessModal.columns.resource,
        },
      },
    ],
    [copy, LongArrowright, rows, handleResourceSelected, handleResetResource]
  );

  const table = useReactTable({
    columns,
    data: teamdeckItems,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnVisibility,
    },
    meta: {
      id: 'reconcileTeamdeck',
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    const top = tableWrapperRef.current?.getBoundingClientRect().top;
    setTableWrapperTop(top || 0);
  }, []);

  const teamdeckEnabled = !!organization?.integrations?.find(
    integration => integration.name === 'teamdeck'
  )?.api_key;

  if (!teamdeckEnabled)
    return (
      <Styled.EmptyWrapper>
        {checkAnyAccessRight(['super admin', 'owner'])
          ? modalCopy.teamdeckConfigurationRequiredAdmin
          : modalCopy.teamdeckConfigurationRequired}
      </Styled.EmptyWrapper>
    );

  const borderTop = `1px solid ${tableColors.dark.lines}`;

  const isWrapping = table
    .getSelectedRowModel()
    .rows.some(({ original }) => !wrapResourcesTeamdeck?.includes(original.id));

  const toggleWrapResources = () => {
    const selectedRowsIds = table
      .getSelectedRowModel()
      .rows.map(({ original }) => original.id);

    if (wrapResourcesTeamdeck) {
      if (isWrapping) {
        setWrapResourcesTeamdeck([
          ...wrapResourcesTeamdeck,
          ...selectedRowsIds,
        ]);
      } else {
        setWrapResourcesTeamdeck(
          wrapResourcesTeamdeck.filter(id => !selectedRowsIds.includes(id))
        );
      }

      table.resetRowSelection();
    }
  };

  return (
    <>
      <Styled.TableTitle>
        <Styled.TableHeaderLabel>
          {`${modalCopy.tableStartLabelTeamdeck}${
            teamdeckItems[0]?.project_name
              ? ` (${teamdeckItems[0]?.project_name})`
              : ''
          }`}
        </Styled.TableHeaderLabel>
        <Styled.TableTitleEnd>
          <Styled.TableHeaderLabel>
            {modalCopy.tableEndLabel}
          </Styled.TableHeaderLabel>
        </Styled.TableTitleEnd>
      </Styled.TableTitle>
      <TableToolbar>
        <TableToolbarStart>
          <ToolbarButton
            onClick={toggleWrapResources}
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
          >
            {(!table.getIsSomeRowsSelected() &&
              !table.getIsAllRowsSelected()) ||
            isWrapping
              ? modalCopy.wrapResources
              : modalCopy.unwrapResources}
          </ToolbarButton>
        </TableToolbarStart>
        <TableToolbarEnd>
          <DynamicColumnsDropdown table={table} />
        </TableToolbarEnd>
      </TableToolbar>
      <ScrollArea>
        <Styled.TableWrapper
          isDisabled={isLoading}
          ref={tableWrapperRef}
          tableWrapperTop={tableWrapperTop}
        >
          <Table>
            <THead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <THeadCell
                      key={header.id}
                      style={{
                        width: header.getSize(),
                        position: 'relative',
                        ...(header.id !== 'arrow'
                          ? {
                              borderTop,
                            }
                          : {}),
                      }}
                      isSelectColumn={
                        header.column.columnDef.meta?.isSelectColumn
                      }
                      isSortEnabled={header.column.getCanSort()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.id === 'quantity' && (
                        <div
                          style={{
                            padding: '0 2px',
                            position: 'absolute',
                            top: '10rem',
                            right: '20rem',
                          }}
                        >
                          <CardDropdown
                            button={<InfoIcon theme="dark" />}
                            cardProps={{
                              title:
                                copy.app.reconcileSuccessModal.columns.days,
                              text: copy.app.reconcileSuccessModal
                                .burnColumnTooltip,
                              cardStyles: {
                                background: colors.black,
                              },
                            }}
                            clickType="hover"
                          />
                        </div>
                      )}
                    </THeadCell>
                  ))}
                </TableHeaderRow>
              ))}
            </THead>
            <TBody>
              {table.getRowModel().rows?.length ? (
                <>
                  {table.getRowModel().rows.map(row => (
                    <TableRow
                      key={row.id}
                      size="small"
                      style={{
                        background: getMatchColor(row.original).secondary,
                      }}
                    >
                      {row.getVisibleCells().map(cell => (
                        <TableCell
                          key={cell.id}
                          style={{
                            width: cell.column.getSize(),
                            ...(cell.column.id === 'select'
                              ? {
                                  backgroundColor: getMatchColor(row.original)
                                    .primary,
                                }
                              : {}),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow size="medium">
                  <TableCell
                    colSpan={table.getVisibleFlatColumns().length}
                    style={{ textAlign: 'center' }}
                  >
                    {modalCopy.listEmptyTeamdeck}
                  </TableCell>
                </TableRow>
              )}
            </TBody>
          </Table>
        </Styled.TableWrapper>
      </ScrollArea>
      <ReconcileStats view="teamdeck" onContinueClick={onContinueClick} />
    </>
  );
};

export default memo(ReconcileTeamdeck);
