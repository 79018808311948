import { FC, memo } from 'react';

import { useDeleteUser } from 'queries/users';
import { useCopyStore, useGlobalStore } from 'store';
import { User } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteIntegrationProps {
  user: User;
}

const DeleteIntegration: FC<DeleteIntegrationProps> = ({ user }) => {
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const { mutateAsync: deleteUser, isLoading } = useDeleteUser();

  const close = () => {
    closeModal('deleteIntegration');
  };

  const handleDelete = async () => {
    await deleteUser(user.username);
    close();
  };

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: copy.admin.deleteIntegrationModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: close,
      }}
      confirmButtonProps={{
        label: copy.admin.deleteIntegrationModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleDelete,
        loading: isLoading,
        disabled: isLoading,
      }}
      title={copy.admin.deleteIntegrationModal.title}
      description={replaceString(
        copy.admin.deleteIntegrationModal.description,
        '{name}',
        user.name
      )}
    />
  );
};

export default memo(DeleteIntegration);
