import { useCopyStore } from 'store';
import { replaceString } from 'utils/replace';

const MINUTE_IN_SECONDS = 60;
const JUST_NOW_SECONDS = 3 * MINUTE_IN_SECONDS; // 3 min
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS;
const YEAR_IN_SECONDS = MONTH_IN_SECONDS * 12;

export const useFormatTimePassed = () => {
  const copy = useCopyStore(s => s.copy);
  const timePassedCopy = copy.app.timePassed;

  /**
   * @returns For example: 'Just now', '10 min ago' or '5 hours ago' etc.
   */
  const formatTimePassed = (date: Date | string) => {
    const d = new Date(date);
    const timePassedMilliseconds = new Date().getTime() - d.getTime();
    const timePassedSeconds = timePassedMilliseconds / 1000;
    if (timePassedSeconds < JUST_NOW_SECONDS) return timePassedCopy.justNow;
    if (timePassedSeconds < HOUR_IN_SECONDS)
      return replaceString(
        timePassedCopy.minAgo,
        '{name}',
        String(Math.floor(timePassedSeconds / MINUTE_IN_SECONDS))
      );
    if (timePassedSeconds < DAY_IN_SECONDS) {
      const hours = Math.floor(timePassedSeconds / HOUR_IN_SECONDS);
      return replaceString(
        hours === 1 ? timePassedCopy.hourAgo : timePassedCopy.hoursAgo,
        '{name}',
        String(hours)
      );
    }

    if (timePassedSeconds < MONTH_IN_SECONDS) {
      const days = Math.floor(timePassedSeconds / DAY_IN_SECONDS);
      return replaceString(
        days === 1 ? timePassedCopy.dayAgo : timePassedCopy.daysAgo,
        '{name}',
        String(days)
      );
    }

    if (timePassedSeconds < YEAR_IN_SECONDS) {
      const months = Math.floor(timePassedSeconds / MONTH_IN_SECONDS);
      return replaceString(
        months === 1 ? timePassedCopy.monthAgo : timePassedCopy.monthsAgo,
        '{name}',
        String(months)
      );
    }

    const years = Math.floor(timePassedSeconds / YEAR_IN_SECONDS);
    return replaceString(
      years === 1 ? timePassedCopy.yearAgo : timePassedCopy.yearsAgo,
      '{name}',
      String(years)
    );
  };

  return {
    formatTimePassed,
  };
};
