import { useMemo } from 'react';

import { useCopyStore } from 'store';
import { ProjectMode, ProjectStatus } from 'utils/api.types';

export const useProjectDisplayText = () => {
  const copy = useCopyStore(s => s.copy);

  const projectDisplayText = useMemo(
    () => ({
      status: {
        [ProjectStatus.active]: copy.app.statuses.active,
        [ProjectStatus.cancelled]: copy.app.statuses.cancelled,
        [ProjectStatus.closed]: copy.app.statuses.closed,
      },
      mode: {
        [ProjectMode.pitch]: copy.app.modes.pitch,
        [ProjectMode.production]: copy.app.modes.production,
        [ProjectMode.hosting]: copy.app.modes.hosting,
      },
    }),
    [copy]
  );

  const getStatusDisplayText = (mode: ProjectMode, status?: ProjectStatus) => {
    if (!status || status === ProjectStatus.active) {
      return projectDisplayText.mode[mode];
    }
    return `${projectDisplayText.status[status]} (${projectDisplayText.mode[mode]})`;
  };

  return getStatusDisplayText;
};

export default useProjectDisplayText;
