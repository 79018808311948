import { FC, memo, useCallback } from 'react';

import { availableCurrencies } from '@u9/bob3-shared/lib/types/api.types';
import { useDeleteSupportedClientRates } from 'queries/supportedClientRates';
import { useCopyStore, useGlobalStore } from 'store';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteClientRateColumnModal {
  currency: typeof availableCurrencies[number];
}

const DeleteClientRateModal: FC<DeleteClientRateColumnModal> = ({
  currency,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { mutateAsync: deleteSupportedClientRate, isLoading } =
    useDeleteSupportedClientRates();

  const handleClose = useCallback(() => {
    closeModal('deleteClientRateColumnModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    try {
      await deleteSupportedClientRate({ currency });
      handleClose();
    } catch (er) {}
  }, [currency, deleteSupportedClientRate, handleClose]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.deleteClientRateModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
        disabled: isLoading,
        dataCy: 'delete-client-rate-confirm',
      }}
      title={title}
      description={replaceString(description, '{name}', currency)}
    />
  );
};

export default memo(DeleteClientRateModal);
