import * as LabelPrimitive from '@radix-ui/react-label';
import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    padding: 20rem 0;
    flex: 1;
    border-bottom: 1px solid ${rgba(colors.white, 0.5)};
    ${({ theme: { locale } }) => setTypography('input', locale)}
    text-align: left;
  }
`;

export const LabelIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelIconWrapper = styled.div`
  margin-right: 5rem;
  margin-left: 15rem;
  display: flex;
`;

export const LabelIcon = styled.div`
  width: 32rem;
  height: 32rem;
  margin-bottom: 8rem;
  margin-top: 8rem;
`;

export const Separator = styled.div``;

export const NoIconWrapper = styled.div`
  ${({ theme: locale }) => setTypography('caption', locale)};
  margin-bottom: 7rem;
  margin-top: 7rem;
`;

export const Label = styled(LabelPrimitive.Root)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: locale }) => setTypography('caption', locale)};
`;
