import { FC, memo, useMemo, useState } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddBrandRequest } from 'queries/brandRequests';
import { useCopyStore, useGlobalStore } from 'store';
import { Brand } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './BrandRequestModal.styles';

export interface BrandRequestModalProps {
  brandName: Brand['name'];
}

const BrandRequestModal: FC<BrandRequestModalProps> = ({ brandName }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const {
    mutateAsync: addBrandRequest,
    isLoading: isAddingBrandRequest,
    isSuccess: isBrandRequested,
  } = useAddBrandRequest();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.nameRequired,
    ]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    brandName,
    validators
  );
  const [message, setMessage] = useState('');

  const close = () => {
    closeModal('brandRequest');
  };

  const suggestBrand = async () => {
    addBrandRequest({
      name: name,
      message: message,
    });
  };

  const isAnyInvalid = !isNameValid;

  return (
    <ConfigModal
      title={copy.app.brandRequestModal.title}
      buttonProps={
        isBrandRequested
          ? {
              label: copy.app.brandRequestModal.successButton,
              color: currentColor,
              onClick: close,
              icon: 'check',
            }
          : {
              label: copy.app.brandRequestModal.cta,
              color: currentColor,
              onClick: suggestBrand,
              icon: 'check',
              disabled: isAnyInvalid || isAddingBrandRequest,
            }
      }
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        {isBrandRequested ? (
          <Styled.Description>
            {copy.app.brandRequestModal.success}
          </Styled.Description>
        ) : (
          <>
            <Styled.Description>
              {copy.app.brandRequestModal.description}
            </Styled.Description>
            <FormInput
              onChange={setName}
              value={name}
              placeholder={copy.app.brandRequestModal.name}
              error={nameInvalidMessage}
              required
            />
            <FormInput
              onChange={setMessage}
              value={message}
              placeholder={copy.app.brandRequestModal.message}
            />
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(BrandRequestModal);
