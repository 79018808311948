import { FC, memo } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useDeclineProjectPermission } from 'queries/projectPermission';
import { useCopyStore } from 'store';
import { Project, ProjectPermission } from 'utils/api.types';

export interface PermittedUserListDropdownProps {
  item: ProjectPermission;
  projectId: Project['id'];
}

enum MenuItems {
  remove,
}

const PermittedUserListDropdown: FC<PermittedUserListDropdownProps> = ({
  item,
  projectId,
}) => {
  const copy = useCopyStore(s => s.copy);

  const dropdownItems = [
    {
      displayName: copy.app.shareProjectModal.removePermission,
      id: MenuItems.remove,
      icon: 'trash',
    },
  ];

  const { mutateAsync: declineProjectPermission } =
    useDeclineProjectPermission();

  const handleRemoveClick = () => {
    declineProjectPermission({
      projectId,
      username: item.username,
    });
  };

  const handleDropdownClick = (itemId: MenuItems) => {
    const actionMap = {
      [MenuItems.remove]: handleRemoveClick,
    };
    actionMap[itemId]?.();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        {copy.app.shareProjectModal.editorLabel}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {dropdownItems.map(({ id, displayName }) => (
          <DropdownMenuItem key={id} onSelect={() => handleDropdownClick(id)}>
            {displayName}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(PermittedUserListDropdown);
