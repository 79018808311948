import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';

import EditableLabel from 'components/inputs/EditableLabel/EditableLabel';
import { useCopyStore } from 'store';
import { BudgetEntry } from 'utils/api.types';

export interface LabelCellProps {
  props: CellContext<BudgetEntry, unknown>;
  isDisabled: boolean;
  saveBudgetEntry: () => void;
  handleChange: (value: any) => void;
}

const LabelCell: FC<LabelCellProps> = ({
  props,
  isDisabled,
  saveBudgetEntry,
  handleChange,
}) => {
  const copy = useCopyStore(s => s.copy);
  const [value, setValue] = useState(props.row.original.label || '');

  const save = () => {
    handleChange(value);
    setTimeout(() => {
      saveBudgetEntry();
    }, 0);
  };

  return (
    <EditableLabel
      onChange={setValue}
      onBlur={save}
      onEnter={save}
      onEscape={save}
      value={value}
      placeholder={copy.project.budgetModal.labelPlaceholder}
      styles={{ width: '100%' }}
      dataCy="budget-modal__label-field"
      disabled={isDisabled}
    />
  );
};

export default LabelCell;
