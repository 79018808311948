import React, { RefObject } from 'react';

import { ReactComponent as AlertIcon } from 'svgs/alert.svg';
import { ReactComponent as CloseIcon } from 'svgs/close.svg';
import { ReactComponent as WifiError } from 'svgs/wifi_error.svg';

import * as Styled from './Card.styles';

export const cardTypes = ['info', 'warning', 'error', 'networkError'] as const;

export interface CardProps {
  title: string;
  text: string;
  subText?: string;
  type?: typeof cardTypes[number];
  handleCloseClick: (() => void) | null;
  cardRef?: RefObject<HTMLDivElement>;
  children?: React.ReactNode | React.ReactNode[];
  dataCy?: string;
  cardStyles?: Record<string, unknown>;
}

const Card = ({
  title,
  text,
  subText,
  handleCloseClick,
  type = 'info',
  cardRef,
  children = null,
  dataCy = '',
  cardStyles,
}: CardProps) => {
  return (
    <Styled.Wrapper ref={cardRef} data-cy={dataCy} style={cardStyles}>
      <Styled.MainIcon>
        <Styled.MainIconWrapper type={type}>
          {type === 'networkError' ? <WifiError /> : <AlertIcon />}
        </Styled.MainIconWrapper>
      </Styled.MainIcon>
      {handleCloseClick && (
        <Styled.CloseButton
          onClick={handleCloseClick}
          data-cy="card-close-button"
        >
          <Styled.CloseButtonIcon>
            <CloseIcon />
          </Styled.CloseButtonIcon>
        </Styled.CloseButton>
      )}
      <Styled.Title type={type}>{title}</Styled.Title>
      <Styled.Text hasSubText={!!subText}>{text}</Styled.Text>
      {subText && <Styled.SubText>{subText}</Styled.SubText>}
      {children}
    </Styled.Wrapper>
  );
};

export default Card;
