import _uniqBy from 'lodash/uniqBy';

import { useCurrentProjectVersion } from 'queries/projectRows';
import { ResourceType } from 'utils/api.types';

import { ResourceTypes } from '../ResourceDropdown/ResourceDropdown';

export const useProjectResources = () => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const rows = currentProjectVersion?.rows ?? [];

  const resources = rows
    .filter(row => row.resource_type !== ResourceType.internal)
    .map(row => row.resource)
    .filter(Boolean);
  const vendors = rows.map(row => row.vendor).filter(Boolean);
  const expenses = rows
    .filter(({ description }) => !!description)
    .map(({ description }) => description);

  const resourcesAndVendors = [
    ..._uniqBy(
      resources?.map(resource => ({
        type: ResourceTypes.resource,
        id: resource?.id,
        name: resource?.name,
      })),
      'id'
    ),
    ..._uniqBy(
      vendors?.map(vendor => ({
        type: ResourceTypes.vendor,
        id: vendor?.id,
        name: vendor?.name,
      })),
      'id'
    ),
    ..._uniqBy(
      expenses?.map(description => ({
        id: description,
        name: description,
        type: ResourceTypes.expense,
      })),
      'id'
    ),
  ];

  return resourcesAndVendors;
};
