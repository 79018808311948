import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

import { BadgeVariantsType } from './Badge.types';

interface WrapperProps {
  variant: BadgeVariantsType;
  color: ColorNames;
  textColor: ColorNames;
  opacity: number;
}

export const Wrapper = styled.span`
  ${({ variant, color, textColor, opacity }: WrapperProps) => css`
    display: inline-flex;
    ${({ theme: { locale } }) => setTypography('subtitle1', locale)}
    border-radius: ${layout.borderRadius.normal}rem;
    color: ${colors[textColor]};
    padding: 6rem 24rem;
    border: 1px solid transparent;
    user-select: none;
    max-width: 100%;

    ${variant === 'contained' &&
    css`
      background: ${rgba(colors[color], opacity)};
    `}

    ${variant === 'outlined' &&
    css`
      border: 1px solid ${rgba(colors[color], opacity)};
    `}
  `}
`;
