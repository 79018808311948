import { FC, memo, useState } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useProject } from 'queries/project';
import { useGrantProjectPermission } from 'queries/projectPermission';
import { useCopyStore, useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ConfirmShareProjectModal.styles';

export interface ConfirmShareProjectModalProps {
  projectId: Project['id'];
  userEmail: string;
  username?: string;
}

const ConfirmShareProjectModal: FC<ConfirmShareProjectModalProps> = ({
  projectId,
  userEmail,
  username,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const [shared, setShared] = useState(false);

  const { mutateAsync: grantProjectPermission, isLoading } =
    useGrantProjectPermission();

  const { data: project } = useProject({ projectId });

  const onCloseClick = () => {
    closeModal('confirmShareProject');
  };

  const onConfirm = async () => {
    try {
      const { data: response } = await grantProjectPermission({
        projectId,
        username: userEmail,
      });
      if (response.username) {
        setShared(true);
      }
    } catch (err) {
      closeModal('confirmShareProject');
    }
  };

  return (
    <ConfigModal
      title={
        shared
          ? copy.app.confirmShareProjectModal.successTitle
          : copy.app.confirmShareProjectModal.title
      }
      buttonProps={
        shared
          ? {
              label: copy.app.confirmShareProjectModal.successButton,
              icon: 'check',
              onClick: onCloseClick,
              dataCy: 'confirm-share-project-modal__ok',
              color: currentColor,
            }
          : {
              label: copy.app.confirmShareProjectModal.confirmButton,
              icon: 'check',
              loading: isLoading,
              disabled: isLoading,
              onClick: onConfirm,
              color: currentColor,
              dataCy: 'confirm-share-project-modal__button',
            }
      }
      onCloseClick={onCloseClick}
    >
      {!shared ? (
        <>
          <Styled.ContentWrapper>
            <Styled.ModalRow>
              {!userEmail ? (
                copy.app.confirmShareProjectModal.username
              ) : (
                <Styled.UserLabel>{username}</Styled.UserLabel>
              )}
            </Styled.ModalRow>
            <Styled.ModalRow>
              <FormInput
                value={project?.name || ''}
                onChange={() => null}
                placeholder={copy.app.confirmShareProjectModal.projectLabel}
                disabled={true}
              />
            </Styled.ModalRow>
            {!userEmail && (
              <Styled.ModalRow>
                {copy.app.confirmShareProjectModal.optional}
              </Styled.ModalRow>
            )}
          </Styled.ContentWrapper>
        </>
      ) : (
        <Styled.FeedbackDescription>
          {copy.app.confirmShareProjectModal.successDescription}
        </Styled.FeedbackDescription>
      )}
    </ConfigModal>
  );
};

export default memo(ConfirmShareProjectModal);
