import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const MixedWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const WarningWrapper = styled.div`
  position: relative;
  top: 10rem;
  margin-right: 10rem;
`;
