import axios from 'axios';
import { useRouter } from 'next/router';
import { FC, useCallback, useRef } from 'react';
import {
  QueryClient,
  QueryClientProvider as QueryClientProviderReact,
} from 'react-query';

import { useErrorPopup } from 'hooks/useErrorPopup';
import { getQueryClient } from 'queries';
import { UNAUTHORIZED } from 'utils/api.helpers';
import { ROUTES } from 'utils/routes';

const QueryClientProvider: FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const router = useRouter();
  const { showError } = useErrorPopup();

  const onError = useCallback(
    (error: unknown) => {
      const isAxiosError = axios.isAxiosError(error);
      if (isAxiosError && error?.response?.status === UNAUTHORIZED) {
        router.push(ROUTES.AUTO_LOGOUT);
      } else {
        showError(error);
      }
    },
    [router, showError]
  );

  const queryClient = useRef<QueryClient>(
    getQueryClient({
      defaultOptions: {
        mutations: { onError },
        queries: { onError },
      },
    })
  );

  return (
    <QueryClientProviderReact client={queryClient.current}>
      {children}
    </QueryClientProviderReact>
  );
};

export default QueryClientProvider;
