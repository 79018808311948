import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { GeoArea } from 'utils/api.types';

export const TERRITORIES_KEY = 'TERRITORIES';

export const useTerritories = (options?: UseQueryOptions<GeoArea[]>) => {
  const getTerritories = useApiStore(s => s.apiClient.getTerritories);
  return useQuery({
    queryKey: TERRITORIES_KEY,
    queryFn: async () => (await getTerritories()).data,
    ...options,
  });
};

export const useAddTerritory = () => {
  const addTerritory = useApiStore(s => s.apiClient.addTerritory);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addTerritory>[0]) => addTerritory(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TERRITORIES_KEY);
      },
    }
  );
};

export const useUpdateTerritory = () => {
  const updateTerritory = useApiStore(s => s.apiClient.updateTerritory);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateTerritory>[0]) =>
      updateTerritory(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TERRITORIES_KEY);
      },
    }
  );
};

export const useDeleteTerritory = () => {
  const deleteTerritory = useApiStore(s => s.apiClient.deleteTerritory);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteTerritory>[0]) =>
      deleteTerritory(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TERRITORIES_KEY);
      },
    }
  );
};
