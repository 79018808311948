import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { useEffect } from 'react';

import Button, {
  ButtonProps,
} from '@u9/bob3-shared/lib/components/Button/Button';

import * as Styled from './ConfirmModal.styles';

export interface ConfirmModalProps {
  title: string;
  description: React.ReactNode | React.ReactNode[];
  cancelButtonProps: ButtonProps;
  confirmButtonProps: ButtonProps;
  content?: React.ReactNode | React.ReactNode[];
}

const ConfirmModal = ({
  title,
  description,
  cancelButtonProps,
  confirmButtonProps,
  content,
}: ConfirmModalProps) => {
  // https://github.com/radix-ui/primitives/issues/2122#issuecomment-1531430926
  useEffect(() => {
    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 0);

    return () => {
      document.body.style.pointerEvents = '';
    };
  }, []);

  return (
    <AlertDialog.Root defaultOpen open={true}>
      <AlertDialog.Portal>
        <Styled.Overlay />
        <Styled.Content>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Description>{description}</Styled.Description>
          {content && content}
          <Styled.Buttons>
            <AlertDialog.Cancel asChild>
              <Button {...cancelButtonProps} />
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button {...confirmButtonProps} />
            </AlertDialog.Action>
          </Styled.Buttons>
        </Styled.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default React.memo(ConfirmModal);
