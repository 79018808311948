import { memo } from 'react';

import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import { useCopyStore } from 'store';
import { Project, ProjectMode } from 'utils/api.types';
import { formatDate } from 'utils/formatters';

import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface ProjectInfoProps {
  projectId?: Project['id'];
}

export const ProjectInfo = ({ projectId }: ProjectInfoProps) => {
  const copy = useCopyStore(s => s.copy);
  const { project } = useProject(projectId);
  const { addJobNumberPrefix } = useJobNumberPrefix();

  if (!project) return null;

  return (
    <Styled.ProjectInfoWrapper>
      <Styled.AdditionalInfoSpace />
      <Styled.AdditionalInfo>
        <Styled.AdditionalInfoLabel>
          {copy.app.projectModal.editDate}
        </Styled.AdditionalInfoLabel>
        <Styled.AdditionalInfoValue>
          {formatDate(project.updated_at)}
        </Styled.AdditionalInfoValue>
      </Styled.AdditionalInfo>
      <Styled.AdditionalInfo>
        <Styled.AdditionalInfoLabel>
          {copy.app.projectModal.createdDate}
        </Styled.AdditionalInfoLabel>
        <Styled.AdditionalInfoValue>
          {formatDate(project.created_at)}
        </Styled.AdditionalInfoValue>
      </Styled.AdditionalInfo>
      {project.job_number_date && project.job_number_date !== '-' && (
        <Styled.AdditionalInfo>
          <Styled.AdditionalInfoLabel>
            {copy.app.projectModal.jobNumberDate}
          </Styled.AdditionalInfoLabel>
          <Styled.AdditionalInfoValue>
            {formatDate(project.job_number_date)}
          </Styled.AdditionalInfoValue>
        </Styled.AdditionalInfo>
      )}
      {project.date_closed && (
        <Styled.AdditionalInfo>
          <Styled.AdditionalInfoLabel>
            {copy.app.projectModal.closedDate}
          </Styled.AdditionalInfoLabel>
          <Styled.AdditionalInfoValue>
            {formatDate(project.date_closed)}
          </Styled.AdditionalInfoValue>
        </Styled.AdditionalInfo>
      )}
      {project.kickoff_date && (
        <Styled.AdditionalInfo>
          <Styled.AdditionalInfoLabel>
            {copy.app.projectModal.kickoffDate}
          </Styled.AdditionalInfoLabel>
          <Styled.AdditionalInfoValue>
            {formatDate(project.kickoff_date)}
          </Styled.AdditionalInfoValue>
        </Styled.AdditionalInfo>
      )}
      {project.delivery_date && (
        <Styled.AdditionalInfo>
          <Styled.AdditionalInfoLabel>
            {copy.app.projectModal.deliveryDate}
          </Styled.AdditionalInfoLabel>
          <Styled.AdditionalInfoValue>
            {formatDate(project.delivery_date)}
          </Styled.AdditionalInfoValue>
        </Styled.AdditionalInfo>
      )}
      {project.pitch_number && project.mode !== ProjectMode.pitch && (
        <Styled.AdditionalInfo>
          <Styled.AdditionalInfoLabel>
            {copy.app.projectModal.pitchNumber}
          </Styled.AdditionalInfoLabel>
          <Styled.AdditionalInfoValue>
            {addJobNumberPrefix(project.pitch_number, ProjectMode.pitch)}
          </Styled.AdditionalInfoValue>
        </Styled.AdditionalInfo>
      )}
    </Styled.ProjectInfoWrapper>
  );
};

export default memo(ProjectInfo);
