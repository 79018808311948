import { mountStoreDevtool } from 'simple-zustand-devtools';
import { StoreApi, UseBoundStore } from 'zustand';

import useAdminStore from './admin';
import useApiStore from './api';
import useCopyStore from './copy';
import useGlobalStore from './global';
import usePerformanceDashboardStore from './performanceDashboard';
import useProjectStore from './project';
import useProjectsListStore from './projectsList';

const addStoreDevtool = (
  name: string,
  store: UseBoundStore<StoreApi<Record<string, any>>>
) => {
  const separateRoot = document.createElement('div');
  separateRoot.id = `devtools-${name}`;
  document.body.appendChild(separateRoot);
  mountStoreDevtool(name, store, separateRoot);
};

const setupStoreDevTools = () => {
  if (
    process.env.IS_DEBUG ||
    new URLSearchParams(window.location.search).get('debugStore')
  ) {
    addStoreDevtool('ProjectStore', useProjectStore);
    addStoreDevtool('AdminStore', useAdminStore);
    addStoreDevtool('PerformanceDashboardStore', usePerformanceDashboardStore);
  }
};

export {
  setupStoreDevTools,
  useAdminStore,
  useApiStore,
  useCopyStore,
  useGlobalStore,
  usePerformanceDashboardStore,
  useProjectsListStore,
  useProjectStore,
};
