import { FC, memo, useState } from 'react';

import { availableCurrencies } from '@u9/bob3-shared/lib/types/api.types';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useDownloadReport } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectsListItem, ProjectVersion } from 'utils/api.types';
import { downloadBlob } from 'utils/download';
import { formatDateFilename } from 'utils/formatters';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ClientReportDownloadModal.styles';

export interface ClientReportDownloadModalProps {
  project: Project | ProjectsListItem;
  currentVersionId?: ProjectVersion['id'];
}

const ClientReportDownloadModal: FC<ClientReportDownloadModalProps> = ({
  project,
  currentVersionId,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: handler, isLoading } = useDownloadReport();
  const copy = useCopyStore(s => s.copy);

  const clientRatesItems = availableCurrencies.map(item => ({
    id: item,
    displayName: copy.app.currenciesLong[item],
  }));

  const [activeClientRateId, setActiveClientRateId] = useState<string>(
    clientRatesItems[0]?.id
  );

  const close = () => {
    closeModal('clientReportDownloadModal');
  };

  const download = async () => {
    const downloadResponse = await handler({
      projectId: project.id,
      reportType: 'clientFacing',
      ...(currentVersionId ? { versionId: currentVersionId } : {}),
      currency: activeClientRateId as (typeof availableCurrencies)[number],
    });
    if (!downloadResponse?.data) return;
    const date = new Date();
    const formattedDate = formatDateFilename(date);
    downloadBlob(
      `${project.name} ${formattedDate}.xlsx`,
      downloadResponse.data
    );
    close();
  };

  return (
    <ConfigModal
      buttonProps={{
        label: copy.app.downloadClientReportModal.button,
        color: currentColor,
        onClick: download,
        disabled: isLoading,
        loading: isLoading,
        icon: 'check',
        dataCy: 'download-client-facing-report-confirm',
      }}
      title={copy.app.downloadClientReportModal.title}
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        <Styled.Description>
          {copy.app.downloadClientReportModal.description}
        </Styled.Description>
        <Styled.DropdownWrapper>
          <Select
            label={copy.app.downloadClientReportModal.currencyLabel}
            value={String(activeClientRateId)}
            onValueChange={setActiveClientRateId}
          >
            {clientRatesItems.map(({ id, displayName }) => (
              <SelectItem key={id} value={String(id)}>
                {displayName}
              </SelectItem>
            ))}
          </Select>
        </Styled.DropdownWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ClientReportDownloadModal);
