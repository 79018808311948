import { FC, memo } from 'react';

import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useUpdateResource } from 'queries/resources';
import { useCopyStore, useGlobalStore } from 'store';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface AdminItemUnusedPostponeModalProps {
  item: AdminListItem;
}

const AdminItemUnusedPostponeModal: FC<AdminItemUnusedPostponeModalProps> = ({
  item,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { mutateAsync: updateResource } = useUpdateResource();

  const handleDecline = () => {
    closeModal('adminItemUnusedPostpone');
  };

  const handleConfirm = async () => {
    if (item.resourceId) {
      await updateResource({
        id: item.resourceId,
        unused: false,
      });
    }
    closeModal('adminItemUnusedPostpone');
  };

  return (
    <ConfirmModal
      title={copy.admin.adminItemUnusedPostpone.title}
      description={replaceString(
        copy.admin.adminItemUnusedPostpone.description,
        '{name}',
        item.name
      )}
      cancelButtonProps={{
        label: copy.admin.adminItemUnusedPostpone.declineButton,
        color: ColorNames.nandor,
        onClick: handleDecline,
        icon: 'close',
      }}
      confirmButtonProps={{
        label: copy.admin.adminItemUnusedPostpone.confirmButton,
        color: currentColor,
        onClick: handleConfirm,
        icon: 'check',
      }}
    />
  );
};

export default memo(AdminItemUnusedPostponeModal);
