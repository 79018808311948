import { useRouter } from 'next/router';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { BudgetEntry, Project, ProjectVersion } from 'utils/api.types';

import { PROJECT_CLOSURE_STATUS_KEY, PROJECTS_KEY } from './project';

export const BUDGET_ENTRIES_KEY = 'BUDGET_ENTIRES';
export const PITCH_BUDGET_ENTRIES_KEY = 'PITCH_BUDGET_ENTIRES';

export const getProjectBudgetEntiresKey = ({
  projectId,
  projectVersionId,
}: {
  projectId?: Project['id'];
  projectVersionId?: ProjectVersion['id'];
}) => {
  return [BUDGET_ENTRIES_KEY, String(projectId), String(projectVersionId)];
};

export const useProjectBudgetEntries = (
  params: Partial<Parameters<typeof getBudgetEntries>[0]>,
  options?: UseQueryOptions<BudgetEntry[]>
) => {
  const getBudgetEntries = useApiStore(s => s.apiClient.getBudgetEntries);
  return useQuery({
    queryKey: getProjectBudgetEntiresKey(params),
    queryFn: async () => {
      if (params?.projectId === undefined) {
        return Promise.reject(new Error('projectId is undefined'));
      }
      if (params?.projectVersionId === undefined) {
        return Promise.reject(new Error('projectVersionId is undefined'));
      }
      return (
        await getBudgetEntries({
          projectId: params.projectId,
          projectVersionId: params.projectVersionId,
        })
      ).data;
    },
    ...options,
  });
};

export const useCurrentProjectBudgetEntries = () => {
  const router = useRouter();
  const projectId = router.query.projectSlug?.[0];
  const projectVersionId = router.query.projectSlug?.[1];

  return useProjectBudgetEntries(
    {
      projectId: Number(projectId),
      projectVersionId: Number(projectVersionId),
    },
    { enabled: !!projectId && !!projectVersionId }
  );
};

export const useAddProjectBudgetEntry = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof addBudgetEntry>>,
    unknown,
    Parameters<typeof addBudgetEntry>[0]
  >
) => {
  const addBudgetEntry = useApiStore(s => s.apiClient.addBudgetEntry);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addBudgetEntry>[0]) =>
      addBudgetEntry(variables),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData(
          getProjectBudgetEntiresKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          }),
          data
        );
        queryClient.invalidateQueries(PROJECT_CLOSURE_STATUS_KEY);
        const affectsProject =
          variables.budgetEntry.invoice_amount !== undefined;
        if (affectsProject) {
          queryClient.invalidateQueries([
            PROJECTS_KEY,
            String(variables.projectId),
          ]);
        }
      },
      ...options,
    }
  );
};

export const useUpdateProjectBudgetEntry = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBudgetEntry>>,
    unknown,
    Parameters<typeof updateBudgetEntry>[0]
  >
) => {
  const updateBudgetEntry = useApiStore(s => s.apiClient.updateBudgetEntry);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateBudgetEntry>[0]) =>
      updateBudgetEntry(variables),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData(
          getProjectBudgetEntiresKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          }),
          data
        );
        queryClient.invalidateQueries(PROJECT_CLOSURE_STATUS_KEY);
        const affectsProject =
          variables.budgetEntry.invoice_amount !== undefined ||
          variables.budgetEntry.invoice_currency !== undefined ||
          variables.budgetEntry.date !== undefined;
        if (affectsProject) {
          queryClient.invalidateQueries([
            PROJECTS_KEY,
            String(variables.projectId),
          ]);
        }
      },
      ...options,
    }
  );
};

export const useDeleteProjectBudgetEntry = () => {
  const deleteBudgetEntry = useApiStore(s => s.apiClient.deleteBudgetEntry);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteBudgetEntry>[0]) =>
      deleteBudgetEntry(variables),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(PROJECT_CLOSURE_STATUS_KEY);
        queryClient.invalidateQueries(
          getProjectBudgetEntiresKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          })
        );
        queryClient.invalidateQueries([
          PROJECTS_KEY,
          String(variables.projectId),
        ]);
      },
    }
  );
};
