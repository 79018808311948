import React from 'react';

import { useSvgIcon } from 'u9/hooks/useSvgIcon';

import * as Styled from './InfoIcon.styles';

interface InfoIconProps {
  theme?: typeof Styled.themes[number];
}

const InfoIcon = ({ theme = 'light' }: InfoIconProps) => {
  const { SvgIcon } = useSvgIcon('info');
  return (
    <Styled.Wrapper theme={theme}>
      <SvgIcon />
    </Styled.Wrapper>
  );
};

export default React.memo(InfoIcon);
