import { Dispatch, FC, memo, SetStateAction } from 'react';

import { Project } from 'utils/api.types';

import { useRndDescriptionField } from '../hooks/useRndDescriptionField';
import { useRndExcludeField } from '../hooks/useRndExcludeField';
import { useRndPercentLevelField } from '../hooks/useRndPercentLevelField';
import RndCategory, { RndCategoryType } from './RndCategory/RndCategory';
import RndDescription from './RndDescription/RndDescription';
import RndExclude from './RndExclude/RndExclude';
import RndPercentLevel from './RndPercentLevel/RndPercentLevel';
import RndRange from './RndRange/RndRange';

import * as Styled from '../ProjectModal.styles';

export interface AdditionalInfoTabProps {
  project?: Project;
  rndCategoryId?: RndCategoryType;
  setRndCategoryId: Dispatch<SetStateAction<RndCategoryType | undefined>>;
  rndRange: [number, number];
  rndPercentLevelField: ReturnType<typeof useRndPercentLevelField>;
  rndExcludeField: ReturnType<typeof useRndExcludeField>;
  rndDescriptionField: ReturnType<typeof useRndDescriptionField>;
}

const AdditionalInfoTab: FC<AdditionalInfoTabProps> = ({
  project,
  rndCategoryId,
  setRndCategoryId,
  rndRange,
  rndDescriptionField,
  rndExcludeField,
  rndPercentLevelField,
}) => {
  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <RndCategory
          rndCategoryId={rndCategoryId}
          setRndCategoryId={setRndCategoryId}
          project={project}
        />
        {rndRange && (
          <>
            <Styled.FieldSpace />
            <RndRange rndRange={rndRange} />
          </>
        )}
        <Styled.FieldSpace />
        <RndPercentLevel
          rndPercentLevelField={rndPercentLevelField}
          project={project}
        />
      </Styled.Row>
      <Styled.Row>
        <RndExclude rndExcludeField={rndExcludeField} project={project} />
      </Styled.Row>
      <Styled.Row>
        <RndDescription
          rndDescriptionField={rndDescriptionField}
          project={project}
        />
      </Styled.Row>
    </Styled.TabWrapper>
  );
};

export default memo(AdditionalInfoTab);
