import { FC, memo } from 'react';

import { useNotificationsForList } from 'queries/notifications';
import { useSvgIcon } from 'u9/hooks';

import * as Styled from './NotificationsButton.styles';

export interface NotificationsButtonProps {
  onClick: () => void;
}

const NotificationsButton: FC<NotificationsButtonProps> = ({ onClick }) => {
  const { SvgIcon: BellIcon } = useSvgIcon('bell');
  const { data: notifications } = useNotificationsForList();

  const isNewNotifications = notifications?.some(
    notification => !notification.seen
  );

  return (
    <Styled.Wrapper
      tabIndex={0}
      onClick={onClick}
      data-cy="notifications-panel__button"
    >
      <Styled.BellIconWrapper>
        <BellIcon />
      </Styled.BellIconWrapper>
      {isNewNotifications && (
        <Styled.NewNotificationIndicator data-cy="notifications-panel__new-notification" />
      )}
    </Styled.Wrapper>
  );
};

export default memo(NotificationsButton);
