import { useMemo } from 'react';

import { Project, ProjectMode } from 'utils/api.types';

import { useJobNumberField } from './useJobNumberFIeld';
import { useProject } from './useProject';

export interface JobNumberProps {
  projectId?: Project['id'];
  activeMode: Project['mode'];
}

export const useJobNumber = ({ projectId, activeMode }: JobNumberProps) => {
  const { project } = useProject(projectId);

  const isJobNumberRequired = useMemo(
    () =>
      activeMode === ProjectMode.production ||
      activeMode === ProjectMode.hosting,
    [activeMode]
  );

  const {
    jobNumber,
    setJobNumber,
    isJobNumberValid,
    jobNumberError,
    duplicateError,
    setDuplicateError,
  } = useJobNumberField({
    isJobNumberRequired,
    project,
    activeMode: activeMode as Project['mode'],
  });

  return {
    isJobNumberRequired,
    jobNumber,
    setJobNumber,
    isJobNumberValid,
    jobNumberError,
    duplicateError,
    setDuplicateError,
  };
};
