import { useCallback } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useDownloadReport } from 'queries/project';
import { Project, ProjectsListItem } from 'utils/api.types';
import { downloadBlob } from 'utils/download';
import { formatDateFilename } from 'utils/formatters';

export const useDownloadMfrReportAction = ({
  currentProject,
}: {
  currentProject?: ProjectsListItem | Project;
}) => {
  const currentVersionId = useCurrentVersionId();
  const { mutateAsync: downloadReport } = useDownloadReport();

  const downloadAction = useCallback(async () => {
    if (currentProject && currentVersionId) {
      const downloadResponse = await downloadReport({
        projectId: currentProject?.id,
        reportType: 'mfrReport',
        versionId: currentVersionId,
      });
      if (!downloadResponse?.data) return;
      const date = new Date();
      const formattedDate = formatDateFilename(date);
      downloadBlob(
        `${currentProject.name} ${formattedDate}.xlsx`,
        downloadResponse.data
      );
    }
  }, [currentProject, currentVersionId, downloadReport]);

  return [downloadAction];
};
