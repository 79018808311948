import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  max-width: 1200rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 50rem;
`;

export const Divider = styled.div`
  width: 50rem;
`;

export const Info = styled.div`
  color: ${rgba(colors.white, 0.5)};
  margin: 50rem 0;
`;
