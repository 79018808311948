import { useCallback, useMemo, useState } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useListItems';
import { useAddUser, useUpdateUser } from 'queries/users';
import { useCopyStore } from 'store';
import { Permission } from 'utils/api.types';

export const useUserPartial = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.usernameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.usernameRequired,
    ]
  );
  const usernameField = useField(item?.name ?? '', validators);

  const emailValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.emailRequired,
      },
      email: { enabled: true, message: copy.app.validatorMessages.validEmail },
    }),
    [
      copy.app.validatorMessages.emailRequired,
      copy.app.validatorMessages.validEmail,
    ]
  );
  const emailField = useField(item?.email ?? '', emailValidators);

  const previousPermissionIds = useMemo(
    () => item?.permissionSet?.map(({ id }) => id) ?? [],
    [item?.permissionSet]
  );

  const [activePermissionIds, setActivePermissionIds] = useState<
    Permission['id'][]
  >(previousPermissionIds);

  const { mutateAsync: handleAdd } = useAddUser();
  const { mutateAsync: handleUpdate } = useUpdateUser();

  const isPartialValid = useMemo(() => {
    const [, , isUsernameValid] = usernameField;
    const [, , isEmailValid] = emailField;
    return isUsernameValid && isEmailValid && activePermissionIds?.length > 0;
  }, [activePermissionIds?.length, emailField, usernameField]);

  const save = useCallback(
    async (callback: () => void) => {
      const [username] = usernameField;
      const [email] = emailField;

      await handleAdd({
        name: username,
        username: email,
        account_type: 'user',
        permission_set: activePermissionIds,
      });

      callback?.();
    },
    [activePermissionIds, emailField, handleAdd, usernameField]
  );

  const update = useCallback(
    async (callback: () => void) => {
      const [username] = usernameField;
      const [email] = emailField;

      const addPermission = activePermissionIds.filter(
        id => !previousPermissionIds.includes(id)
      );
      const removePermission = previousPermissionIds.filter(
        id => !activePermissionIds.includes(id)
      );

      if (item?.userId) {
        await handleUpdate({
          id: item?.userId,
          name: username,
          username: email,
          add_permission: addPermission,
          remove_permission: removePermission,
        });
      }

      callback?.();
    },
    [
      activePermissionIds,
      emailField,
      handleUpdate,
      item?.userId,
      previousPermissionIds,
      usernameField,
    ]
  );

  return {
    usernameField,
    emailField,
    isPartialValid,
    save,
    update,
    activePermissionIds,
    setActivePermissionIds,
  };
};
