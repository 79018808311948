import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ProjectGroup } from 'utils/api.types';

import { PROJECTS_KEY } from './project';

export const PROJECT_GROUPS_KEY = 'PROJECT_GROUPS';

export const useProjectGroups = (options?: UseQueryOptions<ProjectGroup[]>) => {
  const getProjectGroups = useApiStore(s => s.apiClient.getProjectGroups);
  return useQuery({
    queryKey: PROJECT_GROUPS_KEY,
    queryFn: async () => (await getProjectGroups()).data,
    ...options,
  });
};

export const useAddProjectGroup = () => {
  const addProjectGroup = useApiStore(s => s.apiClient.addProjectGroup);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addProjectGroup>[0]) =>
      addProjectGroup(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_GROUPS_KEY);
      },
    }
  );
};

export const useUpdateProjectGroup = () => {
  const updateProjectGroup = useApiStore(s => s.apiClient.updateProjectGroup);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateProjectGroup>[0]) =>
      updateProjectGroup(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_GROUPS_KEY);
      },
    }
  );
};

export const useDeleteProjectGroup = () => {
  const deleteProjectGroup = useApiStore(s => s.apiClient.deleteProjectGroup);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteProjectGroup>[0]) =>
      deleteProjectGroup(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_GROUPS_KEY);
      },
    }
  );
};

export const useAddProjectToGroup = () => {
  const addProjectToGroup = useApiStore(s => s.apiClient.addProjectToGroup);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addProjectToGroup>[0]) =>
      addProjectToGroup(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_GROUPS_KEY);
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useRemoveProjectFromGroup = () => {
  const removeProjectFromGroup = useApiStore(
    s => s.apiClient.removeProjectFromGroup
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof removeProjectFromGroup>[0]) =>
      removeProjectFromGroup(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_GROUPS_KEY);
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};
