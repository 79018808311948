import React, { CSSProperties, useContext } from 'react';

import { TableContext } from '../Table';

import * as Styled from './THead.styles';

interface THeadProps {
  children: React.ReactNode | React.ReactNode[];
  style?: CSSProperties;
}

const THead = ({ children, style }: THeadProps) => {
  const { tableTheme } = useContext(TableContext);

  return (
    <Styled.Wrapper tableTheme={tableTheme} style={style}>
      {children}
    </Styled.Wrapper>
  );
};

export default THead;
