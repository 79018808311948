import { useCallback, useMemo, useRef } from 'react';

export const masksType = [
  'integer',
  'float',
  'floatNegative',
  'money',
] as const;
export type MasksType = (typeof masksType)[number];

export const useMask = (
  maskType?: MasksType,
  initialValue?: string | number
) => {
  const lastCorrectValue = useRef<string>(String(initialValue));
  const regex = useMemo(() => {
    if (!maskType) return undefined;
    const regexMap: Record<MasksType, RegExp> = {
      integer: /\d*/g,
      float: /\d*[.,]?\d*/g,
      floatNegative: /-{0,1}\d*[.,]?\d*/g,
      money: /\d*[.,]?\d{0,2}/g,
    };
    return regexMap[maskType];
  }, [maskType]);

  const sanitize = useCallback(
    (value: string) => {
      const maskEnabled = !!regex;
      if (!maskEnabled) return value;

      const shouldReplaceCommaWithDot =
        maskType && ['float', 'floatNegative', 'money'].includes(maskType);
      const match = value.match(regex);
      if (shouldReplaceCommaWithDot && match) {
        const hasComma = match[0].includes(',');
        if (hasComma) {
          match[0] = match[0].replace(',', '.');
        }
      }
      if (match) {
        lastCorrectValue.current = match[0];
      }

      return lastCorrectValue.current;
    },
    [maskType, regex]
  );

  const maskResetHelper = useCallback((value: string) => {
    lastCorrectValue.current = value;
  }, []);

  return { sanitize, maskResetHelper };
};
