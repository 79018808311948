import styled from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { hover, setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  width: 1700rem;
  padding: 70rem 0 250rem 70rem;
`;

export const Form = styled.div`
  width: 70%;
`;

export const BackButton = styled.div`
  margin-bottom: 100rem;
  cursor: pointer;
  ${hover(brightnessFilter)}
`;

export const ArrowLeftWrapper = styled.div`
  width: 44rem;
  height: 44rem;
`;

export const Title = styled.div`
  margin-bottom: 100rem;
  ${({ theme: { locale } }) => setTypography('body2', locale)};
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 80rem;
`;

export const FieldSpace = styled.div`
  margin-right: 100rem;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 80rem;
  right: 80rem;
`;

export const RoleNameWrapper = styled.div`
  width: 45%;
`;

export const CategoryDropdownWrapper = styled.div`
  width: 45%;
`;

export const CommentWrapper = styled.div`
  width: 100%;
`;
