import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40rem;
`;

export const Info = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)}
`;

export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20rem;
`;
