import { Dispatch, memo, SetStateAction, useMemo } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useTerritories } from 'queries/territories';
import { useCopyStore } from 'store';
import { GeoArea, Project, ProjectStatus } from 'utils/api.types';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface TerritoryProps {
  projectId?: Project['id'];
  activeTerritoryId?: GeoArea['id'];
  setActiveTerritoryId: Dispatch<SetStateAction<GeoArea['id'] | undefined>>;
  canEditProject: boolean;
}

export const Territory = ({
  projectId,
  activeTerritoryId,
  canEditProject,
  setActiveTerritoryId,
}: TerritoryProps) => {
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { data: territories } = useTerritories();

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.territory?.closed &&
    !activeTerritoryId;

  const modalCopy = copy.app.projectModal;

  const items = useMemo(
    () =>
      sort(
        territories?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [territories, locale]
  );

  const territoryItems = items;

  return (
    <Styled.FormDropdownWrapper>
      <Select
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.territory}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.territoryClosedWarningTitle}
                text={modalCopy.territoryClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
        value={activeTerritoryId ? String(activeTerritoryId) : undefined}
        onValueChange={v => setActiveTerritoryId(Number(v))}
        disabled={!canEditProject}
        placeholder={displayClosedWarning ? project.territory.name : undefined}
        dataCy="project-modal__territory-dropdown"
      >
        {territoryItems.map(({ displayName, id }) => (
          <SelectItem key={id} value={String(id)} dataCy={`${id}__menu-item`}>
            {displayName}
          </SelectItem>
        ))}
      </Select>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Territory);
