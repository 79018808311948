import { useMemo, useState } from 'react';

import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useListItems';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useCopyStore } from 'store';
import { ADMIN_ROUTES } from 'utils/routes';

const defaultValuesMap: Record<string, CategoryIdentifier> = {
  [ADMIN_ROUTES.USERS]: CategoryIdentifier.User,
  [ADMIN_ROUTES.BOB_ACCESS]: CategoryIdentifier.User,
  [ADMIN_ROUTES.INTERNAL]: CategoryIdentifier.Internal,
  [ADMIN_ROUTES.EXTERNAL]: CategoryIdentifier.External,
  [ADMIN_ROUTES.CLIENTS]: CategoryIdentifier.Client,
  [ADMIN_ROUTES.BRANDS]: CategoryIdentifier.Brand,
  [ADMIN_ROUTES.VENDOR]: CategoryIdentifier.Vendor,
};

export const useCategoryDropdown = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);
  const route = useCurrentAdminRoute();
  const [activeCategoryId, setActiveCategoryId] = useState(
    item?.categoryIdentifier ||
      defaultValuesMap[route as unknown as string] ||
      CategoryIdentifier.User
  );
  const categoryItems = useMemo(() => {
    const { users, internal, external, mfr, clients, brands, vendor } =
      copy.admin.editModal.categoryDropdown;
    return [
      {
        displayName: users,
        id: CategoryIdentifier.User,
      },
      {
        displayName: internal,
        id: CategoryIdentifier.Internal,
      },
      {
        displayName: external,
        id: CategoryIdentifier.External,
      },
      {
        displayName: mfr,
        id: CategoryIdentifier.Mfr,
      },
      { displayName: vendor, id: CategoryIdentifier.Vendor },
      {
        displayName: clients,
        id: CategoryIdentifier.Client,
      },
      {
        displayName: brands,
        id: CategoryIdentifier.Brand,
      },
    ];
  }, [copy.admin.editModal.categoryDropdown]);

  const activeCategoryDisplayName = useMemo(() => {
    return (
      categoryItems.find(item => item.id === activeCategoryId)?.displayName ||
      ''
    );
  }, [activeCategoryId, categoryItems]);

  return {
    categoryItems,
    activeCategoryId,
    setActiveCategoryId,
    activeCategoryDisplayName,
  };
};
