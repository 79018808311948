import { flexRender, Row } from '@tanstack/react-table';
import { rgba } from 'polished';
import { FC, memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DragHandle, TableCell, TableRow } from 'components/ShadTable/Table';
import { useGlobalStore } from 'store';
import { BudgetEntry } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

export interface BudgetTableRowProps {
  row: Row<BudgetEntry>;
  reorderRow: (startIndex: number, hoverIndex: number) => void;
}

const BudgetTableRow: FC<BudgetTableRowProps> = ({ row, reorderRow }) => {
  const currentColor = useGlobalStore(s => s.currentColor);

  const [{ isOver, offsetDiff }, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<any>) => {
      return reorderRow(draggedRow.index, row.index);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      offsetDiff: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  });

  const isSelectedStyles = row.getIsSelected()
    ? {
        background: rgba(colors[currentColor], 0.15),
      }
    : {};

  const additionalRowStyles = {
    ...isSelectedStyles,
  };

  return (
    <TableRow
      style={{
        opacity: isDragging ? 0 : 1,
        ...additionalRowStyles,
      }}
      ref={el => {
        dropRef(el);
        previewRef(el);
      }}
      isDragOver={isOver}
      draggingDirection={offsetDiff && offsetDiff.y < 0 ? 'top' : 'bottom'}
      {...{ 'data-cy': 'budget-entry-row' }}
      size="medium"
    >
      <TableCell ref={dragRef}>
        <DragHandle showOnRowHover />
      </TableCell>
      {row.getVisibleCells().map(cell => (
        <TableCell
          key={cell.id}
          style={{
            width: cell.column.getSize(),
          }}
          isSelectColumn={cell.column.columnDef.meta?.isSelectColumn}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default memo(BudgetTableRow);
