import { useMutation } from 'react-query';

import { useApiStore } from 'store';

export const useValidateAddress = () => {
  const validateAddress = useApiStore(s => s.apiClient.validateAddress);
  return useMutation((variables: Parameters<typeof validateAddress>[0]) =>
    validateAddress(variables)
  );
};
