import { FC } from 'react';
import React from 'react';

import * as Styled from './Breadcrumbs.styles';

export interface BreadcrumbsProps {
  children: React.ReactNode | React.ReactNode[];
}
export const BreadcrumbsRoot: FC<BreadcrumbsProps> = ({ children }) => {
  return <Styled.Wrapper aria-label="Breadcrumb">{children}</Styled.Wrapper>;
};

export type BreadcrumbsItemProps = {
  asChild?: boolean;
  lastChild?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};
export const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({
  asChild,
  lastChild,
  ...props
}) => {
  const Comp = asChild ? Styled.SlotItem : Styled.DirectItem;
  return (
    <Comp {...props} {...(lastChild ? { ['aria-current']: 'page' } : {})} />
  );
};
