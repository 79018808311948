import { Dispatch, FC, memo, SetStateAction } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useCopyStore } from 'store';

export interface MonetaryRangeProps {
  budgetFrom: string;
  budgetTo: string;
  setBudgetFrom: Dispatch<SetStateAction<string>>;
  setBudgetTo: Dispatch<SetStateAction<string>>;
}

const MonetaryRange: FC<MonetaryRangeProps> = ({
  budgetFrom,
  budgetTo,
  setBudgetFrom,
  setBudgetTo,
}) => {
  const copy = useCopyStore(s => s.copy);

  return (
    <>
      <FormInput
        onChange={setBudgetFrom}
        value={budgetFrom}
        placeholder={copy.app.projectsListFiltersModal.budgetFrom}
        mask="float"
      />
      <FormInput
        onChange={setBudgetTo}
        value={budgetTo}
        placeholder={copy.app.projectsListFiltersModal.budgetTo}
        mask="float"
      />
    </>
  );
};

export default memo(MonetaryRange);
