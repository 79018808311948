import React, { useCallback, useRef, useState } from 'react';

import {
  ToggleGroup,
  ToggleGroupItem,
} from 'components/ToggleGroup/ToggleGroup';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { usePayments } from 'queries/payments';
import { useCurrentProject } from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { BudgetEntry } from 'utils/api.types';
import { formatPercent } from 'utils/formatters';
import { replaceString } from 'utils/replace';

import ConfigModal from '../ConfigModal/ConfigModal';
import BudgetTable from './BudgetTable/BudgetTable';
import ContractsLink from './ContractsLink/ContractsLink';
import Payments from './Payments/Payments';

import * as Styled from './BudgetModal.styles';

export const emptyBudgetEntry: BudgetEntry = {
  invoice_amount: 0,
  label: '',
  terms: '',
};

type View = 'invoices' | 'payments';

const isBudgetViewType = (view: string): view is View =>
  ['invoices', 'payments'].includes(view);

const BudgetModal = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { getCanEditProject } = useProjectAccess();
  const [view, setView] = useState<View>('invoices');
  const { formatMoney } = useFormatMoney();
  const { data: payments } = usePayments(
    { projectId: currentProject?.id },
    { enabled: !!currentProject?.id }
  );

  const { data: projectVersion } = useCurrentProjectVersion();
  const budget = projectVersion?.budget;

  const close = useCallback(() => {
    closeModal('budgetModal');
  }, [closeModal]);

  const paymentsTotalAmount =
    payments?.reduce((acc, payment) => acc + (payment?.amount ?? 0), 0) ?? 0;

  return (
    <ConfigModal
      onCloseClick={close}
      color={currentColor}
      title={copy.project.budgetModal.title}
      buttonProps={{
        label: copy.project.budgetModal.button,
        icon: 'check',
        onClick: close,
        dataCy: 'budget-modal-save-button',
      }}
      zeroPadding
    >
      <Styled.Wrapper
        ref={wrapperRef}
        data-cy={
          !getCanEditProject(currentProject) ? 'budget-modal-disabled' : ''
        }
      >
        <Styled.ToggleWrapper>
          <ToggleGroup
            type="single"
            value={view}
            onValueChange={value => {
              if (value && isBudgetViewType(value)) {
                setView(value);
              }
            }}
          >
            <ToggleGroupItem value="invoices" colorScheme="dark">
              {copy.project.budgetModal.toggleInvoices}
            </ToggleGroupItem>
            <ToggleGroupItem value="payments" colorScheme="dark">
              {replaceString(
                copy.project.budgetModal.togglePayments,
                '{value}',
                paymentsTotalAmount
                  ? ` (${formatMoney(paymentsTotalAmount)} ${
                      copy.project.budgetModal.togglePaymentsTotal
                    })`
                  : ''
              )}
            </ToggleGroupItem>
          </ToggleGroup>
        </Styled.ToggleWrapper>
        {view === 'invoices' && (
          <Styled.TablesWrapper>
            <Styled.TableWrapper>
              <BudgetTable />
            </Styled.TableWrapper>
            <div>
              <Styled.ContractsWrapper>
                <ContractsLink />
              </Styled.ContractsWrapper>
            </div>
          </Styled.TablesWrapper>
        )}
        {view === 'payments' && <Payments />}

        {budget?.total_budget && budget?.total_budget > 0 ? null : (
          <Styled.Description>
            {copy.project.budgetModal.description}
          </Styled.Description>
        )}
        <Styled.InfoSection>
          <Styled.InfoField>
            <Styled.InfoLabel>
              {copy.project.budgetModal.margin}
            </Styled.InfoLabel>
            <Styled.InfoValueMargin>
              {formatPercent(budget?.margin_percent || 0)}
            </Styled.InfoValueMargin>
          </Styled.InfoField>
          <Styled.InfoField>
            <Styled.InfoLabel>
              {copy.project.budgetModal.totalBudget}
            </Styled.InfoLabel>
            <Styled.InfoValueBudget data-cy="budget-modal__project-budget">
              {formatMoney(budget?.total_budget || 0)}
            </Styled.InfoValueBudget>
          </Styled.InfoField>
        </Styled.InfoSection>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(BudgetModal);
