import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as Styled from './BaseTextArea.styles';

export type BaseTextAreaProps = {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  fieldRef?: MutableRefObject<HTMLTextAreaElement | null>;
  disabled?: boolean;
  rows?: number;
  readOnly?: boolean;
  styles?: Record<string, any>;
  dataCy?: string;
  dynamicRows?: {
    min: number;
    max: number;
  };
};

const BaseTextArea = ({
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  onClick,
  fieldRef,
  disabled,
  rows = 5,
  readOnly = false,
  dataCy = '',
  styles,
  dynamicRows,
}: BaseTextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [textAreaRows, setTextAreaRows] = useState(
    dynamicRows ? dynamicRows.min : rows
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event.currentTarget.value);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    onFocus && onFocus();
  }, [onFocus]);

  useEffect(() => {
    if (dynamicRows) {
      const height = (ref.current?.offsetHeight || 0) / textAreaRows;
      const scrollHeight = ref.current?.scrollHeight;
      const desiredRows = Math.ceil(scrollHeight || 0 / height);
      const constrainedRows = Math.min(
        Math.max(dynamicRows.min, desiredRows),
        dynamicRows.max
      );

      setTextAreaRows(constrainedRows);
    }
  }, [dynamicRows, rows, textAreaRows, value]);

  return (
    <Styled.Wrapper>
      <Styled.Field
        readOnly={readOnly}
        disabled={disabled}
        ref={node => {
          if (fieldRef && node) {
            fieldRef.current = node;
          }

          if (node) {
            ref.current = node;
          }
        }}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={onClick}
        rows={textAreaRows}
        data-cy={dataCy}
        style={styles}
      />
    </Styled.Wrapper>
  );
};

export default React.memo(BaseTextArea);
