import { CellContext } from '@tanstack/react-table';
import { FC, useState } from 'react';

import EditableLabel from 'components/inputs/EditableLabel/EditableLabel';
import { useCopyStore } from 'store';
import { BudgetEntry } from 'utils/api.types';
import { formatMoney } from 'utils/formatters';

export interface InvoiceAmountCellProps {
  props: CellContext<BudgetEntry, unknown>;
  isDisabled: boolean;
  saveBudgetEntry: () => void;
  handleChange: (value: any) => void;
}

const InvoiceAmountCell: FC<InvoiceAmountCellProps> = ({
  props,
  isDisabled,
  saveBudgetEntry,
  handleChange,
}) => {
  const locale = useCopyStore(s => s.locale);
  const item = props.row.original;
  const [value, setValue] = useState(
    String(item.invoice_amount ? item.invoice_amount : '')
  );

  const save = () => {
    handleChange(value);
    setTimeout(() => {
      saveBudgetEntry();
    }, 0);
  };

  return (
    <EditableLabel
      onChange={setValue}
      onBlur={save}
      onEnter={save}
      onEscape={save}
      value={value}
      labelValue={
        item.invoice_amount
          ? formatMoney(item.invoice_amount, item.invoice_currency, locale)
          : undefined
      }
      placeholder={formatMoney(0, item.invoice_currency, locale)}
      styles={{ width: '100%' }}
      mask="floatNegative"
      dataCy="budget-modal__invoice_amount"
      disabled={isDisabled}
    />
  );
};

export default InvoiceAmountCell;
