import { FC, memo, useCallback, useMemo } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCurrentProject } from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useDeleteProjectRow } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectRow } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import * as Styled from './DeleteProjectRowModal.styles';

export interface DeleteProjectRowModalProps {
  rowsIds: ProjectRow['id'][];
  onSuccess?: () => void;
}

const DeleteProjectRowModal: FC<DeleteProjectRowModalProps> = ({
  rowsIds,
  onSuccess,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { data: versionData } = useCurrentProjectVersion();
  const rows = versionData?.rows;

  const {
    mutateAsync: deleteProjectRows,
    isLoading: isDeleteProjectRowsLoading,
  } = useDeleteProjectRow();

  const deleteLoading = isDeleteProjectRowsLoading;

  const handleClose = useCallback(() => {
    closeModal('confirmDeleteProjectRow');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (currentProject?.id && currentVersionId) {
      const deleted = await deleteProjectRows({
        projectId: currentProject?.id,
        versionId: currentVersionId,
        rowsIds,
      });
      if (deleted?.data?.deleted) {
        handleClose();
        onSuccess?.();
      }
    }
  }, [
    currentProject?.id,
    currentVersionId,
    deleteProjectRows,
    rowsIds,
    handleClose,
    onSuccess,
  ]);

  const singleRow =
    rowsIds.length === 1 && rows?.find(({ id }) => id === rowsIds[0]);

  const description = useMemo(
    () => (
      <Styled.Description>
        <Styled.DescriptionNormal>
          {copy.project.deleteProjectRowsModal.description1}
        </Styled.DescriptionNormal>
        <Styled.DescriptionBold>
          {singleRow ? (
            <>
              ‘
              {singleRow?.type === 'spacer'
                ? singleRow?.spacer_name
                : singleRow?.role_name}
              ‘
            </>
          ) : (
            <>
              {replaceString(
                copy.project.deleteProjectRowsModal.rowsCount,
                '{name}',
                String(rowsIds.length)
              )}
            </>
          )}
        </Styled.DescriptionBold>
        <Styled.DescriptionNormal>
          {copy.project.deleteProjectRowsModal.description2}
        </Styled.DescriptionNormal>
      </Styled.Description>
    ),
    [
      copy.project.deleteProjectRowsModal.description1,
      copy.project.deleteProjectRowsModal.rowsCount,
      copy.project.deleteProjectRowsModal.description2,
      singleRow,
      rowsIds.length,
    ]
  );

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: deleteLoading,
        label: copy.project.deleteProjectRowsModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: deleteLoading,
        label: copy.project.deleteProjectRowsModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: deleteLoading,
        dataCy: 'delete-project-row-confirm',
      }}
      description={description}
      title={copy.project.deleteProjectRowsModal.title}
    />
  );
};

export default memo(DeleteProjectRowModal);
