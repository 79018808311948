import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';

import { ProjectMode, ProjectStatus } from 'utils/api.types';
import { getDefaultProjectsListFilter } from 'utils/getDefaultProjectsListFilter';

import { ParsedUrlQuery } from 'querystring';

export const getParsedProjectsListQueryString = (
  query: ParsedUrlQuery,
  session?: Session | null
) => {
  const budgetFrom = isNaN(Number(query.budgetFrom))
    ? null
    : Number(query.budgetFrom);
  const budgetTo = isNaN(Number(query.budgetTo))
    ? null
    : Number(query.budgetTo);
  const modes = String(query.modes || undefined)
    .split(',')
    .every(item => Object.values(ProjectMode).includes(item as ProjectMode))
    ? (String(query.modes).split(',') as ProjectMode[])
    : [];
  const statuses = String(query.statuses || undefined)
    .split(',')
    .every(item => Object.values(ProjectStatus).includes(item as ProjectStatus))
    ? (String(query.statuses).split(',') as ProjectStatus[])
    : [];
  const verticalIds = String(query.verticalIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.verticalIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const brandIds = String(query.brandIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.brandIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const clientIds = String(query.clientIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.clientIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const resourceIds = String(query.resourceIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.resourceIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const vendorIds = String(query.vendorIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.vendorIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const vendorServiceIds = String(query.vendorServiceIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.vendorServiceIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const roleIds = String(query.roleIds || undefined)
    .split(',')
    .every(item => !isNaN(Number(item)))
    ? String(query.roleIds)
        .split(',')
        .map(item => Number(item))
    : [];
  const groupId = isNaN(Number(query.groupId)) ? null : Number(query.groupId);
  const search = String(query.search || '');
  const select = String(
    query.select || (session ? getDefaultProjectsListFilter(session) : 'my')
  );

  return {
    ...(budgetFrom && { budgetFrom }),
    ...(budgetTo && { budgetTo }),
    ...(modes?.length && { modes }),
    ...(statuses?.length && { statuses }),
    ...(verticalIds?.length && { verticalIds }),
    ...(brandIds?.length && { brandIds }),
    ...(clientIds?.length && { clientIds }),
    ...(resourceIds?.length && { resourceIds }),
    ...(vendorIds?.length && { vendorIds }),
    ...(vendorServiceIds?.length && { vendorServiceIds }),
    ...(roleIds?.length && { roleIds }),
    ...(groupId && { groupId }),
    ...(search && { search }),
    ...(select && { select }),
  };
};

export const useParseProjectsListQueryString = () => {
  const router = useRouter();
  const { data: session } = useSession();

  return getParsedProjectsListQueryString(router.query, session);
};
