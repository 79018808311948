import * as Dialog from '@radix-ui/react-dialog';
import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const Title = styled(Dialog.Title)`
  ${({ theme: { locale } }) => setTypography('body3', locale)}
  color: ${colors.sunsetOrange};
  height: 100rem;
`;

export const Text = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  color: ${colors.white};
  margin: 34rem 0;
`;

export const SubText = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  color: ${rgba(colors.white, 0.5)};
`;

export const Buttons = styled.div`
  margin-top: 50rem;
  display: flex;
  gap: 40rem;
  justify-content: center;
`;

export const Content = styled(Dialog.Content)`
  max-height: 80vh;
  width: 730rem;
  border-radius: ${layout.borderRadius.big}rem;
  color: ${colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${contentShow} 150ms;
  overflow: hidden;
  background: ${colors.outerSpace};
  padding: 40rem;
  text-align: center;
`;

const overlayShow = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: ${rgba(colors.white, 0.5)};
  animation: ${overlayShow} 150ms;
`;
