import * as LabelPrimitive from '@radix-ui/react-label';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  max-width: 1000rem;
  padding-bottom: 120rem;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 50rem;
  width: 100%;
`;

export const FieldSpace = styled.div`
  width: 50rem;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const SuggestedAddress = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

export const SuggestedAddressLabel = styled.div`
  color: ${rgba(colors.white, 0.5)};
  margin-bottom: 5rem;
`;

export const SuggestedAddressButton = styled.button`
  text-decoration: underline;
  text-align: left;
  ${hover(css`
    color: ${rgba(colors.white, 0.8)};
  `)}
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    padding: 20rem 0;
    flex: 1;
    border-bottom: 1px solid ${rgba(colors.white, 0.5)};
    ${({ theme: { locale } }) => setTypography('input', locale)}
    text-align: left;
  }
`;

export const Label = styled(LabelPrimitive.Root)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: locale }) => setTypography('caption', locale)};
`;
