import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 40rem;
  height: 40rem;
  background: var(--current-color);
  color: ${colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  line-height: 1px;
`;
