import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

const DROPDOWN_OFFSET_PX = 6;

export type PopupWidthVariants = string | 'rootWidth' | 'initial';
export type PositionVariants = 'fixed' | 'absolute';

export type PopupXDirections = 'right' | 'left';
export type PopupYDirections = 'up' | 'down';

export const usePopupPosition = <T extends HTMLElement>({
  isOpen,
  popupWidthVariant = 'rootWidth',
}: {
  isOpen?: boolean;
  popupWidthVariant: PopupWidthVariants;
}) => {
  const popupWrapperRef = useRef<T>(null);
  const [width, setWidth] = useState<string | null>(null);
  const [top, setTop] = useState<number | null>(null);
  const [left, setLeft] = useState<number | null>(null);
  const [right, setRight] = useState<number | null>(null);
  const [bottom, setBottom] = useState<number | null>(null);
  const [yDirection, setYDirection] = useState<PopupYDirections>('down');
  const [xDirection, setXDirection] = useState<PopupXDirections>('right');
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      const wrapperRect = popupWrapperRef.current?.getBoundingClientRect();

      const topOffset = (wrapperRect?.bottom || 0) + DROPDOWN_OFFSET_PX;
      const leftOffset = wrapperRect?.left || 0;
      const rightOffset = window?.innerWidth - (wrapperRect?.right || 0);
      const bottomOffset =
        window?.innerHeight - (wrapperRect?.top || 0) + DROPDOWN_OFFSET_PX;

      const maxHeightDownwardsVh =
        (window.innerHeight - topOffset) / window.innerHeight;
      const maxHeightUpwardsVh =
        (window.innerHeight - bottomOffset) / window.innerHeight;
      const maxWidthLeftwardsVw =
        (window.innerWidth - rightOffset) / window.innerWidth;
      const maxWidthRightwardsVw =
        (window.innerWidth - leftOffset) / window.innerWidth;

      const tempYDirection: PopupYDirections =
        maxHeightDownwardsVh > maxHeightUpwardsVh ? 'down' : 'up';
      const tempXDirection: PopupXDirections =
        maxWidthLeftwardsVw > maxWidthRightwardsVw ? 'left' : 'right';
      setYDirection(tempYDirection);
      setXDirection(tempXDirection);

      switch (popupWidthVariant) {
        case 'rootWidth':
          setWidth(`${wrapperRect?.width || 0}px`);
          break;
        case 'initial':
          setWidth('initial');
          break;
        default:
          setWidth(popupWidthVariant);
      }

      const popupPadding = 0.02;

      if (tempYDirection === 'down') {
        setTop(topOffset);
        setBottom(null);
        setMaxHeight(maxHeightDownwardsVh - popupPadding);
      } else {
        setTop(null);
        setBottom(bottomOffset);
        setMaxHeight(maxHeightUpwardsVh - popupPadding);
      }

      if (tempXDirection === 'right') {
        setLeft(leftOffset);
        setRight(null);
        setMaxWidth(maxWidthRightwardsVw - popupPadding);
      } else {
        setLeft(null);
        setRight(rightOffset);
        setMaxWidth(maxWidthLeftwardsVw - popupPadding);
      }
    }
  }, [isOpen, popupWidthVariant]);

  const popupStyle: CSSProperties = useMemo(() => {
    return {
      ...(width ? { width } : {}),
      ...(maxWidth ? { maxWidth: `${maxWidth * 100}vw` } : {}),
      ...(maxHeight ? { maxHeight: `${maxHeight * 100}vh` } : {}),
      ...(top !== null ? { top: `${top}px` } : {}),
      ...(left !== null ? { left: `${left}px` } : {}),
      ...(right !== null ? { right: `${right}px` } : {}),
      ...(bottom !== null ? { bottom: `${bottom}px` } : {}),
    };
  }, [bottom, left, maxHeight, maxWidth, right, top, width]);

  return {
    xDirection,
    yDirection,
    popupWrapperRef,
    popupStyle,
  };
};
