import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import { useCreateProjectVersion, useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectVersion } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { getProjectRoute } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DuplicateProjectVersionModalProps {
  projectId: Project['id'];
  versionId: ProjectVersion['id'];
}

const DuplicateProjectVersionModal: FC<DuplicateProjectVersionModalProps> = ({
  projectId,
  versionId,
}) => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const { mutateAsync: createProjectVersion, isLoading } =
    useCreateProjectVersion();

  const { data: project } = useProject({
    projectId,
  });
  const version = project?.versions?.find(({ id }) => id === versionId);
  const versionName = version?.name;

  const duplicateProjectVersion = async () => {
    const { data: version } = await createProjectVersion({
      projectId,
      input: {
        duplicate_id: versionId,
      },
    });
    router.push(getProjectRoute(projectId, version.id));
  };

  const modalCopy = copy.app.duplicatePitchModal;

  return (
    <ConfirmModal
      title={modalCopy.title}
      description={replaceString(
        modalCopy.description,
        '{name}',
        versionName || ''
      )}
      cancelButtonProps={{
        label: modalCopy.cancelButton,
        color: ColorNames.nandor,
        onClick: () => closeModal('duplicateProjectVersion'),
        icon: 'close',
      }}
      confirmButtonProps={{
        label: modalCopy.confirmButton,
        color: currentColor,
        onClick: duplicateProjectVersion,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading,
        dataCy: 'duplicate-version-confirm',
      }}
    />
  );
};

export default memo(DuplicateProjectVersionModal);
