import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { Address } from '@u9/bob3-shared/lib/types/api.types';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useFilter } from 'hooks/userFilterData';
import { useBrandRequests } from 'queries/brandRequests';
import { useBrands } from 'queries/brands';
import { useClientRequests } from 'queries/clientRequests';
import { useClients } from 'queries/clients';
import { useOrganization } from 'queries/organizations';
import { useResources } from 'queries/resources';
import { useRoles } from 'queries/roles';
import { useSupportedMfrRate } from 'queries/supportedMfrRates';
import { useUsers } from 'queries/users';
import { useVendors } from 'queries/vendors';
import { useCopyStore } from 'store';
import {
  Brand,
  Client,
  Resource,
  ResourceType,
  Role,
  User,
  Vendor,
  VendorService,
  VerificationStatus,
  VerificationStatusType,
} from 'utils/api.types';
import { formatMoney } from 'utils/formatters';
import { ADMIN_ROUTES } from 'utils/routes';

import { ConfigType } from './useListConfig';

export enum CategoryIdentifier {
  User,
  Internal,
  External,
  Mfr,
  Client,
  Brand,
  Vendor,
  ClientRequest,
  BrandRequest,
}

export interface AdminListItem {
  name: string;
  category: string;
  categoryIdentifier: CategoryIdentifier;
  email?: string;
  role?: Role['role'];
  roleId?: Role['role_id'];
  userId?: User['id'];
  clientId?: Client['id'];
  brandId?: Brand['id'];
  resourceId?: Resource['id'];
  vendorId?: Vendor['id'];
  rate?: Resource['rate'];
  type?: Resource['type'];
  mfrId?: Resource['mfr_id'];
  mfrName?: string;
  vendorService?: VendorService['name'];
  vendorServiceId?: VendorService['id'];
  verificationStatus?: VerificationStatusType;
  clientCategory?: Client['category'];
  department?: Resource['department'];
  companyName?: Resource['company_name'];
  unused?: Resource['unused'];
  formatted?: {
    email?: string;
    ext?: string;
    accessGroup?: string;
    rate?: string;
    permissionSet?: string;
  };
  address?: Address;
  message?: string;
  requestId?: number;
  permissionSet?: User['permission_set'];
}

interface UseListItemsProps {
  filterText?: string;
  config: ConfigType;
}

export const useListItems = ({
  filterText,
  config,
}: UseListItemsProps): AdminListItem[] => {
  const copy = useCopyStore(s => s.copy);
  const router = useRouter();
  const route = useCurrentAdminRoute();

  const { data: users } = useUsers(
    {},
    {
      enabled: route === 'users',
    }
  );
  const { data: resources } = useResources({
    enabled: [
      'internal',
      'external',
      'internal/unused',
      'external/unused',
      'internal/verificationPending',
      'external/verificationPending',
    ].includes(route),
  });
  const { data: roles } = useRoles({
    enabled: [
      'roles',
      'internal',
      'external',
      'internal/unused',
      'external/unused',
      'internal/verificationPending',
      'external/verificationPending',
    ].includes(route),
  });
  const { data: clients } = useClients({ enabled: route === 'clients' });
  const { data: vendors } = useVendors({ enabled: route === 'vendor' });
  const { data: brands } = useBrands({ enabled: route === 'brands' });
  const { data: organization } = useOrganization();
  const { data: supportedMfrRates } = useSupportedMfrRate({
    enabled: route === 'external',
  });
  const { data: clientRequests } = useClientRequests({
    enabled: route === 'clients/requests',
  });
  const { data: brandRequests } = useBrandRequests({
    enabled: route === 'brands/requests',
  });

  const filterVerificationPending = useCallback(
    (item: AdminListItem) => {
      const routes = router.query.adminRoute as string[];
      const filterId = routes.find(route => {
        return route === 'verificationPending';
      });
      if (filterId === 'verificationPending') {
        return (
          item.verificationStatus?.status &&
          [VerificationStatus.unverified, VerificationStatus.rejected].includes(
            item.verificationStatus?.status
          )
        );
      } else {
        return true;
      }
    },
    [router.query.adminRoute]
  );

  const items: AdminListItem[] = useMemo(() => {
    const { categories } = copy.admin.list;
    return [
      ...(users?.map(user => ({
        userId: user.id,
        name: user.name,
        category: categories.user,
        categoryIdentifier: CategoryIdentifier.User,
        email: user.username,
        permissionSet: user.permission_set,
        formatted: {
          email: user.username || '-',
          permissionSet: user.permission_set
            ?.map(({ name }) => name)
            .join(', '),
        },
      })) ?? []),
      ...(resources
        ?.filter(resource => resource?.type === ResourceType.external)
        .map(external => ({
          resourceId: external.id,
          name: external.name,
          categoryIdentifier: CategoryIdentifier.External,
          category: categories.external,
          type: external.type,
          email: external.email,
          role: roles?.find(role => role.role_id === external.role_id)?.role,
          roleId: roles?.find(role => role.role_id === external.role_id)
            ?.role_id,
          verificationStatus: external.verification_status,
          companyName: external.company_name,
          unused: external.unused,
          department: external.department,
          address: external.address,
          formatted: {
            email: external.email || '-',
            ext: copy.app.territoryShort.external,
          },
        })) ?? []),
      ...(resources
        ?.filter(resource => resource?.type === ResourceType.mfr)
        .map(mfr => ({
          resourceId: mfr.id,
          name: mfr.name,
          categoryIdentifier: CategoryIdentifier.Mfr,
          category: categories.mfr,
          type: mfr.type,
          rate: mfr.rate,
          email: mfr.email,
          role: roles?.find(role => role.role_id === mfr.role_id)?.role,
          roleId: roles?.find(role => role.role_id === mfr.role_id)?.role_id,
          verificationStatus: mfr.verification_status,
          companyName: mfr.company_name,
          unused: mfr.unused,
          mfrId: mfr.mfr_id,
          mfrName: supportedMfrRates?.find(({ id }) => id === mfr.mfr_id)?.code,
          department: mfr.department,
          address: mfr.address,
          formatted: {
            email: mfr.email || '-',
            ext: copy.app.territoryShort.mfr,
            rate: formatMoney(
              mfr.rate,
              organization?.currency,
              organization?.language
            ),
          },
        })) ?? []),
      ...(resources
        ?.filter(resource => resource?.type === ResourceType.internal)
        .map(internal => ({
          resourceId: internal.id,
          name: internal.name,
          categoryIdentifier: CategoryIdentifier.Internal,
          category: categories.internal,
          email: internal.email,
          type: internal.type,
          role: roles?.find(role => role.role_id === internal.role_id)?.role,
          roleId: roles?.find(role => role.role_id === internal.role_id)
            ?.role_id,
          verificationStatus: internal.verification_status,
          unused: internal.unused,
          department: internal.department,
          address: internal.address,
          formatted: {
            email: internal.email || '-',
            ext: copy.app.territoryShort.internal,
          },
        })) ?? []),
      ...(vendors?.map(vendor => ({
        name: vendor.name,
        email: vendor.email,
        isExternal: true,
        vendorService: vendor?.service?.name,
        vendorServiceId: vendor?.service_id,
        categoryIdentifier: CategoryIdentifier.Vendor,
        category: categories.vendor,
        vendorId: vendor.id,
        address: vendor.address,
        formatted: {
          email: vendor.email || '-',
          ext: copy.app.territoryShort.external,
        },
      })) ?? []),
      ...(clients?.map(client => ({
        name: client.name,
        email: client.email,
        category: categories.client,
        categoryIdentifier: CategoryIdentifier.Client,
        clientId: client.id,
        clientCategory: client.category || '',
        formatted: {
          email: client.email || '-',
        },
      })) ?? []),
      ...(brands?.map(brand => ({
        name: brand.name,
        category: categories.brand,
        categoryIdentifier: CategoryIdentifier.Brand,
        brandId: brand.id,
      })) ?? []),
      ...(clientRequests?.map(client => ({
        name: client.name,
        email: client.email,
        formatted: {
          email: client.email || '-',
        },
        categoryIdentifier: CategoryIdentifier.ClientRequest,
        category: categories.client,
        requestId: client.id,
        message: client.message,
      })) ?? []),
      ...(brandRequests?.map(brand => ({
        name: brand.name,
        categoryIdentifier: CategoryIdentifier.BrandRequest,
        category: categories.brand,
        requestId: brand.id,
        message: brand.message,
      })) ?? []),
    ];
  }, [
    copy,
    users,
    resources,
    vendors,
    clients,
    brands,
    clientRequests,
    brandRequests,
    roles,
    supportedMfrRates,
    organization?.currency,
    organization?.language,
  ]);

  const filteredItems = useMemo(() => {
    if (route === ADMIN_ROUTES.USERS)
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.User
      );
    if (
      route === ADMIN_ROUTES.EXTERNAL ||
      route === ADMIN_ROUTES.EXTERNAL_UNVERIFIED
    )
      return items
        .filter(
          item =>
            item.categoryIdentifier === CategoryIdentifier.External ||
            item.categoryIdentifier === CategoryIdentifier.Mfr
        )
        .filter(filterVerificationPending);
    if (
      route === ADMIN_ROUTES.INTERNAL ||
      route === ADMIN_ROUTES.INTERNAL_UNVERIFIED
    )
      return items
        .filter(item => item.categoryIdentifier === CategoryIdentifier.Internal)
        .filter(filterVerificationPending);
    if (route === ADMIN_ROUTES.EXTERNAL_UNUSED)
      return items.filter(
        item =>
          (item.categoryIdentifier === CategoryIdentifier.External ||
            item.categoryIdentifier === CategoryIdentifier.Mfr) &&
          item.unused
      );
    if (route === ADMIN_ROUTES.INTERNAL_UNUSED)
      return items.filter(
        item =>
          item.categoryIdentifier === CategoryIdentifier.Internal && item.unused
      );
    if (route === ADMIN_ROUTES.CLIENTS)
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.Client
      );
    if (route === ADMIN_ROUTES.BRANDS)
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.Brand
      );
    if (route === ADMIN_ROUTES.VENDOR)
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.Vendor
      );
    if (route === ADMIN_ROUTES.CLIENTS_REQUESTS) {
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.ClientRequest
      );
    }
    if (route === ADMIN_ROUTES.BRANDS_REQUESTS) {
      return items.filter(
        item => item.categoryIdentifier === CategoryIdentifier.BrandRequest
      );
    }

    return items;
  }, [filterVerificationPending, items, route]);

  const filteredByText = useFilter(filteredItems, filterText, config.fields);

  return filteredByText ?? [];
};
