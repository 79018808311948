import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 1000rem;
`;

export const CostRow = styled.div<{ header?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  padding: 10rem 0;
  border-bottom: 1px solid ${rgba(colors.white, 0.1)};

  ${({ header }) =>
    header
      ? css`
          padding: 30rem 0;
          ${({ theme: { locale } }) => setTypography('caption', locale)};
          color: ${rgba(colors.white, 0.5)};
        `
      : ''}
`;

export const CostLabel = styled.div`
  width: 60%;
  min-width: 60%;
  max-width: 60%;
`;

export const CostValue = styled.div`
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  text-align: right;
`;

export const EntityName = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  padding-bottom: 50rem;
`;
