import { FC, useCallback, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useBulkUpdateResource, useResources } from 'queries/resources';
import { useDeleteRole, useRoles } from 'queries/roles';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { Resource, Role } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './DeleteRoleModal.styles';

type SelectedRoleType = Record<Resource['id'], Role['role_id'] | null>;

export interface DeleteRoleModalProps {
  role: Role;
}

const DeleteRoleModal: FC<DeleteRoleModalProps> = ({ role }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { SvgIcon: CheckIcon } = useSvgIcon('check');
  const { SvgIcon: AlertIcon } = useSvgIcon('alert');
  const { SvgIcon: LongArrowRight } = useSvgIcon('transition_arrow_right');
  const { mutateAsync: deleteRoleHandler, isLoading: deleteRoleLoading } =
    useDeleteRole();
  const { mutateAsync: bulkUpdateResource, isLoading: bulkUpdateLoading } =
    useBulkUpdateResource();

  const getInitialSelectedRoles = (resources: Resource[]) => {
    const resourcesInRole = resources.filter(
      resource => resource.role_id === role.role_id
    );
    const entries = resourcesInRole.map(resource => [resource.id, null]);
    const selectedRoles = Object.fromEntries(entries);
    return selectedRoles;
  };
  const { data: resources } = useResources({
    onSuccess: data => {
      setSelectedRoles(getInitialSelectedRoles(data));
    },
  });
  const { data: roles } = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<SelectedRoleType>(
    resources ? getInitialSelectedRoles(resources) : {}
  );

  const handleClose = useCallback(() => {
    closeModal('deleteRoleModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    const updateMap = Object.entries(selectedRoles)
      .map(([resourceId, roleId]) => ({
        id: Number(resourceId),
        role_id: Number(roleId),
      }))
      .filter(({ role_id }) => !!role_id);
    if (updateMap.length > 0) {
      await bulkUpdateResource(updateMap);
    }

    await deleteRoleHandler(role.row_no);
    handleClose();
  }, [
    bulkUpdateResource,
    deleteRoleHandler,
    handleClose,
    role.row_no,
    selectedRoles,
  ]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.deleteRoleModal;

  const resourcesInRole = resources?.filter(
    resource => resource.role_id === role.role_id
  );
  const rolesWithoutCurrent = roles
    ?.filter(r => r.role_id)
    .filter(r => r.role_id !== role.role_id);

  const isAllResourcesMapped =
    Object.entries(selectedRoles).length === 0 ||
    Object.entries(selectedRoles).every(([, roleId]) => !!roleId);

  return (
    <ConfigModal
      title={
        role?.role_id ? title : replaceString(title, '{role}', role?.category)
      }
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Description>
          {replaceString(
            description,
            '{role}',
            role?.role_id ? role?.role : role?.category
          )}
        </Styled.Description>
        {resourcesInRole && resourcesInRole?.length > 0 ? (
          <Styled.ActiveResources>
            {resourcesInRole?.map(resource => (
              <Styled.ActiveResource key={resource.id}>
                <Styled.ResourceName>
                  <Styled.ValidationIcon>
                    {selectedRoles[resource.id] ? (
                      <Styled.ValidIcon>
                        <CheckIcon />
                      </Styled.ValidIcon>
                    ) : (
                      <Styled.InvalidIcon>
                        <AlertIcon />
                      </Styled.InvalidIcon>
                    )}
                  </Styled.ValidationIcon>

                  <OverflowEllipsis text={resource.name} />
                </Styled.ResourceName>
                <Styled.Arrow>
                  <LongArrowRight />
                </Styled.Arrow>
                <Styled.RoleDropdown>
                  <Select
                    value={
                      selectedRoles[resource.id]
                        ? String(selectedRoles[resource.id])
                        : undefined
                    }
                    onValueChange={roleId =>
                      setSelectedRoles(prev => ({
                        ...prev,
                        [resource.id]: Number(roleId),
                      }))
                    }
                  >
                    {rolesWithoutCurrent?.map(roleItem => (
                      <SelectItem
                        key={roleItem.role_id}
                        value={String(roleItem.role_id)}
                      >
                        {roleItem.role}
                      </SelectItem>
                    ))}
                  </Select>
                </Styled.RoleDropdown>
              </Styled.ActiveResource>
            ))}
          </Styled.ActiveResources>
        ) : null}

        <Styled.Buttons>
          <Button
            label={cancelButton}
            icon="close"
            color={ColorNames.nandor}
            onClick={handleClose}
          />
          <Button
            label={confirmButton}
            icon="check"
            color={currentColor}
            onClick={handleConfirmClick}
            loading={deleteRoleLoading || bulkUpdateLoading}
            disabled={
              deleteRoleLoading || bulkUpdateLoading || !isAllResourcesMapped
            }
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default DeleteRoleModal;
