import { FC, memo, useCallback, useMemo, useState } from 'react';

import { useDeleteAdminItem } from 'components/modules/Admin/hooks/useDeleteAdminItem';
import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useListItems';
import { useCopyStore, useGlobalStore } from 'store';
import { Client, Resource } from 'utils/api.types';
import { replaceString, replaceStrings } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ClientContent from './ClientContent/ClientContent';
import ResourceContent from './ResourceContent/ResourceContent';

export interface DeleteAdminItemModalProps {
  item: AdminListItem;
}

const DeleteAdminItemModal: FC<DeleteAdminItemModalProps> = ({ item }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);

  const isResource = [
    CategoryIdentifier.Internal,
    CategoryIdentifier.External,
    CategoryIdentifier.Mfr,
  ].includes(item.categoryIdentifier);

  const isClient = [CategoryIdentifier.Client].includes(
    item.categoryIdentifier
  );

  const [activeResourceId, setActiveResourceId] = useState<
    Resource['id'] | null
  >(null);

  const [activeClientId, setActiveClientId] = useState<Client['id'] | null>(
    null
  );

  const { handleDelete, anyDeleteRunning } = useDeleteAdminItem();

  const title = useMemo(() => {
    const titleMap: Record<CategoryIdentifier, string> = {
      [CategoryIdentifier.User]: copy.admin.deleteModal.userLabel,
      [CategoryIdentifier.Client]: copy.admin.deleteModal.clientLabel,
      [CategoryIdentifier.Brand]: copy.admin.deleteModal.brandsLabel,
      [CategoryIdentifier.Internal]: copy.admin.deleteModal.resourceLabel,
      [CategoryIdentifier.External]: copy.admin.deleteModal.resourceLabel,
      [CategoryIdentifier.Mfr]: copy.admin.deleteModal.resourceLabel,
      [CategoryIdentifier.Vendor]: copy.admin.deleteModal.vendorLabel,
      [CategoryIdentifier.BrandRequest]: '',
      [CategoryIdentifier.ClientRequest]: '',
    };
    return replaceString(
      copy.admin.deleteModal.deleteTitle,
      '{category}',
      titleMap[item?.categoryIdentifier]
    );
  }, [copy, item?.categoryIdentifier]);

  const description = useMemo(() => {
    const resourceGroupMap: Record<CategoryIdentifier, string> = {
      [CategoryIdentifier.User]: copy.admin.deleteModal.usersLabel,
      [CategoryIdentifier.Client]: copy.admin.deleteModal.clientsLabel,
      [CategoryIdentifier.Brand]: copy.admin.deleteModal.brandsLabel,
      [CategoryIdentifier.Internal]: copy.admin.deleteModal.resourcesLabel,
      [CategoryIdentifier.External]: copy.admin.deleteModal.resourcesLabel,
      [CategoryIdentifier.Mfr]: copy.admin.deleteModal.resourcesLabel,
      [CategoryIdentifier.Vendor]: copy.admin.deleteModal.vendorsLabel,
      [CategoryIdentifier.BrandRequest]: '',
      [CategoryIdentifier.ClientRequest]: '',
    };

    return replaceStrings(copy.admin.deleteModal.description, [
      ['{name}', item?.name],
      ['{category}', resourceGroupMap[item?.categoryIdentifier]],
    ]);
  }, [item?.categoryIdentifier, item?.name, copy]);

  const handleClose = useCallback(() => {
    closeModal('deleteAdminItemModal');
  }, [closeModal]);

  const handleConfirm = useCallback(async () => {
    await handleDelete({
      ...item,
      ...(activeResourceId ? { parent_id: activeResourceId } : {}),
      ...(activeClientId ? { parent_id: activeClientId } : {}),
    });
    handleClose();
  }, [activeClientId, activeResourceId, handleClose, handleDelete, item]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: copy.admin.deleteModal.cancelButton,
        color: ColorNames.nandor,
        icon: 'close',
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: copy.admin.deleteModal.confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleConfirm,
        dataCy: 'admin-delete-item-confirm',
        loading: anyDeleteRunning,
        disabled: anyDeleteRunning,
      }}
      title={title}
      description={description}
      content={
        isResource ? (
          <ResourceContent
            item={item}
            activeResourceId={activeResourceId}
            setActiveResourceId={setActiveResourceId}
          />
        ) : isClient ? (
          <ClientContent
            activeClientId={activeClientId}
            setActiveClientId={setActiveClientId}
            item={item}
          />
        ) : null
      }
    />
  );
};

export default memo(DeleteAdminItemModal);
