import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import {
  useCurrentProject,
  useProjectClosureStatus,
  useRequestJobClosure,
} from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './RequestJobClosureModal.styles';

export interface RequestJobClosureModalProps {}

const RequestJobClosureModal: FC<RequestJobClosureModalProps> = () => {
  const closeModal = useGlobalStore(s => s.closeModal);
  const openModal = useGlobalStore(s => s.openModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const [success, setSuccess] = useState(false);

  const { data: projectClosureStatus } = useProjectClosureStatus({
    projectId: currentProject?.id,
  });
  const { mutateAsync: requestClosure } = useRequestJobClosure();

  const close = () => {
    closeModal('requestJobClosureModal');
  };

  const handleConfirm = async () => {
    if (!currentProject?.id) return;
    const {
      data: { success },
    } = await requestClosure({
      projectId: currentProject?.id,
    });
    if (success) {
      setSuccess(true);
    }
  };

  const contractsFolderMissing = projectClosureStatus?.issues?.some(
    ({ code }) => code === 'contract_missing'
  );
  const invoiceStatusMissing = projectClosureStatus?.issues?.some(
    ({ code }) => code === 'invoice_status'
  );

  const openContracts = () => {
    window.open(currentProject?.contract_url, '_blank');
  };

  const openBudgetModal = () => {
    openModal('budgetModal');
  };

  return (
    <ConfigModal
      title={copy.project.requestJobClosureModal.title}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      {success ? (
        <Styled.Wrapper>
          {copy.project.requestJobClosureModal.confirmText}
          <Styled.ButtonsWrapper>
            <Button
              label={copy.project.requestJobClosureModal.closeButton}
              icon="check"
              color={currentColor}
              onClick={close}
            />
          </Styled.ButtonsWrapper>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          {currentProject?.job_closure_requested && (
            <Styled.AlreadyRequestedLabel>
              {copy.project.requestJobClosureModal.alreadyRequested}
            </Styled.AlreadyRequestedLabel>
          )}
          <Styled.Description>
            {projectClosureStatus?.closable
              ? copy.project.requestJobClosureModal.allDoneDescription
              : copy.project.requestJobClosureModal.actionRequiredDescription}
          </Styled.Description>
          <Styled.ConditionList>
            <Styled.ConditionItem>
              <Styled.ConditionItemStart>
                <Styled.ConditionItemText>
                  {copy.project.requestJobClosureModal.contractsConditionLabel}
                </Styled.ConditionItemText>
                {'-'}
                <Styled.ConditionItemStatus isDone={!contractsFolderMissing}>
                  {contractsFolderMissing
                    ? copy.project.requestJobClosureModal.actionRequired
                    : copy.project.requestJobClosureModal.done}
                </Styled.ConditionItemStatus>
              </Styled.ConditionItemStart>
              <Styled.LookupButton onClick={openContracts}>
                {copy.project.handleJobClosureRequestModal.open}
              </Styled.LookupButton>
            </Styled.ConditionItem>
            <Styled.ConditionItem>
              <Styled.ConditionItemStart>
                <Styled.ConditionItemText>
                  {
                    copy.project.requestJobClosureModal
                      .invoiceStatusConditionLabel
                  }
                </Styled.ConditionItemText>
                {'-'}
                <Styled.ConditionItemStatus isDone={!invoiceStatusMissing}>
                  {invoiceStatusMissing
                    ? copy.project.requestJobClosureModal.actionRequired
                    : copy.project.requestJobClosureModal.done}
                </Styled.ConditionItemStatus>
              </Styled.ConditionItemStart>
              <Styled.LookupButton onClick={openBudgetModal}>
                {copy.project.handleJobClosureRequestModal.open}
              </Styled.LookupButton>
            </Styled.ConditionItem>
            <Styled.ConditionItem>
              <Styled.ConditionItemStart>
                <Styled.ConditionItemText>
                  {copy.project.requestJobClosureModal.jasLabel}
                </Styled.ConditionItemText>
                {'-'}
                <Styled.ConditionItemStatus isIndeterminate>
                  {copy.project.requestJobClosureModal.indeterminate}
                </Styled.ConditionItemStatus>
              </Styled.ConditionItemStart>
            </Styled.ConditionItem>
            <Styled.ConditionItem>
              <Styled.ConditionItemStart>
                <Styled.ConditionItemText>
                  {copy.project.requestJobClosureModal.sowMatchLabel}
                </Styled.ConditionItemText>
                {'-'}
                <Styled.ConditionItemStatus isIndeterminate>
                  {copy.project.requestJobClosureModal.indeterminate}
                </Styled.ConditionItemStatus>
              </Styled.ConditionItemStart>
            </Styled.ConditionItem>
            <Styled.ConditionItem>
              <Styled.ConditionItemStart>
                <Styled.ConditionItemText>
                  {copy.project.requestJobClosureModal.invoiceReceivedLabel}
                </Styled.ConditionItemText>
                {'-'}
                <Styled.ConditionItemStatus isIndeterminate>
                  {copy.project.requestJobClosureModal.indeterminate}
                </Styled.ConditionItemStatus>
              </Styled.ConditionItemStart>
            </Styled.ConditionItem>
          </Styled.ConditionList>
          <Styled.ButtonsWrapper>
            <Button
              label={copy.project.requestJobClosureModal.cancelButton}
              icon="arrow_left"
              color={currentColor}
              onClick={close}
            />
            <Button
              label={copy.project.requestJobClosureModal.confirmButton}
              icon="check"
              color={currentColor}
              onClick={handleConfirm}
              disabled={!projectClosureStatus?.closable}
            />
          </Styled.ButtonsWrapper>
        </Styled.Wrapper>
      )}
    </ConfigModal>
  );
};

export default memo(RequestJobClosureModal);
