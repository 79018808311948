import { useMemo } from 'react';

import { useRoles } from 'queries/roles';
import { Role } from 'utils/api.types';

export const useGetRoleCategory = (
  row?: Partial<Pick<Role, 'category' | 'row_no' | 'role_id'>>
) => {
  const { data: items } = useRoles();

  const roleCategory = useMemo(() => {
    if (row?.category) return row.category;

    if (!items) return '';
    const rowNo = items.find(({ role_id }) => role_id === row?.role_id)?.row_no;
    if (!rowNo) return '';

    return row
      ? [...items]
          .reverse()
          .splice(items.length - rowNo)
          .find(item => item.category)?.category
      : '';
  }, [items, row]);

  return { roleCategory };
};
