import { FC, memo, useState } from 'react';

import Button, {
  ButtonProps,
} from '@u9/bob3-shared/lib/components/Button/Button';
import { useOrganization, useUpdateOrganization } from 'queries/organizations';
import { useAdminStore, useCopyStore } from 'store';
import { uploadFile } from 'utils/nextApi';

interface WorkspaceSettingsButtonProps {}

const WorkspaceSettingsButton: FC<WorkspaceSettingsButtonProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const { data: organization } = useOrganization();
  const [isLoading, setIsLoading] = useState(false);
  const isSettingsEdit = useAdminStore(s => s.isSettingsEdit);
  const setIsSettingsEdit = useAdminStore(s => s.setIsSettingsEdit);
  const workspaceSettings = useAdminStore(s => s.workspaceSettings);
  const uploadedLogo = useAdminStore(s => s.uploadedLogo);

  const { mutateAsync: updateOrganization } = useUpdateOrganization();

  const handleEdit = () => {
    setIsSettingsEdit(true);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      let fileLink = '';
      setIsSettingsEdit(false);
      if (uploadedLogo) {
        const {
          data: { fileUrl },
        } = await uploadFile({ file: uploadedLogo });
        fileLink = fileUrl;
      }
      if (workspaceSettings?.id) {
        const { integrations, ...remainingWorkspaceSettings } =
          workspaceSettings;
        const updateIntegrations = integrations?.some(integration => {
          const teamdeckChanged =
            integration.name === 'teamdeck' &&
            integration.api_key !==
              organization?.integrations?.find(int => int.name === 'teamdeck')
                ?.api_key;
          const floatChanged =
            integration.name === 'float' &&
            integration.api_key !==
              organization?.integrations?.find(int => int.name === 'float')
                ?.api_key;
          return teamdeckChanged || floatChanged;
        });
        await updateOrganization({
          ...remainingWorkspaceSettings,
          ...(updateIntegrations
            ? {
                integrations,
              }
            : {}),
          ...(fileLink ? { logo: fileLink } : {}),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isOrganizationValid = () => {
    const { currency, language, id, name } = workspaceSettings || {};
    return [currency, language, id, name].every(Boolean);
  };

  const buttonProps: ButtonProps = isSettingsEdit
    ? {
        label: copy.admin.workspaceSettings.saveButton,
        icon: 'check',
        onClick: handleSave,
      }
    : {
        label: copy.admin.workspaceSettings.editButton,
        icon: 'edit',
        onClick: handleEdit,
      };

  return (
    <Button
      {...buttonProps}
      loading={isLoading}
      disabled={isSettingsEdit && !isOrganizationValid()}
      dataCy="workspace-settings__button"
    />
  );
};

export default memo(WorkspaceSettingsButton);
