import { useRouter } from 'next/router';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { usePopupPosition } from 'hooks/usePopupPosition';
import gtm from 'u9/utils/gtm';

import NotificationsButton from './NotificationsButton/NotificationsButton';
import NotificationsList from './NotificationsList/NotificationsList';

import * as Styled from './Notifications.styles';

export interface NotificationsProps {}

const Notifications: FC<NotificationsProps> = () => {
  const router = useRouter();
  const [isListOpen, setIsListOpen] = useState(false);

  const { popupStyle, popupWrapperRef } = usePopupPosition<HTMLDivElement>({
    isOpen: isListOpen,
    popupWidthVariant: '500px',
  });

  const toggleOpen = () => {
    setIsListOpen(prev => {
      const newIsOpen = !prev;
      if (newIsOpen) {
        gtm.trackEvent('Action', 'notifications_open');
      }
      return newIsOpen;
    });
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const isOutsideClick = !popupWrapperRef.current?.contains(
        event.target as HTMLDivElement
      );
      if (isOutsideClick) {
        setIsListOpen(false);
      }
    },
    [popupWrapperRef]
  );

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside);

    return () => document.body.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    setIsListOpen(false);
  }, [router.route, router.asPath]);

  return (
    <Styled.Wrapper ref={popupWrapperRef}>
      <NotificationsButton onClick={toggleOpen} />
      {isListOpen && (
        <NotificationsList
          handleCloseButtonClick={() => setIsListOpen(false)}
          popupStyle={popupStyle}
        />
      )}
    </Styled.Wrapper>
  );
};

export default memo(Notifications);
