import { rgba } from 'polished';

export enum ColorNames {
  transparent = 'transparent',
  white = 'white',
  black = 'black',
  blueRibbon = 'blueRibbon',
  crusta = 'crusta',
  electricViolet = 'electricViolet',
  grayNurse = 'grayNurse',
  mountainMeadow = 'mountainMeadow',
  nandor = 'nandor',
  outerSpace = 'outerSpace',
  selectiveYellow = 'selectiveYellow',
  sunsetOrange = 'sunsetOrange',
  doveGray = 'doveGray',
  dustyGray = 'dustyGray',
  silver = 'silver',
  malibu = 'malibu',
  alabaster = 'alabaster',
  concrete = 'concrete',
  alto = 'alto',
  mercury = 'mercury',
  gallery = 'gallery',
}

export enum FontNames {
  manrope = 'manrope',
}

export interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface Theme {
  colors: Record<ColorNames, string>;
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.transparent]: rgba('#ffffff', 0),
  [ColorNames.white]: '#fff',
  [ColorNames.black]: '#000',

  [ColorNames.blueRibbon]: '#0036ff',
  [ColorNames.malibu]: '#8ab4f8',
  [ColorNames.crusta]: '#ff7e35',
  [ColorNames.electricViolet]: '#6e33fb',
  [ColorNames.grayNurse]: '#fafbfa',
  [ColorNames.mountainMeadow]: '#1dbe53',
  [ColorNames.nandor]: '#555c5a',
  [ColorNames.outerSpace]: '#2b3331',
  [ColorNames.selectiveYellow]: '#ffbc01',
  [ColorNames.sunsetOrange]: '#ff5151',
  [ColorNames.doveGray]: '#666666',
  [ColorNames.dustyGray]: '#999999',
  [ColorNames.silver]: '#BBBBBB',
  [ColorNames.alabaster]: '#F7F7F7',
  [ColorNames.concrete]: '#F2F2F2',
  [ColorNames.alto]: '#E0E0E0',
  [ColorNames.mercury]: '#e6e6e6',
  [ColorNames.gallery]: '#EDEDED',
} as const;

export const fonts = {
  face: {
    // eslint-disable-next-line quotes
    [FontNames.manrope]:
      "'Manrope', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  scale: {
    heading1: {
      'font-family': FontNames.manrope,
      'font-size': '72rem',
      'line-height': '1.35',
      'font-weight': 500,
      'text-transform': 'none',
    },
    heading2: {
      'font-family': FontNames.manrope,
      'font-size': '48rem',
      'line-height': '1.35',
      'font-weight': 700,
      'text-transform': 'none',
    },
    heading3: {
      'font-size': '36rem',
      'line-height': '1.35',
      'font-weight': 600,
      'text-transform': 'none',
    },
    heading4: {
      'font-family': FontNames.manrope,
      'font-size': '32rem',
      'line-height': '1.35',
      'font-weight': 600,
      'text-transform': 'none',
    },
    body1: {
      'font-family': FontNames.manrope,
      'font-size': '48rem',
      'line-height': '1.5',
      'font-weight': 500,
      'text-transform': 'none',
    },
    body2: {
      'font-family': FontNames.manrope,
      'font-size': '44rem',
      'line-height': '1.5',
      'font-weight': 500,
      'text-transform': 'none',
    },
    body3: {
      'font-family': FontNames.manrope,
      'font-size': '32rem',
      'line-height': '1.5',
      'font-weight': 500,
      'text-transform': 'none',
    },
    body4: {
      'font-family': FontNames.manrope,
      'font-size': '28rem',
      'line-height': '1.5',
      'font-weight': 500,
      'text-transform': 'none',
    },
    body5: {
      'font-family': FontNames.manrope,
      'font-size': '24rem',
      'line-height': '1.5',
      'text-transform': 'none',
      'font-weight': 500,
      'letter-spacing': 0.03,
    },
    subtitle1: {
      'font-family': FontNames.manrope,
      'font-size': '28rem',
      'line-height': '1.5',
      'font-weight': 600,
      'text-transform': 'none',
    },
    subtitle2: {
      'font-family': FontNames.manrope,
      'font-size': '48rem',
      'line-height': '1.5',
      'font-weight': 600,
      'text-transform': 'none',
    },
    button: {
      'font-family': FontNames.manrope,
      'font-size': '32rem',
      'line-height': '1.5',
      'font-weight': 700,
      'text-transform': 'capitalize',
    },
    input: {
      'font-family': FontNames.manrope,
      'font-size': '32rem',
      'line-height': '1.5',
      'font-weight': 600,
      'text-transform': 'none',
    },
    caption: {
      'font-family': FontNames.manrope,
      'font-size': '22rem',
      'line-height': '1.5',
      'font-weight': 700,
      'letter-spacing': 0.03,
      'text-transform': 'uppercase',
    },
    label: {
      'font-family': FontNames.manrope,
      'font-size': '24rem',
      'line-height': '1.5',
      'font-weight': 700,
      'text-transform': 'uppercase',
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  pagePadding: { w: 100, h: 60 }, // rem
  contentPadding: { h: 100 }, // rem
  borderRadius: {
    small: 20,
    normal: 30,
    big: 40,
    large: 60,
  },
  lineOpacity: 0.1,
  inactiveBackgroundOpacity: 0.05,
  header: { h: 10 }, // vh
  performanceDashboardSideMenu: { w: 670, collapsedW: 50 }, // rem
  buttonPadding: {
    standalone: '20rem 40rem',
    form: '20rem 20rem 20rem 0',
    inTable: '20rem 40rem',
  },
  zIndex: {
    // Global stacking context
    nonFunctionals: 9999,

    // Layout stacking context
    modals: 12,
  },
} as const;

const theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
