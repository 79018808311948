import _groupBy from 'lodash/groupBy';
import { FC, Fragment, memo, useEffect, useRef, useState } from 'react';
import { useIsMutating } from 'react-query';

import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useGetColumnWidth } from 'components/Table/hooks/useGetColumnWidth';
import Row from 'components/Table/Row/Row';
import Table from 'components/Table/Table';
import TBody from 'components/Table/TBody/TBody';
import THead from 'components/Table/THead/THead';
import THeadCell from 'components/Table/THeadCell/THeadCell';
import {
  RECONCILE_MUTATION_KEY,
  useCurrentProject,
  useReconciliationData,
} from 'queries/project';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { ProjectRow } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import { useColumns } from './hooks/useColumns';
import { useDropdownActions } from './hooks/useDropdownActions';
import ReconcileGroupItem from './ReconcileGroupItem/ReconcileGroupItem';
import ReconcileItem from './ReconcileItem/ReconcileItem';
import ReconcileStats from './ReconcileStats/ReconcileStats';

import * as Styled from './ReconcileModal.styles';

export interface ReconcileJASProps {
  onContinueClick: () => void;
}

const ReconcileJAS: FC<ReconcileJASProps> = ({ onContinueClick }) => {
  const copy = useCopyStore(s => s.copy);
  const { columns } = useColumns({ type: 'JAS' });
  const { getColumnWidth } = useGetColumnWidth();
  const currentProject = useCurrentProject();
  const mutationsCount = useIsMutating({ mutationKey: RECONCILE_MUTATION_KEY });
  const isLoading = mutationsCount > 0;
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [tableWrapperTop, setTableWrapperTop] = useState(0);
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const rows = currentProjectVersion?.rows ?? [];

  const { data: reconcileData } = useReconciliationData({
    projectId: currentProject?.id,
  });

  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const {
    handleResetResourceSelected,
    handleResourceSelected,
    handleRowSelected,
  } = useDropdownActions();

  useEffect(() => {
    const top = tableWrapperRef.current?.getBoundingClientRect().top;
    setTableWrapperTop(top || 0);
  }, []);

  if (!reconcileData) return null;
  const { items } = reconcileData;

  const modalCopy = copy.app.reconcileSuccessModal;

  const groupedItems = Object.entries({
    ..._groupBy(
      items.filter(({ expense_id }) => !!expense_id),
      'expense_id'
    ),
    ..._groupBy(
      items.filter(({ expense_id }) => !expense_id),
      'acc_item'
    ),
  });

  const toggleExpand = (groupKey: string) => {
    setExpandedGroups(prev => {
      if (prev.includes(groupKey)) {
        return prev.filter(item => item !== groupKey);
      } else {
        return [...prev, groupKey];
      }
    });
  };

  const selectedRows = Array.from(new Set(items.map(({ row_id }) => row_id)))
    .map(rowId => rows?.find(({ id }) => id === rowId))
    .filter(Boolean);

  const overspentRowIds: ProjectRow['id'][] = [];

  selectedRows.forEach(selectedRow => {
    if (!selectedRow) return;
    const { id, total } = selectedRow;
    const relatedJasItems = items.filter(({ row_id }) => row_id === id);
    const jasItemsTotal = relatedJasItems.reduce(
      (prev, { jas_amount }) => prev + jas_amount,
      0
    );

    if (Math.round(jasItemsTotal * 100) / 100 > Math.round(total * 100) / 100) {
      overspentRowIds.push(id);
    }
  });

  const crossItemGroupOverspendRowIds = overspentRowIds.filter(rowId => {
    return (
      groupedItems.filter(([, items]) => {
        return items.filter(({ row_id }) => row_id === rowId).length > 0;
      }).length > 1
    );
  });

  return (
    <>
      {items.length > 0 ? (
        <>
          <Styled.TableTitle>
            <Styled.TableHeaderLabel>
              {modalCopy.tableStartLabel}
            </Styled.TableHeaderLabel>
            <Styled.TableTitleEnd>
              <Styled.TableHeaderLabel>
                {modalCopy.tableEndLabel}
              </Styled.TableHeaderLabel>
            </Styled.TableTitleEnd>
          </Styled.TableTitle>
          <ScrollArea>
            <Styled.TableWrapper
              isDisabled={isLoading}
              ref={tableWrapperRef}
              tableWrapperTop={tableWrapperTop}
            >
              <Table tableTheme="dark" size="smallNarrow">
                <THead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 99,
                    background: colors.outerSpace,
                  }}
                >
                  <Row>
                    {columns.map(column => (
                      <THeadCell
                        key={column.id}
                        width={getColumnWidth(column.flex, columns)}
                        align={column.align || 'left'}
                        minWidth={column.minWidth}
                        topBorder={column.topBorder}
                        divider={column.divider}
                      >
                        {column.headerLabel}
                      </THeadCell>
                    ))}
                  </Row>
                </THead>
                <TBody bottomSafeArea={false} topBorderRadius={false}>
                  {groupedItems.map(([groupKey, items]) => (
                    <Fragment key={groupKey}>
                      <ReconcileGroupItem
                        toggleExpand={toggleExpand}
                        groupKey={groupKey}
                        items={items}
                        isExpanded={expandedGroups.includes(groupKey)}
                        handleResetResourceSelected={
                          handleResetResourceSelected
                        }
                        handleResourceSelected={handleResourceSelected}
                        handleRowSelected={handleRowSelected}
                        isExpenseItem={items[0].expense_type === 'expense'}
                        overspentRowIds={overspentRowIds}
                        crossItemGroupOverspendRowIds={
                          crossItemGroupOverspendRowIds
                        }
                      />
                      {items.map(
                        (item, index, arr) =>
                          (item.expense_id
                            ? expandedGroups.includes(item.expense_id)
                            : expandedGroups.includes(item.acc_item)) && (
                            <ReconcileItem
                              key={item.jas_id}
                              item={item}
                              handleRowSelected={handleRowSelected}
                              isLastItem={index === arr.length - 1}
                              isExpenseItem={
                                items[0]?.expense_type === 'expense'
                              }
                              overspentRowIds={overspentRowIds}
                              view="JAS"
                              crossItemGroupOverspendRowIds={
                                crossItemGroupOverspendRowIds
                              }
                            />
                          )
                      )}
                    </Fragment>
                  ))}
                </TBody>
              </Table>
            </Styled.TableWrapper>
          </ScrollArea>
        </>
      ) : (
        <Styled.EmptyWrapper>{modalCopy.listEmpty}</Styled.EmptyWrapper>
      )}
      <ReconcileStats view="JAS" onContinueClick={onContinueClick} />
    </>
  );
};

export default memo(ReconcileJAS);
