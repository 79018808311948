import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100vw;
  min-width: 2000rem;
  max-width: 3000rem;
  position: relative;
  max-height: ${setVh(80)};
`;

export const InfoSection = styled.div`
  display: flex;
  padding: 40rem 110rem 0;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  gap: 400rem;
  align-self: center;
  background: ${colors.outerSpace};
`;

export const InfoField = styled.div`
  display: flex;
  align-items: baseline;
`;

export const InfoLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)}
  color: ${rgba(colors.white, 0.5)};
  margin-right: 40rem;
  display: flex;
  align-items: center;
  cursor: default;
`;

export const InfoValueBudget = styled.div`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  color: ${rgba(colors.white, 0.5)};
  max-width: 500rem;
  cursor: default;
`;

export const InfoValueMargin = styled.div`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  color: ${rgba(colors.white, 0.5)};
  cursor: default;
`;

export const Toolbar = styled.div`
  position: absolute;
  left: 20rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;

export const SpinnerWrapper = styled.div``;

export const Spinner = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const EmptyList = styled.div`
  position: relative;
  top: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyListLabel = styled.div`
  margin-bottom: 20rem;
`;

export const DatepickerWrapper = styled.div`
  width: 100%;
`;

export const TablesWrapper = styled.div`
  display: flex;
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const ContractsWrapper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
`;

export const Description = styled.div`
  text-align: center;
  color: ${rgba(colors.white, 0.5)};
  margin-top: 40rem;
`;

export const TotalInvoice = styled.div`
  width: 100%;
  color: ${rgba(colors.white, 0.5)};
`;

export const AlertWrapper = styled.div`
  position: relative;
  width: 30rem;
  height: 30rem;
  color: ${colors.sunsetOrange};
  margin-right: 20rem;
`;

export const DueDateWrapper = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${rgba(colors.white, 0.3)};
        `
      : ''}
`;

export const ToggleWrapper = styled.div`
  margin: 20rem;
`;
