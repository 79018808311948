import { useCallback } from 'react';

import { useDownloadReport } from 'queries/project';
import { Project, ProjectsListItem } from 'utils/api.types';
import { downloadBlob } from 'utils/download';
import { formatDateFilename } from 'utils/formatters';

interface UseDownloadAction {
  currentProject?: ProjectsListItem | Project;
}

export const useDownloadAction = ({ currentProject }: UseDownloadAction) => {
  const { mutateAsync: handler } = useDownloadReport();

  const downloadAction = useCallback(async () => {
    if (currentProject) {
      const downloadResponse = await handler({ projectId: currentProject?.id });
      if (!downloadResponse?.data) return;
      const date = new Date();
      const formattedDate = formatDateFilename(date);
      downloadBlob(
        `${currentProject.name} ${formattedDate}.xlsx`,
        downloadResponse.data
      );
    }
  }, [handler, currentProject]);

  return [downloadAction];
};
