import { useCallback } from 'react';

import { EditProjectVersionNameModalProps } from 'components/modals/EditProjectVersionNameModal/EditProjectVersionNameModal';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useGlobalStore } from 'store';
import { Project } from 'utils/api.types';

export const useEditProjectVersionNameAction = ({
  currentProjectId,
}: {
  currentProjectId?: Project['id'];
}) => {
  const currentVersionId = useCurrentVersionId();
  const openModal = useGlobalStore(s => s.openModal);

  const editProjectVersionNameAction = useCallback(() => {
    if (!currentProjectId || !currentVersionId) return;
    openModal<EditProjectVersionNameModalProps>('editProjectVersionName', {
      projectId: currentProjectId,
      versionId: currentVersionId,
    });
  }, [currentProjectId, openModal, currentVersionId]);

  return [editProjectVersionNameAction];
};
