import { FC, memo } from 'react';
import reactStringReplace from 'react-string-replace';

type Replacement = [string, string | React.ReactNode | React.ReactNode[]];

export interface CopyProps {
  text: string;
  replacements?: Replacement[];
}

const Copy: FC<CopyProps> = ({ text, replacements = [] }) => {
  let result: ReturnType<typeof reactStringReplace> = [text];
  replacements.forEach(replacement => {
    result = reactStringReplace(result, replacement[0], () => replacement[1]);
  });

  return <>{result}</>;
};

export default memo(Copy);
