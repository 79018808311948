import React, { MouseEvent, useCallback } from 'react';

import { useSvgIcon } from 'u9/hooks/useSvgIcon';

import * as Styled from './Checkbox.styles';

export const checkboxIconVariants = ['check', 'dash'];

export interface CheckboxProps {
  value: boolean;
  setValue: (value: boolean, event: MouseEvent<HTMLDivElement>) => void;
  border?: boolean;
  label?: string;
  disabled?: boolean;
  variant?: typeof Styled.variants[number];
  icon?: typeof checkboxIconVariants[number];
  dataCy?: string;
}

const Checkbox = ({
  value,
  setValue,
  border = true,
  label,
  disabled = false,
  variant = 'round',
  icon = 'check',
  dataCy = '',
}: CheckboxProps) => {
  const { SvgIcon: CheckIcon } = useSvgIcon(icon);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) return;
      setValue(!value, event);
    },
    [disabled, setValue, value]
  );

  return (
    <Styled.OuterWrapper
      onClick={handleClick}
      tabIndex={0}
      disabled={disabled}
      {...(disabled ? { 'data-disabled': true } : {})}
      data-cy={dataCy}
    >
      <Styled.Wrapper border={border} variant={variant}>
        {value && (
          <Styled.IconWrapper>
            <CheckIcon />
          </Styled.IconWrapper>
        )}
      </Styled.Wrapper>
      {label && <Styled.LabelWrapper>{label}</Styled.LabelWrapper>}
    </Styled.OuterWrapper>
  );
};

export default React.memo(Checkbox);
