import create from 'zustand';

import { Organization } from '@u9/bob3-shared/lib/types/api.types';
import { AtLeast } from 'types/helpers';

export interface AdminStore {
  isSettingsEdit: boolean;
  setIsSettingsEdit: (value: boolean) => void;
  workspaceSettings: AtLeast<Organization, 'id'> | null;
  setWorkspaceSettings: (
    organization: AtLeast<Organization, 'id'> | null
  ) => void;
  uploadedLogo: File | null;
  setUploadedLogo: (file: File | null) => void;
}

const useAdminStore = create<AdminStore>(set => {
  return {
    isSettingsEdit: false,
    setIsSettingsEdit: isEdit => {
      set(() => ({
        isSettingsEdit: isEdit,
      }));
    },

    // These are to share the state between components in workspace settings
    // If you want to use workspace settings outside of this case, use useOrganization hook
    workspaceSettings: null,
    setWorkspaceSettings: organization => {
      set(() => ({
        workspaceSettings: organization,
      }));
    },

    uploadedLogo: null,
    setUploadedLogo: file => {
      set(() => ({
        uploadedLogo: file,
      }));
    },
  };
});

export default useAdminStore;
