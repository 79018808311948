import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { hover } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div<{
  backgroundColor: ColorNames;
  disabled: boolean;
}>`
  background: ${({ backgroundColor }) => colors[backgroundColor]};
  display: inline-flex;
  align-items: center;
  border-radius: ${layout.borderRadius.normal}rem;

  ${({ disabled }) =>
    disabled
      ? css`
          ${brightnessFilter};
          cursor: default;
        `
      : ''}
`;

export const Item = styled.div<{
  backgroundColor: ColorNames;
  iconColor: ColorNames;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 60rem;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.2s ease-out, color 0.2s ease-out;

  background: ${({ backgroundColor }) =>
    colors[backgroundColor] || 'transparent'};

  color: ${({ iconColor }) => colors[iconColor] || 'white'};

  ${hover(brightnessFilter)};
`;

export const TextItem = styled.div<{
  backgroundColor: ColorNames;
  color: ColorNames;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60rem;
  padding: 0 20rem;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.2s ease-out, color 0.2s ease-out;

  background: ${({ backgroundColor }) =>
    colors[backgroundColor] || 'transparent'};

  color: ${({ color }) => colors[color] || 'white'};

  ${hover(brightnessFilter)};
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 20rem;
  background: ${rgba(colors.white, 0.2)};
`;
