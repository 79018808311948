import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useClients } from 'queries/clients';
import { useCopyStore } from 'store';
import { Client as ClientType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface ClientProps {
  clientIds: ClientType['id'][];
  setClientIds: Dispatch<SetStateAction<ClientType['id'][]>>;
}

const Client: FC<ClientProps> = ({ setClientIds, clientIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: clients } = useClients();

  const toggleClient = (clientId: ClientType['id']) => {
    setClientIds(prev => {
      if (prev.includes(clientId)) {
        return prev.filter(m => m !== clientId);
      } else {
        return [...prev, clientId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.client}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(clientIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {clientIds.length > 0
            ? clientIds
                .map(
                  clientId => clients?.find(({ id }) => id === clientId)?.name
                )
                .join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {clients?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={clientIds.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleClient(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Client);
