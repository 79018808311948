import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

import { searchInputTheme } from './SearchInput';

export const Wrapper = styled.div<{
  theme: (typeof searchInputTheme)[number];
}>`
  border-radius: ${layout.borderRadius.small}rem;
  height: 72rem;
  display: flex;
  align-items: center;
  padding: 0 40rem;
  ${({ theme: { locale } }) => setTypography('body5', locale)}
  cursor: text;
  transition: box-shadow 0.1s ease-in-out;

  ${({ theme }) =>
    theme === 'light'
      ? css`
          color: ${colors.outerSpace};
          border: 1px solid ${rgba(colors.outerSpace, 0.15)};
        `
      : ''};

  ${({ theme }) =>
    theme === 'dark'
      ? css`
          color: ${rgba(colors.white, 0.4)};
          border: 1px solid ${rgba(colors.white, 0.15)};
        `
      : ''};

  &:focus-within {
    ${focusVisible};
  }
`;

export const StartIcon = styled.div`
  width: 24rem;
  height: 24rem;
  margin-right: 20rem;
`;

export const ClearIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33rem;
  height: 33rem;
  border-radius: 50%;
  background: ${colors.outerSpace};
  cursor: pointer;
  color: ${colors.white};
  padding: 7rem;
`;
