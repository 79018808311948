import { Table } from '@tanstack/react-table';
import { FC, useCallback } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { ToolbarButton } from 'components/ShadTable/Table';
import { useProjectAccess } from 'hooks/useProjectAccess';
import {
  useAddProjectBudgetEntry,
  useDeleteProjectBudgetEntry,
} from 'queries/budgetEntries';
import { useCurrentProject } from 'queries/project';
import { useCopyStore } from 'store';
import { BudgetEntry } from 'utils/api.types';

import { emptyBudgetEntry } from '../../BudgetModal';

export interface BudgetTableToolbarProps {
  table: Table<BudgetEntry>;
}

const BudgetTableToolbar: FC<BudgetTableToolbarProps> = ({ table }) => {
  const copy = useCopyStore(s => s.copy);
  const { mutateAsync: addProjectBudgetEntry } = useAddProjectBudgetEntry();
  const { mutateAsync: deleteProjectBudgetEntry } =
    useDeleteProjectBudgetEntry();
  const currentProject = useCurrentProject();
  const { getCanEditProject } = useProjectAccess();
  const canEditProject = getCanEditProject(currentProject);
  const currentVersionId = useCurrentVersionId();

  const handleToolbarAdd = useCallback(() => {
    if (currentProject && currentVersionId) {
      addProjectBudgetEntry({
        projectId: currentProject?.id,
        projectVersionId: currentVersionId,
        budgetEntry: {
          ...emptyBudgetEntry,
        },
      });
    }
  }, [currentProject, currentVersionId, addProjectBudgetEntry]);

  const handleToolbarDuplicate = useCallback(async () => {
    const item = table.getSelectedRowModel().rows[0].original;
    if (currentProject?.id && currentVersionId) {
      await addProjectBudgetEntry({
        projectId: currentProject?.id,
        projectVersionId: currentVersionId,
        budgetEntry: {
          invoice_amount: item.invoice_amount,
          label: item.label,
          terms: item.terms,
          date: item.date,
          invoice_currency: item.invoice_currency,
          payment_status: item.payment_status,
          po_number: item.po_number,
          ...(item?.row_no !== undefined && {
            row_no: item?.row_no + 1,
          }),
        },
      });
      table.resetRowSelection();
    }
  }, [addProjectBudgetEntry, currentProject?.id, currentVersionId, table]);

  const handleToolbarDelete = useCallback(async () => {
    const item = table.getSelectedRowModel().rows[0].original;
    if (currentProject?.id && currentVersionId) {
      await deleteProjectBudgetEntry({
        projectId: currentProject?.id,
        projectVersionId: currentVersionId,
        budgetEntryId: item.row_no,
      });
      table.resetRowSelection();
    }
  }, [currentProject?.id, currentVersionId, deleteProjectBudgetEntry, table]);

  return (
    <>
      <ToolbarButton
        disabled={!canEditProject}
        onClick={handleToolbarAdd}
        data-cy="budget-modal__add-button"
      >
        {copy.app.toolbarTooltips.add}
      </ToolbarButton>
      <ToolbarButton
        disabled={
          !canEditProject || table.getSelectedRowModel().rows.length !== 1
        }
        onClick={handleToolbarDuplicate}
        data-cy="budget-modal__duplicate-button"
      >
        {copy.app.toolbarTooltips.duplicate}
      </ToolbarButton>
      <ToolbarButton
        disabled={
          !canEditProject || table.getSelectedRowModel().rows.length !== 1
        }
        onClick={handleToolbarDelete}
        data-cy="budget-modal__delete-button"
      >
        {copy.app.toolbarTooltips.delete}
      </ToolbarButton>
    </>
  );
};

export default BudgetTableToolbar;
