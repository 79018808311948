import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Awaited } from 'types/helpers';
import { Resource } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';
import { ARCHIVE_KEY } from './archive';

export const RESOURCES_KEY = 'RESOURCES';

export const useResources = (options?: UseQueryOptions<Resource[]>) => {
  const getResources = useApiStore(s => s.apiClient.getResources);
  return useQuery({
    queryKey: RESOURCES_KEY,
    queryFn: async () => (await getResources()).data,
    ...options,
  });
};

export const useAddResource = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof addResource>>,
    unknown,
    Parameters<typeof addResource>[0]
  >
) => {
  const addResource = useApiStore(s => s.apiClient.addResource);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addResource>[0]) => addResource(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RESOURCES_KEY);
      },
      ...options,
    }
  );
};

export const useUpdateResource = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateResource>>,
    unknown,
    Parameters<typeof updateResource>[0]
  >
) => {
  const updateResource = useApiStore(s => s.apiClient.updateResource);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateResource>[0]) =>
      updateResource(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(RESOURCES_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
      ...options,
    }
  );
};

export const useBulkUpdateResource = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUpdateResources>>,
    unknown,
    Parameters<typeof bulkUpdateResources>[0]
  >
) => {
  const bulkUpdateResources = useApiStore(s => s.apiClient.bulkUpdateResources);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof bulkUpdateResources>[0]) =>
      bulkUpdateResources(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(RESOURCES_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
      ...options,
    }
  );
};

export const useDeleteResource = () => {
  const deleteResource = useApiStore(s => s.apiClient.deleteResource);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteResource>[0]) =>
      deleteResource(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(RESOURCES_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
