import React from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCopyStore } from 'store';

import * as AdminStyled from '../AdminItemModal.styles';

interface BrandPartialProps {
  nameField: ReturnType<typeof useField>;
}

const BrandPartial = ({ nameField }: BrandPartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const [name, setName, , nameInvalidMessage] = nameField;

  const { brandLabel } = copy.admin.editModal.brand;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={name}
          onChange={setName}
          error={nameInvalidMessage}
          required
          placeholder={brandLabel}
          dataCy="brand-partial__name"
        />
      </AdminStyled.Row>
    </>
  );
};

export default React.memo(BrandPartial);
