import styled from 'styled-components';

export const Wrapper = styled.div<{
  width: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;
