import * as Sentry from '@sentry/react';
import axios from 'axios';
import router from 'next/router';
import { useCallback } from 'react';

import { getErrorCode, knownErrorCodes, UNAUTHORIZED } from 'utils/api.helpers';
import { ROUTES } from 'utils/routes';

import { useErrorPopup } from './useErrorPopup';

export const useCommonErrorHandling = () => {
  const { showError } = useErrorPopup();
  const commonErrorHandler = useCallback(
    ({
      error,
      handledLocally,
      localHandler,
    }: {
      error: unknown;
      handledLocally: (typeof knownErrorCodes)[number][];
      localHandler: (error: unknown) => void;
    }) => {
      const isAxiosError = axios.isAxiosError(error);
      const errorCode = getErrorCode(error);
      if (errorCode && handledLocally && handledLocally.includes(errorCode)) {
        localHandler(error);
      } else if (isAxiosError && error?.response?.status === UNAUTHORIZED) {
        router.push(ROUTES.AUTO_LOGOUT);
      } else {
        showError(error);
        Sentry.captureException(error);
      }
    },
    [showError]
  );

  return {
    commonErrorHandler,
  };
};
