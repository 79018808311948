import { FC, memo, useCallback } from 'react';

import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectStatus } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface ReopenProjectModalProps {}

const ReopenProjectModal: FC<ReopenProjectModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: handleUpdateProject, isLoading: updateLoading } =
    useUpdateProject();

  const updateProject = useCallback(async () => {
    if (!currentProject) return;

    await handleUpdateProject({
      projectId: currentProject?.id,

      project: {
        status: ProjectStatus.active,
      },
    });
  }, [currentProject, handleUpdateProject]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.reopenProjectModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        color: ColorNames.nandor,
        icon: 'close',
        onClick: () => {
          closeModal('reopenProjectModal');
        },
      }}
      confirmButtonProps={{
        label: confirmButton,
        color: ColorNames.blueRibbon,
        icon: 'check',
        onClick: async () => {
          updateProject();
          closeModal('reopenProjectModal');
        },
        dataCy: 'change-category-confirm',
        disabled: updateLoading,
        loading: updateLoading,
      }}
      title={title}
      description={description}
    />
  );
};

export default memo(ReopenProjectModal);
