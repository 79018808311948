import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect } from 'react';

import Button, {
  ButtonProps,
} from '@u9/bob3-shared/lib/components/Button/Button';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { ReactComponent as Close } from 'svgs/close.svg';
import { ColorNames } from 'utils/styles/theme';
import { AppThemeProvider } from 'utils/ThemeProvider';

import * as Styled from './ConfigModal.styles';

export interface ConfigModalProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  buttonProps?: ButtonProps;
  color?: ColorNames;
  onCloseClick?: () => void;
  paddingBottom?: number;
  zeroPadding?: boolean;
}

const ConfigModal = ({
  title,
  children,
  buttonProps,
  color = ColorNames.blueRibbon,
  onCloseClick,
  paddingBottom,
  zeroPadding = false,
}: ConfigModalProps) => {
  // https://github.com/radix-ui/primitives/issues/2122#issuecomment-1531430926
  useEffect(() => {
    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 0);

    return () => {
      document.body.style.pointerEvents = '';
    };
  }, []);

  return (
    <Dialog.Root defaultOpen open={true}>
      <Dialog.Portal>
        <AppThemeProvider theme="dark">
          <Styled.Overlay onClick={onCloseClick} />
          <Styled.Content>
            <Styled.TitleWrapper color={color}>
              <Styled.Title>{title}</Styled.Title>
            </Styled.TitleWrapper>

            <Styled.ContentWrapper>
              <ScrollArea>
                <Styled.ContentScroll
                  paddingBottom={paddingBottom}
                  zeroPadding={zeroPadding}
                  hasButton={!!buttonProps}
                >
                  {children}
                </Styled.ContentScroll>
              </ScrollArea>

              {buttonProps && (
                <Styled.Buttons>
                  <Button color={color} {...buttonProps} />
                </Styled.Buttons>
              )}
            </Styled.ContentWrapper>
            {onCloseClick && (
              <Styled.CloseButton
                onClick={onCloseClick}
                data-cy={'config_modal__close'}
                tabIndex={0}
              >
                <Styled.IconWrapper>
                  <Close />
                </Styled.IconWrapper>
              </Styled.CloseButton>
            )}
          </Styled.Content>
        </AppThemeProvider>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default React.memo(ConfigModal);
