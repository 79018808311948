import { rgba } from 'polished';
import { FC, memo, useMemo, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import BaseTextArea from 'components/inputs/BaseTextArea/BaseTextArea';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { usePermissions } from 'queries/permissions';
import { useAddUser, useUpdateUser, useUser } from 'queries/users';
import { useCopyStore, useGlobalStore } from 'store';
import { Permission, User } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './EditIntegration.styles';

export interface EditIntegrationProps {
  userId?: User['id'];
}

const EditIntegration: FC<EditIntegrationProps> = ({ userId }) => {
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const isNewIntegration = userId === undefined;

  const { data: user } = useUser(
    {
      id: userId,
      account_type: 'service_account',
    },
    {
      enabled: userId !== undefined,
    }
  );

  const { mutateAsync: addUser, isLoading: addLoading } = useAddUser();
  const { mutateAsync: updateUser, isLoading: updateLoading } = useUpdateUser();

  const nameValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [appName, setAppName, isAppNameValid, appNameInvalidMessage] = useField(
    user?.name ?? '',
    nameValidators
  );

  const publicRsaValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.fieldRequired,
      },
    }),
    [copy.app.validatorMessages.fieldRequired]
  );
  const [pubRsa, setPubRsa, isPubRsaValid] = useField(
    user?.public_key ?? '',
    publicRsaValidators
  );

  const previousPermissionIds = user?.permission_set?.map(({ id }) => id) ?? [];

  const [activePermissionIds, setActivePermissionIds] = useState<
    Permission['id'][]
  >(previousPermissionIds);

  const close = () => {
    closeModal('editIntegration');
  };

  const handleAdd = async () => {
    await addUser({
      name: appName,
      public_key: pubRsa,
      account_type: 'service_account',
      permission_set: activePermissionIds,
    });
    close();
  };

  const handleEdit = async () => {
    if (user) {
      const addPermission = activePermissionIds.filter(
        id => !previousPermissionIds.includes(id)
      );
      const removePermission = previousPermissionIds.filter(
        id => !activePermissionIds.includes(id)
      );

      await updateUser({
        id: user.id,
        name: appName,
        public_key: pubRsa,
        add_permission: addPermission,
        remove_permission: removePermission,
      });
      close();
    }
  };

  const { data: permissions } = usePermissions();

  const togglePermissions = (activeId: Permission['id']) => {
    if (activePermissionIds.includes(activeId)) {
      setActivePermissionIds(
        activePermissionIds.filter(prevItem => prevItem !== activeId)
      );
    } else {
      setActivePermissionIds([...activePermissionIds, activeId]);
    }
  };

  const label =
    activePermissionIds?.map(
      id => permissions?.find(item => item.id === id)?.name
    ) ?? [];

  const isFormValid = isAppNameValid && isPubRsaValid;
  const isAnyLoading = addLoading || updateLoading;

  return (
    <ConfigModal
      title={
        isNewIntegration
          ? copy.admin.editIntegrationModal.title
          : copy.admin.editIntegrationModal.editTitle
      }
      color={currentColor}
      onCloseClick={close}
      buttonProps={
        isNewIntegration
          ? {
              label: copy.admin.editIntegrationModal.cta,
              icon: 'add',
              color: currentColor,
              onClick: handleAdd,
              disabled: !isFormValid,
              loading: isAnyLoading,
            }
          : {
              label: copy.admin.editIntegrationModal.editCta,
              icon: 'check',
              color: currentColor,
              onClick: handleEdit,
              disabled: !isFormValid,
              loading: isAnyLoading,
            }
      }
    >
      <Styled.Wrapper>
        <Styled.Row>
          <FormInput
            placeholder={copy.admin.editIntegrationModal.namePlaceholder}
            required
            value={appName}
            onChange={setAppName}
            error={appNameInvalidMessage}
          />
        </Styled.Row>
        <Styled.Row>
          <Styled.FormTextArea>
            <Styled.Label>
              {copy.admin.editIntegrationModal.rsaKeyLabel}
            </Styled.Label>
            <Styled.TextAreaWrapper>
              <BaseTextArea
                value={pubRsa}
                onChange={setPubRsa}
                placeholder={copy.admin.editIntegrationModal.rsaKeyPlaceholder}
                styles={{ width: '100%' }}
              />
            </Styled.TextAreaWrapper>
          </Styled.FormTextArea>
        </Styled.Row>
        <Styled.Row>
          <Styled.DropdownWrapper>
            <Styled.DropdownLabel>
              {copy.admin.editIntegrationModal.permissionLabel}
            </Styled.DropdownLabel>
            <DropdownMenu>
              <DropdownMenuTrigger
                style={{
                  ...(label.length > 0
                    ? {}
                    : { color: rgba(colors.white, 0.5) }),
                }}
              >
                {label.length > 0
                  ? label.join(', ')
                  : copy.app.selectPlaceholder}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {permissions?.map(({ name, id }) => (
                  <DropdownMenuCheckboxItem
                    key={id}
                    onSelect={event => {
                      event.preventDefault();
                      togglePermissions(id);
                    }}
                    checked={activePermissionIds.includes(id)}
                  >
                    {name}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </Styled.DropdownWrapper>
        </Styled.Row>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditIntegration);
