import styled, { css } from 'styled-components';

import { colors, layout } from 'utils/styles/theme';

import { TableTheme } from '../Table.types';

export const Wrapper = styled.div<
  TableTheme & {
    bottomSafeArea: boolean;
    topBorderRadius: boolean;
  }
>`
  background: ${colors.white};
  flex: 1;
  height: 100%;

  ${({ topBorderRadius }) =>
    topBorderRadius
      ? css`
          border-top-left-radius: ${layout.borderRadius.large}rem;
          border-top-right-radius: ${layout.borderRadius.large}rem;
        `
      : ''}

  ${({ bottomSafeArea }) =>
    bottomSafeArea
      ? css`
          padding-bottom: 180rem;
        `
      : ''}

  ${({ tableTheme }) =>
    tableTheme === 'dark'
      ? css`
          background: transparent;
        `
      : ''}
`;
