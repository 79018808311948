import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Permission } from 'utils/api.types';

const PERMISSIONS_KEY = 'PERMISSIONS';

export const usePermissions = (options?: UseQueryOptions<Permission[]>) => {
  const getPermissions = useApiStore(s => s.apiClient.getPermissions);
  return useQuery({
    queryKey: PERMISSIONS_KEY,
    queryFn: async () => (await getPermissions()).data,
    ...options,
  });
};

export const useAddPermission = () => {
  const addPermission = useApiStore(s => s.apiClient.addPermission);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addPermission>[0]) =>
      addPermission(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERMISSIONS_KEY);
      },
    }
  );
};

export const useUpdatePermission = () => {
  const updatePermission = useApiStore(s => s.apiClient.updatePermission);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updatePermission>[0]) =>
      updatePermission(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERMISSIONS_KEY);
      },
    }
  );
};

export const useDeletePermission = () => {
  const deletePermission = useApiStore(s => s.apiClient.deletePermission);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deletePermission>[0]) =>
      deletePermission(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERMISSIONS_KEY);
      },
    }
  );
};
