import React, { useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCopyStore } from 'store';
import { replaceString } from 'utils/replace';

import { useCategoryDropdown } from './hooks/useCategoryDropdown';

import * as AdminStyled from '../AdminItemModal.styles';

interface ClientPartialProps {
  nameField: ReturnType<typeof useField>;
  emailField: ReturnType<typeof useField>;
  categoryDropdown: ReturnType<typeof useCategoryDropdown>;
}

const ClientPartial = ({
  nameField,
  emailField,
  categoryDropdown,
}: ClientPartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const {
    activeCategoryId,
    setActiveCategoryId,
    searchValue,
    setSearchValue,
    items,
    categoryToCreate,
    setCategoryToCreate,
  } = categoryDropdown;

  const [name, setName, , nameInvalidMessage] = nameField;
  const [email, setEmail, , emailInvalidMessage] = emailField;
  const [open, setOpen] = useState(false);

  const { clientLabel, emailLabel, clientCategoryLabel } =
    copy.admin.editModal.client;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={name}
          onChange={setName}
          error={nameInvalidMessage}
          required
          placeholder={clientLabel}
          dataCy="client-partial__name"
        />
        <AdminStyled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          error={emailInvalidMessage}
          placeholder={emailLabel}
          dataCy="client-partial__email"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <Combobox
          open={open}
          onOpenChange={isOpen => {
            setOpen(isOpen);
            setSearchValue('');
          }}
          label={clientCategoryLabel}
        >
          <ComboboxTrigger
            label={
              categoryToCreate ||
              items.find(({ id }) => id === activeCategoryId)?.displayName
            }
          />
          <ComboboxContentRoot>
            <ComboboxCommandRoot>
              <ComboboxInput
                value={searchValue}
                onValueChange={setSearchValue}
              />
              <ComboboxEmpty />
              <ComboboxList>
                {searchValue.length > 0 && (
                  <ComboboxItem
                    value={searchValue}
                    onSelect={() => {
                      setCategoryToCreate(searchValue);
                      setOpen(false);
                    }}
                  >
                    {replaceString(
                      copy.app.comboboxCreateButton,
                      '{name}',
                      searchValue
                    )}
                  </ComboboxItem>
                )}
                {items.map(({ displayName, id }) => (
                  <ComboboxItem
                    key={id}
                    selected={activeCategoryId === id}
                    onSelect={() => {
                      setCategoryToCreate('');
                      setActiveCategoryId(prev =>
                        prev === id ? null : id ?? null
                      );
                      setOpen(false);
                    }}
                  >
                    {displayName}
                  </ComboboxItem>
                ))}
              </ComboboxList>
            </ComboboxCommandRoot>
          </ComboboxContentRoot>
        </Combobox>
      </AdminStyled.Row>
    </>
  );
};

export default React.memo(ClientPartial);
