import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { RoleRequest } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';

export const ROLES_REQUESTS_KEY = 'ROLES_REQUESTS';

export const useRolesRequests = (options?: UseQueryOptions<RoleRequest[]>) => {
  const getRolesRequests = useApiStore(s => s.apiClient.getRolesRequests);
  return useQuery({
    queryKey: ROLES_REQUESTS_KEY,
    queryFn: async () => (await getRolesRequests()).data,
    ...options,
  });
};

export const useAddRoleRequest = () => {
  const addRoleRequest = useApiStore(s => s.apiClient.addRoleRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addRoleRequest>[0]) =>
      addRoleRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(ROLES_REQUESTS_KEY);
      },
    }
  );
};

export const useDeleteRoleRequest = () => {
  const deleteRoleRequest = useApiStore(s => s.apiClient.deleteRoleRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteRoleRequest>[0]) =>
      deleteRoleRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ROLES_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};
