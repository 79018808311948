import { FC, memo } from 'react';

import { useDeleteSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore, useGlobalStore } from 'store';
import { MfrRate } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteMfrRateColumnModalProps {
  mfrRate: MfrRate;
}

const DeleteMfrRateColumnModal: FC<DeleteMfrRateColumnModalProps> = ({
  mfrRate,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: deleteSupportedMfrRate, isLoading } =
    useDeleteSupportedMfrRate();

  const handleDecline = () => {
    closeModal('deleteMfrRateColumnModal');
  };

  const handleConfirm = async () => {
    await deleteSupportedMfrRate({ id: mfrRate.id });
    closeModal('deleteMfrRateColumnModal');
  };

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: copy.admin.deleteMfrModal.declineButton,
        onClick: handleDecline,
        color: ColorNames.nandor,
        icon: 'close',
      }}
      confirmButtonProps={{
        label: copy.admin.deleteMfrModal.confirmButton,
        color: currentColor,
        dataCy: 'delete-mfr-column-confirm',
        disabled: isLoading,
        loading: isLoading,
        icon: 'check',
        onClick: handleConfirm,
      }}
      description={replaceString(
        copy.admin.deleteMfrModal.description,
        '{name}',
        mfrRate?.code
      )}
      title={copy.admin.deleteMfrModal.title}
    />
  );
};

export default memo(DeleteMfrRateColumnModal);
