import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { JobNumberApprovedNotification as JobNumberApprovedNotificationType } from 'utils/api.types';
import { getProjectRoute } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface JobNumberApprovedNotificationProps {
  notification: JobNumberApprovedNotificationType;
}

const JobNumberApprovedNotification: FC<JobNumberApprovedNotificationProps> = ({
  notification,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.jobNumberApproved}
        replacements={[
          ['{user}', notification.extra_info.username],
          [
            '{project}',
            <Link
              href={getProjectRoute(notification.extra_info.projectId)}
              key="button"
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {notification.extra_info.projectName}
              </Styled.NotificationInlineButton>
            </Link>,
          ],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(JobNumberApprovedNotification);
