import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useCopyStore } from 'store';
import { Project } from 'utils/api.types';

import { useCanEditRndFields } from '../../hooks/useCanEditRndFields';
import { useRndDescriptionField } from '../../hooks/useRndDescriptionField';

export interface RndDescriptionProps {
  rndDescriptionField: ReturnType<typeof useRndDescriptionField>;
  project?: Project;
}

const RndDescription: FC<RndDescriptionProps> = ({
  rndDescriptionField,
  project,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { rndDescription, setRndDescription } = rndDescriptionField;

  const { canEditRndFields } = useCanEditRndFields({ project });

  return (
    <FormInput
      onChange={setRndDescription}
      value={rndDescription}
      placeholder={copy.app.projectModal.rndDescriptionLabel}
      dataCy="rnd-description"
      disabled={!canEditRndFields}
    />
  );
};

export default memo(RndDescription);
