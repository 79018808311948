import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { availableCurrencies } from '@u9/bob3-shared/lib/types/api.types';
import { useApiStore } from 'store';

export const SUPPORTED_CLIENT_RATES_KEY = 'SUPPORTED_CLIENT_RATES';

export const useSupportedClientRates = (
  options?: UseQueryOptions<typeof availableCurrencies[number][]>
) => {
  const getSupportedClientRates = useApiStore(
    s => s.apiClient.getSupportedClientRates
  );
  return useQuery({
    queryKey: SUPPORTED_CLIENT_RATES_KEY,
    queryFn: async () => (await getSupportedClientRates()).data,
    ...options,
  });
};

export const useAddSupportedClientRates = () => {
  const addSupportedClientRate = useApiStore(
    s => s.apiClient.addSupportedClientRate
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addSupportedClientRate>[0]) =>
      addSupportedClientRate(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SUPPORTED_CLIENT_RATES_KEY);
      },
    }
  );
};

export const useDeleteSupportedClientRates = () => {
  const deleteSupportedClientRate = useApiStore(
    s => s.apiClient.deleteSupportedClientRate
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteSupportedClientRate>[0]) =>
      deleteSupportedClientRate(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SUPPORTED_CLIENT_RATES_KEY);
      },
    }
  );
};
