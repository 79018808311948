import { rgba } from 'polished';
import React, { Dispatch, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { usePermissions } from 'queries/permissions';
import { useCopyStore } from 'store';
import { Permission } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as AdminStyled from '../AdminItemModal.styles';

interface UserPartialProps {
  usernameField: ReturnType<typeof useField>;
  emailField: ReturnType<typeof useField>;
  activePermissionIds: Permission['id'][];
  setActivePermissionIds: Dispatch<SetStateAction<Permission['id'][]>>;
}

const UserPartial = ({
  usernameField,
  emailField,
  activePermissionIds,
  setActivePermissionIds,
}: UserPartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const [username, setUsername, , usernameInvalidMessage] = usernameField;

  const [email, setEmail, , emailInvalidMessage] = emailField;

  const { nameLabel, emailLabel, userPermission } = copy.admin.editModal.user;

  const { data: permissions } = usePermissions();

  const togglePermissions = (activeId: Permission['id']) => {
    if (activePermissionIds.includes(activeId)) {
      setActivePermissionIds(
        activePermissionIds.filter(prevItem => prevItem !== activeId)
      );
    } else {
      setActivePermissionIds([...activePermissionIds, activeId]);
    }
  };

  const label =
    activePermissionIds?.map(
      id => permissions?.find(item => item.id === id)?.name
    ) ?? [];

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={username}
          onChange={setUsername}
          error={usernameInvalidMessage}
          required
          placeholder={nameLabel}
          dataCy="user-partial__username"
        />
        <AdminStyled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          error={emailInvalidMessage}
          required
          placeholder={emailLabel}
          dataCy="user-partial__email"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <AdminStyled.DropdownWrapper>
          <AdminStyled.Label>{userPermission}</AdminStyled.Label>
          <DropdownMenu>
            <DropdownMenuTrigger
              style={{
                ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
              }}
            >
              {label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {permissions?.map(({ name, id }) => (
                <DropdownMenuCheckboxItem
                  key={id}
                  onSelect={event => {
                    event.preventDefault();
                    togglePermissions(id);
                  }}
                  checked={activePermissionIds.includes(id)}
                >
                  {name}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </AdminStyled.DropdownWrapper>
      </AdminStyled.Row>
    </>
  );
};

export default React.memo(UserPartial);
