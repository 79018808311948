import { useCallback, useEffect, useState } from 'react';

import { useCurrentProject, useUpdateProject } from 'queries/project';

import { useDeleteLinkModal } from './useDeleteLinkModal';

export const useContractsLink = () => {
  const currentProject = useCurrentProject();
  const [isEditing, setIsEditing] = useState(false);
  const [link, setLink] = useState(currentProject?.contract_url);
  const [toolbarVisible, setToolbarVisible] = useState(false);

  const { mutateAsync: handleUpdateProject } = useUpdateProject();
  const { handleOpenDeleteModal } = useDeleteLinkModal();

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleDeleteClick = useCallback(() => {
    handleOpenDeleteModal();
  }, [handleOpenDeleteModal]);

  const handleMouseOver = useCallback(() => {
    setToolbarVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setToolbarVisible(false);
  }, []);

  const handleLinkChange = useCallback((value: string) => {
    setLink(value);
  }, []);

  const handleSave = useCallback(async () => {
    const hasChanged = link !== currentProject?.contract_url;
    if (!hasChanged) {
      setIsEditing(false);
      return;
    }

    if (currentProject?.id) {
      const response = await handleUpdateProject({
        projectId: currentProject?.id,
        project: { contract_url: link },
      });
      if (response?.data) {
        setIsEditing(false);
      }
    }
  }, [
    handleUpdateProject,
    currentProject?.id,
    currentProject?.contract_url,
    link,
  ]);

  const handleDiscard = useCallback(() => {
    setIsEditing(false);
    setLink(currentProject?.contract_url);
  }, [currentProject?.contract_url]);

  const handleOpenLink = useCallback(() => {
    if (link) {
      window.open(link, '_blank');
    }
  }, [link]);

  useEffect(() => {
    setLink(currentProject?.contract_url);
  }, [currentProject?.contract_url]);

  return {
    toolbarVisible,
    isEditing,
    link,
    handleEditClick,
    handleDeleteClick,
    handleMouseOver,
    handleMouseLeave,
    handleLinkChange,
    handleOpenLink,
    handleSave,
    handleDiscard,
  };
};
