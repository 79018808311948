import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useBrands } from 'queries/brands';
import { useCopyStore } from 'store';
import { Brand as BrandType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface BrandProps {
  brandIds: BrandType['id'][];
  setBrandIds: Dispatch<SetStateAction<BrandType['id'][]>>;
}

const Brand: FC<BrandProps> = ({ setBrandIds, brandIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: brands } = useBrands();

  const toggleBrand = (brandId: BrandType['id']) => {
    setBrandIds(prev => {
      if (prev.includes(brandId)) {
        return prev.filter(m => m !== brandId);
      } else {
        return [...prev, brandId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>
        {copy.app.projectsListFiltersModal.brand}
      </Styled.DropdownLabel>
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            ...(brandIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        >
          {brandIds.length > 0
            ? brandIds
                .map(
                  clientId => brands?.find(({ id }) => id === clientId)?.name
                )
                .join(', ')
            : copy.app.selectPlaceholder}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {brands?.map(({ name, id }) => (
            <DropdownMenuCheckboxItem
              key={id}
              checked={brandIds.includes(id)}
              onSelect={event => {
                event.preventDefault();
                toggleBrand(id);
              }}
            >
              {name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default memo(Brand);
