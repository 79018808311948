import { useCallback } from 'react';

import { useCopyStore } from 'store';
import { formatDays } from 'utils/formatters';

export const useFormatDays = () => {
  const copy = useCopyStore(s => s.copy);

  const format = useCallback(
    (days?: number) => {
      const daysSuffix = copy.app.daysSuffix;
      return formatDays(days, daysSuffix);
    },
    [copy.app.daysSuffix]
  );

  return {
    formatDays: format,
  };
};
