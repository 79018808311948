import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { ROLES_KEY, useAddRole } from 'queries/roles';
import { useCopyStore } from 'store';
import { useGlobalStore } from 'store';
import { Role } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './RoleCategoryModal.styles';

const RoleCategoryModal = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const [addedItem, setAddedItem] = useState<Role | null>(null);
  const isRolesFetching = useIsFetching(ROLES_KEY);

  const { mutateAsync: add, isLoading: addLoading } = useAddRole();

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    '',
    validators
  );

  const isAnyInvalid = useMemo(() => !isNameValid, [isNameValid]);

  const handleAddClick = useCallback(async () => {
    const response = await add({ category: name });
    const addedItem = response.data.rows.find(
      ({ category }) => category === name
    );
    setAddedItem(addedItem ?? null);
  }, [add, name]);

  const handleClose = useCallback(() => {
    closeModal('roleCategoryModal');
  }, [closeModal]);

  useEffect(() => {
    if (addedItem && !isRolesFetching) {
      setAddedItem(null);
      document
        .querySelector(`[data-id="${addedItem.row_no}"]`)
        ?.scrollIntoView({ behavior: 'smooth' });
      handleClose();
    }
  }, [addedItem, isRolesFetching, closeModal, handleClose]);

  const { title, addButton, namePlaceholder, subText } =
    copy.admin.roleCategoryModal;

  return (
    <ConfigModal
      title={title}
      color={currentColor}
      buttonProps={{
        color: currentColor,
        label: addButton,
        icon: 'add',
        onClick: handleAddClick,
        disabled: isAnyInvalid || addLoading || isRolesFetching > 0,
        loading: addLoading || isRolesFetching > 0,
        dataCy: 'category-modal__submit',
      }}
      onCloseClick={handleClose}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <FormInput
            value={name}
            onChange={setName}
            placeholder={namePlaceholder}
            required
            error={nameInvalidMessage}
            dataCy="category-modal__name"
          />
        </Styled.Row>
        <Styled.Info>{subText}</Styled.Info>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(RoleCategoryModal);
