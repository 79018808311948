import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

const modalHeight = '70vh';
const modalBottomPadding = 100 - (100 - 70) / 2;

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 3000rem;
  min-width: 2000rem;
  max-height: ${modalHeight};
  min-height: ${modalHeight};
  z-index: 10;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const TableHeaderLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('heading4', locale)};
  color: ${colors.white};
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50rem 50rem 100rem;
  justify-content: space-between;
`;

export const TableTitleEnd = styled.div`
  display: flex;
  gap: 40rem;
  align-items: center;
`;

export const TableWrapper = styled.div<{
  isDisabled: boolean;
  tableWrapperTop: number;
}>`
  display: flex;
  border-bottom: 1px solid ${rgba(colors.white, 0.1)};
  flex-direction: column;
  max-height: calc(
    ${modalBottomPadding}vh - ${({ tableWrapperTop }) => tableWrapperTop}px -
      180rem
  );
  height: calc(
    ${modalBottomPadding}vh - ${({ tableWrapperTop }) => tableWrapperTop}px -
      180rem
  );

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ''}
`;

export const RightArrowWrapper = styled.div`
  width: 100rem;
  height: auto;
  color: ${rgba(colors.white, 0.5)};
  margin: 0 auto;
`;

export const RowHeight = styled.div`
  height: 70rem;
`;

export const RowInner = styled.div<{ border: boolean }>`
  display: flex;
  width: 100%;

  ${({ border }) =>
    border
      ? css`
          border-bottom: 15rem solid ${rgba(colors.black, 1)};
        `
      : ''}
`;

export const ExpandButton = styled.div`
  border: 1px solid ${rgba(colors.white, 0.1)};
  padding: 10rem 30rem;
  border-radius: ${layout.borderRadius.normal}rem;

  display: flex;
`;

export const ExpandButtonStartHtml = styled.div`
  width: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandButtonIcon = styled.div<{ rotated: boolean }>`
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-out;

  ${({ rotated }) =>
    rotated
      ? css`
          transform: rotate(180deg);
          transform-origin: center;
        `
      : ''}
`;

export const ExpandButtonText = styled.div`
  user-select: none;
`;

export const GroupRowHeight = styled.div`
  height: 200rem;
`;

export const InfoTextWrapper = styled.div`
  color: ${rgba(colors.white, 0.5)};
  width: 100%;
`;

export const IndicatorWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 65%;
  bottom: -72rem;
  width: 20px;
  height: 9px;
  justify-content: center;
  color: ${colors.white};
  opacity: 0.2;
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  flex: 1;
  ${({ theme: { locale } }) => setTypography('body1', locale)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ToggleWrapper = styled.div`
  padding-left: 100rem;
  padding-top: 40rem;
  padding-bottom: 20rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
