import { useEffect, useMemo } from 'react';

import { PopupNotificationProps } from 'components/modals/PopupNotification/PopupNotification';
import {
  useNotificationsForPopup,
  useUpdateNotifications,
} from 'queries/notifications';
import { useGlobalStore } from 'store';
import { sortDates } from 'utils/sort';

export const usePopupNotifications = () => {
  const { data: notifications } = useNotificationsForPopup();
  const { mutateAsync: updateNotifications } = useUpdateNotifications();
  const openModal = useGlobalStore(s => s.openModal);

  const unseenNotifications = useMemo(
    () => notifications?.filter(notification => !notification.seen) ?? [],
    [notifications]
  );

  useEffect(() => {
    if (unseenNotifications.length > 0) {
      const mostRecentNotification = [...unseenNotifications].sort((a, b) =>
        sortDates(a.time, b.time, 'asc')
      )[0];
      updateNotifications(
        unseenNotifications.map(notification => ({
          id: notification.id,
          seen: true,
        }))
      );
      openModal<PopupNotificationProps>('popupNotification', {
        notification: mostRecentNotification,
      });
    }
  }, [
    openModal,
    unseenNotifications,
    unseenNotifications.length,
    updateNotifications,
  ]);
};
