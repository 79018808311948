import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import { useDeleteProjectVersion, useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectVersion } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { getProjectRoute } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteProjectVersionModalProps {
  projectId: Project['id'];
  versionId: ProjectVersion['id'];
}

const DeleteProjectVersionModal: FC<DeleteProjectVersionModalProps> = ({
  projectId,
  versionId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const router = useRouter();

  const { data: project } = useProject({
    projectId,
  });
  const projectVersion = project?.versions?.find(({ id }) => id === versionId);
  const projectVersionName = projectVersion?.name;

  const { mutateAsync: deleteProjectVersion, isLoading } =
    useDeleteProjectVersion();

  const handleConfirm = async () => {
    if (project) {
      await deleteProjectVersion({ projectVersionId: versionId, projectId });
      router.push(getProjectRoute(projectId, project.main_version_id));
      closeModal('deleteProjectVersion');
    }
  };

  const modalCopy = copy.app.deletePitchModal;

  return (
    <ConfirmModal
      title={modalCopy.title}
      description={replaceString(
        modalCopy.description,
        '{name}',
        projectVersionName || ''
      )}
      cancelButtonProps={{
        label: modalCopy.cancelButton,
        color: ColorNames.nandor,
        onClick: () => closeModal('deleteProjectVersion'),
        icon: 'close',
      }}
      confirmButtonProps={{
        label: modalCopy.confirmButton,
        color: currentColor,
        onClick: handleConfirm,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading,
      }}
    />
  );
};

export default memo(DeleteProjectVersionModal);
