import { FC, memo } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useBrandRequests, useDeleteBrandRequest } from 'queries/brandRequests';
import {
  useClientRequests,
  useDeleteClientRequest,
} from 'queries/clientRequests';
import { useCopyStore, useGlobalStore } from 'store';
import { BrandRequest, ClientRequest } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './RequestDeclineConfirmation.styles';

export type RequestDeclineConfirmationProps =
  | {
      clientRequestId: ClientRequest['id'];
      brandRequestId?: never;
    }
  | {
      clientRequestId?: never;
      brandRequestId: BrandRequest['id'];
    };

const RequestDeclineConfirmation: FC<RequestDeclineConfirmationProps> = ({
  clientRequestId,
  brandRequestId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const [message, setMessage] = useField('');

  const { data: clientRequests } = useClientRequests({
    enabled: !!clientRequestId,
  });
  const clientRequest = clientRequests?.find(
    ({ id }) => id === clientRequestId
  );

  const { data: brandRequests } = useBrandRequests({
    enabled: !!brandRequestId,
  });
  const brandRequest = brandRequests?.find(({ id }) => id === brandRequestId);

  const { mutateAsync: deleteClientRequest, isLoading: deleteClientLoading } =
    useDeleteClientRequest();
  const { mutateAsync: deleteBrandRequest, isLoading: deleteBrandLoading } =
    useDeleteBrandRequest();

  const close = () => {
    closeModal('requestDeclineConfirmation');
  };

  const handleConfirmClick = async () => {
    if (clientRequestId) {
      await deleteClientRequest({
        id: clientRequestId,
        type: 'rejected',
        ...(message ? { message } : {}),
      });
    } else if (brandRequestId) {
      await deleteBrandRequest({
        id: brandRequestId,
        type: 'rejected',
        ...(message ? { message } : {}),
      });
    }
    close();
  };

  const isLoading = deleteClientLoading || deleteBrandLoading;

  return (
    <ConfigModal
      title={copy.app.requestDeclineConfirmationModal.title}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Description>
          {replaceString(
            copy.app.requestDeclineConfirmationModal.description,
            '{name}',
            clientRequest?.name || brandRequest?.name || ''
          )}
        </Styled.Description>
        <Styled.Field>
          <FormInput
            value={message}
            onChange={setMessage}
            placeholder={copy.app.requestDeclineConfirmationModal.messageField}
          />
        </Styled.Field>
        <Styled.Buttons>
          <Button
            disabled={isLoading}
            label={copy.app.requestDeclineConfirmationModal.cancelButton}
            icon={'close'}
            color={ColorNames.nandor}
            onClick={close}
          />
          <Button
            disabled={isLoading}
            label={copy.app.requestDeclineConfirmationModal.confirmButton}
            icon={'check'}
            color={currentColor}
            loading={isLoading}
            onClick={handleConfirmClick}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(RequestDeclineConfirmation);
