import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('body5', locale)}
  color: ${rgba(colors.white, 0.5)};
`;

export const Row = styled.div<{ hasMorePadding?: boolean }>`
  display: flex;
  margin-bottom: 50rem;

  ${({ hasMorePadding }) =>
    hasMorePadding
      ? css`
          margin-bottom: 90rem;
        `
      : ''}
`;

export const FieldSpace = styled.div`
  width: 50rem;
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme: { locale } }) => setTypography('body5', locale)}
  color: ${rgba(colors.white, 0.5)};
  border-bottom: 1px solid ${rgba(colors.white, 0.15)};
  padding-bottom: 15rem;
  margin-top: 32rem;
`;

export const AdditionalInfoLabel = styled.div``;

export const AdditionalInfoValue = styled.div``;

export const AdditionalInfoSpace = styled.div`
  height: 15rem;
`;

export const ProjectInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled.div`
  height: 900rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 1500rem;
  padding: 30rem;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150rem;
`;

export const FormDropdownWrapper = styled.div`
  width: 33%;
  flex: 1;
  display: flex;
  position: relative;
`;

export const SwitchWrapper = styled.div``;

export const DropdownFieldLabelWrapper = styled.div`
  display: inline-flex;
`;

export const FormDropdownLabelWrapper = styled.div`
  display: flex;
`;

export const AccordionContent = styled.div`
  padding: 30rem;
`;
