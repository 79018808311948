import _groupBy from 'lodash/groupBy';
import _isString from 'lodash/isString';

import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { ProjectRow, ResourceType } from 'utils/api.types';

import { useProjectResources } from '../../hooks/useProjectResources';
import { ResourceTypes } from '../../ResourceDropdown/ResourceDropdown';
import DropdownRowItem from '../DropdownRowItem/DropdownRowItem';

export const useDropdownItems = () => {
  const projectResourcesAndVendors = useProjectResources();
  const copy = useCopyStore(s => s.copy);
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const rows = currentProjectVersion?.rows ?? [];

  const dumpLabel = copy.app.reconcileSuccessModal.other;

  const rowGroups = _groupBy(
    rows
      .filter(({ type }) => type !== 'spacer')
      .filter(
        ({ resource_type }) =>
          resource_type &&
          [ResourceType.external, ResourceType.mfr].includes(resource_type)
      ),
    row => {
      const hasResource = !!row?.resource?.id;
      const hasVendor = !!row?.vendor?.id;
      const hasDescription = !!row?.description;

      if (hasResource) {
        return (
          projectResourcesAndVendors.find(
            ({ type, id }) =>
              type === ResourceTypes.resource && id === row?.resource?.id
          )?.name || dumpLabel
        );
      } else if (hasVendor) {
        return (
          projectResourcesAndVendors.find(
            ({ type, id }) =>
              type === ResourceTypes.vendor && id === row?.vendor?.id
          )?.name || dumpLabel
        );
      } else if (hasDescription) {
        return (
          projectResourcesAndVendors.find(
            ({ type, id }) =>
              type === ResourceTypes.expense && id === row?.description
          )?.name || dumpLabel
        );
      }
      return dumpLabel;
    }
  );
  const rowsWithGroupsSorted = Object.entries(rowGroups)
    .sort(([a], [b]) => {
      if (a === dumpLabel) {
        return 1;
      }
      if (b === dumpLabel) {
        return -1;
      }
      return 0;
    })
    .flat()
    .flat();

  const dropdownItems = [
    ...rowsWithGroupsSorted.map((item, index, arr) => {
      if (_isString(item)) {
        return {
          id: item,
          displayName: item,
          groupItem: true,
        };
      }
      const rowItem = item as ProjectRow;
      return {
        id: rowItem.id,
        displayName: <DropdownRowItem item={rowItem} />,
        divider: _isString(arr[index + 1]),
        groupItem: false,
      };
    }),
  ];

  return dropdownItems;
};
