import { FC, memo } from 'react';

import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { BobAccessGrantedNotification as BobAccessGrantedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface BobAccessGrantedNotificationProps {
  notification: BobAccessGrantedNotificationType;
}

const BobAccessGrantedNotification: FC<
  BobAccessGrantedNotificationProps
> = () => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {copy.app.notifications.bobAccessGranted}
    </Styled.Wrapper>
  );
};

export default memo(BobAccessGrantedNotification);
