import { useCallback } from 'react';

import { useOrganization } from 'queries/organizations';
import { useCopyStore } from 'store';
import { formatMoney } from 'utils/formatters';

export const useFormatMoney = () => {
  const locale = useCopyStore(s => s.locale);
  const { data: organization } = useOrganization();

  const formatMoneyShort = useCallback(
    (money?: number) =>
      formatMoney(money, organization?.currency, locale, { precision: 0 }),
    [locale, organization?.currency]
  );
  const formatMoneyLong = useCallback(
    (money?: number) => formatMoney(money, organization?.currency, locale),
    [locale, organization?.currency]
  );

  return {
    formatMoneyShort,
    formatMoney: formatMoneyLong,
  };
};
