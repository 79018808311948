import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ClientRequest } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';

export const CLIENT_REQUESTS_KEY = 'CLIENT_REQUESTS';

export const useClientRequests = (
  options?: UseQueryOptions<ClientRequest[]>
) => {
  const getClientRequests = useApiStore(s => s.apiClient.getClientRequests);
  return useQuery({
    queryKey: CLIENT_REQUESTS_KEY,
    queryFn: async () => (await getClientRequests()).data,
    ...options,
  });
};

export const useAddClientRequest = () => {
  const addClientRequest = useApiStore(s => s.apiClient.addClientRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addClientRequest>[0]) =>
      addClientRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
        queryClient.invalidateQueries(CLIENT_REQUESTS_KEY);
      },
    }
  );
};

export const useDeleteClientRequest = () => {
  const deleteClientRequest = useApiStore(s => s.apiClient.deleteClientRequest);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteClientRequest>[0]) =>
      deleteClientRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLIENT_REQUESTS_KEY);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};
