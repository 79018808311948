import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  padding-bottom: 40rem;

  ${({ theme: { locale } }) => setTypography('body4', locale)}
`;

export const NotificationInlineButton = styled.span<{ underlined: boolean }>`
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;

  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
`;

export const NotificationItemList = styled.ul`
  padding-top: 20rem;
  list-style: circle;
  list-style-position: inside;
`;

export const NotificationItemListItem = styled.li`
  display: list-item;
  padding-left: 5rem;
`;
