import styled from 'styled-components';

import { setVisibility } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  pointer-events: none;
  width: 32rem;
  height: 32rem;
`;

export const ErrorIcon = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  display: inline-flex;

  ${({ isActive }) => setVisibility(isActive)}
  ${({ isActive }) =>
    !isActive
      ? `
    > div:nth-child(1),
    > div:nth-child(2),
    > div:nth-child(3) {
      animation: none;
    }
  `
      : ''}
`;

export const IconWrapper = styled.div`
  width: 32rem;
  height: 32rem;
  color: ${colors.sunsetOrange};
`;
