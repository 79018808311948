import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 20rem;
`;
