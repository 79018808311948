import React from 'react';

import { ColorNames } from 'utils/styles/theme';

import { BadgeProps } from './Badge.types';

import * as Styled from './Badge.styles';

const Badge = ({
  children,
  variant = 'contained',
  color = ColorNames.outerSpace,
  textColor = ColorNames.white,
  opacity = 1,
  styles,
}: BadgeProps) => {
  return (
    <Styled.Wrapper
      variant={variant}
      color={color}
      textColor={textColor}
      opacity={opacity}
      style={styles}
    >
      {children}
    </Styled.Wrapper>
  );
};

export default Badge;
