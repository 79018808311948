import { FC } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useGetColumnById } from 'components/Table/hooks/useGetColumnById';
import { useGetColumnWidth } from 'components/Table/hooks/useGetColumnWidth';
import Row from 'components/Table/Row/Row';
import TBodyCell from 'components/Table/TBodyCell/TBodyCell';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useCopyStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ReconcileItem as ReconcileItemType } from 'utils/api.types';
import { replaceString } from 'utils/replace';

import { ColumnId, useColumns } from '../hooks/useColumns';
import { useGetColorMatch } from '../hooks/useGetColorMatch';
import { useProjectResources } from '../hooks/useProjectResources';
import { ResourceTypes } from '../ResourceDropdown/ResourceDropdown';
import RowDropdown, { RowDropdownProps } from '../RowDropdown/RowDropdown';

import * as Styled from '../ReconcileModal.styles';

export interface ReconcileItemProps {
  item: ReconcileItemType;
  handleRowSelected: RowDropdownProps['onRowSelected'];
  isLastItem: boolean;
  isExpenseItem: boolean;
  overspentRowIds: number[];
  crossItemGroupOverspendRowIds: number[];
  view: 'JAS' | 'float';
}
7;
const ReconcileItem: FC<ReconcileItemProps> = ({
  item,
  handleRowSelected,
  isLastItem,
  isExpenseItem,
  overspentRowIds,
  crossItemGroupOverspendRowIds,
  view,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { formatMoney } = useFormatMoney();
  const { columns, rowDropdownTotalFlex } = useColumns({
    type: view,
  });
  const { getMatchColor } = useGetColorMatch({ overspentRowIds });
  const { getColumnById } = useGetColumnById(columns);
  const { getColumnWidth } = useGetColumnWidth();
  const projectResources = useProjectResources();

  const { SvgIcon: LongArrowright } = useSvgIcon('transition_arrow_right');

  const resourceId = item.resource_id;
  const vendorId = item.vendor_id;
  const description = item.description;

  const resource = resourceId
    ? projectResources.find(
        ({ type, id }) => type === ResourceTypes.resource && id === resourceId
      )?.name
    : vendorId
    ? projectResources.find(
        ({ type, id }) => type === ResourceTypes.vendor && id === vendorId
      )?.name
    : description
    ? description
    : '-';

  return (
    <Row
      key={item.jas_id}
      background={getMatchColor(item).secondary}
      hover={false}
    >
      <Styled.RowInner border={isLastItem}>
        <Styled.RowHeight />
        <TBodyCell
          align={getColumnById(ColumnId.id).align || 'left'}
          width={getColumnWidth(getColumnById(ColumnId.id).flex, columns)}
          background={getMatchColor(item).primary}
        >
          <OverflowEllipsis text={item.jas_id} />
        </TBodyCell>
        <TBodyCell
          align={getColumnById(ColumnId.accItem).align || 'left'}
          width={getColumnWidth(getColumnById(ColumnId.accItem).flex, columns)}
        >
          <Styled.InfoTextWrapper>
            <OverflowEllipsis
              text={
                isExpenseItem
                  ? replaceString(
                      copy.app.reconcileSuccessModal.expenseGroupKey,
                      '{name}',
                      item.acc_item
                    )
                  : item.acc_item
              }
            />
          </Styled.InfoTextWrapper>
        </TBodyCell>
        <TBodyCell
          align={getColumnById(ColumnId.description).align || 'left'}
          width={getColumnWidth(
            getColumnById(ColumnId.description).flex,
            columns
          )}
        >
          <OverflowEllipsis text={item.jas_description} />
        </TBodyCell>
        <TBodyCell
          align={getColumnById(ColumnId.amount).align || 'left'}
          width={getColumnWidth(getColumnById(ColumnId.amount).flex, columns)}
        >
          <OverflowEllipsis text={formatMoney(item.jas_amount)} />
        </TBodyCell>
        <TBodyCell
          align={getColumnById(ColumnId.divider).align || 'left'}
          width={getColumnWidth(getColumnById(ColumnId.divider).flex, columns)}
        >
          <Styled.RightArrowWrapper>
            <LongArrowright />
          </Styled.RightArrowWrapper>
        </TBodyCell>
        <TBodyCell
          align={getColumnById(ColumnId.resource).align || 'left'}
          width={getColumnWidth(getColumnById(ColumnId.resource).flex, columns)}
        >
          <Styled.InfoTextWrapper>{resource}</Styled.InfoTextWrapper>
        </TBodyCell>
        <TBodyCell width={getColumnWidth(rowDropdownTotalFlex, columns)}>
          <RowDropdown
            items={[item]}
            onRowSelected={handleRowSelected}
            crossItemGroupOverspendRowIds={crossItemGroupOverspendRowIds}
          />
        </TBodyCell>
      </Styled.RowInner>
    </Row>
  );
};

export default ReconcileItem;
