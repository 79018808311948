import { FC, memo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useProjects } from 'queries/project';
import { useCopyStore, usePerformanceDashboardStore } from 'store';

export interface SimilarProjectsFieldProps {}

const SimilarProjectsField: FC<SimilarProjectsFieldProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const projectsQuery = useProjects({ select: 'all', limit: 0, page: 0 });
  const projects = projectsQuery.data?.data;
  const similarProjectId = usePerformanceDashboardStore(
    s => s.similarProjectId
  );
  const setSimilarProjectId = usePerformanceDashboardStore(
    s => s.setSimilarProjectId
  );

  const items = projects?.map(project => ({
    displayName: `${project?.job_number ? `${project.job_number} -` : ''}${
      project.name
    }`,
    id: project.id,
  }));

  const [open, setOpen] = useState(false);

  return (
    <Combobox
      open={open}
      onOpenChange={setOpen}
      label={copy.performanceDashboard.filtersModal.rolesMix.similarProjects}
    >
      <ComboboxTrigger
        label={items?.find(({ id }) => id === similarProjectId)?.displayName}
      />
      <ComboboxContentRoot>
        <ComboboxCommandRoot>
          <ComboboxInput />
          <ComboboxEmpty />
          <ComboboxList>
            {items?.map(({ displayName, id }) => (
              <ComboboxItem
                key={id}
                selected={similarProjectId === id}
                onSelect={() => {
                  setSimilarProjectId(similarProjectId === id ? null : id);
                  setOpen(false);
                }}
              >
                {displayName}
              </ComboboxItem>
            ))}
          </ComboboxList>
        </ComboboxCommandRoot>
      </ComboboxContentRoot>
    </Combobox>
  );
};

export default memo(SimilarProjectsField);
