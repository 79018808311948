import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { PERFORMANCE_ROUTES } from 'utils/routes';

export const useCurrentPerformanceRoute = () => {
  const router = useRouter();

  const route = useMemo(
    () => router.query.performanceRoute?.[0]?.toUpperCase(),
    [router.query.performanceRoute]
  );

  return route as keyof typeof PERFORMANCE_ROUTES;
};
