import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

import { cardTypes } from './Card';

const colorsMap: Record<typeof cardTypes[number], string> = {
  error: colors.sunsetOrange,
  info: rgba(colors.white, 0.4),
  warning: colors.selectiveYellow,
  networkError: colors.sunsetOrange,
};

export const Wrapper = styled.div`
  position: relative;
  background: ${colors.outerSpace};
  border-radius: ${layout.borderRadius.big}rem;
  padding: 80rem 80rem 100rem 80rem;
  text-align: center;
  width: 730rem;
`;

export const Title = styled.div<{ type: typeof cardTypes[number] }>`
  ${({ theme: { locale } }) => setTypography('body3', locale)}
  color: ${({ type }) => colorsMap[type]};
`;

export const MainIcon = styled.div`
  position: absolute;
  top: 40rem;
  left: 40rem;
`;

export const MainIconWrapper = styled.div<{ type: typeof cardTypes[number] }>`
  width: 26rem;
  height: 26rem;
  color: ${({ type }) => colorsMap[type]};

  ${({ type }) =>
    type === 'networkError'
      ? css`
          width: 50rem;
          height: 50rem;
        `
      : ''}
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20rem;
  top: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${rgba(colors.white, 0.2)};
  width: 60rem;
  height: 60rem;
  z-index: 1;

  &:focus-visible {
    ${focusVisible};
  }
`;

export const CloseButtonIcon = styled.div`
  width: 28rem;
  height: 28rem;
  color: ${colors.white};
  cursor: pointer;

  ${hover(brightnessFilter)}
`;

export const Text = styled.div<{ hasSubText: boolean }>`
  margin: 34rem 0;
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  color: ${colors.white};

  ${({ hasSubText }) =>
    !hasSubText
      ? css`
          margin-bottom: 0;
        `
      : ''}
`;

export const SubText = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  color: ${rgba(colors.white, 0.5)};
`;
