import { FC, memo } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import AddressFields, {
  ModalAddressFieldsProps,
} from 'components/modals/AdminItemModal/AddressFields/AddressFields';
import { useCopyStore } from 'store';

import { CategoryItems } from '../EditResourceModal.types';
import { useEditResource } from '../hooks/useEditResource';

import * as Styled from '../EditResourceModal.styles';

export interface ResourcePartialProps {
  rolesDropdown: ReturnType<typeof useEditResource>['rolesDropdown'];
  companyField: ReturnType<typeof useEditResource>['companyField'];
  emailErrorMessage: ReturnType<typeof useEditResource>['emailErrorMessage'];
  emailField: ReturnType<typeof useEditResource>['emailField'];
  nameField: ReturnType<typeof useEditResource>['nameField'];
  activeTerritoryField: ReturnType<
    typeof useEditResource
  >['activeTerritoryField'];
  addressLine1: ModalAddressFieldsProps['addressLine1'];
  setAddressLine1: ModalAddressFieldsProps['setAddressLine1'];
  addressLine2: ModalAddressFieldsProps['addressLine2'];
  setAddressLine2: ModalAddressFieldsProps['setAddressLine2'];
  city: ModalAddressFieldsProps['city'];
  setCity: ModalAddressFieldsProps['setCity'];
  zipCode: ModalAddressFieldsProps['zipCode'];
  setZipCode: ModalAddressFieldsProps['setZipCode'];
  countryCode: ModalAddressFieldsProps['countryCode'];
  setCountryCode: ModalAddressFieldsProps['setCountryCode'];
}

const ResourcePartial: FC<ResourcePartialProps> = ({
  companyField,
  emailErrorMessage,
  emailField,
  rolesDropdown,
  nameField,
  activeTerritoryField,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
}) => {
  const copy = useCopyStore(s => s.copy);
  const modalCopy = copy.project.fillResourceInformation.editResourceModal;

  const { activeRoleId, rolesItems, rolesOpen, setActiveRoleId, setRolesOpen } =
    rolesDropdown;

  const [resourceName, setResourceName, , resourceNameError] = nameField;
  const [email, setEmail, , emailError] = emailField;
  const [companyName, setCompanyName] = companyField;
  const [activeTerritoryId] = activeTerritoryField;

  return (
    <>
      <Styled.Row>
        <FormInput
          value={resourceName}
          onChange={setResourceName}
          placeholder={modalCopy.resourceLabel}
          required
          error={resourceNameError}
          dataCy="edit-resource-modal__resource-name"
        />
        <Styled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          placeholder={modalCopy.emailLabel}
          required
          error={emailError || emailErrorMessage}
          dataCy="edit-resource-modal__email"
        />
      </Styled.Row>
      {activeTerritoryId === CategoryItems.External && (
        <Styled.Row>
          <FormInput
            value={companyName}
            onChange={setCompanyName}
            placeholder={modalCopy.companyLabel}
            dataCy="edit-resource-modal__company"
          />
        </Styled.Row>
      )}
      <Combobox
        open={rolesOpen}
        onOpenChange={setRolesOpen}
        label={modalCopy.roleLabel}
      >
        <ComboboxTrigger
          label={rolesItems?.find(({ id }) => id === activeRoleId)?.displayName}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput />
            <ComboboxEmpty />
            <ComboboxList>
              {rolesItems?.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeRoleId === id}
                  onSelect={() => {
                    setActiveRoleId(id ?? null);
                    setRolesOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
      <Styled.AddressFields>
        <AddressFields
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          city={city}
          setCity={setCity}
          zipCode={zipCode}
          setZipCode={setZipCode}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      </Styled.AddressFields>
    </>
  );
};

export default memo(ResourcePartial);
