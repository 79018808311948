import { useRouter } from 'next/router';
import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useParseProjectsListQueryString } from 'components/modules/ProjectsList/hooks/useParseProjectsListQueryString';
import { useCopyStore, useGlobalStore } from 'store';
import {
  Brand as BrandType,
  Client as ClientType,
  ProjectMode,
  ProjectStatus,
  Resource as ResourceType,
  Role as RoleType,
  Vendor as VendorType,
  VendorService as VendorServiceType,
  Vertical as VerticalType,
} from 'utils/api.types';
import { ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import Brand from './Brand/Brand';
import Client from './Client/Client';
import Mode from './Mode/Mode';
import MonetaryRange from './MonetaryRange/MonetaryRange';
import Resource from './Resource/Resource';
import Role from './Role/Role';
import Statuses from './Statuses/Statuses';
import Vendor from './Vendor/Vendor';
import VendorService from './VendorService/VendorService';
import Vertical from './Vertical/Vertical';

import * as Styled from './ProjectsListFiltersModal.styles';

export interface ProjectsListFiltersModalProps {}

const ProjectsListFiltersModal: FC<ProjectsListFiltersModalProps> = () => {
  const router = useRouter();
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const parsedQuery = useParseProjectsListQueryString();
  const setSearchbarValue = useGlobalStore(s => s.setSearchbarValue);

  const [budgetFrom, setBudgetFrom] = useState(
    String(parsedQuery.budgetFrom ?? '')
  );
  const [budgetTo, setBudgetTo] = useState(String(parsedQuery.budgetTo ?? ''));
  const [modes, setModes] = useState<ProjectMode[]>(parsedQuery.modes ?? []);
  const [statuses, setStatuses] = useState<ProjectStatus[]>(
    parsedQuery.statuses ?? []
  );
  const [verticalId, setVerticalId] = useState<VerticalType['id'][]>(
    parsedQuery.verticalIds ?? []
  );
  const [brandIds, setBrandIds] = useState<BrandType['id'][]>(
    parsedQuery.brandIds ?? []
  );
  const [clientIds, setClientIds] = useState<ClientType['id'][]>(
    parsedQuery.clientIds ?? []
  );
  const [resourceIds, setResourceIds] = useState<ResourceType['id'][]>(
    parsedQuery.resourceIds ?? []
  );
  const [vendorIds, setVendorIds] = useState<VendorType['id'][]>(
    parsedQuery.vendorIds ?? []
  );
  const [vendorServiceIds, setVendorServiceIds] = useState<
    VendorServiceType['id'][]
  >(parsedQuery.vendorServiceIds ?? []);
  const [roleIds, setRoleIds] = useState<RoleType['role_id'][]>(
    parsedQuery.roleIds ?? []
  );

  const handleReset = () => {
    setSearchbarValue('');
    router.push(ROUTES.PROJECTS_LIST, undefined, { shallow: true });
    closeModal('projectsListFilters');
  };

  const handleFilter = () => {
    router.push(
      {
        pathname: ROUTES.PROJECTS_LIST,
        query: {
          ...parsedQuery,
          budgetFrom,
          budgetTo,
          modes: modes.join(','),
          statuses: statuses.join(','),
          verticalIds: verticalId.join(','),
          brandIds: brandIds.join(','),
          clientIds: clientIds.join(','),
          resourceIds: resourceIds.join(','),
          vendorIds: vendorIds.join(','),
          vendorServiceIds: vendorServiceIds.join(','),
          roleIds: roleIds.join(','),
        },
      },
      undefined,
      { shallow: true }
    );
    closeModal('projectsListFilters');
  };

  return (
    <ConfigModal
      title={copy.app.projectsListFiltersModal.title}
      onCloseClick={() => closeModal('projectsListFilters')}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Row>
          <MonetaryRange
            budgetFrom={budgetFrom}
            budgetTo={budgetTo}
            setBudgetFrom={setBudgetFrom}
            setBudgetTo={setBudgetTo}
          />
        </Styled.Row>
        <Styled.Row>
          <Vertical setVerticalIds={setVerticalId} verticalIds={verticalId} />
        </Styled.Row>
        <Styled.Row>
          <Mode modes={modes} setModes={setModes} />
          <Statuses statuses={statuses} setStatuses={setStatuses} />
        </Styled.Row>
        <Styled.Row>
          <Brand brandIds={brandIds} setBrandIds={setBrandIds} />
          <Client clientIds={clientIds} setClientIds={setClientIds} />
        </Styled.Row>
        <Styled.Row>
          <Resource resourceIds={resourceIds} setResourceIds={setResourceIds} />
          <Vendor vendorIds={vendorIds} setVendorIds={setVendorIds} />
        </Styled.Row>
        <Styled.Row>
          <Role roleIds={roleIds} setRoleIds={setRoleIds} />
          <VendorService
            vendorServiceIds={vendorServiceIds}
            setVendorServiceIds={setVendorServiceIds}
          />
        </Styled.Row>
        <Styled.Buttons>
          <Button
            label={copy.app.projectsListFiltersModal.resetButton}
            color={ColorNames.nandor}
            icon="close"
            onClick={handleReset}
          />
          <Button
            label={copy.app.projectsListFiltersModal.saveButton}
            color={currentColor}
            icon="check"
            onClick={handleFilter}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ProjectsListFiltersModal);
