import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Project, ProjectComment } from 'utils/api.types';

export const PROJECT_COMMENTS_KEY = 'PROJECT_COMMENTS';

export const useProjectComments = (
  projectId?: Project['id'],
  options?: UseQueryOptions<ProjectComment[]>
) => {
  const getProjectComments = useApiStore(s => s.apiClient.getProjectComments);
  return useQuery({
    queryKey: [PROJECT_COMMENTS_KEY, String(projectId)],
    queryFn: async () =>
      projectId
        ? (await getProjectComments(projectId)).data
        : Promise.reject('Project ID is required'),
    ...options,
  });
};

export const useAddProjectComment = () => {
  const addProjectComment = useApiStore(s => s.apiClient.addProjectComment);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addProjectComment>[0]) =>
      addProjectComment(variables),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData(
          [PROJECT_COMMENTS_KEY, String(variables.projectSlug)],
          [data]
        );
      },
    }
  );
};

export const useUpdateProjectComment = () => {
  const updateComment = useApiStore(s => s.apiClient.updateComment);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateComment>[0]) =>
      updateComment(variables),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData(
          [PROJECT_COMMENTS_KEY, String(variables.projectSlug)],
          [data]
        );
      },
    }
  );
};

export const useDeleteProjectComment = () => {
  const deleteComment = useApiStore(s => s.apiClient.deleteComment);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteComment>[0]) =>
      deleteComment(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          PROJECT_COMMENTS_KEY,
          String(variables.projectSlug),
        ]);
      },
    }
  );
};
