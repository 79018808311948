import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useUpdateProjectGroup } from 'queries/projectGroups';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectGroup } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './EditProjectGroup.styles';

export interface EditProjectGroupProps {
  projectGroup: ProjectGroup;
}

const EditProjectGroup: FC<EditProjectGroupProps> = ({ projectGroup }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.fieldRequired,
      },
    }),
    [copy.app.validatorMessages.fieldRequired]
  );
  const [pitchName, setGroupName, isGroupNameValid, invalidMessage] = useField(
    projectGroup.name,
    validators
  );

  const { mutateAsync: updateProjectGroup, isLoading } =
    useUpdateProjectGroup();

  const handleSave = async () => {
    if (!isGroupNameValid) return;
    await updateProjectGroup({
      id: projectGroup.id,
      name: pitchName,
    });
    closeModal('editProjectGroup');
  };

  const modalCopy = copy.app.editProjectGroupModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading || !isGroupNameValid,
        onClick: handleSave,
      }}
      color={currentColor}
      onCloseClick={() => closeModal('editProjectGroup')}
    >
      <Styled.Wrapper>
        <FormInput
          value={pitchName}
          onChange={setGroupName}
          placeholder={modalCopy.label}
          error={!isGroupNameValid && invalidMessage ? invalidMessage : ''}
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditProjectGroup);
