import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ProjectAccessRequest } from 'utils/api.types';

export const PROJECT_ACCESS_REQUESTS_KEY = 'PROJECT_ACCESS_REQUESTS';

export const useProjectAccessRequests = (
  variables?: Parameters<typeof getProjectAccessRequests>[0],
  options?: UseQueryOptions<ProjectAccessRequest[]>
) => {
  const getProjectAccessRequests = useApiStore(
    s => s.apiClient.getProjectAccessRequests
  );
  return useQuery({
    queryKey: [
      PROJECT_ACCESS_REQUESTS_KEY,
      ...(variables?.projectId ? [variables?.projectId] : []),
      ...(variables?.history ? [variables?.history] : []),
    ],
    queryFn: async () => (await getProjectAccessRequests(variables)).data,
    ...options,
  });
};

export const useAddProjectAccessRequest = () => {
  const addProjectAccessRequest = useApiStore(
    s => s.apiClient.addProjectAccessRequest
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addProjectAccessRequest>[0]) =>
      addProjectAccessRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_ACCESS_REQUESTS_KEY);
      },
    }
  );
};
