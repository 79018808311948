import { FC, memo } from 'react';

import Logo from 'components/Logo/Logo';
import OrganizationLogo from 'components/OrganizationLogo/OrganizationLogo';
import { ROUTES } from 'utils/routes';

import * as Styled from './LogoItem.styles';

export interface LogoItemProps {}

const LogoItem: FC<LogoItemProps> = () => {
  return (
    <Styled.LogoSectionLink href={ROUTES.HOME}>
      <Styled.LogoSection>
        <Logo />
        <Styled.LogoMargin />
        <OrganizationLogo />
      </Styled.LogoSection>
    </Styled.LogoSectionLink>
  );
};

export default memo(LogoItem);
