import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
  width: 1600rem;
`;

export const Buttons = styled.div`
  gap: 40rem;
  display: flex;
  align-items: center;
  padding-top: 80rem;
  justify-content: center;
`;

export const CorrectResourceWrapper = styled.div``;

export const ResourcesToResolve = styled.div``;

export const ResourceToResolveItem = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 40rem;
  gap: 20rem;
`;

export const Resource = styled.div`
  width: 50%;
  padding-bottom: 20rem;
`;

export const ChoiceDropdown = styled.div`
  width: 50%;
`;

export const ResourcesToResolveLabel = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)};
  color: ${rgba(colors.white, 0.5)};
  padding-top: 70rem;
  padding-bottom: 10rem;
`;
