import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore, useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './AddMfrRateColumnModal.styles';

export interface AddMfrRateColumnModalProps {}

const AddMfrRateColumnModal: FC<AddMfrRateColumnModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: addMfrRate, isLoading } = useAddSupportedMfrRate();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [
    columnName,
    setColumnName,
    isColumnNameValid,
    columnNameInvalidMessage,
  ] = useField('', validators);

  const close = () => {
    closeModal('addMfrRateColumnModal');
  };

  const handleSave = async () => {
    await addMfrRate({ name: columnName });
    closeModal('addMfrRateColumnModal');
  };

  return (
    <ConfigModal
      buttonProps={{
        label: copy.admin.addMfrModal.confirmButton,
        icon: 'add',
        color: currentColor,
        dataCy: 'add-mfr-rate-confirm',
        disabled: !isColumnNameValid || isLoading,
        loading: isLoading,
        onClick: handleSave,
      }}
      title={copy.admin.addMfrModal.title}
      onCloseClick={close}
      color={currentColor}
    >
      <Styled.Wrapper>
        <FormInput
          onChange={value => setColumnName(value)}
          value={columnName}
          dataCy="add-mfr-rate-input"
          required
          error={!isColumnNameValid ? columnNameInvalidMessage : ''}
          placeholder={copy.admin.addMfrModal.mfrFieldLabel}
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(AddMfrRateColumnModal);
