import { useState } from 'react';

import { useParseProjectQueryString } from 'components/modules/Project/hooks/useParseProjectQueryString';
import { useCurrentProjectVersion } from 'queries/projectRows';

export const useQuantitySlider = () => {
  let minQuantity = Infinity;
  let maxQuantity = 0;
  const { data: projectVersion } = useCurrentProjectVersion();
  const queryString = useParseProjectQueryString();

  projectVersion?.rows.forEach(row => {
    const quantity = row.quantity;
    if (quantity < minQuantity) {
      minQuantity = Math.floor(quantity);
    }
    if (quantity > maxQuantity) {
      maxQuantity = Math.ceil(quantity);
    }
  });

  const [isQuantityDirty, setIsQuantityDirty] = useState(false);
  const [quantity, setQuantity] = useState([
    queryString.minQuantity && queryString.minQuantity >= minQuantity
      ? queryString.minQuantity
      : minQuantity,
    queryString.maxQuantity && queryString.maxQuantity <= maxQuantity
      ? queryString.maxQuantity
      : maxQuantity,
  ]);

  const onQuantityChange: typeof setQuantity = (...args) => {
    setIsQuantityDirty(true);
    setQuantity(...args);
  };

  return {
    quantity,
    setQuantity: onQuantityChange,
    minQuantity,
    maxQuantity,
    isQuantityDirty,
  };
};
