import { useSession } from 'next-auth/react';
import { useCallback } from 'react';

import { useAddProjectAccessRequest } from 'queries/projectAccessRequests';
import { Project } from 'utils/api.types';

export const useRequestPermissionAction = ({
  currentProjectId,
}: {
  currentProjectId?: Project['id'];
}) => {
  const { data: session } = useSession();
  const { mutateAsync: addProjectAccessRequest } = useAddProjectAccessRequest();

  const handleRequestPermission = useCallback(async () => {
    if (session?.user?.email && currentProjectId) {
      try {
        await addProjectAccessRequest({
          projectId: currentProjectId,
          username: session?.user?.email,
        });
      } catch (err) {}
    }
  }, [addProjectAccessRequest, currentProjectId, session?.user?.email]);

  return [handleRequestPermission];
};
