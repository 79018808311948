import { FC, memo } from 'react';

import { useProjectPermissions } from 'queries/projectPermission';
import { Project } from 'utils/api.types';

import PermittedUserListDropdown from './PermittedUserListDropdown/PermittedUserListDropdown';

import * as Styled from './PermittedUserList.styles';

export interface PermittedUserListProps {
  projectId: Project['id'];
}

const PermittedUserList: FC<PermittedUserListProps> = ({ projectId }) => {
  const { data: projectPermissions } = useProjectPermissions({ projectId });

  return (
    <Styled.Wrapper>
      {projectPermissions?.map(item => (
        <Styled.ItemWrapper key={item.username}>
          <Styled.Name>{item.username}</Styled.Name>
          <Styled.PermissionDropdown>
            <PermittedUserListDropdown item={item} projectId={projectId} />
          </Styled.PermissionDropdown>
        </Styled.ItemWrapper>
      ))}
    </Styled.Wrapper>
  );
};

export default memo(PermittedUserList);
