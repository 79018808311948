import { FC, memo } from 'react';

import * as Styled from './Counter.styles';

export interface CounterProps {
  number: number;
}

const Counter: FC<CounterProps> = ({ number }) => {
  return <Styled.Wrapper>{number}</Styled.Wrapper>;
};

export default memo(Counter);
