import { Slot } from '@radix-ui/react-slot';
import styled, { css } from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.nav`
  color: ${colors.outerSpace};
  display: flex;
  ${({ theme: { locale } }) => setTypography('body5', locale)};
`;

const item = css`
  display: flex;
  align-items: center;
  &:focus-visible {
    ${focusVisible};
  }

  &:not([aria-current='page']) {
    color: ${colors.dustyGray};

    &:hover {
      text-decoration: underline;
    }

    &::after {
      content: '';
      width: 1px;
      background: ${colors.dustyGray};
      display: block;
      transform: rotate(15deg);
      height: 1em;
      margin: 0 0.5em;
    }
  }
`;

export const SlotItem = styled(Slot)`
  ${item}
`;

export const DirectItem = styled.a`
  ${item};
`;
