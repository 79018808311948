import { FC, memo } from 'react';

import FormSlider, {
  DefaultTopHtml,
} from 'components/inputs/FormSlider/FormSlider';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useCopyStore } from 'store';

import { useTotalSlider } from './hooks/useTotalSlider';

export type TotalSliderProps = ReturnType<typeof useTotalSlider>;

const TotalSlider: FC<TotalSliderProps> = ({
  total,
  maxTotal,
  minTotal,
  setTotal,
}) => {
  const { formatMoney } = useFormatMoney();
  const copy = useCopyStore(s => s.copy);

  return (
    <FormSlider
      label={copy.app.projectFiltersModal.total}
      step={1}
      min={minTotal === Infinity ? 0 : minTotal}
      max={maxTotal === 0 ? 0 : maxTotal}
      disabled={minTotal === Infinity && maxTotal === 0}
      value={total}
      onValueChange={setTotal}
      topHtml={
        <DefaultTopHtml
          value={[formatMoney(total[0]), formatMoney(total[1])]}
        />
      }
    />
  );
};

export default memo(TotalSlider);
