import { useQuery, UseQueryOptions } from 'react-query';

import { useApiStore } from 'store';
import { CostAnalysis } from 'utils/api.types';

export const COST_ANALYSIS_KEY = 'COST_ANALYSIS';

export const useCostAnalysis = (
  variables: Parameters<typeof getCostAnalysis>[0],
  options?: UseQueryOptions<CostAnalysis[]>
) => {
  const getCostAnalysis = useApiStore(s => s.apiClient.getCostAnalysis);
  return useQuery({
    queryKey: [COST_ANALYSIS_KEY, variables.type, variables.id],
    queryFn: async () => (await getCostAnalysis(variables)).data,
    ...options,
  });
};
