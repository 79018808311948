import { useCallback } from 'react';

import { ClientReportDownloadModalProps } from 'components/modals/ClientReportDownloadModal/ClientReportDownloadModal';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useGlobalStore } from 'store';
import { Project, ProjectsListItem } from 'utils/api.types';

export const useDownloadClientReportAction = ({
  currentProject,
}: {
  currentProject?: ProjectsListItem | Project;
}) => {
  const currentVersionId = useCurrentVersionId();
  const openModal = useGlobalStore(s => s.openModal);

  const downloadAction = useCallback(async () => {
    if (currentProject) {
      // https://github.com/radix-ui/primitives/issues/2122
      setTimeout(() => {
        openModal<ClientReportDownloadModalProps>('clientReportDownloadModal', {
          project: currentProject,
          currentVersionId: currentVersionId ?? undefined,
        });
      }, 0);
    }
  }, [currentVersionId, currentProject, openModal]);

  return [downloadAction];
};
