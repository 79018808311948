import { FC, memo } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { useFormatPercent } from 'hooks/useFormatPercent';
import { useProject } from 'queries/project';
import { useProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Project, ProjectVersion, ResourceType } from 'utils/api.types';

import * as Styled from './MfrDiscountPreview.styles';

export interface MfrDiscountPreviewProps {
  discount: ProjectVersion['mfr_discount'];
  projectId: Project['id'];
  mfrNet: number;
}

const MfrDiscountPreview: FC<MfrDiscountPreviewProps> = ({
  discount,
  projectId,
  mfrNet,
}) => {
  const { data: project } = useProject({ projectId });
  const copy = useCopyStore(s => s.copy);

  const currentVersionId = useCurrentVersionId();
  const { data: currentVersion } = useProjectVersion({
    projectId,
    projectVersionId: currentVersionId ?? project?.main_version_id,
  });
  const currentRows = currentVersion?.rows ?? [];
  const budget = currentVersion?.budget;

  const { formatMoney } = useFormatMoney();
  const { formatPercent } = useFormatPercent();

  const totalCost = currentRows
    .filter(({ type }) => type === 'resource')
    .reduce((prev, { quantity, rate, resource_type }) => {
      const applyDiscount = resource_type === ResourceType.mfr;
      const undiscountedTotal = quantity * rate;
      const discountToApply = applyDiscount ? 1 - (discount ?? 0) : 1;

      return prev + undiscountedTotal * discountToApply;
    }, 0);

  const mfrTotalCostDifference =
    currentRows.reduce((prev, current) => {
      const currentInternalMfrCost =
        current.resource_type === ResourceType.mfr
          ? current.quantity * current.rate
          : 0;
      const applyDiscount = current.resource_type === ResourceType.mfr;
      const discountToApply = applyDiscount ? discount : 1;
      return prev + currentInternalMfrCost * (discountToApply ?? 0);
    }, 0) ?? 0;

  const internalTotalCost =
    currentRows
      ?.filter(({ type }) => type === 'resource')
      ?.reduce((prev, current) => {
        if (current.resource_type === ResourceType.internal) {
          return prev + current.quantity * current.rate;
        } else if (current.resource_type === ResourceType.mfr) {
          return (
            prev +
            current.quantity *
              Math.max(current.rate - (current?.resource?.rate || 0), 0)
          );
        } else {
          return prev;
        }
      }, 0) ?? 0 - mfrTotalCostDifference;

  const margin = (budget?.total_budget ?? 0) - (totalCost ?? 0);
  const marginPercent = margin / (budget?.total_budget ?? 0);

  const previewCopy = copy.app.mfrDiscountModal.preview;

  return (
    <Styled.Wrapper>
      <Styled.PreviewTitle>{previewCopy.title}</Styled.PreviewTitle>
      <Styled.ContentWrapper>
        <Styled.Row>
          <Styled.Label>{previewCopy.margin}</Styled.Label>
          <Styled.Value>{formatMoney(margin)}</Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>{previewCopy.marginPercent}</Styled.Label>
          <Styled.Value>{formatPercent(marginPercent * 100)}</Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>{previewCopy.mfrNet}</Styled.Label>
          <Styled.Value>{formatMoney(mfrNet)}</Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>{previewCopy.internalCost}</Styled.Label>
          <Styled.Value>{formatMoney(internalTotalCost)}</Styled.Value>
        </Styled.Row>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

export default memo(MfrDiscountPreview);
