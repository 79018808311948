import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import {
  useAddVendorService,
  useUpdateVendorService,
  VENDOR_SERVICES_KEY,
} from 'queries/vendorServices';
import { useCopyStore } from 'store';
import { useGlobalStore } from 'store';
import { VendorService } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './VendorServiceModal.styles';

export interface VendorServiceModalProps {
  vendorService?: VendorService;
}

const VendorServiceModal = ({ vendorService }: VendorServiceModalProps) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const isFetchingVendorServices = useIsFetching(VENDOR_SERVICES_KEY);
  const [closing, setClosing] = useState(false);
  const isEditing = useMemo(() => !!vendorService, [vendorService]);

  const { mutateAsync: update, isLoading: updateLoading } =
    useUpdateVendorService();
  const { mutateAsync: add, isLoading: addLoading } = useAddVendorService();

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    vendorService?.name ? vendorService.name : '',
    validators
  );
  const [aliases, setAliases] = useField(
    vendorService?.aliases?.join(', ') || ''
  );
  const transformedAliases = useMemo(
    () =>
      aliases
        ?.split(',')
        .map(alias => alias.trim())
        .filter(Boolean),
    [aliases]
  );

  const isAnyInvalid = useMemo(() => !isNameValid, [isNameValid]);

  const handleEditClick = useCallback(async () => {
    if (!vendorService?.id) return;
    await update({
      id: vendorService.id,
      name,
      aliases: transformedAliases,
    });
    setClosing(true);
  }, [update, vendorService?.id, name, transformedAliases]);

  const handleClose = useCallback(() => {
    closeModal('vendorServiceModal');
  }, [closeModal]);

  const handleAddClick = useCallback(async () => {
    await add({
      name,
      aliases: transformedAliases,
    });
    setClosing(true);
  }, [add, name, transformedAliases]);

  useEffect(() => {
    if (closing && !isFetchingVendorServices) {
      handleClose();
    }
  }, [closing, handleClose, isFetchingVendorServices]);

  const {
    title,
    editTitle,
    addButton,
    editButton,
    namePlaceholder,
    aliasesPlaceholder,
  } = copy.admin.vendorServiceModal;

  return (
    <ConfigModal
      title={isEditing ? editTitle : title}
      color={currentColor}
      buttonProps={{
        color: currentColor,
        label: isEditing ? editButton : addButton,
        icon: isEditing ? 'check' : 'add',
        onClick: isEditing ? handleEditClick : handleAddClick,
        disabled:
          isAnyInvalid ||
          addLoading ||
          updateLoading ||
          isFetchingVendorServices > 0,
        loading: addLoading || updateLoading || isFetchingVendorServices > 0,
        dataCy: 'vendor-service-modal-save',
      }}
      onCloseClick={handleClose}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <FormInput
            value={name}
            onChange={setName}
            placeholder={namePlaceholder}
            required
            error={nameInvalidMessage}
            dataCy="vendor-service-modal__name"
          />
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={aliases}
            onChange={setAliases}
            placeholder={aliasesPlaceholder}
          />
        </Styled.Row>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(VendorServiceModal);
