import { useQuery, UseQueryOptions } from 'react-query';

import { useApiStore } from 'store';
import { RolesSuggestions } from 'utils/api.types';

export const ROLES_SUGGESTIONS_KEY = 'ROLES_SUGGESTIONS';

export const useRolesSuggestions = (
  variables?: Parameters<typeof getRolesSuggestions>[0],
  options?: UseQueryOptions<RolesSuggestions>
) => {
  const getRolesSuggestions = useApiStore(s => s.apiClient.getRolesSuggestions);
  return useQuery({
    queryKey: [ROLES_SUGGESTIONS_KEY, variables?.searchValue],
    queryFn: async () =>
      variables?.searchValue !== undefined
        ? (await getRolesSuggestions({ searchValue: variables.searchValue }))
            .data
        : Promise.reject('searchValue is undefined'),
    ...options,
  });
};
