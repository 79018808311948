import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import React, { useCallback, useMemo } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCopyStore } from 'store';
import { generateRandomString } from 'utils/string';

import * as Styled from './Menu.styles';

enum MenuIds {
  LOGOUT_ID = 'LOGOUT',
  FAQ_ID = 'FAQ',
  FEEDBACK_ID = 'FEEDBACK',
}

const Menu = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const { data: session } = useSession();

  const items = useMemo(
    () => [
      { id: MenuIds.FAQ_ID, displayName: copy.app.menuItems.faq },
      {
        id: MenuIds.FEEDBACK_ID,
        displayName: copy.app.menuItems.feedback,
        divider: true,
      },
      { id: MenuIds.LOGOUT_ID, displayName: copy.app.menuItems.logout },
    ],
    [copy.app]
  );

  const handleClick = useCallback(
    async (id: MenuIds) => {
      if (id === MenuIds.FAQ_ID) {
        window.open(copy.app.FAQ.link, '_blank');
      }

      if (id === MenuIds.FEEDBACK_ID) {
        const eventId = Sentry.captureMessage(
          `User feedback (${generateRandomString()})`
        );
        Sentry.showReportDialog({
          eventId: eventId,
          labelSubmit: copy.app.feedbackDialog.labelSubmit,
          title: copy.app.feedbackDialog.title,
          subtitle: copy.app.feedbackDialog.subtitle,
          subtitle2: '',
          labelComments: copy.app.feedbackDialog.labelComments,
          user: {
            email: session?.user?.email || '',
            name: session?.user?.name || '',
          },
          onLoad: () => {
            const input = document.getElementById('id_comments');
            input &&
              input.setAttribute(
                'placeholder',
                copy.app.feedbackDialog.placeholderComments
              );
          },
        });
      }

      if (id === MenuIds.LOGOUT_ID) {
        await signOut({
          redirect: false,
        });
        router.reload();
      }
    },
    [copy.app, router, session?.user?.email, session?.user?.name]
  );

  return (
    <Styled.DropdownWrapper>
      <DropdownMenu>
        <DropdownMenuTrigger>{session?.user?.name}</DropdownMenuTrigger>
        <DropdownMenuContent>
          {items.map(({ displayName, id }) => (
            <DropdownMenuItem key={id} onSelect={() => handleClick(id)}>
              {displayName}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default React.memo(Menu);
