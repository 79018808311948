import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Brand } from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';

export const BRANDS_KEY = 'BRANDS';

export const useBrands = (options?: UseQueryOptions<Brand[]>) => {
  const getBrands = useApiStore(s => s.apiClient.getBrands);
  return useQuery({
    queryKey: BRANDS_KEY,
    queryFn: async () => (await getBrands()).data,
    ...options,
  });
};

export const useAddBrand = () => {
  const addBrand = useApiStore(s => s.apiClient.addBrand);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addBrand>[0]) => addBrand(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BRANDS_KEY);
      },
    }
  );
};

export const useUpdateBrand = () => {
  const updateBrand = useApiStore(s => s.apiClient.updateBrand);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateBrand>[0]) => updateBrand(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BRANDS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};

export const useDeleteBrand = () => {
  const deleteBrand = useApiStore(s => s.apiClient.deleteBrand);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteBrand>[0]) => deleteBrand(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BRANDS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
