import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Profile } from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';

export const PROFILE_KEY = 'PROFILE';

export const useProfile = (options?: UseQueryOptions<Profile>) => {
  const getProfile = useApiStore(s => s.apiClient.getProfile);
  return useQuery({
    queryKey: PROFILE_KEY,
    queryFn: async () => (await getProfile()).data,
    ...options,
  });
};

export const useUpdateProfile = () => {
  const updateProfile = useApiStore(s => s.apiClient.updateProfile);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateProfile>[0]) =>
      updateProfile(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROFILE_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
